import { useEffect, useState } from "react";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import handleWidgetColors from "./handleWidgetColors";

const CustomDashboardStackedLine = (props) => {
  const {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    widgetsMqttResponses,
    finalWidgetData,
    customizedWidgets,
    dashboardInfo,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    allMonitorsIdWidget,
    isComparison,
    customizedType,
    showControl,
  } = props;

  const [labelsColors, setLabelsColors] = useState([]);
  const [widgetFlags, setWidgetFlags] = useState({ hideLegend: true });

  useEffect(() => {
    if (widget && finalWidgetData) {
      const handledLabelsColors = handleWidgetColors(finalWidgetData?.pie_data);
      if (handledLabelsColors) setLabelsColors(handledLabelsColors);

      switch (widget?.widget_name) {
        case "comments_sentiment_analysis":
        case "sentiment_analysis":
          setWidgetFlags({
            isSentimentAnalysisWidget: true,
            isSentimentAnalysisShowCharts: true,
            hideLegend: true,
            inspectorName: "sentiment_overtime",
          });
          break;

        case "posts_reach_impressions":
        case "page_reach_impressions":
          setWidgetFlags({
            isPostsReachImpression: true,
            hideLegend: true,
          });
          break;
      }
    }
  }, [widget, finalWidgetData]);

  return (
    <StackedLineWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={widgetTitleTooltip || ""}
      data={finalWidgetData?.data || []}
      showGroupBy={!isControlWidget && !isComparison}
      areaStyle={true}
      toolTipLabel={"toolTipLabel"}
      showAI={!isControlWidget && !isComparison}
      showDownloadIcon={!isControlWidget}
      bigImg
      isSwitchCase
      isCityAnalysisWidget={isComparison}
      isTopicSection={widgetFlags?.isPostsReachImpression || isComparison}
      showScales={!isControlWidget && !isComparison}
      isSentimentAnalysisWidget={widgetFlags?.isSentimentAnalysisWidget}
      isSentimentAnalysisShowCharts={widgetFlags?.isSentimentAnalysisShowCharts}
      isPostsReachImpression={
        widgetFlags?.isPostsReachImpression && !isComparison
      }
      dataPiechart={finalWidgetData?.pie_data || []}
      hideLegend={!isControlWidget && widgetFlags?.hideLegend}
      labels={
        widgetFlags?.isSentimentAnalysisWidget
          ? undefined
          : labelsColors?.labels || []
      }
      labelColors={
        widgetFlags?.isSentimentAnalysisWidget
          ? undefined
          : labelsColors?.colors || []
      }
      colors={
        widgetFlags?.isSentimentAnalysisWidget
          ? undefined
          : labelsColors?.colors || []
      }
      stackedLineWidgetPreLoaderDataSources={
        finalWidgetData?.preLoaderMonitorsId || []
      }
      preLoaderTrackerDataSources={allMonitorsIdWidget || []}
      isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
      isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
      // The needed data in widget component for case of Custom Dashboard only
      customDashboardData={{
        dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
        handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
        widget: widget, // the widget metric returned from backend in view API
        customizedType: customizedType, // null, aggregate, comparison
        widgetsMqttResponses, // the main state for RabbitMQ responses
        customizedWidgets, // cuztomized widget state for RabbitMQ responses
        getCustomDashboardView, // call back view widgets after any update in page
        setSnackBarData, // set snackbar related to main index view
        showControl, // show widget control icon
      }}
      isComparison={isComparison}
    />
  );
};

export default CustomDashboardStackedLine;
