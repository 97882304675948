import { Box, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const RESPONSE_LENGTH_VALUES = {
  long: "Long: Offers a comprehensive and in-depth response.",
  medium: "Medium: Provides a balanced amount of detail.",
  short: "Short: Concise and to the point.",
};

const ResponseLengthOption = ({ value, selected, onClick, intl }) => (
  <Grid item xs={4}>
    <Box
      onClick={onClick}
      className={`ai-agent-response-length-item-wrapper ${selected ? "selected" : ""}`}
    >
      <Typography className="ai-agent-response-length-item-title">
        {CheckValueLocale(value, "", {}, intl)}
      </Typography>
      <Typography className="ai-agent-response-length-item-description">
        {CheckValueLocale(
          `ai_agent_response_time_${value}_description`,
          "",
          {},
          intl,
        )}
      </Typography>
    </Box>
  </Grid>
);

const AgentIdentityResponseLength = ({
  formik,
  customHandleChange,
  isFormDisabled,
}) => {
  const intl = useIntl();
  const responseLengthError = formik?.errors?.response_length;
  const responseLengthValue = formik?.values?.response_length;

  const handleChange = (value) => {
    if (isFormDisabled) return;
    customHandleChange("response_length", value);
  };

  return (
    <>
      <Grid className={`${responseLengthError ? "Mui-error" : ""}`} item xs={5}>
        <Box className="ai-agents-form-box-description" component="p">
          {CheckValueLocale("ai_agent_response_length", "", {}, intl)}
          <Box component="span" className="red-star-req">
            {` *`}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Grid alignItems="stretch" container spacing="9px">
          {Object.keys(RESPONSE_LENGTH_VALUES || {})?.map((key) => (
            <ResponseLengthOption
              key={key}
              value={key}
              selected={responseLengthValue === RESPONSE_LENGTH_VALUES[key]}
              onClick={() => handleChange(RESPONSE_LENGTH_VALUES[key])}
              intl={intl}
            />
          ))}
          {responseLengthError ? (
            <Grid item xs={12}>
              <Box component="span" className="ai-agents-form-helper-text">
                {CheckValueLocale(responseLengthError, "", {}, intl)}
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default AgentIdentityResponseLength;
