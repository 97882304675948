import { configureStore } from "@reduxjs/toolkit";
import socialListeningSlice from "../features/SocialListening/socialListeningSlice";
import channelAnalyticsSlice from "../features/ChannelAnalytics/channelAnalyticsSlice";
import explorePageSlice from "../features/ExplorePage/exploreSlice";
import engagementsSlice from "../features/Engagements/EngagementsSlice";
import manageChannels from "../features/ManageChannels/ManageChannels";
import aiAgentChatSlice from "../features/AiAgentChat/AiAgentChatSlice";

const store = configureStore({
  reducer: {
    socialListening: socialListeningSlice,
    channelAnalytics: channelAnalyticsSlice,
    explorePageSlice: explorePageSlice,
    engagements: engagementsSlice,
    manageChannels,
    aiAgentChatSlice,
  },
});

export default store;
