import { useMutation, useQueryClient } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useForceRefreshResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => AiAgentsController.forceRefresh(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["aiAgentResources"],
      });
    },
  });
};
