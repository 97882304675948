import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "shared/createHeader/createHeader";
import Steppers from "shared/steppers/steppers";
import "./addTeam.scss";
import { TeamsDetails } from "./teamsDetailsStep";
import { AccoutnsAndMonitors } from "./accoutnsAndMonitorsStep";
import { StepperFooter } from "../components/stepperFooter";
import { useHistory } from "react-router-dom";
import { useShowMore } from "hooks/useShowMore";
import { TeamSharedFuntions } from "../sharedFunctions/teamSharedFunctions";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import CompaniesController from "services/controllers/companiesController";
import { transformWorkingHours } from "./addTeamUtils";

// this component will hold all the show more state and logic for every section that has show more
// and will render the current step
const RenderCurrentStep = ({ step, ...props }) => {
  const showMoreMembersObj = useShowMore({
    data: props?.members?.data,
    pagination: props?.members?.pagination,
  });
  switch (step) {
    case 0:
      return (
        <TeamsDetails {...props} showMoreMembersObj={showMoreMembersObj} />
      );
    case 1:
      return <AccoutnsAndMonitors {...props} />;
    default:
      return null;
  }
};

const steps = ["teams_details" /*"accounts_and_monitors"]*/];

export const AddTeam = () => {
  const intl = useIntl();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const {
    teamInformation,
    setTeamInformation,
    members,
    setMembers,
    selectedMembers,
    setSelectedMembers,
    socialAccounts,
    setSocialAccounts,
    selectedSocialAccounts,
    setSelectedSocialAccounts,
    monitors,
    setMonitors,
    selectedMonitors,
    setSelectedMonitors,
    channels,
    setChannels,
    selectedChannels,
    setSelectedChannels,
    handleTeamInformationChange,
    handleSelectMember,
    handleSelectSocialAccount,
    handleSelectMonitor,
    handleSelectChannel,
    handleSaveTeam,
    initilizeTeamDetails,
    isTeamChannelsLoading,
    isTeamMemberLoading,
    isTeamMonitorsLoading,
    isTeamProductsLoading,
    isTeamSocialAccountsLoading,
    products,
    teamError,
    setTeamError,
    setWorkingHours,
    workingHours,
    tabsErrors,
    setTabsErrors,
    setAutoReplyMessages,
    autoReplyMessages,
  } = TeamSharedFuntions();
  const handleCloseSnackBar = () => {
    setTeamError({
      openSnackBar: false,
    });
  };
  const createTeam = () => {
    setIsLoading(true);
    const createTeamBody = {
      team_info: {
        name: teamInformation?.teamName,
        product_id: teamInformation?.selectedProduct,
        auto_reply_message: autoReplyMessages,
      },
      users: selectedMembers,
      working_hours: transformWorkingHours(workingHours.workingHours),
    };
    CompaniesController.createTeam(createTeamBody).then((res) => {
      if (res?.errorMsg) {
        // check if duplicate team name
        const isDuplicateTeamName =
          res?.errorMsg?.response?.data?.exception?.message ===
          "TEAM_NAME_ALREADY_EXIST";
        if (isDuplicateTeamName) {
          setTeamError({
            teamNameError: "team_name_already_exist",
          });
          // go to the top of the page
          window.scrollTo(0, 0);
        } else {
          setTeamError({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnackBar: true,
          });
        }
      } else {
        const createdTeamId = res?.data?.id;
        history.push(
          `/settings/teams/view/${createdTeamId}?status=added_successfully`,
        );
      }
      setIsLoading(false);
    });
  };
  const handleChangeStep = (step) => {
    if (step < activeStep) {
      setActiveStep(step);
    } else if (step === steps.length) {
      createTeam();
    } else if (completed[activeStep]) {
      // check if the current step is completed, this happen when click on next step from the stepper
      setActiveStep(step);
    }
  };
  const handleCancel = () => {
    // go to teams list
    history.push("/settings/teams");
  };

  useEffect(() => {
    if (activeStep === 0) {
      // check all required fields to be able to go the next step
      if (
        teamInformation?.teamName &&
        teamInformation?.teamName?.trim() &&
        teamInformation?.selectedProduct &&
        selectedMembers?.length > 0
      ) {
        setCompleted((prev) => ({ ...prev, 0: true }));
      } else {
        setCompleted({});
      }
    }
  }, [teamInformation, selectedMembers]);

  useEffect(() => {
    initilizeTeamDetails();
  }, []);
  useEffect(() => {
    // we want to set the default product to the first engagment product
    if (!products?.length) return;
    const engagment = products.find(
      (product) => product?.name === "ENGAGEMENTS",
    );
    setTeamInformation((prev) => ({
      ...prev,
      selectedProduct: engagment?.id,
    }));
  }, [products]);
  if (isTeamMemberLoading || isTeamProductsLoading) return <CircularLoading />;

  const isMaxCharactersError = autoReplyMessages?.length > 255;

  return (
    <Container maxWidth="xl" className="add-team-container">
      <Header
        mainHeaderTitle="settings"
        subHeaderTitle="teams_list"
        subHeaderLink="/settings/teams"
        mainHeaderLink="/settings"
        cardHeaderHeading="create_new_team"
        isCreateScreen
      />
      <Box className="main-container">
        {steps?.length > 1 ? (
          <Steppers
            steps={steps}
            activeStep={activeStep}
            setActiveStep={handleChangeStep}
            completed={completed}
            setCompleted={setCompleted}
          />
        ) : null}
        <Box className="step-content">
          <Container>
            <RenderCurrentStep
              step={activeStep}
              teamInformation={teamInformation}
              handleTeamInformationChange={handleTeamInformationChange}
              members={members}
              handleSelectMember={handleSelectMember}
              selectedMembers={selectedMembers}
              setMembers={setMembers}
              isProductsDisabled={true}
              selectedMonitors={selectedMonitors}
              handleSelectMonitor={handleSelectMonitor}
              selectedChannels={selectedChannels}
              handleSelectChannel={handleSelectChannel}
              products={products}
              teamNameError={teamError?.teamNameError}
              setWorkingHours={setWorkingHours}
              workingHours={workingHours}
              tabsErrors={tabsErrors}
              setTabsErrors={setTabsErrors}
              setAutoReplyMessages={setAutoReplyMessages}
              autoReplyMessages={autoReplyMessages}
              isMaxCharactersError={isMaxCharactersError}
            />
          </Container>
        </Box>
        <StepperFooter
          activeStep={activeStep}
          disableNextStep={!completed[activeStep] || teamError?.teamNameError}
          handleCancel={handleCancel}
          handleChangeStep={handleChangeStep}
          btnText={activeStep < steps.length - 1 ? "next_Step" : "create"}
          isLoading={isLoading}
          tabsErrors={tabsErrors}
          isMaxCharactersError={isMaxCharactersError}
        />
      </Box>
      <SnackBar
        open={teamError?.openSnackBar}
        severity={teamError?.severity}
        message={CheckValueLocale(teamError?.message, "", {}, intl)}
        title={CheckValueLocale(teamError?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Container>
  );
};
