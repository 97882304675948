import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Grid } from "@mui/material";
import { handlelUserRoles } from "utils/helpers";
import { useWidgetName } from "../../hooks/useWidgetName";
import { useCustomDashboardWidgetTooltip } from "../../hooks/useCustomDashboardWidgetTooltip";
import CustomDashboardStackedBarChart from "./widgetsTypes/customDashboardStackedBarChart";
import CustomDashboardMediaComponent from "./widgetsTypes/customDashboardMediaComponent";
import CustomDashboardBarChart from "./widgetsTypes/customDashboardBarChart";
import CustomDashboardPunchCard from "./widgetsTypes/customDashboardPunchCard";
import CustomDashboardLineChart from "./widgetsTypes/customDashboardLineChart";
import CustomDashboardStackedLine from "./widgetsTypes/customDashboardStackedLine";
import CustomDashboardPieChart from "./widgetsTypes/customDashboardPieChart";
import CustomDashboardTableWidget from "./widgetsTypes/customDashboardTableWidget";
import CustomDashboardThemesWidget from "./widgetsTypes/customDashboardThemesWidget";
import handleWidgetTypes from "./widgetsTypes/handleWidgetTypes";
import useEventDataByWidgetType from "./controlWidget/useEventDataByWidgetType";

// to handel list of monitors id from API to compare with RabbitMQ respone
const handelMonitorsIDList = (widget) => {
  let newListIds = [widget?.monitor_id];
  if (widget?.customized_widget) {
    widget?.customized_widget?.attributes?.monitors?.map((monitor) => {
      newListIds?.push(monitor?.id);
    });
  }
  return newListIds;
};

const WidgetComponent = (props) => {
  const {
    widget,
    dashboardInfo,
    widgetsMqttResponses,
    customizedWidgets,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    selectedType, // Null, Aggregate, Comparison
  } = props;

  const handledWidgetName = useWidgetName(
    dashboardInfo?.product, // product
    widget?.widget_name, // widget name as rabbitMQ
    widget?.monitor_type?.name, // monitor type
    widget?.dashboard_page_name, // page name
    widget?.data_source, // data source
  );

  const widgetTitleTooltip = useCustomDashboardWidgetTooltip(
    dashboardInfo?.product, // Product name as (sm, cxm)
    widget?.data_source, // Datasource
    widget?.monitor_type?.name, // Monitor Type (ACCOUNT_ANALYSIS, KEYWORD,...)
    handledWidgetName, // Widget name after adding (comments, posts) at the first or not adding
    widget?.is_dm, // In case of Private in Omnichannel returns >> TRUE <<
  );

  const allMonitorsIdWidget = handelMonitorsIDList(widget);
  const [finalWidgetData, setFinalWidgetData] = useState({});
  const [handleEventData] = useEventDataByWidgetType({}); // to handle aggregate or comparison on list of data
  const widgetTypeChart = handleWidgetTypes(dashboardInfo, widget);
  const [customizedType, setCustomizedType] = useState(null);

  useEffect(() => {
    // Only set customized type as LIVE change in case of inside control sidebar
    if (isControlWidget) {
      setFinalWidgetData({}); // To avoid passing data before comparison to each widget
      setCustomizedType(selectedType);
    } else {
      if (widget?.customized_widget !== null)
        setCustomizedType(
          widget?.customized_widget?.attributes?.type?.name?.toLowerCase(),
        );
      else setCustomizedType(null);
    }
  }, [isControlWidget, selectedType]);

  const customizedId = widget?.customized_widget?.id;

  //mainWidgetData have value of main monitor widget data if The response has arrived. {key:value , ...}
  const mainWidgetData =
    widgetsMqttResponses?.[widget?.id]?.[widget?.monitor_id]?.[
      widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
    ];

  //mainWidgetID have main monitor id if The response has arrived
  const mainWidgetID =
    widgetsMqttResponses?.[`${widget?.id}_pre_loader_data_sources`]?.[
      widget?.monitor_id
    ]?.[widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source];

  useEffect(() => {
    if (widget?.customized_widget == null && customizedType == null) {
      // to handle if widget doesn't have aggregate or comparison
      if (mainWidgetData) {
        setFinalWidgetData({
          ...mainWidgetData,
          preLoaderMonitorsId: mainWidgetID,
        });
      }
    } else {
      // handle comparison or aggregate if the response of all monitors in this customized widget has arrived
      if (mainWidgetData) {
        //MonitorsIdList to compare with list of monitors id from API
        const MonitorsIdList = [
          ...(customizedWidgets?.[customizedId]?.preLoaderMonitorsIdList !==
          undefined
            ? customizedWidgets?.[customizedId]?.preLoaderMonitorsIdList
            : []),
          ...mainWidgetID,
        ];

        if (_.isEqual(MonitorsIdList?.sort(), allMonitorsIdWidget?.sort())) {
          // to hadle agggregate or comparison
          const finalDataWidget = handleEventData(
            widget,
            widgetTypeChart,
            mainWidgetData,
            customizedWidgets,
            customizedType,
          );
          setFinalWidgetData({
            ...finalDataWidget, // this final data from return handleEventData
            preLoaderMonitorsId: MonitorsIdList, // add list of ids to pre loader
          });
        }
      }
    }
  }, [
    customizedWidgets,
    widgetsMqttResponses,
    customizedType,
    widget?.customized_widget,
  ]);

  const widgetComponents = {
    pieChartWidget: {
      default: CustomDashboardPieChart,
      comparison: CustomDashboardStackedBarChart,
    },
    lineChartWidget: {
      default: CustomDashboardLineChart,
      comparison: CustomDashboardStackedLine,
    },
    stackedLineWidget: {
      default: CustomDashboardStackedLine,
      comparison: CustomDashboardStackedBarChart,
    },
    barChartWidget: {
      default: CustomDashboardBarChart,
      comparison: CustomDashboardBarChart,
    },
    stackedBarWidget: {
      default: CustomDashboardStackedBarChart,
      comparison: CustomDashboardStackedBarChart,
    },
    themesWidget: {
      default: CustomDashboardThemesWidget,
      comparison: CustomDashboardStackedBarChart,
    },
    punchCardWidget: { default: CustomDashboardPunchCard }, // No Comparison
    tableWidget: { default: CustomDashboardTableWidget }, // No Comparison
    mediaWidget: { default: CustomDashboardMediaComponent }, // No Comparison
  };

  // Check if widget is comparison to get its widget component else get the default component
  const WidgetToBeRender = widgetComponents?.[widgetTypeChart]?.[customizedType]
    ? widgetComponents?.[widgetTypeChart]?.[customizedType]
    : widgetComponents?.[widgetTypeChart]?.default;

  const slProduct = +localStorage?.getItem("sm_id");
  // The user must have the permission to edit or destroy the dashboard to control widgets
  const showControl =
    slProduct == dashboardInfo?.product_id
      ? handlelUserRoles("SM", "DESTROY_DASHBOARD")
        ? true
        : dashboardInfo?.dashboard_owner &&
          handlelUserRoles("SM", "EDIT_DASHBOARD")
      : handlelUserRoles("CXM", "DESTROY_DASHBOARD")
        ? true
        : dashboardInfo?.dashboard_owner &&
          handlelUserRoles("CXM", "EDIT_DASHBOARD");

  const widgetProps = {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    widgetsMqttResponses,
    customizedWidgets,
    finalWidgetData,
    dashboardInfo,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    allMonitorsIdWidget,
    customizedType,
    isComparison: customizedType == "comparison",
    showControl,
  };

  const isComparison =
    widget?.customized_widget?.attributes?.type?.name === "COMPARISON";

  return (
    <Grid
      item
      xs={
        ["pieChartWidget", "tableWidget"]?.includes(widgetTypeChart) &&
        !isControlWidget &&
        customizedType !== "comparison"
          ? 6
          : 12
      }
      className="custom-dashboard-widget-grid-item"
    >
      {WidgetToBeRender ? <WidgetToBeRender {...widgetProps} /> : null}
    </Grid>
  );
};

export default WidgetComponent;
