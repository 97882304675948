import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faMerge,
  faRectangleVerticalHistory,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import WidgetControlFunctions from "../widgetControlFunctions";
import PopupModal from "components/popupModal";
import "../style.scss";

const CustomizedTypeCard = (props) => {
  const intl = useIntl();

  const { customDashboardData, widgetName } = props;

  const [widgetId, setWidgetId] = useState(null);
  const [customizeType, setCustomizeType] = useState(null);

  const {
    openedRemoveDialog,
    setOpenedRemoveDialog,
    removeCustomizeLoading,
    handleRemoveCustomized,
  } = WidgetControlFunctions(props);

  useEffect(() => {
    if (customDashboardData?.widget) {
      setWidgetId(customDashboardData?.widget?.id);
      setCustomizeType(
        customDashboardData?.widget?.customized_widget?.attributes?.type?.name,
      );
    }
  }, [customDashboardData?.widget]);

  return (
    <Box component={"span"}>
      <Box component="span" className="widget-view-type">
        <FontAwesomeIcon
          icon={
            customizeType === "AGGREGATE" ? faMerge : faRectangleVerticalHistory
          }
          fixedWidth
        />
        <Typography variant="p" className="type-text">
          {CheckValueLocale(
            customizeType === "AGGREGATE"
              ? "custom_dashboard_aggregated"
              : "custom_dashboard_comparison",
            "",
            {},
            intl,
          )}
        </Typography>
        <Tooltip
          arrow
          placement="top"
          title={CheckValueLocale("remove", "", {}, intl)}
        >
          <FontAwesomeIcon
            icon={faClose}
            className="delete-icon"
            fixedWidth
            onClick={() =>
              customDashboardData?.showControl && setOpenedRemoveDialog(true)
            }
          />
        </Tooltip>
      </Box>
      <PopupModal
        open={openedRemoveDialog}
        title={CheckValueLocale(
          customizeType === "AGGREGATE"
            ? "remove_aggregated_view"
            : "remove_comparison_view",
          "",
          {},
          intl,
        )}
        body={CheckValueLocale(
          customizeType === "AGGREGATE"
            ? "remove_aggregated_view_desc"
            : "remove_comparison_view_desc",
          "",
          {
            widgetName: CheckValueLocale(widgetName, "", {}, intl),
            br: <br />,
          },
          intl,
        )}
        addClasses={"custom-dashboard-dialog-actions"}
        modalStyle={"custom-dashboard-dialog-content"}
        leftBtn={CheckValueLocale("cancel_btn", "", {}, intl)}
        rightBtn={CheckValueLocale("confirm_remove", "", {}, intl)}
        isRightBtnLoading={removeCustomizeLoading}
        close={() => setOpenedRemoveDialog(false)}
        accept={() => handleRemoveCustomized(widgetId, customizeType)}
        rightColor="error"
        warning
        leftBtnId="custom-dashboard-customize-remove-cancel-btn"
        rightBtnId="custom-dashboard-customize-confirm-remove-btn"
        minWidth="600px"
      />
    </Box>
  );
};
export default CustomizedTypeCard;
