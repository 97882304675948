import { useMutation, useQueryClient } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useDublicateAiAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => AiAgentsController.dublicateAiAgent(id),
    onSuccess: (data) => {
      if (!data?.errorMsg) {
        queryClient.invalidateQueries({
          queryKey: ["aiAgentList"],
        });
      }
    },
  });
};
