import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Box, Tabs, Tab } from "@mui/material";
import "./sideBarTabs.scss";
import {
  CheckValueLocale,
  classNames,
  handleShowTabsCounter,
} from "utils/helpers";
import TabContent from "./tabContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { allowedTabsChange } from "../hooks/constant";
import { useTabsContentCounter } from "../hooks/useTabsContentCounter";

export const tabsNameGeneral = [
  {
    name: "all",
    key: "all",
  },
  {
    name: "new",
    key: "new",
  },
  {
    name: "active_status",
    key: "active",
  },
  {
    name: "complete",
    key: "complete",
  },
];
export const tabsNameAiAgent = [
  {
    name: "all",
    key: "all",
  },
  {
    name: "active_status",
    key: "active",
  },
  {
    name: "complete",
    key: "complete",
  },
];
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const shouldShowLoading = (
  countLoading,
  filterTypeChange,
  isCurrentTab,
  isInitialLoad,
) => {
  // we show loading in the tab if the following conditions are met
  return (
    // if the countLoading is true
    countLoading &&
    // if the countLoading is true we will show the loading if one of the following conditions are met
    // 1- filterTypeChange if is not included in the allowedTabsChange this means it the tab should be loading
    //    e.g. when the user clicks on referesh btn, change the filters, or change the datapicker
    (!allowedTabsChange.includes(filterTypeChange) ||
      // 2- if we are in the current tab we should show a loading
      isCurrentTab ||
      // 3- if it's the first time the component is loaded we should show a loading
      isInitialLoad)
  );
};

const SideBarTabs = ({
  cardState,
  setCardState,
  selectedCard,
  setSelectedCard,
  setFilters,
  filters,
  applyFilters,
  response,
  allData,
  preLoader,
  tabValue,
  setTabValue,
  completionContent,
  valueInput,
  completionReason,
  setLastOperation,
  setEngagementPostId,
  setSlaCounterData,
  slaCounterData,
  setOpenSidebarToAssignTeam,
  aiAgentList,
  setAiAgentList,
  setIsAiAgentDown,
  setIsLimitReached,
  setIsDMLimitReached,
  setAiAgentDMReachDetails,
  setIsSlaInitialLoad,
  filterTypeChange,
}) => {
  const intl = useIntl();
  const [countLoading, setCountLoading] = useState(true);
  // hasNextPage will be true if there are more engagements to load
  // it will be returned from the event "EngagementsProductPage" in the TabContent component
  const [hasNextPage, setHasNextPage] = useState(false);
  const { tabsContentCount, setTabsContentCount, resetTabsContentCount } =
    useTabsContentCounter(filters, countLoading);

  useEffect(() => {
    setCardState({ activeTab: filters.engagement_status });
    setHasNextPage(false);
    setLastOperation(0);
    setEngagementPostId(null);

    setCountLoading(true);
    setSlaCounterData({ ...slaCounterData, loading: true });
    if (filters.engagement_status == "all") {
      setTabValue(0);
    }
  }, [filters.engagement_status]);

  const handleTabChange = (event, newValue) => {
    setHasNextPage(false);
    setLastOperation(0);
    setEngagementPostId(null);
    setCountLoading(true);
    setSlaCounterData({ ...slaCounterData, loading: true });
    const tabsNames =
      filters?.sla_status === "ai_agent_sla"
        ? ["all", "active", "complete"]
        : ["all", "new", "active", "complete"];
    filters.page = 1;
    filters.engagement_status = tabsNames[newValue];
    setFilters({ ...filters });
    applyFilters(undefined, undefined, "content_tabs");
    setTabValue(newValue);
  };
  let showLoading =
    (!countLoading && window.location.href.includes("alert_id")) ||
    !window.location.href.includes("alert_id");
  const tabsName =
    filters?.sla_status === "ai_agent_sla" ? tabsNameAiAgent : tabsNameGeneral;
  return (
    <>
      <Box className="tabs-wrapper">
        {showLoading ? (
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {tabsName?.map((tab, i) => {
              const { isInitialLoad, changedCountKeys } = tabsContentCount;
              const isMutableTab = tabsContentCount?.mutableTabs?.includes(
                tab?.key,
              );
              const isCurrentTab = tab?.key == filters?.engagement_status;
              return (
                <Tab
                  key={i}
                  label={
                    <p id={`engagements-tab-${tab?.name}`} className="tab-name">
                      {CheckValueLocale(tab?.name, "", {}, intl)}
                      {/* show loading only when coutLoading and only in the current tab*/}
                      {shouldShowLoading(
                        countLoading,
                        filterTypeChange,
                        isCurrentTab,
                        isInitialLoad,
                      ) ? (
                        <FontAwesomeIcon icon={faSpinnerThird} spin />
                      ) : (
                        <span
                          className={classNames(
                            "tab-number",
                            changedCountKeys?.includes(tab?.key) && isMutableTab
                              ? "animated-number-bounce"
                              : "",
                          )}
                        >
                          {handleShowTabsCounter(
                            tabsContentCount?.[tab?.key],
                            isMutableTab,
                          )}
                        </span>
                      )}
                    </p>
                  }
                  {...a11yProps(0)}
                />
              );
            })}
          </Tabs>
        ) : null}
      </Box>
      <Box className="tabs-panel-wrapper">
        {tabsName?.map((tab, i) => (
          <TabPanel
            value={tabValue}
            key={i}
            index={i}
            className="tabs-panel-box"
          >
            <TabContent
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              cardState={cardState}
              setCardState={setCardState}
              response={response}
              setFilters={setFilters}
              filters={filters}
              applyFilters={applyFilters}
              allData={allData}
              preLoader={preLoader}
              setCountLoading={setCountLoading}
              setSlaCounterData={setSlaCounterData}
              slaCounterData={slaCounterData}
              completionContent={completionContent}
              valueInput={valueInput}
              completionReason={completionReason}
              setLastOperation={setLastOperation}
              setEngagementPostId={setEngagementPostId}
              setTabValue={setTabValue}
              setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
              aiAgentList={aiAgentList}
              setAiAgentList={setAiAgentList}
              setIsAiAgentDown={setIsAiAgentDown}
              setIsLimitReached={setIsLimitReached}
              setIsDMLimitReached={setIsDMLimitReached}
              setAiAgentDMReachDetails={setAiAgentDMReachDetails}
              // new props
              tabsContentCount={tabsContentCount}
              setTabsContentCount={setTabsContentCount}
              resetTabsContentCount={resetTabsContentCount}
              setIsSlaInitialLoad={setIsSlaInitialLoad}
              filterTypeChange={filterTypeChange}
              hasNextPage={hasNextPage}
              setHasNextPage={setHasNextPage}
            />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};
export default SideBarTabs;
