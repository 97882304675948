import React, { useEffect } from "react";
// Import routing components
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import MainPage from "pages/MainPage/mainPage.js";
import Login from "pages/loginPage/loginPage";
import AlertsPage from "pages/alerts";
import AlertDetailPage from "pages/alerts/components/AlertDetailPage.js";
import Reports from "pages/reportsNew/index.js";
import AddReport from "pages/reportsNew/create/index.js";
import ViewReport from "pages/reportsNew/view/index";
import EditReport from "pages/reportsNew/edit/index";
import ManageChannels from "pages/channelAnalytics/manageChannels/manageChannels.js";
import AddChannel from "pages/channelAnalytics/manageChannels/addChannel/addChannel.js";
import IntercomPage from "pages/channelAnalytics/InsightsPage/chatPage/intercomPage/IntercomPage.js";
import SocialMediaPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/SocialPage.js";
import EmailPage from "pages/channelAnalytics/InsightsPage/emailPage/emailPage.js";
import GmailPage from "pages/channelAnalytics/InsightsPage/emailPage/GmailPage/gmailPage.js";
import RatingPage from "pages/channelAnalytics/InsightsPage/ratingPage/ratingPage.js";
import GoogleMyBusinessPage from "pages/channelAnalytics/InsightsPage/ratingPage/googleMyBusiness/googleMyBusinessPage";
import GMBInteractionPage from "pages/channelAnalytics/InsightsPage/ratingPage/googleMyBusiness/Interaction-list/gmbInteractionPage";
import GMBSingleInteractionPage from "pages/channelAnalytics/InsightsPage/ratingPage/googleMyBusiness/Interaction-list/singleGmbInteraction/singleGmbInteractions";
import ProfilePage from "pages/profile/profilePage";
import FacebookChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/facebookChannel/facebookPage.js";
import InstagramChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/instagramChannel/instagramPage";
import TwitterChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/twitterChannel/twitterPage.js";
import TikTokPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/tikTokChannel/tikTokPage.js";

//private pages
import FacebookPrivateChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/facebookPrivateChannel/facebookPrivatePage.js";
import InstagramPrivateChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/instagramPrivateChannel/instagramPrivatePage";
import TwitterPrivateChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/twitterPrivateChannel/twitterPrivatePage.js";

import WhatsAppPage from "pages/channelAnalytics/InsightsPage/chatPage/WhatsappPage/whatsAppPage.js";
import DesignSystem from "pages/DesignSystem/DesignSystem";
import InsightsPage from "pages/channelAnalytics/InsightsPage/insightsPage.js";
import settingsPage from "pages/settings/settingsPage.js";
import NotificationPage from "pages/notifications/index";
import SingleTweetAnalysis from "pages/notifications/SingleTweetAnalysis/index";
import TopHashtags from "pages/socialListing/TopHashtags/index.js";
import HashtagDetails from "pages/socialListing/TopHashtags/HashtagDetails/index.js";
import InteractionsPage from "pages/channelAnalytics/interactionsPage/interactionsPage.js";
import Benchmark from "pages/socialListing/benchmark";

//twitter interactions Pages
import TwitterInteractionPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/twitterChannel/Interaction-list/TwitterInteractionPage";
import SingleTwitterInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/twitterChannel/Interaction-list/SingleTweetInteraction/SingleTweetInteraction.js";
//private
import TwitterInteractionPrivatePage from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/twitterPrivateChannel/Interaction-list/TwitterInteractionPage";
import SingleTwitterPrivateInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/twitterPrivateChannel/Interaction-list/SingleTweetInteraction/SingleTweetInteraction.js";
//whatsapp interactions Pages
import WhatsappInteractionPage from "pages/channelAnalytics/InsightsPage/chatPage/WhatsappPage/Interaction-list/WhatsAppInteractionPage";
import SingleWhatsappInteraction from "pages/channelAnalytics/InsightsPage/chatPage/WhatsappPage/Interaction-list/SingleWhatsappInteraction/SingleWhatsappInteraction.js";

//facebook interactions Pages
import FacebookInteractionPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/facebookChannel/Interaction-list/FacebookInteractionPage";
import SingleFacebookInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/facebookChannel/Interaction-list/SingleFacebookInteraction/SingleFacebookInteraction.js";
//private
import FacebookInteractionPrivatePage from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/facebookPrivateChannel/Interaction-list/FacebookInteractionPage";
import SingleFacebookPrivateInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/facebookPrivateChannel/Interaction-list/SingleFacebookInteraction/SingleFacebookInteraction.js";
//instagram interactions Pages
import InstagramInteractionPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/instagramChannel/Interaction-list/InstagramInteractionPage";
import SingleInstagramInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/instagramChannel/Interaction-list/SingleInstagramInteraction/SingleInstagramInteraction.js";
//private
import InstagramInteractionPrivatePage from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/instagramPrivateChannel/Interaction-list/InstagramInteractionPage";
import SingleInstagramPrivateInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/instagramPrivateChannel/Interaction-list/SingleInstagramInteraction/SingleInstagramInteraction.js";
//gmail interactions Pages
import GmailInteractionPage from "pages/channelAnalytics/InsightsPage/emailPage/GmailPage/Interaction-list/GmailInteractionPage";
import SingleEmailInteraction from "pages/channelAnalytics/InsightsPage/emailPage/GmailPage/Interaction-list/SingleEmailInteraction/SingleEmailInteraction.js";

//whatsapp interactions Pages
import IntercomInteractionPage from "pages/channelAnalytics/InsightsPage/chatPage/intercomPage/Interaction-list/IntercomInteractionPage";
import SingleIntercomInteraction from "pages/channelAnalytics/InsightsPage/chatPage/intercomPage/Interaction-list/SingleIntercomInteraction/SingleIntercomInteraction.js";

import NotFoundPage from "pages/not-found/NotFoundPage.js";
import InsightsSideMenu from "pages/channelAnalytics/InsightsPage/insightsSideMenu/insightsSideMenu";
import ResetPasswordForm from "pages/loginPage/loginPage";
import NewUserForm from "pages/loginPage/loginPage";
import MonitorListPage from "pages/socialListing/MonitorList/monitorList/monitorListPage";
import { handleActiveFeature, handlelUserRoles } from "utils/helpers";
import ChatPage from "pages/channelAnalytics/InsightsPage/chatPage/chatPage.js";
import SurveyMainPage from "pages/survey/surveyMainPage";
import SurveyBuilder from "pages/SurveyBuilder";
import PrivateRoute from "../../shared/privateRoute/privateRoute";
import FaqsPage from "pages/faqs";
import CreateMonitor from "pages/socialListing/MonitorsOptions/createMonitor/createMonitor";
import SurveyInsights from "pages/survey/surveyInsights/surveyInsights";
import MainMonitorPage from "pages/Monitors/mainMonitorPage";
import MonitorSettings from "pages/socialListing/MonitorsOptions/monitorSettings/monitorSettings";
import SocialMediaInteractionPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/SocialMediaInteractionList/SocialMediaInteractionPage";
import { Userpilot } from "userpilot";

//Audience interactions Pages
import Audience from "pages/audience";
import MainUserProfile from "pages/audience/userProfile/mainUserProfile";
import SurveyBody from "pages/audience/userActivites/survey/surveyBody";
import ManageAudience from "pages/audience/manageAudience";

// dashboard Pages
import DashboardListPage from "pages/dashboard/dashboardList/dashboardListPage";
import CreateDashboard from "pages/dashboard/createDashboard/createDashboard";
import EditDashboard from "pages/dashboard/editDashboard";
import MainDashboardPage from "pages/dashboard/dashboardMainPages/mainDashboardPage";

// LinkedIn Pages
import LinkedInPage from "pages/channelAnalytics/InsightsPage/linkedInPage/linkedInPage";
import SingleLinkedInInteraction from "pages/channelAnalytics/InsightsPage/linkedInPage/Interaction-list/SingleLinkedInInteraction/singleLinkedInInteraction";
import LinkedInInteractionPage from "pages/channelAnalytics/InsightsPage/linkedInPage/Interaction-list/linkedInInteractionPage";

//Genesys Pages
import CallsPage from "pages/channelAnalytics/InsightsPage/callsPage/callsPage.js";
import GenesysPage from "pages/channelAnalytics/InsightsPage/callsPage/genesys/genesysPage.js";
import SingleGenesysInteraction from "pages/channelAnalytics/InsightsPage/callsPage/genesys/Interaction-list/singleGenesysInteraction/singleGenesysInterations.js";
import GenesysInteractionPage from "../../pages/channelAnalytics/InsightsPage/callsPage/genesys/Interaction-list/genesysInteractionsPage";
import UserAccount from "services/controllers/userAccountController";
//explore page
import ExplorePage from "pages/socialListing/ExplorePage";
import Engagements from "./../../pages/engagements/index";
import ChatInteractionsPage from "pages/channelAnalytics/InsightsPage/chatPage/ChatInteractionsPage/ChatInteractionsPage";
import MainSetup from "./../../pages/engagements/components/setup-flow/setup/mainSetup";
import UserGuide from "pages/engagements/components/publish/componenets/userGuide";
import HomePage from "pages/homepage";
import MainEdit from "pages/engagements/components/setup-flow/edit/mainEdit";
import TiktokInteractionsPage from "./../../pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/tikTokChannel/Interaction-list/tiktokInteractionPage";
import SingleTiktokInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/tikTokChannel/Interaction-list/SingleTiktokInteraction/SingleTiktokInteraction";
import DeleteAlertEngagement from "pages/engagements/components/deleteEngagementAlert/deleteAlert";
import GuidelinesButtons from "shared/lucButton/guidelinesButtons";
import { AddTeam } from "pages/settings/components/teams/addTeam/addTeam";
import AddNewUser from "./../../pages/settings/components/userManagement/addNewUser/index";
import EditUser from "../../pages/settings/components/userManagement/editNewUser/editUser";
import { ViewTeam } from "pages/settings/components/teams/viewTeam/viewTeam";
import { EditTeam } from "pages/settings/components/teams/editTeam/editTeam";
import { ViewUser } from "pages/settings/components/userManagement/viewUser/viewUser";
import EngagementAnalytics from "pages/engagements/components/engagementAnalytics";
import { CreateInboundRouting } from "pages/settings/components/InboundRouting/createInboundRouting/createInboundRouting";
import { EditInboundRouting } from "pages/settings/components/InboundRouting/editInboundRouting/editInboundRouting";
import CustomWidgetEdit from "pages/dashboard/dashboardMainPages/customDashboardPage/customWidgetEdit/customWidgetEdit";
import CustomDashboardView from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView";
import AiAgents from "pages/engagements/components/aiAgents/aiAgents";

//live dashboard page
import CreateLiveDashboard from "pages/createLiveDashboard";
import LiveDashboard from "pages/liveDashboard";
import { clearAiAgent } from "utils/redux/features/Engagements/EngagementsSlice";
import { useDispatch } from "react-redux";
import ManageSubscription from "pages/ManageSubscription";

const Routes = (props) => {
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("user_token") ? true : false;
  Userpilot.initialize("NX-610ca6c3");
  Userpilot.identify(localStorage.user_id, {
    page_url: window.location.href,
    page_title: window.location.href,
    id: localStorage.user_id,
    email: localStorage.email,
    app_language: localStorage.lang,
    name: localStorage.name,
    created_at: localStorage.created_at,
    locale_code: localStorage.lang,
  });
  var meta = { URL: window.location.href };
  Userpilot.track("Page Event", meta);
  Userpilot.reload();
  let location = useLocation();
  const history = useHistory();

  let url = window.location.search;
  if (url.includes("?redirect_url") && localStorage.getItem("user_token")) {
    UserAccount.createUserHutspot().then((result) => {
      if (result?.data) {
        window.open(result?.data?.url, "_self");
      }
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to top
  }, [location.pathname]);

  useEffect(() => {
    const pathNameVar = location?.pathname;
    if (pathNameVar.includes("TWITTER")) {
      history.push(pathNameVar.replace("TWITTER", "X_PLATEFORM"));
    } else if (pathNameVar.includes("twitter")) {
      history.push(pathNameVar.replace("twitter", "x-platform"));
    } else if (pathNameVar.includes("tweet")) {
      history.push(pathNameVar.replace("tweet", "post"));
    }
    if (pathNameVar.includes("/live-dashboard")) {
      props?.setIsLiveDashboard(true);
    } else {
      props?.setIsLiveDashboard(false);
    }
  }, [location]);

  useEffect(() => {
    if (
      !(
        history?.location?.pathname == "/engagements/main" ||
        history?.location?.pathname == "/engagements/main/inbox"
      )
    ) {
      dispatch(clearAiAgent());
    }
  }, [history?.location?.pathname]);

  var activeProducts = JSON.parse(window.localStorage.activeProducts || null);
  let aiAgentActive = activeProducts?.find(
    (product) => product?.name == "AI_AGENT",
  );

  return (
    <React.Fragment>
      {location.pathname === "/login" ||
      location.pathname === "/" ||
      location.pathname.includes("/reset_password") ||
      location.pathname.includes("/invitation_login") ||
      location.pathname.includes("/live-dashboard") ||
      location.pathname.includes("/manage-subscription") ? (
        <></>
      ) : (
        <MainPage switchLang={props.switchLang} lang={props.lang} />
      )}
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            if (localStorage.getItem("user_token")) {
              return <Redirect to="/home_page" />;
            } else {
              return <Redirect to="/login" />;
            }
          }}
          switchLang={props.switchLang}
          lang={props.lang}
        />
        <Route
          exact
          path="/login"
          component={Login}
          switchLang={props.switchLang}
          lang={props.lang}
        />

        <PrivateRoute
          exact
          path="/home_page"
          component={HomePage}
          lang={props.lang}
          isAuthenticated={isAuthenticated}
        />
        <Route
          path="/reset_password"
          render={({ match: { url } }) => (
            <>
              {/* <Route path={`${url}/`} component={ResetPasswordForm} /> */}
              <Route path={`${url}/:token`} component={ResetPasswordForm} />
            </>
          )}
        />
        <Route
          path="/invitation_login"
          render={({ match: { url } }) => (
            <>
              {/* <Route path={`${url}/`} component={NewUserForm} /> */}
              <Route
                path={`${url}/:user_email/:invitation_token`}
                component={NewUserForm}
              />
            </>
          )}
        />
        {/* ________________________________________________________________________________________________ */}
        <Route
          path="/social"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/monitor_list`}
                    component={MonitorListPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/monitors/create_monitor`}
                    component={CreateMonitor}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/monitors/create_monitor/step_1`}
                    component={CreateMonitor}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/monitors/create_monitor/step_2`}
                    component={CreateMonitor}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/benchmark`}
                    isAuthenticated={isAuthenticated}
                    component={Benchmark}
                  />
                  <PrivateRoute
                    path={`${url}/benchmark/social-listening`}
                    isAuthenticated={isAuthenticated}
                    component={Benchmark}
                  />
                  <PrivateRoute
                    path={`${url}/benchmark/omnichannel`}
                    isAuthenticated={isAuthenticated}
                    component={Benchmark}
                  />
                  <PrivateRoute
                    path={`${url}/explore`}
                    component={ExplorePage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />

                  <PrivateRoute path="*" exact component={NotFoundPage} />
                  <Route path="*" exact component={NotFoundPage} />
                </Switch>
              </>
            )
          }
        />
        {/* Monitor Pages */}
        <Route
          path="/monitor/:monitorType/:monitorDataSource/:activeTab/:monitorId"
          component={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`/monitor/:monitorType/:monitorDataSource/:activeTab/:monitorId`}
                    component={MainMonitorPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`/monitor/:monitorType/:monitorDataSource/:activeTab/:monitorId/author-profile/:tweetId/:userId`} //redirects from MainMonitorPage to ...\authorProfilePage.js
                    component={MainMonitorPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`/monitor/:monitorType/:monitorDataSource/:activeTab/:monitorId/deep-insights/:userName/:userId`} //redirects from MainMonitorPage to ...\deepInsightsPage.js
                    component={MainMonitorPage}
                    isAuthenticated={isAuthenticated}
                  />
                  {/* Keyword Monitors */}
                  <PrivateRoute path="*" exact component={NotFoundPage} />
                </Switch>
              </>
            )
          }
        />
        <Route
          path="/monitor/monitor-settings"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/:monitorId`}
                    component={MonitorSettings}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  {/* Keyword Monitors */}
                  <PrivateRoute path="*" exact component={NotFoundPage} />
                </Switch>
              </>
            )
          }
        />
        {/* Monitor Pages */}
        <Route
          path="/manage-channels"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/list`}
                    component={ManageChannels}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-channel`}
                    component={AddChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-channel/Social`}
                    component={AddChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-channel/chats`}
                    component={AddChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-channel/emails`}
                    component={AddChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-channel/Ratings`}
                    component={AddChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-channel/Calls`}
                    component={AddChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-channel/bot-config`}
                    component={AddChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-channel/session-config`}
                    component={AddChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path="*"
                    exact
                    component={NotFoundPage}
                    isAuthenticated={isAuthenticated}
                  />
                </Switch>
              </>
            )
          }
        />
        <Route
          path="/top-trends/view-hashtags"
          exact
          component={HashtagDetails}
        />
        <Route path="/top-trends" component={TopHashtags} />
        {/* channelAnalyticsRoutes */}
        <Route
          path="/insights"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/main`}
                    component={InsightsPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media`}
                    component={SocialMediaPage}
                    exact
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/x-platform-channel/:data_source_id`}
                    component={TwitterChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/x-platform-private-channel/:data_source_id`}
                    component={TwitterPrivateChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/instagram-channel/:data_source_id`}
                    component={InstagramChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/instagram-private-channel/:data_source_id`}
                    component={InstagramPrivateChannel}
                    isAuthenticated={isAuthenticated}
                  />

                  <PrivateRoute
                    path={`${url}/social-media/facebook-channel/:data_source_id`}
                    component={FacebookChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/facebook-private-channel/:data_source_id`}
                    component={FacebookPrivateChannel}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/linkedin-channel/:data_source_id`}
                    component={LinkedInPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/tikTok-channel/:data_source_id`}
                    component={TikTokPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <Route
                    path={`${url}/chat/`}
                    component={ChatPage}
                    exact
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/chat/whatsapp/:data_source_id`}
                    component={WhatsAppPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/chat/intercom/:data_source_id`}
                    component={IntercomPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/email/`}
                    component={EmailPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/email/gmail/:data_source_id`}
                    component={GmailPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/ratings/`}
                    component={RatingPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/ratings/google-my-business/:data_source_id`}
                    component={GoogleMyBusinessPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/calls/genesys/:data_source_id`}
                    component={GenesysPage}
                    isAuthenticated={isAuthenticated}
                  />

                  <PrivateRoute
                    path={`${url}/chat/:data_source_id`}
                    component={ChatPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/calls`}
                    component={CallsPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/email/:data_source_id`}
                    component={EmailPage}
                    isAuthenticated={isAuthenticated}
                  />

                  <PrivateRoute
                    path="*"
                    exact
                    component={NotFoundPage}
                    isAuthenticated={isAuthenticated}
                  />
                </Switch>
                <InsightsSideMenu />
              </>
            )
          }
        />
        <Route
          path="/alerts"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/:alertType/:monitorDataSource/:id`}
                    component={AlertDetailPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/deletedAlerts/engagement`}
                    component={DeleteAlertEngagement}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/`}
                    component={AlertsPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                </Switch>
              </>
            )
          }
        />
        <Route
          path="/interactions"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/`}
                    component={InteractionsPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/ratings/google-my-business/:data_source_id`}
                    component={GMBInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/calls/genesys/:data_source_id`}
                    component={GenesysInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/linkedin-channel/:data_source_id`}
                    component={LinkedInInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/tikTok-channel/:data_source_id`}
                    component={TiktokInteractionsPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/chat/whatsapp/:data_source_id`}
                    component={WhatsappInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/chat/intercom/:data_source_id`}
                    component={IntercomInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/x-platform-public/:data_source_id`}
                    component={TwitterInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/x-platform-private/:data_source_id`}
                    component={TwitterInteractionPrivatePage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/facebook-public/:data_source_id`}
                    component={FacebookInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/facebook-private/:data_source_id`}
                    component={FacebookInteractionPrivatePage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/instagram-public/:data_source_id`}
                    component={InstagramInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/instagram-private/:data_source_id`}
                    component={InstagramInteractionPrivatePage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/email/gmail/:data_source_id`}
                    component={GmailInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/`}
                    component={SocialMediaInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/chat/`}
                    component={ChatInteractionsPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/ratings/google-my-business/:id/:tracker_id/:data_source_id`}
                    component={GMBSingleInteractionPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/calls/genesys/:id/:tracker_id/:data_source_id`}
                    component={SingleGenesysInteraction}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/social-media/x-platform-public/:id/:tracker_id/:data_source_id`}
                    component={SingleTwitterInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/tikTok-channel/:id/:tracker_id/:data_source_id/:type`}
                    component={SingleTiktokInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/x-platform-private/:id/:tracker_id/:data_source_id`}
                    component={SingleTwitterPrivateInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/facebook-public/:id/:tracker_id/:data_source_id`}
                    component={SingleFacebookInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/facebook-private/:id/:tracker_id/:data_source_id`}
                    component={SingleFacebookPrivateInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/chat/whatsapp/:id/:tracker_id/:data_source_id`}
                    component={SingleWhatsappInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/instagram-public/:id/:tracker_id/:data_source_id`}
                    component={SingleInstagramInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/instagram-private/:id/:tracker_id/:data_source_id`}
                    component={SingleInstagramPrivateInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/chat/intercom/:id/:tracker_id/:data_source_id`}
                    component={SingleIntercomInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/email/gmail/:id/:tracker_id/:data_source_id`}
                    component={SingleEmailInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/social-media/linkedin-channel/:id/:tracker_id/:data_source_id`}
                    component={SingleLinkedInInteraction}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute path="*" exact component={NotFoundPage} />
                </Switch>

                {/* SIDEMENU WILL APPEAR WITH INTERACTIONS ONLY */}
                {location.pathname.includes("/interactions/chat/whatsapp/") && (
                  <InsightsSideMenu />
                )}
                {location.pathname.includes("/interactions/chat/intercom/") && (
                  <InsightsSideMenu />
                )}

                {location.pathname.includes(
                  "/interactions/ratings/google-my-business/",
                ) && <InsightsSideMenu />}

                {location.pathname.includes(
                  "/interactions/social-media/x-platform-public/",
                ) && <InsightsSideMenu />}
                {location.pathname.includes(
                  "/interactions/social-media/x-platform-private/",
                ) && <InsightsSideMenu />}
                {location.pathname.includes(
                  "/interactions/social-media/facebook-private/",
                ) && <InsightsSideMenu />}
                {location.pathname.includes(
                  "/interactions/social-media/facebook-public/",
                ) && <InsightsSideMenu />}

                {location.pathname.includes(
                  "/interactions/social-media/instagram-private/",
                ) && <InsightsSideMenu />}
                {location.pathname.includes(
                  "/interactions/social-media/instagram-public/",
                ) && <InsightsSideMenu />}
                {location.pathname.includes("/interactions/email/gmail/") && (
                  <InsightsSideMenu />
                )}
                {location.pathname.includes("/interactions/social-media/") && (
                  <InsightsSideMenu />
                )}
                {location.pathname.includes(
                  "/interactions/social-media/linkedin-channel/",
                ) && <InsightsSideMenu />}
                {location.pathname.includes("/interactions/calls/genesys/") && (
                  <InsightsSideMenu />
                )}
              </>
            )
          }
        />
        <PrivateRoute
          path={`${url}/publish/user_guide`}
          component={UserGuide}
          isAuthenticated={isAuthenticated}
          // exact
        />
        {/* channelAnalyticsRoutes */}
        <Route
          path="/engagements"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/main`}
                    component={
                      handlelUserRoles(
                        "ENGAGEMENTS",
                        "ACCESS_ENGAGEMENT_SYSTEM",
                      ) &&
                      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH"))
                        ? Engagements
                        : NotFoundPage
                    }
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/main/inbox`}
                    component={
                      handlelUserRoles(
                        "ENGAGEMENTS",
                        "ACCESS_ENGAGEMENT_SYSTEM",
                      ) &&
                      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH"))
                        ? Engagements
                        : NotFoundPage
                    }
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/main/publish`}
                    component={
                      handlelUserRoles(
                        "ENGAGEMENTS",
                        "ACCESS_ENGAGEMENT_SYSTEM",
                      ) &&
                      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH"))
                        ? Engagements
                        : NotFoundPage
                    }
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={[`${url}/analytics`, `${url}/analytics/:tab`]}
                    component={
                      handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                      handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")
                        ? EngagementAnalytics
                        : NotFoundPage
                    }
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/setup`}
                    component={
                      handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") &&
                      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH"))
                        ? MainSetup
                        : NotFoundPage
                    }
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/edit`}
                    component={
                      handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") &&
                      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH"))
                        ? MainEdit
                        : NotFoundPage
                    }
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/ai-agents`}
                    component={
                      handlelUserRoles("AI_AGENT", "VIEW_AI_AGENT_SETTINGS") &&
                      handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
                      aiAgentActive
                        ? AiAgents
                        : NotFoundPage
                    }
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path="*"
                    exact
                    component={NotFoundPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/publish/user_guide`}
                    component={UserGuide}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                </Switch>
              </>
            )
          }
        />
        <Route
          path="/reports"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/list`}
                    component={Reports}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-report`}
                    component={AddReport}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-report/information`}
                    component={AddReport}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-report/setting`}
                    component={AddReport}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-report/delivery`}
                    component={AddReport}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/edit-report`}
                    component={EditReport}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/view-report`}
                    component={ViewReport}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path="*"
                    exact
                    component={NotFoundPage}
                    isAuthenticated={isAuthenticated}
                  />
                </Switch>
              </>
            )
          }
        />
        <Route
          path="/audience"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/list`}
                    component={Audience}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/user-profile/:id`}
                    component={MainUserProfile}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/survey/:id`}
                    component={SurveyBody}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/manage-audience`}
                    component={ManageAudience}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path="*"
                    exact
                    component={NotFoundPage}
                    isAuthenticated={isAuthenticated}
                  />
                </Switch>
              </>
            )
          }
        />
        <Route
          path="/survey"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/list`}
                    component={SurveyMainPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/survey-builder/:id`}
                    component={SurveyBuilder}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/add-survey/survey-builder/:id`}
                    component={SurveyBuilder}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/edit-survey/survey-builder/:id`}
                    component={SurveyBuilder}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/survey-insights/:id`}
                    component={SurveyInsights}
                    isAuthenticated={isAuthenticated}
                  />
                </Switch>
              </>
            )
          }
        />
        <Route
          path="/notification"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/`}
                    component={NotificationPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/:id/download`}
                    component={NotificationPage}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/:id/xlsx_download`}
                    component={NotificationPage}
                    isAuthenticated={isAuthenticated}
                  />
                </Switch>
              </>
            )
          }
        />

        <PrivateRoute
          path="/faqs"
          component={FaqsPage}
          isAuthenticated={isAuthenticated}
        />
        {/* dashboard Page */}
        <Route
          path="/dashboard"
          render={({ match: { url } }) =>
            !isAuthenticated ||
            (!(
              handlelUserRoles("SM", "VIEW_DASHBOARD_LIST") &&
              handleActiveFeature("SM", "is_dashboards_enabled")
            ) &&
              !handleActiveFeature("SM", "is_sl_custom_dashboards_enabled") &&
              !handleActiveFeature(
                "CXM",
                "is_cxm_custom_dashboards_enabled",
              )) ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <Redirect
                    exact
                    from={`${url}`}
                    to={`${url}/dashboard_list`}
                  />
                  <PrivateRoute
                    path={`${url}/dashboard_list`}
                    component={DashboardListPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />

                  <PrivateRoute
                    path={`${url}/custom/edit/:dashboardId`}
                    component={CustomWidgetEdit}
                    isAuthenticated={isAuthenticated}
                    exact
                  />

                  <PrivateRoute
                    path={`${url}/custom/view/:dashboardId`}
                    component={CustomDashboardView}
                    isAuthenticated={isAuthenticated}
                    exact
                  />

                  <PrivateRoute
                    path={`${url}/create_dashboard`}
                    component={CreateDashboard}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/edit_dashboard/:dashboardId`}
                    component={EditDashboard}
                    isAuthenticated={isAuthenticated}
                    exact
                  />

                  <PrivateRoute
                    path={`${url}/:monitorType/:dashboardActiveTab/:dashboardID/`}
                    component={MainDashboardPage}
                    isAuthenticated={isAuthenticated}
                    exact
                  />

                  <Route path="*" exact component={NotFoundPage} />
                </Switch>
              </>
            )
          }
        />
        {/* Profile Page */}
        <PrivateRoute
          path="/profile"
          component={ProfilePage}
          isAuthenticated={isAuthenticated}
        />
        {/* Settings Page */}
        <Route
          path="/settings"
          render={({ match: { url } }) =>
            !isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <>
                <Switch>
                  <PrivateRoute
                    path={`${url}/user_managements/add`}
                    component={AddNewUser}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/user_managements/view/:id`}
                    component={ViewUser}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/user_managements/edit/:id`}
                    component={EditUser}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/teams/view/:id`}
                    component={ViewTeam}
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/teams/edit/:id`}
                    component={EditTeam}
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/inbound_routing/create/:step`}
                    component={
                      handlelUserRoles(
                        "ENGAGEMENTS",
                        "ACCESS_ENGAGEMENT_SYSTEM",
                      ) &&
                      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX"))
                        ? CreateInboundRouting
                        : NotFoundPage
                    }
                    isAuthenticated={isAuthenticated}
                    exact
                  />
                  <PrivateRoute
                    path={`${url}/inbound_routing/edit/:id`}
                    component={
                      handlelUserRoles(
                        "ENGAGEMENTS",
                        "ACCESS_ENGAGEMENT_SYSTEM",
                      ) &&
                      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX"))
                        ? EditInboundRouting
                        : NotFoundPage
                    }
                    isAuthenticated={isAuthenticated}
                  />
                  <PrivateRoute
                    path={`${url}/teams/add`}
                    component={AddTeam}
                    isAuthenticated={isAuthenticated}
                  />
                  <Route path="*" exact component={settingsPage} />
                </Switch>
              </>
            )
          }
        />
        <PrivateRoute
          path="/single_post_analysis/:monitor_id/:id/:screen_name"
          isAuthenticated={isAuthenticated}
          component={SingleTweetAnalysis}
        />
        <PrivateRoute
          path="/design-system"
          component={DesignSystem}
          isAuthenticated={isAuthenticated}
        />

        <Route exact path="/guidelinsButtons" component={GuidelinesButtons} />

        <PrivateRoute
          path="/edit-live-dashboard/:monitorType/:monitorId/:userDashboardId"
          component={CreateLiveDashboard}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/create-live-dashboard/:monitorType/:monitorId"
          component={CreateLiveDashboard}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/live-dashboard/:monitorType/:monitorId/:userDashboardId"
          component={LiveDashboard}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/manage-subscription"
          component={ManageSubscription}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="*"
          exact
          component={NotFoundPage}
          isAuthenticated={isAuthenticated}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
