import { Box, Skeleton } from "@mui/material";

const HeaderSkeletonItem = () => {
  return (
    <Box className="ai-agent-activity-log-skeleton-container">
      <Skeleton variant="circular" className="action-type-circle" />
      <Box className="column-wrapper">
        <Skeleton variant="text" className="action-title" spacing={4} />
        <Box className="action-details-container">
          <Skeleton variant="circular" className="author-image" />
          <Skeleton variant="text" className="author-details" />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderSkeletonItem;
