import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import customTheme from "./customeTheme";
import moment from "moment";
import verified_img from "../../../../../images/shared-images/verify-icon.png";

const VolumeOverTimeChart = (props) => {
  const registerTheme = () => {
    echarts?.registerTheme("custom_theme", customTheme);
  };

  const toolTipFormatter = (params, ticket, callback) => {
    const { activeTimeGroup, volumeData } = props || {};
    let data = {};
    switch (activeTimeGroup) {
      case "week":
        const startWeek = params?.[0]?.name?.split("\n")?.[0];
        data = volumeData?.person_day_over_time?.find((item) => {
          return moment(item?.day)?.isSame(startWeek, "week");
        });
        break;
      case "month":
        data = volumeData?.person_day_over_time?.find((item) => {
          return moment(item?.day)?.isSame(params?.[0]?.name, "month");
        });
        break;
      case "hour":
        data = volumeData?.person_hour_over_time?.find((item) => {
          const dateTime = item?.hour?.split(" ");
          const time24 = moment(params?.[0]?.name, ["hh A"])?.format("HH");
          const sameDate = props?.activeDay === dateTime?.[0];
          const sameHour = time24 === dateTime?.[1];
          return sameDate && sameHour;
        });
        break;
      default:
        data = volumeData?.person_day_over_time?.find((item) => {
          return moment(item?.day).isSame(params?.[0]?.name, "day");
        });
        break;
    }

    return data !== undefined
      ? `<div class='chart-tooltip'>
      <div class="flex-row">
        <div class="chart-tooltip-img">
          <img src="${data?.user_img_profile_url}" alt=""/>
        </div>
        <div class="chart-tooltip-content">
          <h6 class="chart-tooltip-user">@${data?.username}
            ${data?.verified_user ? `<img src="${verified_img}" alt=""/>` : ""}
          </h6>
          <div class="chart-tooltip-follower"><i class="fas fa-users"></i> ${
            data?.followers_count
          }</div>
          <div class="chart-tooltip-reacts">
            ${
              "interaction_type_retweet" in data &&
              data?.interaction_type_retweet
                ? `<span>
                <i class="fas fa-retweet"></i>
              </span>`
                : ""
            }
            ${
              "interaction_type_reply" in data && data?.interaction_type_reply
                ? `<span>
                <i class="fa fa-reply"></i>
                <a onclick="filterRepliesHandler('${data?.username}')">${data?.interaction_type_reply}</a>
              </span>`
                : ""
            }
          </div>
        </div>
      </div>
    </div>`
      : null;
  };

  registerTheme();

  let { chartData, xAxisData, activeTimeGroup } = props || {};
  let volumeValues = [];

  if (xAxisData !== undefined) {
    xAxisData = xAxisData?.map((dateKey) => {
      let value = chartData?.[dateKey] ? chartData?.[dateKey] : 0;
      volumeValues?.push(value);

      if (dateKey?.includes("~")) {
        dateKey = dateKey?.replace("~", "\n");
      }
      return dateKey;
    });
  }
  let xAxisLabel_rotate = 0;
  let zoomStatus = true;
  let zoomStart = 0;
  let intervalStatus = "auto";
  if (activeTimeGroup === "hour") {
    xAxisLabel_rotate = 45;
    zoomStatus = false;
    intervalStatus = 0;
  } else {
    zoomStart =
      xAxisData?.length > 7
        ? ((xAxisData?.length - 7) / xAxisData?.length) * 100
        : 0;
  }

  const option = {
    tooltip: {
      trigger: "axis",
      triggerOn: "click",
      backgroundColor: "#FFF",
      extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);",
      position: function (point, params, dom, rect, size) {
        return [point?.[0], "30%"];
      },
      formatter: toolTipFormatter,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      axisLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      axisLabel: {
        rotate: xAxisLabel_rotate,
        interval: intervalStatus,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          type: "dashed",
        },
      },
    },
    series: [
      {
        data: volumeValues,
        type: "line",
        lineStyle: {
          width: 8,
        },
        symbolSize: 10,
        smooth: true,
      },
    ],
    grid: {
      containLabel: true,
      left: 30,
      right: 10,
    },
    dataZoom: [
      {
        show: zoomStatus,
        start: 0,
        end: 100,
        bottom: 0,
      },
    ],
  };

  return (
    <div>
      <ReactEcharts option={option} theme="custom_theme" />
    </div>
  );
};

export default VolumeOverTimeChart;
