import Services from "./Services";

var AiAgents = {};

AiAgents.getAiAgentList = () => {
  return Services.getData("api/v5/ai_agents/ai_agents");
};

AiAgents.createAiAgent = (queryData) => {
  return Services.postData("api/v5/ai_agents/ai_agents", queryData);
};

AiAgents.getAiAgentDetails = (id) => {
  return Services.getData(`api/v5/ai_agents/ai_agents/${id}`);
};

AiAgents.deleteAiAgent = ({ id, queryData }) => {
  return Services.deleteData(`api/v5/ai_agents/ai_agents/${id}`, queryData);
};

AiAgents.dublicateAiAgent = (id) => {
  return Services.postData(`api/v5/ai_agents/ai_agents/${id}/duplicate`);
};

AiAgents.resumeAiAgent = (queryData) => {
  return Services.postData(
    "api/v5/ai_agents/ai_agents/resume_ai_agent",
    queryData,
  );
};

AiAgents.pauseAiAgent = (queryData) => {
  return Services.postData(
    "api/v5/ai_agents/ai_agents/pause_ai_agent",
    queryData,
  );
};

AiAgents.editAiAgent = ({ id, queryData }) => {
  return Services.putData(`api/v5/ai_agents/ai_agents/${id}`, queryData);
};

AiAgents.getSurveysPublishedList = () => {
  return Services.getData("api/v3/surveys/published_list");
};

AiAgents.getPublishedVersions = (id, queryData) => {
  return Services.getData(
    `api/v5/ai_agents/ai_agents/${id}/get_versions`,
    queryData,
  );
};

AiAgents.getSurveysPublishedList = () => {
  return Services.getData("api/v3/surveys/published_list");
};

AiAgents.getAiAgentImage = (id) => {
  return Services.getData(`api/v5/ai_agents/ai_agents/${id}/download_image`);
};

AiAgents.getResourcesList = (queryData) => {
  return Services.getData("api/v5/ai_agents/ai_agent_resources", queryData);
};

AiAgents.addResource = (queryData) => {
  return Services.postData(
    "api/v5/ai_agents/ai_agent_resources",
    queryData,
    undefined,
    undefined,
    {
      "Content-Type": "multipart/form-data",
    },
  );
};

AiAgents.deleteResource = (id) => {
  return Services.deleteData(`api/v5/ai_agents/ai_agent_resources/${id}`);
};

AiAgents.forceRefreshResource = (id) => {
  return Services.postData(
    `api/v5/ai_agents/ai_agent_resources/${id}/force_refresh`,
  );
};

AiAgents.downloadResource = (id, queryData) => {
  return Services.getData(
    `api/v5/ai_agents/ai_agent_resources/${id}/download_file`,
    queryData,
  );
};

AiAgents.editResourceFile = (id, queryData) => {
  return Services.putData(
    `api/v5/ai_agents/ai_agent_resources/${id}`,
    queryData,
    undefined,
    undefined,
    {
      "Content-Type": "multipart/form-data",
    },
  );
};

AiAgents.publishAiAgent = (id) => {
  return Services.postData(`api/v5/ai_agents/ai_agents/${id}/publish`);
};

AiAgents.getPublishedVersionDetails = (id) => {
  return Services.getData(`api/v5/ai_agents/ai_agents/${id}/show_version`);
};

AiAgents.editPublishedVersion = (queryData) => {
  return Services.postData(
    "api/v5/ai_agents/ai_agents/change_draft",
    queryData,
  );
};

AiAgents.autoAssignAiAgent = (queryData) => {
  return Services.postData(
    "api/v5/ai_agents/ai_agents/ai_agent_to_auto_assign",
    queryData,
  );
};

AiAgents.unassignedConversationAiAgent = (queryData) => {
  return Services.postData(
    "api/v5/ai_agents/ai_agents/ai_agent_unassigned_conversation",
    queryData,
  );
};

AiAgents.getAiAgentsLightList = () => {
  return Services.getData(`api/v5/ai_agents/ai_agents/ai_agents_light_list`);
};

AiAgents.runAiAgentTest = (id) => {
  return Services.postData(`api/v5/ai_agents/ai_agents/${id}/run_test`);
};

AiAgents.downloadSwaggerTemplate = () => {
  return Services.getData("api/v5/ai_agents/ai_agents/ai_agent_guide");
};

export default AiAgents;
