import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, Card, Grid, CardContent } from "@mui/material";

import EngagementsImg from "../../../../images/notification-icons/engagements.png";
import PotentialReachImg from "../../../../images/notification-icons/potential-reach.png";
import PotentialImpressionImg from "../../../../images/notification-icons/potential-impression.png";
import { CheckValueLocale } from "utils/helpers";

const AudienceAnalysis = (props) => {
  const intl = useIntl();
  const { engagements, new_potential_reach, new_potential_impression } =
    props?.audienceAnalysis || {};
  return (
    <Grid container spacing={2.5} sx={{ mt: "10px" }}>
      <Grid item xs={4}>
        <Card className="audience-Analysis-card">
          <CardContent>
            <Box>
              <Typography gutterBottom variant="h6" component="div">
                {engagements}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {CheckValueLocale("engagements", "", {}, intl)}
              </Typography>
            </Box>
            <img src={EngagementsImg} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card className="audience-Analysis-card">
          <CardContent>
            <Box>
              <Typography gutterBottom variant="h6" component="div">
                {new_potential_reach}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {CheckValueLocale("potential_reach", "", {}, intl)}
              </Typography>
            </Box>
            <img src={PotentialReachImg} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card className="audience-Analysis-card">
          <CardContent>
            <Box>
              <Typography gutterBottom variant="h6" component="div">
                {new_potential_impression}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {CheckValueLocale("new_potential_impression", "", {}, intl)}
              </Typography>
            </Box>
            <img src={PotentialImpressionImg} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AudienceAnalysis;
