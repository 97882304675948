import { useQuery } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";
export const useTeamUsersList = (queryData) => {
  return useQuery({
    queryKey: ["teamUsers", queryData?.id],
    queryFn: () =>
      EngagementsController.getTeamUsers(queryData?.id, queryData?.product_id),
    enabled: !!queryData?.id,
    select: (data) => data?.data?.data?.attributes?.users?.data,
  });
};
