import "../Media.scss";
import { useIntl } from "react-intl";
import MediaFbItem from "./mediaFbItem.js";
import { isEmptyArray } from "utils/helpers";
import WidgetHeader from "components/widgets/widgetHeader";
import FetchingData from "../../../components/fetchingData/fetchingData";
import _ from "lodash";
import { Box } from "@mui/material";
import NoDataFound from "components/no-Data/noDataFound";
import { useParams } from "react-router-dom";

const MediaFb = (props) => {
  const intl = useIntl();

  const urlParams = useParams();
  const monitorDataSource = urlParams.monitorDataSource;

  return (
    <div className="chart_media">
      <WidgetHeader
        title={props.title}
        showDownloadIcon={props.showDownloadIcon}
        iconAfterTitle={props.iconAfterTitle}
        titleToolTip={props.titleToolTip}
        showNotAppliedFilter={props?.showNotAppliedFilter}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
      />
      {_.isEqual(
        props.mediaFbPreLoaderDataSources.sort(),
        props.preLoaderTrackerDataSources.sort(),
      ) !== true ? (
        <Box>
          <FetchingData />
        </Box>
      ) : isEmptyArray(props.data) ? (
        <NoDataFound />
      ) : (
        <div
          className={
            monitorDataSource === "FACEBOOK"
              ? "topmedia-monitor-wrapper"
              : "topmedia-wrapper"
          }
        >
          {props?.component !== "CA"
            ? props.data
                .slice(0, 8)
                .map((media, index) => (
                  <MediaFbItem
                    key={index}
                    media={media}
                    question={props?.questions[index]}
                    insightsID={props?.insightsID}
                    response={props?.response}
                    dates={props?.dates}
                    isCustomDashboard={props?.isCustomDashboard}
                  />
                ))
            : props.data
                .slice(0, 8)
                .map((media, index) => (
                  <MediaFbItem
                    key={index}
                    media={media}
                    hideTotalInteractions
                    datasourcePostsType={props?.datasourcePostsType}
                    hideMoreInsights={props?.hideMoreInsights}
                    isCustomDashboard={props?.isCustomDashboard}
                  />
                ))}
        </div>
      )}
    </div>
  );
};

export default MediaFb;
