import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
// Stacked bar Chart
import StackedBarChart from "../../../echarts/StackedBarChart";
import {
  CheckValueLocale,
  isEmptyArray,
  isEmptyObj,
  extractPathfromURLForIds,
  getSocialIcon,
} from "utils/helpers";
import _ from "lodash";
import {
  Box,
  Select,
  MenuItem,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import FetchingData from "../../../../components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import "./ThemeAnalysis.scss";

//import icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import aiIcon from "../../../../images/ai.svg";
import WidgetDownloader from "../../../../components/widgets/WidgetDownloader";
import WidgetControl from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView/components/controlWidget/widgetControl";
import {
  faClose,
  faMerge,
  faRectangleVerticalHistory,
} from "@fortawesome/pro-regular-svg-icons";
import WidgetHeaderMonitorsCard from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView/components/controlWidget/components/widgetHeaderMonitorsCard";
import CustomizedTypeCard from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView/components/controlWidget/components/customizedTypeCard";

const reOrderData = (data) => {
  if (data.indexOf("Undefined") !== -1) {
    let temp = data.splice(data.indexOf("Undefined"), 1)[0];
    data.push(temp);
  }
  if (data.indexOf("غير معرف") !== -1) {
    let temp = data.splice(data.indexOf("غير معرف"), 1)[0];
    data.push(temp);
  }
  if (data.indexOf("Other") !== -1) {
    let temp = data.splice(data.indexOf("Other"), 1)[0];
    data.push(temp);
  }
  if (data.indexOf("أخري") !== -1) {
    let temp = data.splice(data.indexOf("أخري"), 1)[0];
    data.push(temp);
  }
  return data;
};
const useStyles = makeStyles((theme) => ({
  customWidth: {
    marginLeft: "50px",
    marginRight: "50px",
  },
}));
const ThemesAnalysis = (props) => {
  const classes = useStyles();
  const [chart, setChart] = useState({
    values: [],
    labels: [],
    barNames: [],
    legends: [],
  });
  const themesColors = {
    complaint: "#E85954",
    Compliment: "#9FC653",
    compliment: "#9FC653",
    Complement: "#9FC653",
    complement: "#9FC653",
    other: "#D3D3D3",
    question: "#2AAAE2",
    "customer services": "#23A41A",
    delivery: "#A4CEE7",
    "portal/app": "#B0E381",
    payment: "#CCB0D8",
    "products & services": "#1176B9",
    undefined: "#FEC160",
    Complement: "#9FC653",
    complement: "#9FC653",
    "custom services": "#23A41A",
    أسئلة: "#2AAAE2",
    تكملة: "#9FC653",
    أخري: "#D3D3D3",
    "غير معرف": "#FEC160",
    توصيل: "#A4CEE7",
    شكاوي: "#E85954",
    "خدمة العملاء": "#23A41A",
    دفع: "#CCB0D8",
    "البوابة / التطبيق": "#B0E381",
    "المنتجات والخدمات": "#1176B9",
    "خدمات المنتج": "#1176B9",
    مدح: "#9FC653",
    شكوي: "#E85954",
    شكوى: "#E85954",
    السؤال: "#2AAAE2",
    أخرى: "#D3D3D3",
  };
  let colorList = [];
  let chartNames = chart.barNames;
  if (chartNames !== null && chartNames !== undefined) {
    for (var i = 0; i < chartNames.length; i++) {
      if (!colorList.includes(themesColors[chartNames[i]?.toLowerCase()])) {
        colorList.push(themesColors[chartNames[i]?.toLowerCase()]);
      }
    }
  }

  const [haveData, setHaveData] = useState(false);
  const [expandBy, setExpandBy] = useState("main-themes");
  const intl = useIntl();
  const { loading } = props;

  useEffect(() => {
    if (!isEmptyObj(props.data) && !loading && !isEmptyArray(props.data)) {
      setDataForChart(props.data, expandBy);
    } else if (isEmptyObj(props.data) && !loading) {
      setDummyDataForChart();
    }
  }, [
    props.data,
    intl?.locale, // re-calculate the chart data when the language is changed
  ]);

  const setDummyDataForChart = () => {
    const themeList = props.data;
    let mainTheme = [],
      subTheme = [];
    if (!_.isEmpty(themeList.main_themes)) {
      themeList.main_themes.forEach((theme) => {
        mainTheme.push(Object.keys(theme)[0]);
      });
      themeList.sub_themes.forEach((theme) => {
        subTheme.push(Object.keys(theme)[0]);
      });
    }

    setChart({
      values: [[0]],
      labels: mainTheme,
      legends: subTheme,
      barNames: subTheme,
    });
    setHaveData(false);
  };

  const setDataForChart = (data, themeType) => {
    let barNames = new Set(),
      legends = new Set(),
      legendsForChart = new Set(),
      labels = [],
      themeValues = [];

    let themes =
      themeType === "main-themes"
        ? data.main_themes_view
        : data.sub_themes_view;

    if (themes) {
      for (let [key, values] of Object.entries(themes)) {
        let newKey = key;
        if (key.toLowerCase() === "complement") newKey = "Compliment";
        labels.push(CheckValueLocale(newKey, "", {}, intl));
        let subTheme =
          themeType === "main-themes" ? values.sub_themes : values.main_themes;
        for (let key in subTheme) {
          if (key.toLowerCase() === "complement") key = "Compliment";
          legends.add(key);
          legendsForChart.add(CheckValueLocale(key, "", {}, intl));
          barNames.add(CheckValueLocale(key, "", {}, intl));
        }
      }
    }

    [...legends].forEach((legend, index) => {
      let subThemeValues = [];
      for (let [key, values] of Object.entries(themes)) {
        let value = "";

        let subTheme =
          themeType === "main-themes" ? values.sub_themes : values.main_themes;
        for (let [key, val] of Object.entries(subTheme)) {
          if (key.toLowerCase() === "complement") key = "Compliment";
          if (key === legend) {
            value = val;
          }
        }
        subThemeValues.push(value);
      }
      themeValues[index] = subThemeValues;
    });

    setChart({
      values: themeValues,
      labels: [...labels],
      legends: [...legendsForChart],
      barNames: [...barNames],
    });
    setHaveData(true);

    if (themeValues.length === 0) {
      setDummyDataForChart();
    }
  };

  const toggleThemes = (event) => {
    setExpandBy(event);
    setDataForChart(props.data, event);
  };

  return (
    <div
      className="chart"
      style={{ height: "450px" }}
      id={
        props?.isCustomDashboard
          ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
          : props?.title ?? "themes-analysis-widget-title"
      }
    >
      <header className="chart__header data-overtime__header theme_header widget-header-container-dashboard">
        <h3 className="widget-header-container-title">
          {props.title && props.titleToolTip ? (
            <Tooltip
              title={
                <div className="tooltip-custom-new ">
                  {props.titleToolTip &&
                    CheckValueLocale(props.titleToolTip, "", {}, intl)}
                </div>
              }
              arrow
              placement="top"
              classes={{ tooltip: classes.customWidth }}
            >
              <Typography variant={"h7"} className="widget-title">
                {CheckValueLocale(props.title, "", {}, intl)}
                {props?.widgetSection ? (
                  <span className="widget-title-section">
                    ({CheckValueLocale(props?.widgetSection, "", {}, intl)})
                  </span>
                ) : (
                  ""
                )}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant={"h7"} className="widget-title">
              {CheckValueLocale(props.title, "", {}, intl)}
              {props?.widgetSection ? (
                <span className="widget-title-section">
                  ({CheckValueLocale(props?.widgetSection, "", {}, intl)})
                </span>
              ) : (
                ""
              )}
            </Typography>
          )}
          {props.iconAfterTitle && (
            <span className="icon-after-title-themes">
              {props.iconAfterTitle}
            </span>
          )}
          {props?.isCustomDashboard &&
          !props?.isControlWidget &&
          !!props?.customDashboardData?.widget?.customized_widget ? (
            <CustomizedTypeCard
              customDashboardData={props?.customDashboardData}
              widgetName={props?.title}
            />
          ) : null}
        </h3>
        <div className="theme_options widget-header-container-filter">
          {!_.isEmpty(props.data) ? (
            <div className="icons-wrapper">
              <Box component="div" className={"select__container"}>
                <TextField
                  select
                  label={CheckValueLocale("expand_by", "", {}, intl)}
                  value={expandBy}
                  onChange={(e) => toggleThemes(e.target.value)}
                  className={"select btn-themes-theme-trand"}
                >
                  <MenuItem
                    value="main-themes"
                    id="themes-analysis-item-themes"
                  >
                    {CheckValueLocale("themes", "", {}, intl)}
                  </MenuItem>
                  <MenuItem
                    value="sub_themes"
                    id="themes-analysis-item-sub-themes"
                  >
                    {CheckValueLocale("sub_themes", "", {}, intl)}
                  </MenuItem>
                </TextField>
              </Box>
            </div>
          ) : null}
          <div className="themes_themestrends">
            {props.showAI && (
              <div className="aiIconDiv">
                <img src={aiIcon} alt="aiIcon" />
              </div>
            )}
            {props.showDownloadIcon && (
              <WidgetDownloader
                chartId={
                  props?.isCustomDashboard
                    ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
                    : props?.title
                }
                bigImg={props.bigImg}
                downloadBtnId={`widget-downloader-theme-analysis-${
                  extractPathfromURLForIds(window?.location?.pathname) ??
                  "themes-analysis-pathName"
                }`}
              />
            )}
            {props?.isCustomDashboard &&
            !props?.isControlWidget &&
            props?.customDashboardData?.showControl ? (
              <WidgetControl customDashboardData={props?.customDashboardData} />
            ) : null}
          </div>
        </div>
      </header>
      {props?.isCustomDashboard &&
      props?.customDashboardData?.customizedType !== "comparison" ? (
        <WidgetHeaderMonitorsCard
          isControlWidget={props?.isControlWidget}
          customDashboardData={props?.customDashboardData}
        />
      ) : null}
      {_.isEqual(
        props.themeAnalysisPreLoaderDataSources.sort(),
        props.preLoaderTrackerDataSources.sort(),
      ) !== true ? (
        <>
          <FetchingData />
        </>
      ) : !haveData && !loading && props.data ? (
        <NoDataFound />
      ) : (
        <>
          <StackedBarChart
            dataNames={chart.barNames}
            dataValues={chart.values}
            dataLabels={chart.labels}
            dataColor={colorList}
            legend={chart.legends}
            legendPosition={
              window.localStorage.lang === "ar" ? "topRight" : "topLeft"
            }
            noData={!haveData && !loading}
            expandBy={expandBy}
            isControlWidget={props?.isControlWidget}
          />
        </>
      )}
    </div>
  );
};

export default ThemesAnalysis;
