import { handleSentimentResponse } from "utils/helpers";

const widgetsMapping = {
  Linkedin__CASingleDataSourceInsightsPage__themes_and_sub_themes: {
    value: (response) => ({
      data: response?.themes,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__volume_overtime: {
    value: (response) => ({
      data: response?.interactions,
      total_count: response?.total_interactions,
      avg_interactions_per_day: response?.avg_interactions_per_day,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__content_type: {
    value: (response) => ({
      pie_data: response?.content_type_piechart,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__dialects_and_sub_dialects: {
    value: (response) => ({
      pie_data: response?.dialects,
      pie_sub_data: response?.sub_dialects,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__channel_accounts: {
    value: (response) => ({
      pie_data: response?.channel_accounts,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__sentiment_analysis: {
    value: (response) => ({
      data: handleSentimentResponse(response?.sentiment_overtime),
      pie_data: response?.sentiment_piechart,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__top_keywords: {
    value: (response) => ({
      pie_data: response?.top_keywords,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__gender_distribution: {
    value: (response) => ({
      pie_data: response?.gender_distribution,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__top_hashtags: {
    value: (response) => ({
      pie_data: response?.top_hashtags,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__reactions: {
    value: (response) => ({
      data: response?.posts_reactions?.posts_reactions
        ?.posts_reactions_over_time,
      pie_data:
        response?.posts_reactions?.posts_reactions?.posts_reactions_piechart,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__contacts_activity: {
    value: (response) => ({
      activity_data: response?.contacts_activity,
      min_value: response?.min_value,
      max_value: response?.max_value,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__top_engagers: {
    value: (response) => ({
      pie_data: response?.top_engagers,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__top_videos: {
    value: (response) => ({
      media_data: response?.top_videos,
    }),
  },
  Linkedin__CASingleDataSourceInsightsPage__top_images: {
    value: (response) => ({
      media_data: response?.top_images,
    }),
  },
};

export const LinkedinOmniRespHandling = (
  response,
  handleWidgetResponse,
  dataSource,
) => {
  const widgetKey = widgetsMapping?.[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      response?.metric_id,
      response?.monitor_id,
      dataSource,
      widgetKey?.value(response?.eventData),
      response?.customized_metric_id,
    );
};
