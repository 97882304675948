import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Pagination, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import AddKnowledgeBaseOptions from "./addKnowledgeBaseOptions";
import { useSortableTable } from "hooks/useSortableTable";
import SharedTable from "shared/table/sharedTable";
import AddKnowledgeBaseEmptyTableBody from "./addKnowledgeBaseEmptyTableBody";
import AddURLKnowledgeBase from "./addURLKnowledgeBase";
import AddKnowledgeBaseFiles from "./addKnowledgeBaseFiles";
import { useAiAgentResources } from "pages/engagements/components/aiAgents/aiAgentsHooks/useAiAgentResources";
import { renderAiAgentTableBody } from "./renderAiAgentTableBody";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";

const AddKnowledgeBase = ({
  aiAgentDetails,
  formik,
  helperText,
  isFormDisabled,
}) => {
  const agentId = aiAgentDetails?.id;
  const { selectedAiAgentPublishedVersion, publishedVersionResources } =
    useContext(AiAgentsContext);
  const intl = useIntl();
  const [stats, setStats] = useState({
    anchorEl: null,
  });
  const [isAddURLKnowledgeBaseOpen, setIsAddURLKnowledgeBaseOpen] =
    useState(false);
  const [isAddFilesOpen, setIsAddFilesOpen] = useState(false);
  const [isSwaggerFilesOpen, setIsSwaggerFilesOpen] = useState(false);
  const [page, setPage] = useState(1);
  const open = Boolean(stats?.anchorEl);

  const handleOpen = (event) => {
    formik?.setErrors({});
    setStats({
      ...stats,
      anchorEl: event?.currentTarget,
    });
  };

  const handlePageChange = (event, value) => {
    if (value === page) return;
    setPage(value);
  };

  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
  };

  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
    setPage(1);
  };

  const {
    columns,
    sortColumn,
    sortDirection,
    setIsSortableLoading = () => {
      return false;
    },
  } = useSortableTable(
    {
      initialColumns: [
        {
          className:
            "ai-agent-knowledge-base-text-transform-letter  ai-agent-knowledge-base-align-cell",
          body: CheckValueLocale("knowledge_base_table_name", "", {}, intl),
          width: "400px",
          name: "title",
          type: "string",
        },
        {
          className:
            "ai-agent-knowledge-base-text-transform-letter  ai-agent-knowledge-base-align-cell",
          body: CheckValueLocale("uploaded_at", "", {}, intl),
          width: "143px",
          name: "created_at",
          type: "date",
        },
        {
          className:
            "ai-agent-knowledge-base-text-transform-letter  ai-agent-knowledge-base-align-cell",
          body: CheckValueLocale("uploaded_by", "", {}, intl),
          width: "143px",
          name: "user_name",
          type: "string",
        },
        {
          className:
            "ai-agent-knowledge-base-text-transform-letter  ai-agent-knowledge-base-align-cell",
          body: CheckValueLocale("file_status", "", {}, intl),
          width: "143px",
          name: "status",
          type: "string",
        },
        {
          className:
            "ai-agent-knowledge-base-text-transform-letter  ai-agent-knowledge-base-align-cell",
          body: null,
          width: "67px",
        },
      ],
    },
    onSortChangeCallback,
  );

  const { data: resourcesResponse, isPending: isAiAgentResourcesLoading } =
    useAiAgentResources({
      ai_agent_id: agentId,
      limit_per_page: 10,
      page: page,
      sortColumn,
      sortDirection,
      enabled: !selectedAiAgentPublishedVersion,
    });

  const aiAgentResources = selectedAiAgentPublishedVersion
    ? publishedVersionResources
    : resourcesResponse?.data?.data;
  const paginationOptions = resourcesResponse?.data?.pagination;

  const transformedBodyData = renderAiAgentTableBody(
    aiAgentResources,
    isFormDisabled,
    handleClose,
    formik,
    selectedAiAgentPublishedVersion,
  );

  return (
    <>
      <Box
        className={`ai-agents-form-box-wrapper ${helperText ? "Mui-error" : ""}`}
      >
        <Box className="ai-agents-form-box-title" component="h4">
          {CheckValueLocale("add_knowledge_base", "", {}, intl)}
          <Box component="span" className="red-star-req">
            {` *`}
          </Box>
          {helperText ? (
            <Box component="span" className="ai-agents-form-helper-text">
              {CheckValueLocale(helperText, "", {}, intl)}
            </Box>
          ) : null}
        </Box>
        <Box className="ai-agents-form-box ai-agent-resources-wrapper">
          <Box className="add-knowledge-base-header">
            <Typography className="add-knowledge-base-description">
              {CheckValueLocale("add_knowledge_base_description", "", {}, intl)}
            </Typography>
            <LucButton
              type="primary"
              variant="outline"
              onClick={handleOpen}
              disabled={isFormDisabled}
              className="ai-agent-add-source-button"
              endIcon={<FontAwesomeIcon icon={faCaretDown} />}
              id="ai-agent-button-add-source-knowledge-base"
            >
              {CheckValueLocale("add_source", "", {}, intl)}
            </LucButton>
          </Box>
          <SharedTable
            tableHeader={columns}
            isSharedStyle
            tableBody={transformedBodyData}
            emptyMsgId="empty_channel_list_title"
            emptysubMsgId="empty_channel_list_massage"
            className="knowledge-base-table"
            image="knowledgeBaseEmptyIcon"
            title="empty_knowledge_resources_title"
            description="empty_knowledge_resources_subtitle"
            loading={isAiAgentResourcesLoading}
            emptyBody={<AddKnowledgeBaseEmptyTableBody />}
          />
          {paginationOptions?.pages > 1 && !isAiAgentResourcesLoading ? (
            <Pagination
              onChange={handlePageChange}
              page={page}
              className="pagination-resources-list"
              count={paginationOptions?.pages}
              variant="outlined"
            />
          ) : null}
        </Box>
      </Box>
      <AddKnowledgeBaseOptions
        stats={stats}
        handleClose={handleClose}
        open={open}
        setIsAddURLKnowledgeBaseOpen={setIsAddURLKnowledgeBaseOpen}
        setIsAddFilesOpen={setIsAddFilesOpen}
        setIsSwaggerFilesOpen={setIsSwaggerFilesOpen}
        hasSwagger={resourcesResponse?.data?.has_swagger}
      />
      <AddURLKnowledgeBase
        open={isAddURLKnowledgeBaseOpen}
        setIsAddURLKnowledgeBaseOpen={setIsAddURLKnowledgeBaseOpen}
        aiAgentDetails={aiAgentDetails}
        handleClose={handleClose}
      />
      <AddKnowledgeBaseFiles
        isAddFilesOpen={isAddFilesOpen}
        setIsAddFilesOpen={setIsAddFilesOpen}
        type="text_file"
        handleClose={handleClose}
      />
      <AddKnowledgeBaseFiles
        isAddFilesOpen={isSwaggerFilesOpen}
        setIsAddFilesOpen={setIsSwaggerFilesOpen}
        type="swagger"
        handleClose={handleClose}
      />
    </>
  );
};

export default AddKnowledgeBase;
