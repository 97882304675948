import React from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Grid } from "@mui/material";
import SnackBar from "components/snackBar";
import CustomDashboardHeader from "../components/customDashboardHeader";
import CustomDashboardSidebar from "./customDashboardSidebar/sidebar";
import CustomDashboardContent from "./customDashboardContent/content";
import "./style.scss";
import EditorFunctions from "./editorFunctions";
import NotFoundDashboard from "../components/not-found/NotFoundDashboard";

const CustomWidgetEdit = () => {
  const intl = useIntl();
  const params = useParams();
  const {
    contentLoading,
    sidebarLoading,
    dashboardInfo,
    sidebarWidgets,
    contentWidgets,
    setContentWidgets,
    updateCustomDashboard,
    getCustomDashboardMonitors,
    getCustomDashboardAccounts,
    availableMonitors,
    setAvailableMonitors,
    selectedMonitors,
    setSelectedMonitors,
    monitorsLoading,
    monitorsPagination,
    setMonitorsPagination,
    handleAddWidget,
    handleDeleteWidget,
    monitorsChanges,
    setMonitorsChanges,
    handleChangeMonitors,
    changesFlag,
    setChangesFlag,
    snackBarData,
    setSnackBarData,
    handleWidgetImage,
    manageDialog,
    setManageDialog,
    expandedAccordion,
    setExpandedAccordion,
    notFoundDashboard,
  } = EditorFunctions(params);

  const handleCloseSnackBar = () => {
    setSnackBarData({
      open: false,
      severity: "",
      title: "",
      message: "",
    });
  };

  return (
    <>
      {notFoundDashboard ? (
        <NotFoundDashboard />
      ) : (
        <Box id="custom-dashboard-editor">
          <CustomDashboardHeader
            isLoading={sidebarLoading || contentLoading}
            dashboardInfo={dashboardInfo}
            updateCustomDashboard={updateCustomDashboard}
            changesFlag={changesFlag}
            isEdit
          />
          <Grid container>
            <Grid item xs={3}>
              <CustomDashboardSidebar
                isLoading={sidebarLoading}
                contentLoading={contentLoading}
                monitorsLoading={monitorsLoading}
                dashboardInfo={dashboardInfo}
                sidebarWidgets={sidebarWidgets}
                getCustomDashboardMonitors={getCustomDashboardMonitors}
                getCustomDashboardAccounts={getCustomDashboardAccounts}
                availableMonitors={availableMonitors}
                setAvailableMonitors={setAvailableMonitors}
                selectedMonitors={selectedMonitors}
                setSelectedMonitors={setSelectedMonitors}
                updateCustomDashboard={updateCustomDashboard}
                monitorsPagination={monitorsPagination}
                setMonitorsPagination={setMonitorsPagination}
                handleAddWidget={handleAddWidget}
                monitorsChanges={monitorsChanges}
                setMonitorsChanges={setMonitorsChanges}
                handleChangeMonitors={handleChangeMonitors}
                contentWidgets={contentWidgets}
                handleWidgetImage={handleWidgetImage}
                manageDialog={manageDialog}
                setManageDialog={setManageDialog}
                expandedAccordion={expandedAccordion}
                setExpandedAccordion={setExpandedAccordion}
              />
            </Grid>
            <Grid item xs={9}>
              <CustomDashboardContent
                isLoading={contentLoading}
                dashboardInfo={dashboardInfo}
                contentWidgets={contentWidgets}
                setContentWidgets={setContentWidgets}
                handleDeleteWidget={handleDeleteWidget}
                setChangesFlag={setChangesFlag}
                handleWidgetImage={handleWidgetImage}
              />
            </Grid>
          </Grid>
          <SnackBar
            open={snackBarData?.open}
            severity={snackBarData?.severity}
            title={CheckValueLocale(
              snackBarData?.title,
              "",
              { maxWidgets: dashboardInfo?.max_widgets },
              intl,
            )}
            message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
            handleClose={handleCloseSnackBar}
            alertStyle={{ maxWidth: "372px" }}
          />
        </Box>
      )}
    </>
  );
};

export default CustomWidgetEdit;
