import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import "./slaSideBar.scss";
import { useIntl } from "react-intl";
import {
  CheckValueLocale,
  handlelUserRoles,
  classNames,
  handleShowTabsCounter,
} from "utils/helpers";
import SparklesImage from "images/ai-agents/sparkles-ai.svg";
import { allowedTabsChange } from "../hooks/constant";

const shouldShowLoading = (
  isSlaCounterLoading,
  filterTypeChange,
  isCurrentTab,
) => {
  // we will show the loading in the tabs if the following conditions are met
  return (
    // isSlaCounterLoading is true, this means we are fetching the data from rabbitmq
    isSlaCounterLoading &&
    // if isSlaCounterLoading is true we show the loader if one of the following conditions are met:
    // 1- the filterTypeChange is not allowed to change the tabs
    //    e.g. when the user clicks on referesh btn, change the filters, or change the datapicker
    (!allowedTabsChange.includes(filterTypeChange) ||
      // 2- the tab is the current tab, we will not show the loader in the current tab
      isCurrentTab)
  );
};

const SlaSidebar = ({
  filters,
  setFilters,
  setTabValue,
  applyFilters,
  slaCounterData,
  isOpen,
  setIsOpen,
  filterTypeChange,
}) => {
  const intl = useIntl();

  let slaItems = [
    "my_assigned_sla",
    "team_assigned_sla",
    "unassigned_sla",
    "all_sla",
    "overdue_sla",
  ];

  var activeProducts = JSON.parse(window.localStorage.activeProducts || null);
  let aiAgentActive = activeProducts?.find(
    (product) => product?.name == "AI_AGENT",
  );

  const getSlaItems = () => {
    if (
      handlelUserRoles("AI_AGENT", "VIEW_AI_AGENT_INBOX") &&
      handlelUserRoles("AI_AGENT", "VIEW_AI_AGENT_SETTINGS") &&
      aiAgentActive
    )
      slaItems.splice(2, 0, "ai_agent_sla");
    return slaItems;
  };

  return (
    <Box className={`main-sla-sidebar ${!isOpen ? "sla-closed" : ""}`}>
      <Box className={"sla-sidebar-button"} onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
      </Box>

      <Box className={`items-sla-sidebar ${!isOpen ? "items-sla-closed" : ""}`}>
        <Box className="menu-sla-sidebar">
          {CheckValueLocale("menu", "", {}, intl)}
        </Box>
        {getSlaItems()?.map((item, x) => {
          const isCurrentTab = filters?.sla_status === item;
          const tabKey = item?.replace("_sla", "");
          const isMutableTab = slaCounterData?.mutableTabs?.includes(tabKey);
          return (
            <Box
              className={`menu-item-sla-sidebar ${
                item == filters?.sla_status ? "item-selected" : ""
              }`}
              key={x}
              onClick={() => {
                if (item === filters.sla_status) return;
                filters.sla_status = item;
                filters.page = 1;
                if (item === "ai_agent_sla") {
                  filters.engagement_status = "all";
                  setTabValue(0);
                } else {
                  filters.engagement_status = "new";
                  setTabValue(1);
                }
                setFilters({ ...filters });
                applyFilters(undefined, undefined, "sla_tabs");
              }}
            >
              <Box>
                {CheckValueLocale(item, "", {}, intl)}
                {item === "ai_agent_sla" ? (
                  <img
                    src={SparklesImage}
                    className="sla-sidebar-ai-sparkles"
                  />
                ) : null}
              </Box>
              {shouldShowLoading(
                slaCounterData?.loading,
                filterTypeChange,
                isCurrentTab,
              ) ? (
                <FontAwesomeIcon icon={faSpinnerThird} spin />
              ) : (
                <Box
                  className={classNames(
                    "menu-item-sla-count",
                    slaCounterData?.changedCountKeys?.includes(tabKey) &&
                      isMutableTab
                      ? "animated-number-bounce"
                      : "",
                  )}
                >
                  {handleShowTabsCounter(
                    slaCounterData?.values?.[tabKey],
                    isMutableTab,
                  )}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SlaSidebar;
