import { IconButton, Popover, TextField, Typography } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlaneTop } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { faGrinAlt } from "@fortawesome/pro-regular-svg-icons";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";

const AIAgentTextInput = ({
  message,
  setMessage,
  handleReply,
  isAiAgentThinking,
}) => {
  const intl = useIntl();
  const [anchorElEmoji, setAnchorElEmoji] = useState(null);
  const openEmoji = Boolean(anchorElEmoji);

  const handleClickEmoji = (event) => {
    setAnchorElEmoji(event?.currentTarget);
  };
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
  };

  const emojiSelect = (e) => {
    setMessage(message + e);
    handleCloseEmoji();
  };

  const anchorPosition =
    intl?.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  const transformPosition =
    intl?.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  const isButtonDisabled =
    message?.length === 0 || message?.length > 280 || isAiAgentThinking;

  return (
    <>
      <div className="ai-agent-chat-full-width">
        <div className="ai-agent-chat-textfield-container">
          <div className="ai-agent-chat-position-relative">
            <TextField
              placeholder={CheckValueLocale(
                "ai-agent-modal-input-placeholder",
                "",
                {},
                intl,
              )}
              fullWidth
              value={message}
              onKeyDown={(e) => {
                if (e?.key === "Enter" && !e?.shiftKey) {
                  e?.preventDefault();
                  handleReply();
                }
              }}
              onChange={(e) => setMessage(e?.target?.value)}
              multiline
              className="ai-agent-chat-textfield"
            />
            <div className="ai-agent-chat-action-row">
              <IconButton onClick={handleClickEmoji} disableTouchRipple>
                <FontAwesomeIcon icon={faGrinAlt} />
              </IconButton>

              <div className="ai-agent-chat-action-row-stack">
                <Typography className="ai-agent-chat-action-row-typography">
                  {message?.length} / 280
                </Typography>
                <LucButton
                  id="ai-agent-button-send-button"
                  size="small"
                  className="ai-agent-chat-send-button"
                  onClick={handleReply}
                  disabled={isButtonDisabled}
                  disableRipple
                  endIcon={
                    <FontAwesomeIcon
                      icon={faPaperPlaneTop}
                      className="ai-agent-chat-send-icon"
                    />
                  }
                >
                  {CheckValueLocale(
                    "ai-agent-modal-input-action-title",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popover
        anchorOrigin={anchorPosition}
        transformOrigin={transformPosition}
        onClose={handleCloseEmoji}
        open={openEmoji}
        anchorEl={anchorElEmoji}
      >
        <Picker data={data} onEmojiSelect={(e) => emojiSelect(e?.native)} />
      </Popover>
    </>
  );
};

export default AIAgentTextInput;
