import { useMutation, useQueryClient } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useEditAiAgentFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, queryData }) =>
      AiAgentsController.editAiAgentFile(id, queryData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["aiAgentSwaggerFile"] });
      queryClient.invalidateQueries({ queryKey: ["aiAgentResources"] });
    },
  });
};
