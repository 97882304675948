import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  faMerge,
  faRectangleVerticalHistory,
} from "@fortawesome/pro-regular-svg-icons";
import CustomDashboardController from "services/controllers/customDashboardController";

const WidgetControlFunctions = (props) => {
  const { customDashboardData } = props;

  const params = useParams();

  const dashboardId = params?.dashboardId;
  const widgetId = +customDashboardData?.widget?.id;
  const sideBarDirection =
    window?.localStorage?.lang !== "ar" ? "right" : "left";

  const [controlSidebarOpened, setControlSidebarOpened] = useState(false);
  const [monitorsLoading, setMonitorsLoading] = useState(true);
  const [availableMonitors, setAvailableMonitors] = useState([]);
  const [selectedMonitors, setSelectedMonitors] = useState([]);
  const [viewType, setViewType] = useState(null);
  const [changesFlag, setChangesFlag] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);
  const [openedRemoveDialog, setOpenedRemoveDialog] = useState(false);
  const [removeCustomizeLoading, setRemoveCustomizeLoading] = useState(false);

  const viewTypes = [
    {
      value: "aggregate",
      title: "custom_dashboard_aggregation",
      desc: "aggregation_view_type_desc",
      icon: faMerge,
    },
    {
      value: "comparison",
      title: "custom_dashboard_comparison",
      desc: "comparison_view_type_desc",
      icon: faRectangleVerticalHistory,
    },
  ];

  const handleChangeContolSidebar = () =>
    setControlSidebarOpened(!controlSidebarOpened);

  // Set selected view type (aggregate / comparison) from returned widget
  useEffect(() => {
    if (controlSidebarOpened) {
      if (
        customDashboardData?.widget?.customized_widget?.attributes?.type?.name
      ) {
        const selectedType =
          customDashboardData?.widget?.customized_widget?.attributes?.type?.name?.toLowerCase();
        setViewType(selectedType);
      }
    } else setViewType(null);
  }, [controlSidebarOpened, customDashboardData?.widget?.customized_widget]);

  const selectedOptionsFromBE = availableMonitors?.filter(
    (option) => option?.is_selected,
  );

  useEffect(() => {
    if (
      customDashboardData?.widget?.customized_widget?.attributes?.type?.name?.toLowerCase() ===
        viewType &&
      selectedMonitors?.length === selectedOptionsFromBE?.length &&
      selectedMonitors?.every((option) => option?.is_selected)
    ) {
      setChangesFlag(false);
    } else {
      setChangesFlag(true);
    }
  }, [viewType, selectedMonitors]);

  const handleViewTypeChange = (e) => {
    setViewType(e?.target?.value);
  };

  // Get available monitors to aggregate and compare widgets with
  const getCustomizedWidgetMonitors = (dashboardId, widgetId) => {
    CustomDashboardController?.getCustomizedWidgetMonitors(
      dashboardId,
      widgetId,
    )?.then((data) => {
      setMonitorsLoading(true);
      if (!data?.errorMsg) {
        setAvailableMonitors(data?.data);
        // To set already selected monitors as selected in dropdown
        const selected = data?.data?.filter(
          (monitor) => monitor?.is_selected == true,
        );
        setSelectedMonitors(selected);
      } else setAvailableMonitors([]);
      setMonitorsLoading(false);
    });
  };

  // Get available monitors when opening the control widget sidebar
  useEffect(() => {
    if (controlSidebarOpened && dashboardId && widgetId)
      getCustomizedWidgetMonitors(dashboardId, widgetId);
  }, [controlSidebarOpened, dashboardId, widgetId]);

  // Customize widget (Choose view type, specific monitors with data sources)
  const handleApplyCustomize = () => {
    setApplyLoading(true);
    const queryData = {
      type: viewType,
      monitors_with_data_sources: selectedMonitors,
    };

    CustomDashboardController?.customizeWidget(
      dashboardId,
      widgetId,
      queryData,
    )?.then((result) => {
      if (!result?.errorMsg) {
        setControlSidebarOpened(false);
        customDashboardData?.getCustomDashboardView(dashboardId);
        customDashboardData?.setSnackBarData({
          open: true,
          severity: "success",
          title: "",
          message:
            viewType === "aggregate"
              ? "aggregated_widget_success"
              : "comparison_widget_success",
        });
      } else {
        customDashboardData?.setSnackBarData({
          open: true,
          severity: "error",
          title: "something_went_wrong",
          message: "try_again",
        });
        setControlSidebarOpened(false);
      }
      setApplyLoading(false);
    });
  };

  // Customize widget (Choose view type, specific monitors with data sources)
  const removeCustomizeWidget = (widget_id, customizeType) => {
    setRemoveCustomizeLoading(true);
    CustomDashboardController?.removeCustomizeWidget(
      dashboardId,
      widget_id,
    )?.then((result) => {
      if (!result?.errorMsg) {
        customDashboardData?.getCustomDashboardView(dashboardId);
        setOpenedRemoveDialog(false);
        customDashboardData?.setSnackBarData({
          open: true,
          severity: "success",
          title: "",
          message:
            customizeType === "AGGREGATE"
              ? "aggregated_widget_deleted_success"
              : "comparison_widget_deleted_success",
        });
      }
      setRemoveCustomizeLoading(false);
    });
  };

  const handleRemoveCustomized = (widgetId, customizeType) =>
    removeCustomizeWidget(widgetId, customizeType);

  return {
    controlSidebarOpened,
    sideBarDirection,
    handleChangeContolSidebar,
    viewTypes,
    viewType,
    handleViewTypeChange,
    monitorsLoading,
    availableMonitors,
    selectedMonitors,
    setSelectedMonitors,
    changesFlag,
    setChangesFlag,
    applyLoading,
    handleApplyCustomize,
    openedRemoveDialog,
    setOpenedRemoveDialog,
    handleRemoveCustomized,
    removeCustomizeLoading,
  };
};

export default WidgetControlFunctions;
