import { Box, Grid } from "@mui/material";
import AiAgentsFields from "../aiAgentsFields";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import SelectTeamAlgorithm from "../../../aiAgentsFormHeader/aiAgentModal/selectTeamAlgorithm";
import { useRoutingAlgorithmList } from "pages/engagements/components/aiAgents/aiAgentsHooks/useRoutingAlgorithmList";
import { useTeamList } from "pages/engagements/components/aiAgents/aiAgentsHooks/useTeamList";
import { useEffect, useState } from "react";

const HumanAssignment = ({
  formik,
  aiAgentshandleChange,
  customHandleChange,
  disabled,
}) => {
  const intl = useIntl();
  const [isTeamsLoading, setIsTeamsLoading] = useState(false);

  const { data: routingAlgorithmList } = useRoutingAlgorithmList();

  const {
    data: teamList,
    isLoading: isTeamListLoading,
    refetch: refetchTeamList,
    isRefetching: isRefetchTeamList,
  } = useTeamList(true);

  const onOpenTeamDropdown = () => {
    setIsTeamsLoading(true);
    refetchTeamList();
  };

  useEffect(() => {
    if (teamList && !isTeamListLoading && !isRefetchTeamList) {
      setIsTeamsLoading(false);
    }
  }, [teamList, isTeamListLoading, isRefetchTeamList]);

  const teamOptionsList =
    teamList?.map((item) => {
      return item?.id;
    }) || [];

  const teamOptionsListObj =
    teamList?.map((item) => {
      return {
        name: item?.name,
        id: item?.id,
      };
    }) || [];

  const onClickAddNewTeam = () => {
    window.open("/settings/teams/add", "_blank");
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={5}>
        <Box className="ai-agents-form-box-description" component="p">
          {CheckValueLocale("auto_assignment_sec_desc", "", {}, intl)}
        </Box>
      </Grid>
      <Grid item xs={7}>
        <AiAgentsFields
          disabled={disabled}
          type="autoComplete"
          id="ai-agent-team-human-select"
          name="team_id"
          value={formik?.values?.team_id}
          optionsList={teamOptionsList}
          optionsListObj={teamOptionsListObj}
          onOpen={onOpenTeamDropdown}
          handleChange={(e, value) => customHandleChange("team_id", value)}
          error={!!formik?.errors?.team_id}
          onBlur={formik?.handleBlur}
          helperText={CheckValueLocale(formik?.errors?.team_id, "", {}, intl)}
          label={CheckValueLocale("route_to_team", "", {}, intl)}
          placeholder={CheckValueLocale(
            "routing_select_team_input",
            "",
            {},
            intl,
          )}
          emptyListMessage={CheckValueLocale(
            "no_teams_found_to_show",
            "",
            {},
            intl,
          )}
          customOptionLabel={CheckValueLocale(
            "routing_add_new_team",
            "",
            {},
            intl,
          )}
          onCustomOptionClick={onClickAddNewTeam}
          icon={faExternalLink}
          isLoading={isTeamsLoading}
          required
        />
        <SelectTeamAlgorithm
          routingAlgorithmList={routingAlgorithmList}
          value={formik?.values?.auto_assign_algorithm_id}
          handleChange={(id) =>
            customHandleChange("auto_assign_algorithm_id", id)
          }
          helperText={CheckValueLocale(
            formik?.errors?.auto_assign_algorithm_id,
            "",
            {},
            intl,
          )}
          disabled={disabled}
        />
        <AiAgentsFields
          disabled={disabled}
          type="textarea"
          id="instructions-ai-agents-luci"
          label={CheckValueLocale("create_an_auto_reply", "", {}, intl)}
          labelHint={`(${CheckValueLocale("optional", "", {}, intl)})`}
          placeholder={CheckValueLocale("write_here", "", {}, intl)}
          name="auto_reply_transfer"
          value={formik?.values?.auto_reply_transfer}
          onChange={aiAgentshandleChange}
          error={!!formik?.errors?.auto_reply_transfer}
          onBlur={formik?.handleBlur}
          helperText={CheckValueLocale(
            formik?.errors?.auto_reply_transfer,
            "",
            {},
            intl,
          )}
        />
      </Grid>
    </Grid>
  );
};

export default HumanAssignment;
