import { Box } from "@mui/material";
import PopupModal from "components/popupModal";
import { CheckValueLocale, isEmptyValue } from "utils/helpers";
import { useIntl } from "react-intl";
import RoutingSelectTeam from "./routingSelectTeam";
import { useDeleteAiAgent } from "pages/engagements/components/aiAgents/aiAgentsHooks/useDeleteAiAgent";
import { useFormik } from "formik";
import { DeleteValidationSchema } from "pages/engagements/components/aiAgents/aiAgentsValidation/deleteValidationSchema";
import { useContext } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";

const DeleteAiAgentModal = ({
  openPopupDelete,
  setOpenPopupDelete,
  aiAgentDetails,
}) => {
  const intl = useIntl();
  const { setAiAgentSnackBar, setAiAgentSelected } =
    useContext(AiAgentsContext);

  const { mutate: deleteAiAgent, isPending: deleteAiAgentLoading } =
    useDeleteAiAgent();

  const unassignedStatus = aiAgentDetails?.auto_assignment === "unassigned";

  const deleteInitialValues = {
    auto_assignment: "unassigned",
    team_id: null,
    auto_assign_algorithm_id: null,
    auto_reply: "",
  };

  const formik = useFormik({
    initialValues: deleteInitialValues,
    validationSchema: DeleteValidationSchema,
  });

  const handleDeleteAiAgent = () => {
    const { auto_assignment, team_id, auto_assign_algorithm_id, auto_reply } =
      formik?.values;
    let queryData = {
      auto_assignment,
      auto_reply,
    };
    if (auto_assignment !== "unassigned") {
      queryData = {
        ...queryData,
        team_id,
        auto_assign_algorithm_id,
      };
    }
    deleteAiAgent(
      { id: aiAgentDetails?.id, queryData },
      {
        onSuccess: (data) => {
          if (!data?.errorMsg) {
            setAiAgentSnackBar({
              success: true,
              message: CheckValueLocale(
                "ai_agent_delete_success_message",
                "",
                { name: aiAgentDetails?.name },
                intl,
              ),
            });
            setAiAgentSelected(null);
          } else {
            setAiAgentSnackBar({
              success: false,
              title: CheckValueLocale("failed_error_message", "", {}, intl),
              message: CheckValueLocale(
                "try_again_error_message",
                "",
                {},
                intl,
              ),
            });
          }
          closeModal();
        },
      },
    );
  };

  const deleteAiAgentButtonDisabled =
    formik?.values?.auto_assignment === "manual_assign"
      ? !isEmptyValue(formik?.errors || {})
      : false;

  const closeModal = () => {
    setOpenPopupDelete(null);
    formik?.resetForm();
  };

  let modalBody = (
    <Box>
      <Box component="p" className="ai-agent-modal-hint">
        {CheckValueLocale(
          "delete_ai_agent_desc",
          "",
          { name: aiAgentDetails?.name },
          intl,
        )}
      </Box>
      <RoutingSelectTeam formik={formik} unassignedStatus={unassignedStatus} />
      <Box component="p" className="ai-agent-modal-hint">
        {CheckValueLocale(
          "delete_ai_agent_sure",
          "",
          { name: aiAgentDetails?.name },
          intl,
        )}
        <Box component="span">
          {CheckValueLocale("delete_ai_agent_not_reversed", "", {}, intl)}
        </Box>
      </Box>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("delete_ai_agent", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("delete_re_assign", "", {}, intl)}
      disabled={deleteAiAgentButtonDisabled || deleteAiAgentLoading}
      isRightBtnLoading={deleteAiAgentLoading}
      open={openPopupDelete || false}
      close={closeModal}
      accept={handleDeleteAiAgent}
      classeName={"ai-agent-popupModal"}
      dialogContentId="delete-ai-agent"
      warning
    />
  );
};
export default DeleteAiAgentModal;
