import Companies from "../api/companies";

var CompaniesController = {};

CompaniesController.getCompanyProducts = () => {
  return new Promise(function (resolve) {
    Companies.getCompanyProducts()
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getCompanyDataCategories = () => {
  return new Promise(function (resolve) {
    Companies.getCompanyDataCategories()
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getDataSourcesForCategory = (categoryName) => {
  return new Promise(function (resolve) {
    Companies.getDataSourcesForCategory("", categoryName)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getCompanyApps = () => {
  var queryData = {};
  return new Promise((resolve) => {
    Companies.getCompanyApps(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getAuthorizedAccounts = (id) => {
  var queryData = {
    id,
  };
  return new Promise(function (resolve) {
    Companies.getAuthorizedAccounts(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.WhatsAppIntegrationRequests = (
  product_id,
  data_source_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Companies.WhatsAppIntegrationRequests(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Get Company Info
CompaniesController.getCompanyInfo = () => {
  return new Promise((resolve) => {
    Companies.getCompanyInfo()
      .then((response) => {
        const data = response?.data?.data?.attributes;
        window.localStorage.setItem(
          "isLuciBusDet",
          data?.has_luci_company_business_detail,
        );
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Edit Company Info
CompaniesController.putCompanyInfo = (companyInfo) => {
  return new Promise((resolve) => {
    Companies.putCompanyInfo(companyInfo)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Get company accounts (authorizations) list
CompaniesController.getCompanyAuthorizations = (
  sort_by,
  order_by,
  page,
  search,
  data_source_id,
  created_by_id,
) => {
  const queryData = {
    sort_by,
    order_by,
    page,
    search,
    data_source: data_source_id,
    created_by_id,
  };
  return new Promise((resolve) => {
    Companies.getCompanyAuthorizations(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};
// Get users accounts (authorizations) list
CompaniesController.getUsersAuthorizations = (
  sort_by,
  order_by,
  page,
  search,
  created_by_id,
  data_source,
) => {
  const queryData = {
    sort_by,
    order_by,
    page,
    search,
    data_source,
    created_by_id,
  };
  return new Promise((resolve) => {
    Companies.getUsersAuthorizations(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};

/* Company Users */

// Get Company Users
CompaniesController.getCompanyUsers = (search, page, sort_by, order_by) => {
  const queryData = {
    search,
    page,
    sort_by,
    order_by,
  };
  return new Promise((resolve) => {
    Companies.getCompanyUsers(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.getCompanyActiveUsers = () => {
  return new Promise((resolve) => {
    Companies.getCompanyActiveUsers()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Get Company User
CompaniesController.getCompanyUser = (id) => {
  return new Promise((resolve) => {
    Companies.getCompanyUser(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Add Company User
CompaniesController.postCompanyUser = (user_info, roles) => {
  const userInfo = { user_info, roles };
  return new Promise((resolve) => {
    Companies.postCompanyUser(userInfo)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Edit Company User
CompaniesController.putCompanyUser = (id, user_info, roles) => {
  const userInfo = { user_info, roles };
  return new Promise((resolve) => {
    Companies.putCompanyUser(id, userInfo)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Delete Company User
CompaniesController.deleteCompanyUser = (id) => {
  return new Promise((resolve) => {
    Companies.deleteCompanyUser(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Deactivate Company User
CompaniesController.deactiveCompanyUser = (id) => {
  return new Promise((resolve) => {
    Companies.deactiveCompanyUser(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Activate Company User
CompaniesController.activeCompanyUser = (id) => {
  return new Promise((resolve) => {
    Companies.activeCompanyUser(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Invite Company User
CompaniesController.inviteCompanyUser = (id) => {
  return new Promise((resolve) => {
    Companies.inviteCompanyUser(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Send Manager Request Call
CompaniesController.sendRequestCall = (manager_id) => {
  let customerSuccessManager = { customer_success_id: manager_id };
  return new Promise((resolve) => {
    Companies.sendRequestCall(customerSuccessManager)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getCompanySubscriptions = () => {
  return new Promise((resolve) => {
    Companies.getCompanySubscriptions()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Request Upgrade Package
CompaniesController.sendRequestPackageUpgrade = (product_id) => {
  let productInfo = {
    product_id: product_id,
  };
  return new Promise((resolve) => {
    Companies.sendRequestPackageUpgrade(productInfo)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Request Product Demo
CompaniesController.sendRequestDemo = (product_id) => {
  let productInfo = {
    product_id: product_id,
  };
  return new Promise((resolve) => {
    Companies.sendRequestDemo(productInfo)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Request Services
CompaniesController.sendServiceRequest = (serviceName) => {
  let serviceInfo = {
    service_name: serviceName,
  };
  return new Promise((resolve) => {
    Companies.sendServiceRequest(serviceInfo)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Integrate Intercom
CompaniesController.integrateIntercom = (intercomInfo) => {
  return new Promise((resolve) => {
    Companies.intercomIntegrate(intercomInfo)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getIntegrateIntercomData = () => {
  return new Promise((resolve) => {
    Companies.getIntegrateIntercomData()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Integrate Zendesk
CompaniesController.integrateZendesk = (zendeskInfo) => {
  return new Promise((resolve) => {
    Companies.zendeskIntegrate(zendeskInfo)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//get zendesk data
CompaniesController.getIntegrateZendeskData = () => {
  return new Promise((resolve) => {
    Companies.getIntegrateZendeskData()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          notInPackage: error.response.data.exception,
        });
      });
  });
};
// Third Party List
CompaniesController.thirdPartyList = () => {
  return new Promise((resolve) => {
    Companies.thirdPartyList()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

/* Reports Branding */

// GET Reports Branding
CompaniesController.getReportsBranding = () => {
  return new Promise((resolve) => {
    Companies.getReportsBranding()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// POST Reports Branding
CompaniesController.postReportsBranding = (reportsBranding) => {
  return new Promise((resolve) => {
    Companies.postReportsBranding(reportsBranding)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};

// GET Fonts List
CompaniesController.getFonts = () => {
  return new Promise((resolve) => {
    Companies.getFonts()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.requestThirdPartyDemo = (serviceName) => {
  return new Promise((resolve) => {
    Companies.requestThirdPartyDemo(serviceName)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//send service_name
CompaniesController.requestService = (serviceName) => {
  return new Promise((resolve) => {
    Companies.requestService(serviceName)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Get Company Monitor Types
CompaniesController.getCompanyMonitorTypes = () => {
  return new Promise((resolve) => {
    Companies.getCompanyMonitorTypes()
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getCompanyDataInsightsQuota = (startDate, endDate) => {
  var queryData = {
    start_date: startDate,
    end_date: endDate,
  };
  return new Promise((resolve) => {
    Companies.getCompanyDataInsightsQuota(queryData)
      .then((response) => {
        resolve({
          data: response.data.platforms,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getHistoricalRequestsList = (page, sort_by, order_by) => {
  const queryData = {
    page: page,
    sort_by: sort_by,
    order_by: order_by,
  };
  return new Promise((resolve) => {
    Companies.getHistoricalRequestsList(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getCompanySingleTweetsQuota = (
  tweetID,
  monitorID,
  productID,
  tweetTime,
  screenName,
  startDate,
  endDate,
) => {
  const queryData = {
    monitor_id: monitorID,
    product_id: productID,
    tweet_time: tweetTime,
    screen_name: screenName,
    start_date: startDate,
    end_date: endDate,
  };
  return new Promise((resolve) => {
    Companies.getCompanySingleTweetsQuota(tweetID, queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.deleteHistReq = (reqId) => {
  return new Promise(function (resolve) {
    Companies.deleteHistReq(reqId)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.getHistoricalCompanyPlatforms = () => {
  return new Promise((resolve) => {
    Companies.getHistoricalCompanyPlatforms()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getHistoricalDatasources = (product_id) => {
  const queryData = {
    product_id: product_id,
  };
  return new Promise((resolve) => {
    Companies.getHistoricalDatasources(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getHistoricalMonitors = (product_id, data_source_id) => {
  const queryData = {
    product_id: product_id,
    data_source_id: data_source_id,
  };
  return new Promise((resolve) => {
    Companies.getHistoricalMonitors(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.createHistoricalRequest = (queryData) => {
  return new Promise((resolve) => {
    Companies.createHistoricalRequest(queryData)
      .then((response) => {
        resolve({
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error.response.status,
        });
      });
  });
};
CompaniesController.getAvailableDataTwitter = (queryData) => {
  return new Promise((resolve) => {
    Companies.getAvailableDataTwitter(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.createTeam = (queryData) => {
  return new Promise((resolve) => {
    Companies.createTeam(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};
CompaniesController.getTeams = (page, search) => {
  const queryData = {
    page,
    search,
  };
  return new Promise((resolve) => {
    Companies.getTeams(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};

CompaniesController.updateTeam = (id, updatedTeam) => {
  return new Promise((resolve) => {
    Companies.updateTeam(id, updatedTeam)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};
CompaniesController.getTeam = (id) => {
  return new Promise((resolve) => {
    Companies.getTeam(id)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};
CompaniesController.getTeamMembersSelected = (id, page_number) => {
  const queryData = {
    page_number,
  };
  return new Promise((resolve) => {
    Companies.getTeamMembersSelected(id, queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};

CompaniesController.getSelectedTeamMembers = (id, page_number) => {
  const queryData = {
    page_number,
  };
  return new Promise((resolve) => {
    Companies.getSelectedTeamMembers(id, queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};

CompaniesController.deleteTeam = (id) => {
  return new Promise((resolve) => {
    Companies.deleteTeam(id)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};

CompaniesController.getTeamMembers = (page_number) => {
  const queryData = {
    page_number,
  };
  return new Promise((resolve) => {
    Companies.getTeamMembers(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};

CompaniesController.getTeamProducts = () => {
  return new Promise((resolve) => {
    Companies.getTeamProducts()
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};

CompaniesController.getCompanySkills = () => {
  return new Promise(function (resolve) {
    Companies.getCompanySkills()
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: error?.response?.status,
        });
      });
  });
};

CompaniesController.addCompanySkills = (skillInfo) => {
  return new Promise((resolve) => {
    Companies.addCompanySkills(skillInfo)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getCompanyAiContent = () => {
  return new Promise(function (resolve) {
    Companies.getCompanyAiContent()
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.getCompanySegments = () => {
  return new Promise(function (resolve) {
    Companies.getCompanySegments()
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getCompanyLanguages = () => {
  return new Promise(function (resolve) {
    Companies.getCompanyLanguages()
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getAllCompanyTeams = () => {
  return new Promise(function (resolve) {
    Companies.getAllCompanyTeams()
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getAllCompanyRoutings = (product_id) => {
  const queryParams = {
    product_id,
  };
  return new Promise(function (resolve) {
    Companies.getAllCompanyRoutings(queryParams)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.getUsersAllowedToCreate = (product_id, monitor_id) => {
  var queryData = {
    product_id,
    monitor_id,
  };
  return new Promise((resolve) => {
    Companies.getUsersAllowedToCreate(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.upgradeQuota = (service_name) => {
  const queryData = {
    service_name: service_name,
  };
  return new Promise((resolve) => {
    Companies.upgradeQuota(queryData)
      .then((response) => {
        resolve({
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.retryRequest = (id) => {
  return new Promise((resolve) => {
    Companies.retryRequest(id)
      .then((response) => {
        resolve({
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.stopRequest = (id) => {
  return new Promise((resolve) => {
    Companies.stopRequest(id)
      .then((response) => {
        resolve({
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          errorMsg: error,
        });
      });
  });
};

CompaniesController.createHistoricalRequestFlag = () => {
  return new Promise((resolve) => {
    Companies.createHistoricalRequestFlag()
      .then((response) => {
        resolve({
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.getSidebarCategories = () => {
  return new Promise((resolve) => {
    Companies.getSidebarCategories()
      .then((response) => {
        resolve({
          data: response?.data?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//get custom domain
CompaniesController.getCustomDomain = () => {
  return new Promise((resolve) => {
    Companies.getCustomDomain()
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//delete & create custom domain
CompaniesController.sendAndDeleteCustomDomain = (custom_domain) => {
  const queryData = {
    custom_domain,
  };
  return new Promise((resolve) => {
    Companies.sendAndDeleteCustomDomain(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// start lucidya apis

// Get lucidya apis list
CompaniesController.getLucidyaApisList = (page, id) => {
  var queryData = {
    page,
    product_id: id,
  };
  return new Promise((resolve) => {
    Companies.getLucidyaApisList(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// create lucidya api
CompaniesController.createLucidyaApisRequest = (queryData) => {
  return new Promise((resolve) => {
    Companies.createLucidyaApisRequest(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// get single lucidya api
CompaniesController.getSingleLucidyaApis = (id) => {
  const queryData = {
    id,
  };
  return new Promise(function (resolve) {
    Companies.getSingleLucidyaApis(queryData)
      .then((response) => {
        resolve({
          response: response,
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// edit lucidya api
CompaniesController.editLucidyaApisRequest = (id, queryData) => {
  return new Promise((resolve) => {
    Companies.editLucidyaApisRequest(id, queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Delete lucidya api
CompaniesController.deleteLucidyaApisRequest = (id) => {
  return new Promise((resolve) => {
    Companies.deleteLucidyaApisRequest(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// activate lucidya api
CompaniesController.activateLucidyaApisRequest = (id) => {
  return new Promise((resolve) => {
    Companies.activateLucidyaApisRequest(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// inActivate lucidya api
CompaniesController.inActivateLucidyaApisRequest = (id) => {
  return new Promise((resolve) => {
    Companies.inActivateLucidyaApisRequest(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// regenerate lucidya api
CompaniesController.regenerateLucidyaApisRequest = (id) => {
  return new Promise((resolve) => {
    Companies.regenerateLucidyaApisRequest(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Get lucidya apis Types list
CompaniesController.getLucidyaApisTypes = () => {
  return new Promise(function (resolve) {
    Companies.getLucidyaApisTypes()
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getAccounts = (page_number, limit_per_page) => {
  let queryData = {
    page_number,
    limit_per_page,
  };
  return new Promise(function (resolve) {
    Companies.getAccounts(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getMonitorsOrChannels = (
  product_id,
  page_number,
  limit_per_page,
) => {
  let queryData = {
    product_id,
    page_number,
    limit_per_page,
  };
  return new Promise(function (resolve) {
    Companies.getMonitorsOrChannels(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getEngagPermissions = () => {
  return new Promise(function (resolve) {
    Companies.getEngagPermissions()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getUserView = (id) => {
  return new Promise((resolve) => {
    Companies.getUserView(id)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.createCompanyUsers = (queryData) => {
  return new Promise((resolve) => {
    Companies.createCompanyUsers(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          errorMsg: error,
        });
      });
  });
};

CompaniesController.editCompanyUsers = (queryData, id) => {
  return new Promise((resolve) => {
    Companies.editCompanyUsers(queryData, id)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getAccountsEdit = (page_number, limit_per_page, id) => {
  let queryData = {
    page_number,
    limit_per_page,
  };
  return new Promise(function (resolve) {
    Companies.getAccountsEdit(queryData, id)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

CompaniesController.getMonitorsOrChannelsEdit = (
  product_id,
  page_number,
  limit_per_page,
  id,
) => {
  let queryData = {
    product_id,
    page_number,
    limit_per_page,
  };
  return new Promise(function (resolve) {
    Companies.getMonitorsOrChannelsEdit(queryData, id)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.getUserActivityLogs = (
  start_date,
  end_date,
  user_ids,
  products,
  page,
  team_id,
  item_id,
) => {
  let queryData = {
    start_date,
    end_date,
    user_ids,
    products,
    page,
    team_id,
    item_id,
  };

  return new Promise(function (resolve) {
    Companies.getUserActivityLogs(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
CompaniesController.getFilterActivityLogs = (queryData) => {
  return new Promise(function (resolve) {
    Companies.getFilterActivityLogs(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default CompaniesController;
