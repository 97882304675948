import {
  days,
  workgingHoursData,
} from "../../userManagement/addNewUser/tabs/workingHours";
import {
  hourNumberToString,
  hourStringToNumber,
} from "../../userManagement/addNewUser/tabs/workingHoursForDay";

export const transformTramWorkingHoursResponse = (workingHoursData) => {
  const workingHoursObject = workingHoursData || {};
  const workingHours = {};
  Object.entries(days).forEach(([day, value]) => {
    let currentDayRanges = workgingHoursData[value];
    if (workingHoursObject[day]?.length) {
      currentDayRanges = workingHoursObject[day]?.map((range) => {
        return {
          from: hourStringToNumber(range?.from),
          to: hourStringToNumber(range?.to),
          selected: true,
        };
      });
    }
    workingHours[value] = currentDayRanges;
  });
  return workingHours;
};

export const transformWorkingHoursPayload = (workingHours) => {
  return Object.entries(workingHours).reduce((acc, [day, dayRanges]) => {
    const validRanges = [];
    dayRanges.forEach((range) => {
      if (range?.from && range?.to) {
        validRanges.push({
          from: hourNumberToString(range?.from),
          to: hourNumberToString(range?.to),
        });
      }
    });
    if (validRanges?.length) {
      acc[day?.toLowerCase()] = validRanges;
    }
    return acc;
  }, {});
};
