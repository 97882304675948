import { Box } from "@mui/material";
import NoDataFoundProducts from "components/no-Data";

const AddKnowledgeBaseEmptyTableBody = () => {
  return (
    <Box className="empty-knowledge-base-box">
      <NoDataFoundProducts
        image="knowledgeBaseEmptyIcon"
        title="empty_knowledge_resources_title"
        description="empty_knowledge_resources_subtitle"
      />
    </Box>
  );
};

export default AddKnowledgeBaseEmptyTableBody;
