import {
  changeFromObjectToArrayOfObject,
  handleSentimentResponse,
} from "utils/helpers";
import {
  handleAverageEngagementStatsData,
  handleCustomerCareStatsData,
} from "../statsDataHandling";

const widgetsMapping = {
  // ----------- Instagram Public -----------
  Instagram__AccountPage__posts_interactions: {
    value: (response) => ({
      data: response?.posts_interactions?.posts_interactions_over_time,
      pie_data: response?.posts_interactions?.posts_interactions_legends,
      max_interactions: response?.posts_interactions?.max_interactions,
      min_interactions: response?.posts_interactions?.min_interactions,
      max_interaction_date: response?.posts_interactions?.max_interaction_date,
      min_interaction_date: response?.posts_interactions?.min_interaction_date,
      average_interactions: response?.posts_interactions?.average_interactions,
    }),
  },
  Instagram__AccountPage__account_author_activity: {
    value: (response) => ({
      activity_data: response?.account_author_activity,
      min_value: response?.min_value,
      max_value: response?.max_value,
    }),
  },
  Instagram__AccountPage__volume_overtime: {
    value: (response) => ({
      data: response?.posts_over_time,
      total_count: response?.total_posts_count,
    }),
  },
  Instagram__EngagementsPage__volume_overtime: {
    value: (response) => ({
      data: response?.posts_over_time,
      total_count: response?.total_posts_count,
    }),
  },
  Instagram__CASingleDataSourceInsightsPage__volume_overtime: {
    value: (response) => ({
      data: response?.posts_over_time,
      total_count: response?.total_posts_count,
    }),
  },
  Instagram__AccountPage__average_engagement_per_post: {
    value: (response) => ({
      data: response?.posts_interactions?.posts_engagements_over_time,
      average_interactions: response?.posts_interactions?.average_interactions,
      max_engagement_date: response?.posts_interactions?.max_engagement_date,
      max_engagements: response?.posts_interactions?.max_engagements,
      min_engagement_date: response?.posts_interactions?.min_engagement_date,
      min_engagements: response?.posts_interactions?.min_engagements,
      stats_data: handleAverageEngagementStatsData(
        response?.posts_interactions,
      ),
    }),
  },
  Instagram__EngagementsPage__themes_and_sub_themes: {
    value: (response) => ({ data: response?.themes }),
  },
  Instagram__AccountPage__content_type: {
    value: (response) => ({
      data: response?.posts_content_type,
      pie_data: response?.posts_content_types_legends,
    }),
  },
  Instagram__EngagementsPage__sentiment_analysis: {
    value: (response) => ({
      data: handleSentimentResponse(response?.sentiment_overtime),
      pie_data: response?.sentiment_piechart,
    }),
  },
  Instagram__EngagementsPage__comments_interactions: {
    value: (response) => ({
      data: response?.comments_interactions,
      pie_data: response?.comments_interactions_legends,
      max_interactions: response?.max_interactions,
      min_interactions: response?.min_interactions,
      max_interaction_date: response?.max_interaction_date,
      min_interaction_date: response?.min_interaction_date,
      average_interactions: response?.average_interactions,
    }),
  },
  Instagram__EngagementsPage__top_hashtags: {
    value: (response) => ({
      pie_data: response?.top_hashtags,
    }),
  },
  Instagram__EngagementsPage__associated_topics: {
    value: (response) => ({
      pie_data: response?.associated_topics,
    }),
  },
  Instagram__EngagementsPage__dialects_and_sub_dialects: {
    value: (response) => ({
      pie_data: response?.dialects,
      pie_sub_data: response?.sub_dialects,
    }),
  },
  Instagram__EngagementsPage__top_keywords: {
    value: (response) => ({
      pie_data: response?.top_keywords,
    }),
  },
  Instagram__CASingleDataSourceInsightsPage__customer_care_performance: {
    value: (response) => ({
      data: changeFromObjectToArrayOfObject(response?.response_time_segment),
      total_number_of_questions: response?.total_number_of_questions,
      avg_response_time_in_seconds: response?.avg_response_time_in_seconds,
      number_of_answered_questions: response?.number_of_answered_questions,
      stats_data: handleCustomerCareStatsData(response),
    }),
  },
  Instagram__AccountPage__top_videos: {
    value: (response) => ({
      media_data: response?.top_videos,
    }),
  },
  Instagram__AccountPage__top_images: {
    value: (response) => ({
      media_data: response?.top_images,
    }),
  },
  Instagram__EngagementsPage__top_engagers: {
    value: (response) => ({
      pie_data: response?.top_engagers,
    }),
  },

  // ----------- Instagram Private -----------
  sentiment: {
    value: (response) => ({
      data: handleSentimentResponse(response?.sentiment_overtime),
      pie_data: response?.sentiment_piechart,
    }),
  },
  themes: {
    value: (response) => ({ data: response?.themes }),
  },
  content_type: {
    value: (response) => ({
      pie_data: response?.content_type_piechart,
    }),
  },
  dialects: {
    value: (response) => ({
      pie_data: response?.dialects,
      pie_sub_data: response?.sub_dialects,
    }),
  },
  channel_accounts: {
    value: (response) => ({
      pie_data: response?.channel_accounts,
    }),
  },
  interactions: {
    value: (response) => ({
      data: response?.interactions,
      total_count: response?.total_interactions,
    }),
  },
  top_keywords: {
    value: (response) => ({
      pie_data: response?.top_keywords,
    }),
  },
  genders: {
    value: (response) => ({
      pie_data: response?.genders,
    }),
  },
  top_engaged_authors: {
    value: (response) => ({
      pie_data: response?.top_engaged_authors,
    }),
  },
  channel_activity: {
    value: (response) => ({
      activity_data: response?.channel_activity?.client,
      min_value: response?.channel_activity?.min_value_client,
      max_value: response?.channel_activity?.max_value_client,
    }),
  },
};

export const InstagramOmniRespHandling = (
  response,
  handleWidgetResponse,
  dataSource,
) => {
  const widgetKey = widgetsMapping[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      response?.metric_id,
      response?.monitor_id,
      response?.eventName?.includes("__")
        ? dataSource
        : `${dataSource}_PRIVATE`,
      widgetKey?.value(response?.eventData),
      response?.customized_metric_id,
    );
};
