import { CheckValueLocale } from "utils/helpers";
import * as yup from "yup";

export const addUrlResourceValidationSchema = (intl) => {
  return yup.object().shape({
    title: yup
      .string()
      .required(CheckValueLocale("required", "", {}, intl))
      .min(
        3,
        CheckValueLocale(
          "ai_agent_url_resource_min_length",
          "",
          { length: 3 },
          intl,
        ),
      ),
    url: yup
      .string()
      .url(CheckValueLocale("invalid_url", "", {}, intl))
      .required(CheckValueLocale("required", "", {}, intl)),
  });
};
