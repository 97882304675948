import { useMutation, useQueryClient } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useDeleteResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => AiAgentsController.deleteResource(data),
    onSuccess: (data) => {
      if (!data?.errorMsg) {
        queryClient.invalidateQueries({ queryKey: ["aiAgentResources"] });
        queryClient.invalidateQueries({ queryKey: ["aiAgentDetails"] });
      }
    },
  });
};
