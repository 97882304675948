import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import SelectTeamAlgorithm from "./selectTeamAlgorithm";
import { useTeamList } from "pages/engagements/components/aiAgents/aiAgentsHooks/useTeamList";
import { useRoutingAlgorithmList } from "pages/engagements/components/aiAgents/aiAgentsHooks/useRoutingAlgorithmList";
import AiAgentsFields from "../../aiAgentsFormContent/component/aiAgentsFields";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";

const SelectTeamBox = ({ formik }) => {
  const intl = useIntl();
  const [isTeamLoading, setIsTeamLoading] = useState(false);

  const { data: routingAlgorithmList } = useRoutingAlgorithmList();

  const {
    data: teamList,
    isLoading: teamListLoading,
    refetch: refetchTeamList,
    isRefetching: isRefetchTeamList,
  } = useTeamList(true);

  const onClickAddNewTeam = () => {
    window.open("/settings/teams/add", "_blank");
  };

  const onOpenTeamDropdown = () => {
    setIsTeamLoading(true);
    refetchTeamList();
  };

  useEffect(() => {
    if (teamList && !teamListLoading && !isRefetchTeamList) {
      setIsTeamLoading(false);
    }
  }, [teamList, teamListLoading, isRefetchTeamList]);
  const teamOptionsList =
    teamList?.map((item) => {
      return item?.id;
    }) || [];

  const teamOptionsListObj =
    teamList?.map((item) => {
      return {
        name: item?.name,
        id: item?.id,
      };
    }) || [];

  return (
    <>
      <AiAgentsFields
        type="autoComplete"
        id="ai-agent-team-select"
        name="team_id"
        value={formik?.values?.team_id}
        optionsList={teamOptionsList}
        optionsListObj={teamOptionsListObj}
        handleChange={(e, value) => formik?.setFieldValue("team_id", value)}
        label={CheckValueLocale("route_to_team", "", {}, intl)}
        placeholder={CheckValueLocale(
          "routing_select_team_input",
          "",
          {},
          intl,
        )}
        emptyListMessage={CheckValueLocale(
          "no_teams_found_to_show",
          "",
          {},
          intl,
        )}
        customOptionLabel={CheckValueLocale(
          "routing_add_new_team",
          "",
          {},
          intl,
        )}
        onCustomOptionClick={onClickAddNewTeam}
        icon={faExternalLink}
        isLoading={isTeamLoading}
        required
        onOpen={onOpenTeamDropdown}
      />
      <SelectTeamAlgorithm
        routingAlgorithmList={routingAlgorithmList}
        value={formik?.values?.auto_assign_algorithm_id}
        handleChange={(id) =>
          formik?.setFieldValue("auto_assign_algorithm_id", id)
        }
      />
    </>
  );
};
export default SelectTeamBox;
