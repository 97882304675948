import {
  faCheckDouble,
  faMessageLines,
  faPlay,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, FormControlLabel, Switch, Tooltip } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { useIntl } from "react-intl";
import AiAgentOptions from "./aiAgentOptions";
import PauseAiAgentModal from "./aiAgentModal/pauseAiAgentModal";
import ResumeAiAgentModal from "./aiAgentModal/resumeAiAgentModal";
import { useContext, useState } from "react";
import RunTestModal from "./aiAgentModal/runTestModal/runTestModal";
import { AiAgentsContext } from "../../../aiAgentsContext";
import { useResumeAiAgent } from "../../../aiAgentsHooks/useResumeAiAgent";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { aiAgentData } from "utils/redux/features/Engagements/EngagementsSlice.js";

const AiAgentsFormHeader = ({
  formik,
  aiAgentDetails,
  isAiAgentEditData,
  publishAiAgentLoading,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAr = intl?.locale === "ar";
  const [isAiAgentTestModalOpen, setIsAiAgentTestModalOpen] = useState(false);
  const [openPopupResume, setOpenPopupResume] = useState(false);
  const [openPopupPause, setOpenPopupPause] = useState(false);

  const isAiAgentPublished = aiAgentDetails?.status === "published";
  const isAiAgentPublishing = aiAgentDetails?.status === "publishing";
  const isAiAgentPaused = aiAgentDetails?.status === "paused";
  const isAiAgentDraft = aiAgentDetails?.status === "draft";
  const isEdited = aiAgentDetails?.edited;
  const validRunTest = aiAgentDetails?.valid_for_publish;
  const pauseResumeButton = isAiAgentPublished || isAiAgentPaused;

  const {
    setAiAgentSnackBar,
    isAiAgentDown,
    isDMLimitReached,
    isLimitReached,
  } = useContext(AiAgentsContext);
  const { mutate: resumeAiAgent, isPending: resumeAiAgentLoading } =
    useResumeAiAgent();

  const hasInboundRouting =
    aiAgentDetails?.auto_assignment === "inbound_routing";
  const isInboundRoutingUsed =
    hasInboundRouting && !aiAgentDetails?.inbound_routing_used?.status;

  const isUnassigned = aiAgentDetails?.auto_assignment === "unassigned";
  const isUnassignedUsed =
    isUnassigned && !aiAgentDetails?.unassigned_used?.status;

  const handleOpenResumeModal = () => {
    if (isInboundRoutingUsed || isUnassignedUsed) {
      handleResume();
    } else {
      setOpenPopupResume(true);
    }
  };

  const handleResume = () => {
    resumeAiAgent(
      { id: aiAgentDetails?.id },
      {
        onSuccess: (data) => {
          if (!data?.errorMsg) {
            setAiAgentSnackBar({
              success: true,
              title: CheckValueLocale(
                "ai_agent_resumed_success_title",
                "",
                {},
                intl,
              ),
              message: CheckValueLocale(
                "ai_agent_resumed_success_message",
                "",
                { name: aiAgentDetails?.name },
                intl,
              ),
            });
          } else {
            setAiAgentSnackBar({
              success: false,
              title: CheckValueLocale("failed_error_message", "", {}, intl),
              message: CheckValueLocale(
                "try_again_error_message",
                "",
                {},
                intl,
              ),
            });
          }
          setOpenPopupResume(null);
        },
      },
    );
  };

  const getButtonState = () => {
    if (isAiAgentDraft) {
      return {
        disabled: false,
        title: CheckValueLocale("publish_ai_agent", "", {}, intl),
        tooltip: CheckValueLocale("go_live_with_ai_agent", "", {}, intl),
      };
    } else if (isAiAgentPublishing) {
      return {
        disabled: true,
        title: CheckValueLocale("ai_agent_status_publishing", "", {}, intl),
      };
    } else if (isAiAgentPublished) {
      if (isEdited) {
        return {
          disabled: false,
          title: CheckValueLocale("publish_changes", "", {}, intl),
          tooltip: CheckValueLocale("publish_changes_tooltip", "", {}, intl),
        };
      } else {
        return {
          disabled: true,
          title: CheckValueLocale("ai_agent_status_published", "", {}, intl),
          startIcon: <FontAwesomeIcon icon={faCheckDouble} />,
        };
      }
    } else if (isAiAgentPaused) {
      return {
        disabled: true,
        title: CheckValueLocale("ai_agent_status_published", "", {}, intl),
        startIcon: <FontAwesomeIcon icon={faCheckDouble} />,
      };
    } else {
      return {
        disabled: false,
        title: CheckValueLocale("publish_ai_agent", "", {}, intl),
        tooltip: CheckValueLocale("go_live_with_ai_agent", "", {}, intl),
      };
    }
  };

  const buttonState = getButtonState();
  const isButtonDisabledForLimitsOrDown =
    isDMLimitReached || isLimitReached || isAiAgentDown;

  const btnPublishDisabled =
    buttonState?.disabled ||
    isButtonDisabledForLimitsOrDown ||
    isAiAgentEditData ||
    publishAiAgentLoading;

  return (
    <Box className="ai-agents-form-header">
      <Box className="form-header-title">
        <Box className="ai-agents-header-name" component="h4">
          {aiAgentDetails?.name}
        </Box>
      </Box>
      <Box className="form-header-action">
        <LucButton
          type="secondary"
          variant="flat"
          disabled={!isAiAgentPublished}
          startIcon={<FontAwesomeIcon icon={faMessageLines} />}
          id="ai-agent-button-inbox"
          onClick={() => {
            dispatch(aiAgentData(aiAgentDetails?.id));
            history.push("/engagements/main");
          }}
        >
          {CheckValueLocale("ai_agent_inbox_header_title", "", {}, intl)}
        </LucButton>
        <Tooltip
          title={
            <Box component="span" className="ai-agents-tooltip-description">
              {CheckValueLocale("ai_agent_run_test_tooltip", "", {}, intl)}
            </Box>
          }
          id="ai-agents-pause-tooltip"
          placement="bottom"
          arrow
        >
          <div>
            <LucButton
              type="secondary"
              variant="flat"
              startIcon={<FontAwesomeIcon icon={faPlay} />}
              onClick={() => setIsAiAgentTestModalOpen(true)}
              disabled={!validRunTest}
              id="ai-agent-button-run-test"
            >
              {CheckValueLocale("run_test", "", {}, intl)}
            </LucButton>
          </div>
        </Tooltip>
        {pauseResumeButton &&
        handlelUserRoles("AI_AGENT", "PAUSE_RESUME_AI_AGENT") ? (
          <>
            <Divider orientation="vertical" />
            <Tooltip
              title={
                <>
                  <Box component="span" className="ai-agents-tooltip-title">
                    {CheckValueLocale(
                      isAiAgentPaused ? "resume" : "pause_ai_agent",
                      "",
                      {},
                      intl,
                    )}
                  </Box>
                  {isAiAgentPaused ? (
                    <Box
                      component="span"
                      className="ai-agents-tooltip-description"
                    >
                      {CheckValueLocale("resume_ai_agent_desc", "", {}, intl)}
                    </Box>
                  ) : null}
                </>
              }
              id="ai-agents-pause-tooltip"
              placement="bottom"
              arrow
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled={isAiAgentDown}
                    checked={!isAiAgentPaused}
                    onChange={() => {
                      isAiAgentPaused
                        ? handleOpenResumeModal()
                        : setOpenPopupPause(true);
                    }}
                  />
                }
              />
            </Tooltip>
          </>
        ) : null}
        {handlelUserRoles("AI_AGENT", "EDIT_AI_AGENT") ? (
          <Tooltip
            title={
              buttonState?.tooltip &&
              !isButtonDisabledForLimitsOrDown &&
              !isAiAgentEditData ? (
                <Box component="span" className="ai-agents-tooltip-description">
                  {CheckValueLocale(buttonState?.tooltip, "", {}, intl)}
                </Box>
              ) : null
            }
            id="ai-agents-publish-tooltip"
            placement="bottom"
            arrow
          >
            <div>
              <LucButton
                className="ai-agents-publish-btn"
                disabled={btnPublishDisabled}
                onClick={() => formik?.handleSubmit()}
                startIcon={
                  buttonState?.startIcon ? buttonState?.startIcon : null
                }
                loading={publishAiAgentLoading}
                id="ai-agent-button-publish"
              >
                {buttonState?.title}
              </LucButton>
            </div>
          </Tooltip>
        ) : null}
        <AiAgentOptions
          aiAgentDetails={aiAgentDetails}
          isAiAgentPublished={isAiAgentPublished}
        />
        <ResumeAiAgentModal
          aiAgentDetails={aiAgentDetails}
          openPopupResume={openPopupResume}
          setOpenPopupResume={setOpenPopupResume}
          accept={handleResume}
          isDisabledOrLoading={resumeAiAgentLoading}
          otherAiAgentName={
            (aiAgentDetails?.auto_assignment === "inbound_routing" &&
              aiAgentDetails?.inbound_routing_used?.name) ||
            aiAgentDetails?.unassigned_used?.name
          }
        />
        <PauseAiAgentModal
          aiAgentDetails={aiAgentDetails}
          openPopupPause={openPopupPause}
          setOpenPopupPause={setOpenPopupPause}
        />
      </Box>
      {isAiAgentTestModalOpen ? (
        <RunTestModal
          open={isAiAgentTestModalOpen}
          handleClose={() => setIsAiAgentTestModalOpen(false)}
          aiAgentDetails={aiAgentDetails}
        />
      ) : null}
    </Box>
  );
};

export default AiAgentsFormHeader;
