import { createSlice } from "@reduxjs/toolkit";

const initialState = { selectedCard: {}, sla: {}, aiAgent: null };

const engagementsSlice = createSlice({
  name: "engagementsSlice",
  initialState,
  reducers: {
    clear: (state) => {
      state.selectedCard = {};
    },
    cardData: (state, action) => {
      state.selectedCard = { ...action?.payload };
    },
    clearSla: (state) => {
      state.sla = {};
    },
    slaData: (state, action) => {
      state.sla = { ...action?.payload };
    },
    aiAgentData: (state, action) => {
      state.aiAgent = action?.payload;
    },
    clearAiAgent: (state) => {
      state.aiAgent = null;
    },
  },
});

export default engagementsSlice.reducer;
export const { clear, cardData, clearSla, slaData, aiAgentData, clearAiAgent } =
  engagementsSlice.actions;
