import customDashboard from "../api/customDashboard.js";

var CustomDashboardController = {};

// Get monitors for selected product Social Listening
CustomDashboardController.getCustomDashboardMonitors = (product_id, page) => {
  let queryData = { product_id, page };
  return new Promise(function (resolve) {
    customDashboard
      .getCustomDashboardMonitors(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        resolve({ errorMsg: error, status: error?.status });
      });
  });
};

// Get accounts for selected product Omnichannel
CustomDashboardController.getCustomDashboardAccounts = (product_id) => {
  let queryData = { product_id };
  return new Promise(function (resolve) {
    customDashboard
      .getCustomDashboardAccounts(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        resolve({ errorMsg: error, status: error?.status });
      });
  });
};

CustomDashboardController.createNewDashboard = (
  name,
  dashboard_type_id,
  monitors,
  product_id,
) => {
  let queryData = { name, dashboard_type_id, monitors, product_id };
  return new Promise(function (resolve) {
    customDashboard
      .createNewDashboard(queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        resolve({ errorMsg: error, status: error?.status });
      });
  });
};

// Custom dashboard get sidebar widgets
CustomDashboardController.getSidebarWidgets = (id) => {
  return new Promise(function (resolve, reject) {
    customDashboard
      .getSidebarWidgets(id)
      .then(function (response) {
        resolve({ data: response?.data });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error?.response?.data?.exception?.message,
          status: error?.response?.status,
        });
      });
  });
};

// Custom dashboard get content widgets
CustomDashboardController.getContentWidgets = (id) => {
  return new Promise(function (resolve, reject) {
    customDashboard
      .getContentWidgets(id)
      .then(function (response) {
        resolve({ data: response?.data });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error?.response?.data?.exception?.message,
          status: error?.response?.status,
        });
      });
  });
};

// Custom dashboard get content widgets
CustomDashboardController.updateCustomDashboard = (id, queryData) => {
  return new Promise(function (resolve, reject) {
    customDashboard
      .updateCustomDashboard(id, queryData)
      .then(function (response) {
        resolve({ data: response?.data });
      })
      .catch(function (error) {
        resolve({ errorMsg: error, status: error?.status });
      });
  });
};

// Custom dashboard updated live dashBoard
CustomDashboardController.updateLiveCustomDashboard = (queryData) => {
  return new Promise(function (resolve, reject) {
    customDashboard
      .updateLiveCustomDashboard(queryData)
      .then(function (response) {
        resolve({ status: response?.status });
      })
      .catch(function (error) {
        resolve({ errorMsg: error, status: error?.status });
      });
  });
};

// CustomDashboard View Widgets
CustomDashboardController.getCustomDashboardView = (id, queryData) => {
  return new Promise(function (resolve) {
    customDashboard
      .getCustomDashboardView(id, queryData)
      .then((response) => {
        resolve({ data: response?.data?.data });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error?.response?.data?.exception?.message,
          status: error?.response?.status,
        });
      });
  });
};

// Custom dashboard control widget available monitors list (to aggregate or compare)
CustomDashboardController.getCustomizedWidgetMonitors = (
  dashboardId,
  widgetId,
) => {
  return new Promise(function (resolve) {
    customDashboard
      .getCustomizedWidgetMonitors(dashboardId, widgetId)
      .then((response) => {
        resolve({ data: response?.data?.data });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};

// Custom dashboard control widget (Customize Widget)
CustomDashboardController.customizeWidget = (
  dashboardId,
  widgetId,
  queryData,
) => {
  return new Promise(function (resolve) {
    customDashboard
      .customizeWidget(dashboardId, widgetId, queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};

// Custom dashboard control widget (Remove Customize Widget)
CustomDashboardController.removeCustomizeWidget = (dashboardId, widgetId) => {
  return new Promise(function (resolve) {
    customDashboard
      .removeCustomizeWidget(dashboardId, widgetId)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};

export default CustomDashboardController;
