import { Box, Tooltip } from "@mui/material";
import followers from "images/engagements-images/followers.svg";
import total from "images/engagements-images/total.svg";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";

const Hint = ({
  showTotalEngagementsHandler,
  selectedCard,
  showEngagementsHint,
  isXPlatform,
}) => {
  const intl = useIntl();

  return (
    <Box className="user-profile-side-bar-content user-profile-side-bar-content-container">
      <Box className="content-box">
        <span className="title-box">
          <img src={followers} />
          {CheckValueLocale("followers_engagements_profile", "", {}, intl)}:
        </span>
        <span className="value-box">
          {selectedCard?.followers_count ? (
            <>
              {+selectedCard?.followers_count >= 1000
                ? `${
                    (+selectedCard?.followers_count / 1000).toFixed() > 1000
                      ? (
                          (+selectedCard?.followers_count / 1000).toFixed() /
                          1000
                        ).toFixed() + "m "
                      : (+selectedCard?.followers_count / 1000).toFixed() + "k "
                  }`
                : `${selectedCard?.followers_count} `}
            </>
          ) : (
            "-"
          )}
        </span>
      </Box>
      {showEngagementsHint && !isXPlatform ? (
        <Box className="content-box">
          <Tooltip
            title={
              <span className="total-tooltip">
                {CheckValueLocale(
                  "total_engagements_profile_hint",
                  "",
                  {},
                  intl,
                )}
              </span>
            }
            placement={
              window?.localStorage?.lang === "en" ? "left-start" : "right-start"
            }
            arrow
          >
            <span className="title-box">
              <img src={total} />
              {CheckValueLocale("total_engagements_profile", "", {}, intl)}:
            </span>
          </Tooltip>
          <span
            className="value-box show-total"
            onClick={showTotalEngagementsHandler}
          >
            {CheckValueLocale("show", "", {}, intl)}
            <FontAwesomeIcon
              className="show-engagements-icon"
              icon={faExternalLink}
            />
          </span>
        </Box>
      ) : null}
    </Box>
  );
};
export default Hint;
