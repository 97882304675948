import { faClone } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckDouble,
  faClockRotateLeft,
  faCopy,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

const DropDownMenuOptions = (props) => {
  const {
    stats,
    handleClose,
    open,
    handleOpenLogsDrawer,
    handleOpenPopupDelete,
    handleOpenVersionsDrawer,
    handleDublicateAiAgent,
    aiAgentDetails,
  } = props;
  const intl = useIntl();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(aiAgentDetails?.id);

    setTimeout(() => {
      setCopied(false);
    }, 2300);
  };

  return (
    <Menu
      id="ai-agents-action-dropdown"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={stats?.anchorEl}
      open={open}
      onClose={handleClose}
      className="menu-ai-agent"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: intl?.locale == "en" ? "right" : "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: intl?.locale == "en" ? "right" : "left",
      }}
    >
      <MenuItem
        onClick={handleOpenVersionsDrawer}
        disableRipple
        id="ai-agent-dropdown-published-versions"
      >
        <FontAwesomeIcon icon={faCheckDouble} />
        {CheckValueLocale("published_versions", "", {}, intl)}
      </MenuItem>
      <MenuItem
        onClick={handleOpenLogsDrawer}
        disableRipple
        id="ai-agent-dropdown-published-versions"
      >
        <FontAwesomeIcon icon={faClockRotateLeft} />
        {CheckValueLocale("activity_log", "", {}, intl)}
      </MenuItem>

      {handlelUserRoles("AI_AGENT", "CREATE_AI_AGENT") ? (
        <MenuItem
          onClick={handleDublicateAiAgent}
          disableRipple
          id="ai-agent-dropdown-published-versions"
        >
          <FontAwesomeIcon icon={faCopy} />
          {CheckValueLocale("duplicate_ai_agent", "", {}, intl)}
        </MenuItem>
      ) : null}
      {handlelUserRoles("AI_AGENT", "DELETE_AI_AGENT") ? (
        <MenuItem
          onClick={() => handleOpenPopupDelete(true)}
          disableRipple
          className="drop-down-btn-ai-agents-delete"
          id="ai-agent-dropdown-published-versions"
        >
          <FontAwesomeIcon icon={faTrash} />
          {CheckValueLocale("delete", "", {}, intl)}
        </MenuItem>
      ) : null}
      <MenuItem
        disableRipple
        className="drop-down-btn-ai-agents-id"
        id="ai-agent-dropdown-published-versions"
      >
        <Box component="span" className="ai-agents-id-content">
          ID: {aiAgentDetails?.id}
        </Box>
        <Tooltip
          title={
            <Box component="span" className="ai-agents-tooltip-title">
              {CheckValueLocale(
                copied ? "copied_sla" : "take_copy",
                "",
                {},
                intl,
              )}
            </Box>
          }
          componentsProps={
            copied
              ? {
                  tooltip: {
                    sx: {
                      background: "#3b873e",
                    },
                  },
                }
              : {
                  tooltip: {
                    sx: {
                      background: "#616161E5",
                    },
                  },
                }
          }
          id="ai-agents-id-tooltip"
          placement="bottom"
        >
          <Box
            component="span"
            onClick={handleCopy}
            className="ai-agents-id-icon"
          >
            <FontAwesomeIcon icon={faClone} />
          </Box>
        </Tooltip>
      </MenuItem>
    </Menu>
  );
};

export default DropDownMenuOptions;
