import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import DeleteEndpointItem from "./deleteEndpointItem";
import EditKnowledgeBaseSwaggerEndpoints from "./editKnowledgeBaseSwaggerEndpoints";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";

const KnowledgeBaseSwaggerEndpointsItem = ({
  endpoint,
  endpoints,
  index,
  setEndpoints,
  oldFileData,
  setOldFileData,
}) => {
  const { setAiAgentSnackBar } = useContext(AiAgentsContext);
  const endpointTitle = endpoint?.operationId;
  const endpointType = endpoint?.method;
  const endpointDescription = endpoint?.summary;
  const intl = useIntl();
  const [stats, setStats] = useState({
    anchorEl: null,
  });
  const open = Boolean(stats?.anchorEl);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
  };

  const handleDelete = () => {
    const newEndpoints = endpoints?.filter((item) => item?.id !== endpoint?.id);
    setEndpoints(newEndpoints);
    const newFileData = { ...oldFileData };
    let newPlaceholderObject = {};
    Object.keys(newFileData || {})?.map((item) => {
      Object.keys(newFileData?.[item] || {})?.map((method) => {
        const newObject = { ...newFileData?.[item]?.[method] };
        if (newObject?.id !== endpoint?.id) {
          newPlaceholderObject = {
            ...newPlaceholderObject,
            [item]: {
              ...newPlaceholderObject?.[item],
              [method]: {
                ...newPlaceholderObject?.[item]?.[method],
                ...newObject,
              },
            },
          };
        }
      });
    });
    setOldFileData(newPlaceholderObject);
    setAiAgentSnackBar({
      success: true,
      message: CheckValueLocale("endpoint_deleted_successfully", "", {}, intl),
    });
    handleClose();
  };

  return (
    <>
      <Box className="knowledge-base-endpoint-container">
        <Box className="knowledge-base-endpoint-info-column">
          <Box className="knowledge-base-endpoint-name-type-container">
            <Typography className="knowledge-base-endpoint-name">
              {endpointTitle}
            </Typography>
            <Box className={`knowledge-base-endpoint-chip ${endpointType}`}>
              <Typography className="knowledge-base-endpoint-chip-label">
                {endpointType}
              </Typography>
            </Box>
          </Box>
          <Typography className="knowledge-base-endpoint-description">
            {endpointDescription}
          </Typography>
        </Box>
        <Box className="knowledge-base-endpoint-item-actions-container">
          {/* <Tooltip arrow title={CheckValueLocale("edit", "", {}, intl)}>
            <IconButton onClick={() => setIsEditModalOpen(true)} size="medium">
              <FontAwesomeIcon
                className="ai-agent-swagger-endpoint-actions"
                icon={faPenToSquare}
              />
            </IconButton>
          </Tooltip> */}
          <Tooltip arrow title={CheckValueLocale("delete", "", {}, intl)}>
            <IconButton
              onClick={(event) => {
                setStats({ ...stats, anchorEl: event?.currentTarget });
              }}
              size="medium"
            >
              <FontAwesomeIcon
                className="ai-agent-swagger-endpoint-actions"
                icon={faTrash}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider className="swagger-endpoints-divider" />
      <DeleteEndpointItem
        handleDelete={handleDelete}
        open={open}
        handleClose={handleClose}
        stats={stats}
      />
      <EditKnowledgeBaseSwaggerEndpoints
        open={isEditModalOpen}
        setEditKnowledgeBaseSwaggerEndpoints={setIsEditModalOpen}
        endpoint={endpoint}
        endpoints={endpoints}
        setEndpoints={setEndpoints}
        index={index}
      />
    </>
  );
};

export default KnowledgeBaseSwaggerEndpointsItem;
