import { useQuery } from "@tanstack/react-query";
import CreateMonitorController from "services/controllers/createMonitorController";

export const useCountries = () => {
  return useQuery({
    queryKey: ["countries"],
    queryFn: () =>
      CreateMonitorController.getCreateMonitorData({
        data_source: "talkwalker",
      }),
    select: (data) => data?.data,
  });
};
