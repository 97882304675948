import { useState } from "react";
import CompaniesController from "services/controllers/companiesController";
import { workgingHoursData } from "../../userManagement/addNewUser/tabs/workingHours";

// this shared functions for add team and edit team pages
export const TeamSharedFuntions = () => {
  // team information state
  const [teamInformation, setTeamInformation] = useState({
    teamName: "",
    selectedProduct: "",
  });
  // products state
  const [products, setProducts] = useState([]);
  // members state
  const [members, setMembers] = useState([]); // {data?.users: [], pagination: {}}
  const [selectedMembers, setSelectedMembers] = useState([]); // [id1,id2]
  // accounts state
  const [socialAccounts, setSocialAccounts] = useState([]);
  const [selectedSocialAccounts, setSelectedSocialAccounts] = useState([]); // [{account_id: "x" , data_source_id: "x"}]
  // monitors state
  const [monitors, setMonitors] = useState([]);
  const [selectedMonitors, setSelectedMonitors] = useState([]); // [id1,id2]
  // channels state
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]); // [id1,id2]
  // loading state
  const [isTeamMemberLoading, setIsTeamMemberLoading] = useState(false);
  const [isTeamProductsLoading, setIsTeamProductsLoading] = useState(false);
  const [isTeamSocialAccountsLoading, setIsTeamSocialAccountsLoading] =
    useState(false);
  const [isTeamMonitorsLoading, setIsTeamMonitorsLoading] = useState(false);
  const [isTeamChannelsLoading, setIsTeamChannelsLoading] = useState(false);
  const [teamError, setTeamError] = useState({
    message: "",
    severity: "",
    title: "",
    openSnakbar: false,
    teamNameError: "",
  });

  const [workingHours, setWorkingHours] = useState({
    workingHours: workgingHoursData,
  });

  const [tabsErrors, setTabsErrors] = useState({
    workgingHoursPreError: false,
    workingHours: false,
  });

  const [autoReplyMessages, setAutoReplyMessages] = useState();

  // team information functions
  const handleTeamInformationChange = (teamInformation) => {
    if (teamInformation?.teamName) {
      if (teamInformation?.teamName?.length > 50) {
        setTeamError({
          teamNameError: "team_name_max_length_error",
        });
      } else {
        setTeamError({
          teamNameError: "",
        });
      }
    }
    setTeamInformation((prev) => ({ ...prev, ...teamInformation }));
  };
  // members functions
  const handleSelectMember = (selectdMemberId) => {
    if (selectedMembers?.includes(selectdMemberId)) {
      setSelectedMembers((prev) =>
        prev?.filter((member) => member !== selectdMemberId),
      );
    } else {
      setSelectedMembers((prev) => [...prev, selectdMemberId]);
    }
  };
  // accounts functions, selectdSocialAccount = {account_id: "x" , data_source_id: "x"}
  const handleSelectSocialAccount = (selectdSocialAccount) => {
    const account_id = selectdSocialAccount?.account_id;
    const findSelectdSocialAccount = selectedSocialAccounts?.find(
      (socialAccount) => socialAccount?.account_id === account_id,
    );
    if (findSelectdSocialAccount) {
      setSelectedSocialAccounts((prev) =>
        prev?.filter(
          (socialAccount) => socialAccount?.account_id !== account_id,
        ),
      );
    } else {
      setSelectedSocialAccounts((prev) => [...prev, selectdSocialAccount]);
    }
  };
  // monitors functions
  const handleSelectMonitor = (selectdMonitorId) => {
    if (selectedMonitors?.includes(selectdMonitorId)) {
      setSelectedMonitors((prev) =>
        prev?.filter((monitor) => monitor !== selectdMonitorId),
      );
    } else {
      setSelectedMonitors((prev) => [...prev, selectdMonitorId]);
    }
  };
  // channels functions
  const handleSelectChannel = (selectdChannelId) => {
    if (selectedChannels?.includes(selectdChannelId)) {
      setSelectedChannels((prev) =>
        prev?.filter((channel) => channel !== selectdChannelId),
      );
    } else {
      setSelectedChannels((prev) => [...prev, selectdChannelId]);
    }
  };
  const getTeamProductOptions = () => {
    setIsTeamProductsLoading(true);
    CompaniesController.getCompanyProducts().then((res) => {
      if (res?.errorMsg) {
        setTeamError({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnakbar: true,
        });
      } else {
        setProducts(res?.data);
      }
      setIsTeamProductsLoading(false);
    });
  };
  // initilize team details (members, products)
  const initilizeTeamDetails = () => {
    setIsTeamMemberLoading(true);
    CompaniesController.getTeamMembers().then((res) => {
      if (res?.errorMsg) {
        setTeamError({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnakbar: true,
        });
      } else {
        setMembers(res?.data?.users);
      }
      setIsTeamMemberLoading(false);
    });
    getTeamProductOptions();
  };
  const initializeDataForEditTeam = (id) => {
    setIsTeamMemberLoading(true);
    CompaniesController.getTeamMembersSelected(id).then((res) => {
      if (res?.errorMsg) {
        setTeamError({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnakbar: true,
        });
      } else {
        setMembers(res?.data);
      }
      setIsTeamMemberLoading(false);
    });
    getTeamProductOptions();
  };
  // save team function
  const handleSaveTeam = () => {
    // save team
  };
  return {
    teamInformation,
    products,
    setProducts,
    setTeamInformation,
    members,
    setMembers,
    selectedMembers,
    setSelectedMembers,
    socialAccounts,
    setSocialAccounts,
    selectedSocialAccounts,
    setSelectedSocialAccounts,
    monitors,
    setMonitors,
    selectedMonitors,
    setSelectedMonitors,
    channels,
    setChannels,
    selectedChannels,
    setSelectedChannels,
    handleTeamInformationChange,
    handleSelectMember,
    handleSelectSocialAccount,
    handleSelectMonitor,
    handleSelectChannel,
    handleSaveTeam,
    initilizeTeamDetails,
    isTeamChannelsLoading,
    isTeamMemberLoading,
    isTeamMonitorsLoading,
    isTeamProductsLoading,
    isTeamSocialAccountsLoading,
    teamError,
    setTeamError,
    initializeDataForEditTeam,
    workingHours,
    setWorkingHours,
    tabsErrors,
    setTabsErrors,
    autoReplyMessages,
    setAutoReplyMessages,
  };
};
