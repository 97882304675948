import { useIntl } from "react-intl";
import { handleTopFiveForStackedBar } from "utils/helpers";
import {
  handleFormatDataToBarAndLineCharts,
  handleFormatDataToPieChart,
  handleFormatDataToPunchChart,
  handleFormatDataToStackedBarCharts,
  handleFormatDataToStackedLineCharts,
  handleFormatDataToTableChart,
  handleFormatDataToThemesChart,
} from "./customAggregateFunctions";
import {
  handleBarComparison,
  handleLineChartComparison,
  handlePieChartCompare,
  handleReachFunnelComparison,
  handleStackedBarComparison,
  handleThemesComparison,
} from "./customComparisonFunctions";

//handleAggregateByWidgetType to handle aggregate with all type of widget.
// parameters:
// widgetTypeChart(ex: "pieChartWidget")
//customizedMonitorsWigets [{evenData , monitorId ,dataSource},....]
//return : final object to pass to chart.
const handleAggregateByWidgetType = (
  widgetTypeChart,
  customizedMonitorsWigets,
) => {
  switch (widgetTypeChart) {
    case "pieChartWidget":
      return handleFormatDataToPieChart(customizedMonitorsWigets);
    case "barChartWidget":
    case "lineChartWidget":
      return handleFormatDataToBarAndLineCharts(customizedMonitorsWigets);
    case "stackedLineWidget":
      return handleFormatDataToStackedLineCharts(customizedMonitorsWigets);
    case "stackedBarWidget":
      return handleFormatDataToStackedBarCharts(customizedMonitorsWigets);
    case "tableWidget":
      return handleFormatDataToTableChart(customizedMonitorsWigets);
    case "punchCardWidget":
      return handleFormatDataToPunchChart(customizedMonitorsWigets);
    case "themesWidget":
      return handleFormatDataToThemesChart(customizedMonitorsWigets);
  }
};

//handleComparisonByWidgetType to handle aggregate with all type of widget.
// Params:
// widgetTypeChart (ex: "pieChartWidget")
// customizedMonitorsWigets [{evenData, monitorId, monitorName, dataSource}, ...]
// return : final object to pass to chart.
const handleComparisonByWidgetType = (
  widgetName,
  widgetTypeChart,
  customizedMonitorsWigets,
  intl,
) => {
  switch (widgetTypeChart) {
    case "pieChartWidget":
      const comparedData = handlePieChartCompare(customizedMonitorsWigets);
      // Return top 5 items plus others only
      if (comparedData) {
        // To exclude (Dialects Sub-Dialects) from this function
        if (!comparedData?.pie_sub_data && comparedData?.pie_data?.length > 5)
          return handleTopFiveForStackedBar(comparedData, intl);
        else return comparedData;
      }
      break;

    case "stackedBarWidget":
    case "stackedLineWidget": // Also StackedLine Comparison usess the same function of StackedBar
      const reachFunnelNames = [
        "reach_funnel",
        "posts_reach_funnel",
        "posts_reach",
      ];
      if (reachFunnelNames?.includes(widgetName))
        return handleReachFunnelComparison(customizedMonitorsWigets);
      else return handleStackedBarComparison(customizedMonitorsWigets);

    case "barChartWidget":
      return handleBarComparison(customizedMonitorsWigets);

    case "lineChartWidget":
      return handleLineChartComparison(customizedMonitorsWigets);

    case "themesWidget":
      return handleThemesComparison(customizedMonitorsWigets);
  }
};

const useEventDataByWidgetType = () => {
  const intl = useIntl();

  const handleEventData = (
    widget,
    widgetTypeChart,
    mainWidgetData, //{ pie_data:[] }
    customizedWidgets, //[{ evenData , monitorId ,dataSource}]
    customizedType,
  ) => {
    const customizedId = widget?.customized_widget?.id;
    //customizedMonitorsWigets to add origin monitor widget in the same customizedWidgets format
    const customizedMonitorsWigets = [
      {
        ...mainWidgetData,
        monitorId: widget?.monitor_id,
        dataSource: widget?.is_dm
          ? widget?.data_source + "_PRIVATE"
          : widget?.data_source,
      },

      ...(customizedWidgets?.[customizedId]?.eventDataList !== undefined
        ? customizedWidgets?.[customizedId]?.eventDataList
        : []),
    ];
    if (customizedType === "aggregate") {
      return handleAggregateByWidgetType(
        widgetTypeChart,
        customizedMonitorsWigets,
      );
    } else if (customizedType === "comparison") {
      // To add monitor name for each row for returned data from RabbitMQ
      const data = customizedMonitorsWigets?.map((item) => {
        if (item?.monitorId === widget?.monitor_id) {
          return {
            ...item,
            monitorName: widget?.monitor_name,
            monitorType: widget?.monitor_type?.name,
          };
        } else {
          return {
            ...item,
            monitorName: widget?.customized_widget?.attributes?.monitors?.find(
              (monitor) => {
                return monitor?.id === item?.monitorId;
              },
            )?.name,
            monitorType: widget?.customized_widget?.attributes?.monitors?.find(
              (monitor) => {
                return monitor?.id === item?.monitorId;
              },
            )?.monitor_type_name,
          };
        }
      });

      return handleComparisonByWidgetType(
        widget?.widget_name,
        widgetTypeChart,
        data,
        intl,
      );
    }
  };

  return [handleEventData];
};

export default useEventDataByWidgetType;
