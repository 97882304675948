import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import AIAgentSwaggerIcon from "images/ai-agents/knowledge-base-swagger.svg";
import KnowledgeBaseSwaggerEndpointsItem from "./knowledgeBaseSwaggerEndpointsItem";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { useContext, useEffect, useMemo, useState } from "react";
import AiAgentFunction from "../../../../aiAgentFunction";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import { useGetAiAgentSwaggerFile } from "pages/engagements/components/aiAgents/aiAgentsHooks/useGetAiAgentSwaggerFile";
import { useEditAiAgentFile } from "pages/engagements/components/aiAgents/aiAgentsHooks/useEditAiAgentFile";
import yaml from "js-yaml";

const KnowledgeBaseSwaggerEndpoints = ({
  open,
  setKnowledgeBaseSwaggerEndpoints,
  id,
  handleClose,
}) => {
  const { aiAgentSelected } = useContext(AiAgentsContext);
  const { parseSwaggerData, processSwaggerFile } = AiAgentFunction();
  const intl = useIntl();
  const [swaggerEndpoints, setSwaggerEndpoints] = useState([]);
  const [oldFileData, setOldFileData] = useState(null);
  const [wholeJSONFileContent, setWholeJSONFileContent] = useState(null);
  const { data: swaggerData, isPending: isGetAiAgentResourceLoading } =
    useGetAiAgentSwaggerFile(
      {
        id,
        queryData: {
          user_id: localStorage.getItem("user_id"),
          ai_agent_id: aiAgentSelected,
          id,
        },
      },
      open,
    );
  const isYAMLFile = swaggerData?.data?.file_name?.includes(".yaml");

  const { mutate: editResource, isPending: isEditResourceLoading } =
    useEditAiAgentFile();

  const handleEditResource = () => {
    const newModifiedData = {
      ...wholeJSONFileContent,
      paths: oldFileData,
    };
    const formData = new FormData();
    if (isYAMLFile) {
      const yamlFile = yaml.dump(newModifiedData);
      const file = new File([yamlFile], swaggerData?.data?.file_name, {
        type: "application/yaml",
      });
      formData.append("files[]", file);
    } else {
      const file = new File(
        [JSON.stringify(newModifiedData, null, 2)],
        swaggerData?.data?.file_name,
        {
          type: "application/json",
        },
      );
      formData.append("files[]", file);
    }

    formData.append("ai_agent_id", aiAgentSelected);
    formData.append("source_type", "swagger");
    editResource(
      { id, queryData: formData },
      {
        onSuccess: () => {
          setKnowledgeBaseSwaggerEndpoints(false);
          handleClose();
        },
      },
    );
  };

  useEffect(() => {
    const result = swaggerData?.data;
    if (result?.encoded_file) {
      processSwaggerFile(result?.encoded_file, isYAMLFile, (jsonData) => {
        setOldFileData(jsonData?.paths);
        setWholeJSONFileContent(jsonData);
        const parsedEndpoints = parseSwaggerData(jsonData);
        setSwaggerEndpoints(parsedEndpoints);
      });
    }
  }, [swaggerData, isYAMLFile]);

  const [searchValue, setSearchValue] = useState("");

  const filteredEndpoints = useMemo(() => {
    return swaggerEndpoints?.filter((endpoint) => {
      return (
        endpoint?.operationId
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        endpoint?.summary?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
    });
  }, [searchValue, swaggerEndpoints]);

  const modalTitle = (
    <Box className="ai-agent-knowledge-base-swagger-modal-title-container ">
      <img src={AIAgentSwaggerIcon} />
      <Typography variant="h6">{swaggerData?.data?.file_name}</Typography>
    </Box>
  );

  const modalBody = (
    <Box>
      <TextField
        placeholder={CheckValueLocale("search", "", {}, intl)}
        className="search-knowledge-base-input"
        fullWidth
        value={searchValue}
        onChange={(e) => setSearchValue(e?.target?.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment className="ai-agent-search-input-adornment-container">
              <Tooltip
                title={CheckValueLocale("delete_search", "", {}, intl)}
                arrow
              >
                <IconButton onClick={() => setSearchValue("")} size="small">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </IconButton>
              </Tooltip>
              <Tooltip title={CheckValueLocale("search", "", {}, intl)} arrow>
                <IconButton size="small">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <Box className="knowledge-base-view-endpoints-container">
        {filteredEndpoints?.map((endpoint, index) => (
          <KnowledgeBaseSwaggerEndpointsItem
            setSwaggerEndpoints={setSwaggerEndpoints}
            key={index}
            endpoints={swaggerEndpoints}
            endpoint={endpoint}
            setEndpoints={setSwaggerEndpoints}
            index={index}
            oldFileData={oldFileData}
            setOldFileData={setOldFileData}
          />
        ))}
      </Box>
    </Box>
  );

  const loadingBody = (
    <Box className="knowledge-base-view-endpoints-loading-container">
      <CircularProgress />
    </Box>
  );

  const modalFooter = (
    <Box className="ai-agent-knowledge-base-swagger-modal-footer-container">
      <Typography>
        {CheckValueLocale(
          "number_of_endpoint",
          "",
          {
            number: filteredEndpoints?.length,
          },
          intl,
        )}
      </Typography>
      <LucButton
        onClick={handleEditResource}
        disabled={isEditResourceLoading}
        loading={isEditResourceLoading}
        id="pop-up-right-btn"
        variant="filled"
        type="primary"
        className="popup__rightbtn"
      >
        {CheckValueLocale("done", "", {}, intl)}
      </LucButton>
    </Box>
  );

  return (
    <PopupModal
      title={modalTitle}
      body={isGetAiAgentResourceLoading ? loadingBody : modalBody}
      disabled={isEditResourceLoading}
      isRightBtnLoading={isEditResourceLoading}
      open={open || false}
      close={() => {
        setKnowledgeBaseSwaggerEndpoints(false);
        handleClose();
      }}
      classeName="ai-agent-popupModal"
      modalStyle="knowledge-base-view-endpoints-modal-body"
      topicSelectedCounter={modalFooter}
      topicsExist={true}
    />
  );
};

export default KnowledgeBaseSwaggerEndpoints;
