import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { faEllipsisH, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import DownloadKnowledgeResource from "images/ai-agents/download-knowledge-resource.svg";
import KnowledgeBaseViewEndpointsIcon from "images/ai-agents/knowledge-base-view-endpoint.svg";
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import DeleteKnowledgeBasesSource from "./deleteKnowledgeBasesSource";
import KnowledgeBaseSwaggerEndpoints from "./knowledgeBaseSwaggerEndpoints";
import { useForceRefreshResource } from "pages/engagements/components/aiAgents/aiAgentsHooks/useForceRefreshResource";
import { useDownloadAiAgentResource } from "pages/engagements/components/aiAgents/aiAgentsHooks/useDownloadAiAgentResource";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import { b64toBlob } from "pages/notifications/list/sharedFunctions/viewDownloadFunctions";
import download from "downloadjs";

const KnowledgeBaseOptionsCellMenuItem = ({
  title,
  icon,
  onClick,
  tooltipTitle,
  handleCloseMenu = () => {},
}) => {
  return tooltipTitle ? (
    <Tooltip placement="left" arrow title={tooltipTitle}>
      <MenuItem
        onClick={() => {
          onClick();
          handleCloseMenu();
        }}
        disableRipple
        className="knowledge-base-options-menu-item"
      >
        {icon}
        <Typography className="knowledge-base-options-menu-item-title">
          {title}
        </Typography>
      </MenuItem>
    </Tooltip>
  ) : (
    <MenuItem
      onClick={onClick}
      disableRipple
      className="knowledge-base-options-menu-item"
    >
      {icon}
      <Typography className="knowledge-base-options-menu-item-title">
        {title}
      </Typography>
    </MenuItem>
  );
};

const KnowledgeBaseOptionsCell = ({
  id,
  resource,
  disabled,
  handleCloseMenu,
  formik,
}) => {
  const { aiAgentSelected } = useContext(AiAgentsContext);
  const { status, source_type } = resource;
  const intl = useIntl();

  const { mutate: forceRefresh } = useForceRefreshResource();
  const { mutate: downloadFile } = useDownloadAiAgentResource();

  const [stats, setStats] = useState({
    anchorEl: null,
  });
  const [isDeleteSourceOpen, setIsDeleteSourceOpen] = useState(false);
  const [isSwaggerEndpointsOpen, setIsSwaggerEndpointsOpen] = useState(false);
  const open = Boolean(stats?.anchorEl);
  const handleOpen = (event) => {
    formik?.setErrors({});
    setStats({
      ...stats,
      anchorEl: event?.currentTarget,
    });
  };
  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
    handleCloseMenu();
  };

  const handleForceRefresh = () => {
    forceRefresh(id);
    handleClose();
  };

  const handleDownload = () => {
    downloadFile(
      {
        id,
        queryData: {
          user_id: localStorage.getItem("user_id"),
          ai_agent_id: aiAgentSelected,
          id: id,
        },
      },
      {
        onSuccess: (data) => {
          const result = data?.data;
          if (result?.encoded_file) {
            const contentType = "application/vnd.ms-excel";
            const blob = b64toBlob(result?.encoded_file, contentType);
            download(blob, result?.file_name, contentType);
          }
        },
      },
    );
    handleClose();
  };

  const isTextFile = source_type === "text_file";
  const isURL = source_type === "url";
  const isProcessed = status === "processed";
  const isSwagger = source_type === "swagger";

  return (
    <>
      <LucButton disabled={disabled} onClick={handleOpen} variant="flat">
        <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
      </LucButton>
      <Menu
        id="knowledge-base-options-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        PaperProps={{
          style: {
            minWidth: "152px",
          },
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleClose}
        className="menu-ai-agent"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale == "en" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale == "en" ? "right" : "left",
        }}
      >
        {isSwagger && isProcessed ? (
          <KnowledgeBaseOptionsCellMenuItem
            icon={<img src={KnowledgeBaseViewEndpointsIcon} />}
            title={CheckValueLocale("view_endpoints", "", {}, intl)}
            onClick={() => {
              setIsSwaggerEndpointsOpen(true);
              handleClose();
            }}
          />
        ) : null}
        {isTextFile && isProcessed ? (
          <KnowledgeBaseOptionsCellMenuItem
            icon={<img src={DownloadKnowledgeResource} />}
            title={CheckValueLocale("download", "", {}, intl)}
            onClick={handleDownload}
          />
        ) : null}
        {isURL && isProcessed ? (
          <KnowledgeBaseOptionsCellMenuItem
            icon={
              <FontAwesomeIcon
                className="ai-agent-reset-margin"
                icon={faRotateRight}
              />
            }
            title={CheckValueLocale("force_refresh", "", {}, intl)}
            onClick={handleForceRefresh}
            tooltipTitle={CheckValueLocale(
              "force_refresh_tooltip",
              "",
              {},
              intl,
            )}
          />
        ) : null}
        <KnowledgeBaseOptionsCellMenuItem
          icon={
            <FontAwesomeIcon className="ai-agent-reset-margin" icon={faTrash} />
          }
          title={CheckValueLocale("delete", "", {}, intl)}
          onClick={() => {
            setIsDeleteSourceOpen(true);
            handleClose();
          }}
          handleCloseMenu={handleClose}
        />
      </Menu>
      <DeleteKnowledgeBasesSource
        open={isDeleteSourceOpen}
        setOpenPopupDelete={setIsDeleteSourceOpen}
        resourceId={id}
        handleClose={handleClose}
      />
      <KnowledgeBaseSwaggerEndpoints
        open={isSwaggerEndpointsOpen}
        setKnowledgeBaseSwaggerEndpoints={setIsSwaggerEndpointsOpen}
        id={id}
        handleClose={handleClose}
      />
    </>
  );
};

export default KnowledgeBaseOptionsCell;
