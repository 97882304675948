import { Avatar, Box, Stack, Typography } from "@mui/material";
import AIImage from "images/ai-agents/ai-agent-chat-icon.svg";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const AIAgentChatMessage = ({
  text,
  isSent,
  showNameOrAvatar,
  itemRef,
  aiAgentDetails,
}) => {
  const intl = useIntl();
  const isSentClass = isSent
    ? "ai-agent-chat-message-stack-sent"
    : "ai-agent-chat-message-stack-received";
  const firstMsg = showNameOrAvatar ? "ai-agent-first-message" : "";
  const directionMsg = isSent ? "row-reverse" : "row";
  const hasAvatar = localStorage.getItem("avatar") !== "null";
  const aiAgentImage = aiAgentDetails?.ai_agent_image || AIImage;

  const imgUser = isSent
    ? hasAvatar
      ? localStorage.getItem("avatar")
      : null
    : aiAgentImage;

  const loggedUsername = localStorage.getItem("firstName");

  const firstLetter = text.charAt(0);
  const isArabicMatch = firstLetter.match(/[\u0600-\u06FF]/);
  const isEnglishMatch = firstLetter.match(/[A-Za-z]/);

  const isArabic = isArabicMatch?.length > 0;
  const isEnglish = isEnglishMatch?.length > 0;
  const aiAgentNickName =
    aiAgentDetails?.agent_nickname ||
    CheckValueLocale("ai_agent", "", {}, intl);

  return (
    <Stack
      className={`ai-agent-chat-message-stack ${isSentClass} ${firstMsg}`}
      direction={directionMsg}
      ref={itemRef}
    >
      {showNameOrAvatar ? (
        <Avatar src={imgUser} className="ai-agent-chat-message-avatar" />
      ) : (
        <Box className="ai-agent-chat-message-placeholder" />
      )}
      <Box className="ai-agent-chat-message-wrapper">
        {showNameOrAvatar && (
          <Typography className="ai-agent-chat-message-typography">
            {isSent ? loggedUsername : aiAgentNickName}
          </Typography>
        )}
        <Box className="ai-agent-chat-message-bubble">
          <Typography
            className={`ai-agent-chat-message-text 
            ${isArabic ? "arabic-message" : isEnglish ? "english-message" : ""}
            `}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default AIAgentChatMessage;
