import { Typography, Avatar, IconButton } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import AIAgentImageUpload from "images/ai-agents/ai-agent-image-upload.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import aiAgentImageDefault from "images/ai-agents/ai-agent-image.png";
import AiAgentFunction from "../../../../aiAgentFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import { useRef } from "react";

const UploadImage = ({ value, onChangeValue, isFormDisabled }) => {
  const intl = useIntl();
  const inputRef = useRef(null);
  const isNewFile = value instanceof File;
  const { extractAndTruncateFilename } = AiAgentFunction();

  const imagePreview = isNewFile ? URL.createObjectURL(value) : value;

  const { getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/webp": [],
      "image/heic": [],
    },
    onDrop: (acceptedFiles) => {
      if (isFormDisabled) return;
      if (acceptedFiles?.length === 0) return;
      onChangeValue(acceptedFiles?.[0]);
      inputRef.current.value = "";
    },
  });

  return (
    <div className="ai-agents-form-upload">
      <div className="ai-agents-form-avatar-name-row">
        <Avatar
          src={value ? imagePreview : aiAgentImageDefault}
          className="ai-agents-form-upload-avatar"
        />
        <Typography>{extractAndTruncateFilename(isNewFile, value)}</Typography>
      </div>

      {!value ? (
        <LucButton
          variant="text"
          disabled={isFormDisabled}
          startIcon={<img src={AIAgentImageUpload} />}
          component="label"
          id="ai-agent-upload-another-image"
          className="ai-agents-form-upload-button"
        >
          <input {...getInputProps()} ref={inputRef} />
          {CheckValueLocale("ai_agent_upload_another_image", "", {}, intl)}
        </LucButton>
      ) : (
        <IconButton
          size="medium"
          className="ai-agent-delete-image-icon-container"
          disabled={isFormDisabled}
          onClick={() => {
            if (isFormDisabled) return;
            onChangeValue(null);
          }}
        >
          <FontAwesomeIcon
            icon={faX}
            className={`ai-agent-delete-image-icon ${
              isFormDisabled ? "ai-agent-delete-image-icon-disabled" : ""
            }`}
          />
        </IconButton>
      )}
    </div>
  );
};

export default UploadImage;
