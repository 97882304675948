import { useState } from "react";
import {
  allowedTabsChange,
  maxTabsCount,
  slaInitialMutableTabs,
  slaTabsInitialValues,
} from "./constant";

export const useSlaTabsCounter = (filterTypeChange, filters) => {
  const [slaCounterData, setSlaCounterData] = useState({
    loading: true,
    // changedCountKeys will hold the keys that have been changed from the previous state
    changedCountKeys: [],
    values: { ...slaTabsInitialValues },
    // mutableTabs will hold the tabs that can be changed that less than maxTabsCount
    mutableTabs: slaInitialMutableTabs,
  });
  // isSlaInitialLoad is used to prevent the animation of the changed count keys
  const [isSlaInitialLoad, setIsSlaInitialLoad] = useState(true);

  const updateSlaCounterData = (data) => {
    const newCountValues = data?.values || {};

    const mutableTabs = data?.mutableTabs || slaCounterData?.mutableTabs;
    setSlaCounterData((prev) => {
      let changedCountKeys = [];
      if (!isSlaInitialLoad && allowedTabsChange.includes(filterTypeChange)) {
        changedCountKeys = Object.keys(newCountValues)?.filter?.(
          // if the tab count has changed from the previous value, and the tab is not in the loading state then we animate the count
          (key) =>
            // if the tab count has changed from the previous value
            newCountValues[key] != prev?.values?.[key] &&
            // if of both prev and current values is less than maxTabsCount
            (newCountValues[key] < maxTabsCount ||
              prev?.values?.[key] < maxTabsCount) &&
            // if the tab is not in the loading state and the tab is not the current tab
            !(prev?.loading && filters?.sla_status?.replace("_sla", "") == key),
        );
      }
      return {
        ...prev,
        ...data,
        mutableTabs,
        changedCountKeys: changedCountKeys?.length
          ? changedCountKeys
          : prev?.changedCountKeys,
      };
    });
    // reset the changed count keys after 900 ms
    setTimeout(resetSlaChangedCount, 900);
  };

  const resetSlaChangedCount = () => {
    setSlaCounterData((prev) => ({
      ...prev,
      changedCountKeys: [],
    }));
  };

  return {
    slaCounterData,
    setSlaCounterData: updateSlaCounterData,
    isSlaInitialLoad,
    setIsSlaInitialLoad,
  };
};
