import { Box, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusLarge } from "@fortawesome/pro-solid-svg-icons";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { useIntl } from "react-intl";
import { useCreateAiAgent } from "../../aiAgentsHooks/useCreateAiAgent";
import { useContext } from "react";
import { AiAgentsContext } from "../../aiAgentsContext";

const SideBarHeader = ({ searchInputSideBar, setSearchInputSideBar }) => {
  const intl = useIntl();
  const { mutate: createAiAgent } = useCreateAiAgent();
  const {
    setAiAgentSelected,
    setSelectedAiAgentPublishedVersion,
    setScrollType,
  } = useContext(AiAgentsContext);

  const handleCreateAiAgent = () => {
    const queryData = {
      auto_assignment: "inbound_routing",
    };
    createAiAgent(queryData, {
      onSuccess: (data) => {
        if (!data?.errorMsg) {
          setSelectedAiAgentPublishedVersion(null);
          setAiAgentSelected(data?.data?.id);
          setScrollType("create");
        }
      },
    });
  };

  return (
    <Box className="ai-agents-side-bar-header">
      <SearchIcon className="side-bar-search-icon" />
      <TextField
        id="search-input"
        placeholder={CheckValueLocale("search_ai_agent", "", {}, intl)}
        value={searchInputSideBar}
        onChange={(e) => setSearchInputSideBar(e?.target?.value)}
        inputProps={{
          sx: {
            "&::placeholder": {
              opacity: 1,
            },
          },
        }}
      />
      {handlelUserRoles("AI_AGENT", "CREATE_AI_AGENT") ? (
        <LucButton
          type="primary"
          variant="flat"
          size="small"
          startIcon={<FontAwesomeIcon icon={faPlusLarge} />}
          onClick={handleCreateAiAgent}
          id="ai-agent-button-create"
        >
          {CheckValueLocale("new", "", {}, intl)}
        </LucButton>
      ) : null}
    </Box>
  );
};

export default SideBarHeader;
