import { useIntl } from "react-intl";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import WidgetComponent from "../../widgetComponent";
import CustomDashboardAutoComplete from "./customDashboardAutoComplete";
import CustomizedTooltip from "components/customizedTooltip";

const WidgetControlContent = (props) => {
  const {
    controlSidebarOpened,
    viewTypes,
    viewType,
    handleViewTypeChange,
    customDashboardData,
    monitorsLoading,
    availableMonitors,
    selectedMonitors,
    setSelectedMonitors,
  } = props;

  const intl = useIntl();

  return (
    <Box className="widget-control-sidebar-content">
      <Box className="widget-view-wrapper">
        {controlSidebarOpened === true ? (
          <WidgetComponent
            isControlWidget // To identify that widget component will be rendered in control side bar
            widget={customDashboardData?.widget}
            dashboardInfo={customDashboardData?.dashboardInfo}
            widgetsMqttResponses={customDashboardData?.widgetsMqttResponses}
            customizedWidgets={customDashboardData?.customizedWidgets}
            handledWidgetName={customDashboardData?.handledWidgetName}
            selectedType={viewType}
          />
        ) : null}
      </Box>
      <Box>
        <Typography className="section-box-title">
          {CheckValueLocale("select_view_type", "", {}, intl)}
        </Typography>
        <Box>
          <FormControl id="control-widget-view-type-control">
            <RadioGroup
              id="control-widget-view-type-radio-grooup"
              aria-labelledby="view-type-controlled-radio-buttons-group"
              name="view-type-controlled-radio-buttons-group"
              value={viewType}
              onChange={handleViewTypeChange}
            >
              {viewTypes
                ?.filter((type) => {
                  return !(
                    type?.value === "comparison" &&
                    customDashboardData?.noComparison
                  );
                })
                ?.map((type, index) => (
                  <FormControlLabel
                    key={index}
                    className={`view-type-form-control-label ${viewType === type?.value ? "selected" : ""}`}
                    value={type?.value}
                    control={<Radio className="view-type-checkbox" />}
                    label={
                      <Box className="label-box">
                        <Box className="type-title-wrapper">
                          <FontAwesomeIcon
                            className="type-icon"
                            icon={type?.icon}
                          />
                          {CheckValueLocale(type?.title, "", {}, intl)}
                        </Box>
                        <Typography className="view-type-desc">
                          {CheckValueLocale(`${type?.desc}`, "", {}, intl)}
                        </Typography>
                      </Box>
                    }
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <Typography className="section-box-title">
          {CheckValueLocale("monitors", "", {}, intl)}:
          <CustomizedTooltip
            placement={intl?.locale == "ar" ? "left" : "right"}
            title={CheckValueLocale("monitors_title_tooltip_msg", "", {}, intl)}
            arrow
            styles={{ padding: "13px", zIndex: 9999 }}
          >
            <Box component="span" className="monitors-info-icon">
              <FontAwesomeIcon icon={faCircleInfo} fixedWidth />
            </Box>
          </CustomizedTooltip>
        </Typography>
        <Box className="control-wdget-select-monitors-wrapper">
          <CustomDashboardAutoComplete
            placeholder={CheckValueLocale(
              "select_custom_dashboard",
              "",
              {},
              intl,
            )}
            options={availableMonitors ? availableMonitors : []}
            selectedOptions={selectedMonitors}
            setSelectedOptions={setSelectedMonitors}
            loading={monitorsLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WidgetControlContent;
