import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
const AiAgentsCardLoading = ({ number }) => {
  return (
    <Box className="ai-agents-side-bar-content">
      {number?.map(() => (
        <Box className="main-cards-loader">
          <Box className="cards-loader-header">
            <Box className="cards-loader-title">
              <Skeleton
                animation="pulse"
                variant="circular"
                width={13}
                height={13}
              />
              <Skeleton
                animation="pulse"
                variant="text"
                sx={{ fontSize: "1rem" }}
              />
            </Box>
            <Skeleton
              animation="pulse"
              variant="circular"
              width={15}
              height={15}
            />
          </Box>
          <Box className="cards-loader-contant">
            <Skeleton
              animation="pulse"
              variant="text"
              sx={{ fontSize: "1rem" }}
            />
          </Box>
          <Box>
            <Skeleton
              animation="pulse"
              variant="text"
              sx={{ fontSize: "1rem" }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
export default AiAgentsCardLoading;
