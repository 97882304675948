import { Box } from "@mui/material";
import "./detailsSideBar.scss";
import ProfileSideBar from "./profileSideBar/profileSideBar";
import MainSavedReply from "./savedReplais/mainSavedReply";

const DetailsSideBarReply = ({
  setOpenDetailsSideBarReplay,
  text,
  setText,
  replyBoxInputTextRef,
}) => {
  return (
    <Box className="details-side-bar-wrapper">
      <MainSavedReply
        setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
        text={text}
        setText={setText}
        replyBoxInputTextRef={replyBoxInputTextRef}
      />
    </Box>
  );
};
export default DetailsSideBarReply;
