import React from "react";
import { useIntl } from "react-intl";
import { Box, Tab, Tabs } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const StackedBarComparisonTabs = (props) => {
  const { tabs, selectedTab, handleChangeTab } = props?.comparisonReachFunnel;

  const intl = useIntl();

  return (
    <Box id="custom-dashboard-comparison-widget-tabs">
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => handleChangeTab(newValue)}
      >
        {tabs?.map((tab) => {
          return (
            <Tab
              className={`tab-button ${tab == selectedTab ? "selected" : ""}`}
              label={CheckValueLocale(tab, "", {}, intl)}
              value={tab}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default StackedBarComparisonTabs;
