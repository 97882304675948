import { useQuery } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";
export const useSurveysPublishedList = (surveyActive) => {
  return useQuery({
    queryKey: ["surveysPublishedList"],
    queryFn: () => AiAgentsController.getSurveysPublishedList(),
    select: (data) => data?.data?.surveys?.data,
    enabled: !!surveyActive,
  });
};
