import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, Card, Grid, CardContent } from "@mui/material";
import TotalEngagersImg from "../../../../images/notification-icons/total-engagers.png";
import VerifiedEngagersImg from "../../../../images/notification-icons/verified-engagers.png";
import TotalVerifiedEngagersImg from "../../../../images/notification-icons/total-verified-engagers.png";
import { CheckValueLocale } from "utils/helpers";

const EngagersAnalysis = (props) => {
  const intl = useIntl();
  const {
    number_of_verified_engagers,
    total_engagers,
    total_verified_engagers,
  } = props?.engagersAnalysis || {};
  return (
    <>
      <Grid container spacing={2.5} sx={{ mt: "25px" }}>
        <Grid item xs={4}>
          <Card className="audience-Analysis-card">
            <CardContent>
              <Box>
                <Typography gutterBottom variant="h6" component="div">
                  {total_engagers}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {CheckValueLocale("total_engagers", "", {}, intl)}
                </Typography>
              </Box>
              <img src={TotalEngagersImg} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className="audience-Analysis-card">
            <CardContent>
              <Box>
                <Typography gutterBottom variant="h6" component="div">
                  {number_of_verified_engagers}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {CheckValueLocale("verified_engagers", "", {}, intl)}
                </Typography>
              </Box>
              <img src={VerifiedEngagersImg} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className="audience-Analysis-card">
            <CardContent>
              <Box>
                <Typography gutterBottom variant="h6" component="div">
                  {Number(total_verified_engagers?.toFixed(1))}%
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {CheckValueLocale(
                    "total_verified_engagers_in_tweet",
                    "",
                    {},
                    intl,
                  )}
                </Typography>
              </Box>
              <img src={TotalVerifiedEngagersImg} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EngagersAnalysis;
