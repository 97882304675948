import moment from "moment";
import { commasAfterDigit } from "utils/helpers";
import {
  faClock,
  faPercent,
  faQuestion,
} from "@fortawesome/pro-regular-svg-icons";

const handleCustomerCareStatsData = (response) => {
  const totalQuestions = +response?.total_number_of_questions;
  const answeredQuestions = +response?.number_of_answered_questions;
  const responseRate = (answeredQuestions / totalQuestions) * 100;

  return [
    {
      name: "total_ques",
      value: totalQuestions ? commasAfterDigit(totalQuestions) : 0,
      icon: faQuestion,
      titleToolTip: "number_of_ques_tooltip",
    },
    {
      name: "number_of_answered_questions",
      value: isNaN(responseRate) ? "0%" : `${Number(responseRate.toFixed(2))}%`,
      icon: faPercent,
      titleToolTip: "response_rate_tooltip",
    },
    {
      name: "avg_response_time_in_seconds",
      value: +response?.avg_response_time_in_seconds,
      icon: faClock,
      type: "time",
      titleToolTip: "avg_response_time_tooltip",
    },
  ];
};

const handleAverageEngagementStatsData = (response) => {
  return [
    {
      name: "max_interactions_per_post",
      value: response?.max_engagements,
      date: moment?.unix(response?.max_engagement_date)?.format("D-M-YYYY"),
    },
    {
      name: "min_interactions_per_post",
      value: response?.min_engagements,
      date: moment?.unix(response?.min_engagement_date)?.format("D-M-YYYY"),
    },
    {
      name: "avg_interaction_per_post",
      value: response?.average_interactions,
    },
  ];
};

const handleFollowersGrowthStatsData = (response) => {
  return [
    {
      name: "total_followers",
      value: Array?.isArray(response?.total_followers)
        ? +response?.total_followers?.[0]
        : +response?.total_followers,
    },
    {
      name: "max_change_of_followers",
      value: response?.max_change_of_followers,
    },
    { name: "avg_change_per_day", value: response?.avg_chage_of_day },
  ];
};

export {
  handleCustomerCareStatsData,
  handleAverageEngagementStatsData,
  handleFollowersGrowthStatsData,
};
