import { Typography, Avatar } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import AIAgentImageUpload from "images/ai-agents/ai-agent-image-upload.svg";
import AIAgentImageUploadFilled from "images/ai-agents/upload-ai-agent-image-filled.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const UploadFields = ({ fileValue, onChangeFile }) => {
  const intl = useIntl();
  const isNewFile = fileValue instanceof File;

  const imagePreview = isNewFile ? URL.createObjectURL(fileValue) : fileValue;

  return (
    <>
      {fileValue ? (
        <div className="ai-agents-form-upload">
          <div className="ai-agents-form-avatar-name-row">
            <Avatar
              src={imagePreview}
              className="ai-agents-form-upload-avatar"
            />
            <Typography>
              {CheckValueLocale("ai_agent_image", "", {}, intl)}
            </Typography>
          </div>
          <LucButton
            variant="text"
            startIcon={<img src={AIAgentImageUpload} />}
            id="ai-agent-upload-another-image"
            component="label"
            className="ai-agents-form-upload-button"
          >
            <input
              type="file"
              hidden
              onChange={(e) => {
                const file = e?.target?.files?.[0];
                if (file) {
                  onChangeFile(file);
                }
              }}
            />
            {CheckValueLocale("ai_agent_upload_another_image", "", {}, intl)}
          </LucButton>
        </div>
      ) : (
        <LucButton
          variant="outline"
          fullWidth
          id="ai-agent-upload-image"
          startIcon={<img src={AIAgentImageUploadFilled} />}
          component="label"
          className="ai-agents-form-upload-another"
        >
          <input
            type="file"
            hidden
            onChange={(e) => {
              const file = e?.target?.files?.[0];
              if (file) {
                onChangeFile(file);
              }
            }}
          />
          {CheckValueLocale("ai_agent_upload_image", "", {}, intl)}
        </LucButton>
      )}
    </>
  );
};

export default UploadFields;
