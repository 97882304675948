import { useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import { isEmpty } from "lodash";

//components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import TweetItem from "./TweetItem";
import { CheckValueLocale } from "utils/helpers";

const RepliesList = (props) => {
  const intl = useIntl();
  const [numOfReplies, setNumOfReplies] = useState(10);
  let { timeZone, topReplies, unSortedTopReplies } = props || {};
  const [repliesSortBy, setRepliesSortBy] = useState("interactions_count");

  const handleSortValues = (e) => {
    setRepliesSortBy(e?.target?.value);
  };
  if (repliesSortBy == "interactions_count") {
    topReplies = unSortedTopReplies;
  }
  topReplies?.sort((a, b) => {
    let sort = 0;
    switch (repliesSortBy) {
      case "date_asc":
        sort =
          moment(a?.created_at)?.format("x") -
          moment(b?.created_at)?.format("x");
        break;
      case "date_desc":
        sort =
          moment(b?.created_at)?.format("x") -
          moment(a?.created_at)?.format("x");
        break;
      case "followers_count":
        sort = b?.user?.followers_count - a?.user?.followers_count;
        break;
      default:
    }
    return sort;
  });

  let remained_tweets = Math.max(0, topReplies?.length - numOfReplies);
  let filteredReplies = topReplies?.slice(0, numOfReplies);
  const loadMore = () => {
    let currentReplies = numOfReplies;
    setNumOfReplies(currentReplies + 10);
  };
  const showLess = () => {
    setNumOfReplies(10);
  };

  return (
    <Box className="replies-box">
      <Card className="replies-card">
        <CardHeader
          action={
            <Box>
              <Typography variant="body2">
                {CheckValueLocale("sort_by", "", {}, intl)}:
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <Select
                  value={repliesSortBy}
                  onChange={(e) => handleSortValues(e)}
                >
                  <MenuItem
                    value="interactions_count"
                    id="single-tweets-reply-list-most-interactions"
                  >
                    {CheckValueLocale("most_interactions", "", {}, intl)}
                  </MenuItem>
                  <MenuItem
                    value="followers_count"
                    id="single-tweets-reply-list-most-followers"
                  >
                    {CheckValueLocale("most_followers", "", {}, intl)}
                  </MenuItem>
                  <MenuItem
                    value="date_asc"
                    id="single-tweets-order-reply-list-asc"
                  >
                    {CheckValueLocale("order_asc", "", {}, intl)}
                  </MenuItem>
                  <MenuItem
                    value="date_desc"
                    id="single-tweets-order-reply-list-desc"
                  >
                    {CheckValueLocale("order_desc", "", {}, intl)}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          }
          title={CheckValueLocale("replies", "", {}, intl)}
        />
        {isEmpty(topReplies) ? (
          <div className="chart__no-data">
            <FontAwesomeIcon icon={faExclamationCircle} />
            <div>
              {CheckValueLocale("no_data_for_this_period", "", {}, intl)}
              <p>{CheckValueLocale("adjust_dates_filters", "", {}, intl)}</p>
            </div>
          </div>
        ) : (
          <>
            <CardContent>
              {filteredReplies?.map((item) => {
                return <TweetItem data={item} timeZone={timeZone} />;
              })}
            </CardContent>
            {topReplies?.length && topReplies?.length > 10 && (
              <CardActions disableSpacing>
                {remained_tweets ? (
                  <Button
                    variant="text"
                    onClick={loadMore}
                    id="notification-single-tweet-analysis-load-replies-btn"
                  >
                    {CheckValueLocale("load_more_replies", "", {}, intl)}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    onClick={showLess}
                    id="notification-single-tweet-analysis-show-less-replies-btn"
                  >
                    {CheckValueLocale("show_less", "", {}, intl)}
                  </Button>
                )}
              </CardActions>
            )}
          </>
        )}
      </Card>
    </Box>
  );
};

export default RepliesList;
