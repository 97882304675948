// This files accepts an following props :
// labelColor, totalLabel, totalValue, hideLegend, percentage, textColor
// If hideLegend is passed, then the percentage with legend will be displayed
// If we don't pass hideLegend, then only legend name with value will be displayed

import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { getSocialUrlImg } from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView/components/controlWidget/components/dataSourceImage";
const TotalLabels = (props) => {
  const intl = useIntl();
  const [showDataChartValue, setShowDataChartValue] = useState(true);
  let tooltipTitle = !props?.singleLabel
    ? CheckValueLocale(props?.totalLabel, "", {}, intl) +
      " " +
      CheckValueLocale(showDataChartValue ? "hide" : "show", "", {}, intl)
    : "";
  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <Box
        className={`flex-style label-text ${!props?.singleLabel ? "" : "single-label-text"}`}
        onClick={(e) => {
          if (!props?.singleLabel) {
            props?.handleDataChartValue &&
              props?.handleDataChartValue(e, props?.totalLabel);

            setShowDataChartValue(!showDataChartValue);
          }
        }}
      >
        {props?.labelColor ? (
          <Box
            className="widget-boxes"
            style={{ background: props?.labelColor }}
          />
        ) : null}
        <Typography
          className={"chart-label-style flex-style"}
          color={props?.textColor && props?.textColor}
        >
          {/* to display monitor dataSource icon when this widget in comparison */}
          {props?.dataSourceMonitors ? (
            <Box
              component={"img"}
              src={getSocialUrlImg(props?.dataSourceMonitors)}
              alt="dataSource-monitor-icon"
            />
          ) : null}
          <div className="chart-label-title">
            {CheckValueLocale(props?.totalLabel, "", {}, intl)}
            {" : "}
          </div>

          {props?.hideLegend ? (
            <>
              {"(" + props?.totalValue?.toLocaleString() + ")" || "(" + 0 + ")"}
              {props?.hidePercentage ? (
                <></>
              ) : (
                <Typography
                  variant={"h7"}
                  className="chart-label-percent"
                  color={props?.textColor && props?.textColor}
                >
                  {props?.percentage}{" "}
                </Typography>
              )}
            </>
          ) : (
            <div>{props?.totalValue?.toLocaleString() || 0}</div>
          )}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default TotalLabels;
