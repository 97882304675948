// Common file used in whole app  !
// Don't repeat/update or remove this file!

import { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  MenuList,
  MenuItem,
  Popover,
  TextField,
  ListItemText,
  Button,
  styled,
  Box,
} from "@mui/material";
import { useIntl } from "react-intl";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  reduxDatePickerState,
  reduxDates,
  reduxDatesUnix,
  reduxDatesUnixToDisplay,
} from "utils/redux/features/SocialListening/socialListeningSlice.js";

import {
  reduxDatePickerStateCA,
  reduxDatesCA,
  reduxDatesUnixCA,
  reduxDatesUnixToDisplayCA,
} from "utils/redux/features/ChannelAnalytics/channelAnalyticsSlice.js";

import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { CheckValueLocale, delayFunction, isEmptyObj } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarRange } from "@fortawesome/pro-regular-svg-icons";
import { handleSelectedDate } from "shared/dateRangePicker/dateRagePicker.hooks";
import dayjs from "dayjs";

const DateRangePicker = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    isReduxCA,
    isReduxSM,
    handleInitialCall,
    filterFields,
    applyFilter,
    isActive,
    allTimeEnabled,
    showFilterBtn,
  } = props;

  // isReduxSM [Default Case | can used for other products]
  const reduxStartDate = useSelector(
    (state) => state?.socialListening?.startDate,
  );
  const reduxEndDate = useSelector((state) => state?.socialListening?.endDate);

  const reduxStartDateUnix = useSelector(
    (state) => state?.socialListening?.startDateUnix,
  );
  const reduxEndDateUnix = useSelector(
    (state) => state?.socialListening?.endDateUnix,
  );
  const reduxStartDateUnixToDisplay = useSelector(
    (state) => state?.socialListening?.startDateUnixToDisplay,
  );
  const reduxEndDateUnixToDisplay = useSelector(
    (state) => state?.socialListening?.endDateUnixToDisplay,
  );
  const reduxPickerState = useSelector(
    (state) => state?.socialListening?.pickerState,
  );
  const reduxFilterParams = useSelector(
    (state) => state?.socialListening?.newFilterParams,
  );
  // isReduxCA [Special Case]
  const reduxStartDateCA = useSelector(
    (state) => state?.channelAnalytics?.startDate,
  );
  const reduxEndDateCA = useSelector(
    (state) => state?.channelAnalytics?.endDate,
  );

  const reduxStartDateUnixCA = useSelector(
    (state) => state?.channelAnalytics?.startDateUnix,
  );
  const reduxEndDateUnixCA = useSelector(
    (state) => state?.channelAnalytics?.endDateUnix,
  );
  const reduxPickerStateCA = useSelector(
    (state) => state?.channelAnalytics?.pickerState,
  );

  const reduxStartDateUnixToDisplayCA = useSelector(
    (state) => state?.channelAnalytics?.startDateUnixToDisplay,
  );
  const reduxEndDateUnixToDisplayCA = useSelector(
    (state) => state?.channelAnalytics?.endDateUnixToDisplay,
  );

  // _______________________________________________________________________________________
  // Handle ID method for ChurnZero elements
  let handleMonitorDatesId = (val) => {
    let monitorDateId = val
      ? `date-range-picker-${val}-tab-id`
      : `date-range-picker-tab-id`;
    return monitorDateId;
  };
  // _______________________________________________________________________________________
  const [isDropOpen, setIsDropOpen] = useState(false);
  var PICKER_STATE = {
    TODAY: "today",
    YESTERDAY: "yesterday",
    LAST_7_DAYS: "last_7_days",
    LAST_15_DAYS: "last_15_days",
    LAST_30_DAYS: "last_30_days",
    CUSTOM: "custom_dates",
  };
  if (allTimeEnabled) {
    PICKER_STATE = {
      ALL_TIME: "all_time",
      ...PICKER_STATE,
    };
  }
  const eDate = moment();
  let reduxCustomDatesArray = isReduxCA
    ? [reduxStartDateUnixToDisplayCA, reduxEndDateUnixToDisplayCA]
    : [reduxStartDateUnixToDisplay, reduxEndDateUnixToDisplay];

  const [customDateValue, setCustomDateValue] = useState(reduxCustomDatesArray);
  const [pickerState, setPickerState] = useState(
    allTimeEnabled
      ? PICKER_STATE.ALL_TIME
      : isReduxCA
        ? reduxPickerStateCA
        : reduxPickerState,
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [pickerStateApply, setPickerStateApply] = useState(
    allTimeEnabled
      ? PICKER_STATE.ALL_TIME
      : isReduxCA
        ? `${reduxStartDateCA} - ${reduxEndDateCA}`
        : `${reduxStartDate} - ${reduxEndDate}`,
  );
  const startCustomDate = moment(customDateValue[0])
    .tz("utc", true)
    .format("DD/MM/YYYY");
  const endCustomDate = moment(customDateValue[1])
    .tz("utc", true)
    .format("DD/MM/YYYY");

  useEffect(() => {
    let startDate;
    let endDate;

    if (
      pickerState === "Last 7 Days" ||
      pickerState === "آخر ٧ أيام" ||
      pickerState === PICKER_STATE.LAST_7_DAYS
    ) {
      startDate = moment().tz("utc", true).subtract(6, "d").startOf("day");
      endDate = moment().tz("utc", true);
      handleApply();
    } else if (pickerState === PICKER_STATE.TODAY) {
      startDate = moment().tz("utc", true).startOf("day");
      endDate = moment().tz("utc", true);
      handleApply();
    } else if (pickerState === PICKER_STATE.YESTERDAY) {
      startDate = moment().tz("utc", true).subtract(1, "d").startOf("day");
      endDate = moment().tz("utc", true).subtract(1, "d").endOf("day");
      handleApply();
    } else if (pickerState === PICKER_STATE.LAST_15_DAYS) {
      startDate = moment().tz("utc", true).subtract(14, "d").startOf("day");
      endDate = moment().tz("utc", true);
      handleApply();
    } else if (pickerState === PICKER_STATE.LAST_30_DAYS) {
      startDate = moment().tz("utc", true).subtract(29, "d").startOf("day");
      endDate = moment().tz("utc", true);
      handleApply();
    } else if (pickerState === PICKER_STATE.ALL_TIME) {
      startDate = moment.unix(parseInt(props.allTimeStartDate)).tz("utc", true);
      endDate = moment().tz("utc", true);
      handleApply();
    } else {
      startDate = moment(customDateValue[0]).startOf("day");
      endDate =
        moment(customDateValue[1]).startOf("day") ||
        moment().tz("utc", true).startOf("day");
    }

    setCustomDateValue([startDate, endDate]);
  }, [pickerState, CheckValueLocale(pickerState, "", {}, intl)]);

  useEffect(() => {
    document.body.classList.toggle("bodyhidden", isDropOpen);
  }, [isDropOpen]);

  useEffect(() => {
    if (props.resetCalendar !== undefined && props.resetCalendar !== 1) {
      setPickerState(PICKER_STATE.LAST_7_DAYS);
    }
  }, [props.resetCalendar]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPickerState(pickerStateApply);

    if (isReduxCA) {
      dispatch(reduxDatePickerStateCA(pickerStateApply));
    } else {
      dispatch(reduxDatePickerState(pickerStateApply));
    }
  };

  const handleClose = () => {
    setIsDropOpen(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-picker-popover" : undefined;

  const handleApply = () => {
    handleSelectedDate(
      pickerState,
      customDateValue,
      handleDateSubmit,
      props.allTimeStartDate,
    );
    if (pickerState === PICKER_STATE.CUSTOM) {
      setCustomDateValue([customDateValue[0], customDateValue[1]]);
      setPickerStateApply(`${startCustomDate} - ${endCustomDate}`);

      const startDate = moment(customDateValue[0])?.startOf("day");
      const endDate = moment(customDateValue[1])?.startOf("day");

      if (isReduxCA) {
        dispatch(reduxDatesUnixToDisplayCA({ startDate, endDate }));
        dispatch(reduxDatePickerStateCA("custom_dates"));
        dispatch(reduxDatesCA({ startCustomDate, endCustomDate }));
      } else {
        dispatch(reduxDatesUnixToDisplay({ startDate, endDate }));
        dispatch(reduxDatePickerState("custom_dates"));
        dispatch(reduxDates({ startCustomDate, endCustomDate }));
      }
    } else {
      setPickerStateApply(pickerState);
      if (isReduxCA) dispatch(reduxDatePickerStateCA(pickerState));
      else dispatch(reduxDatePickerState(pickerState));
    }
    delayFunction(50).then(() => {
      handleClose();
    });
  };

  useEffect(() => {
    if (!showFilterBtn && !isEmptyObj(reduxFilterParams)) {
      if (!isReduxCA) applyFilter(reduxStartDateUnix, reduxEndDateUnix);
      else applyFilter(reduxStartDateUnixCA, reduxEndDateUnixCA);
    }
  }, [showFilterBtn]);

  const handleDateSubmit = (startDate, endDate) => {
    if (isEmptyObj(reduxFilterParams) || showFilterBtn) {
      applyFilter(startDate, endDate);
    } else if (!showFilterBtn && !isEmptyObj(reduxFilterParams)) {
      applyFilter(startDate, endDate);
    }
    if (isReduxCA) dispatch(reduxDatesUnixCA({ startDate, endDate }));
    else dispatch(reduxDatesUnix({ startDate, endDate }));
  };

  const custemDate = () => {
    if (
      pickerState === PICKER_STATE.CUSTOM ||
      pickerState === `${startCustomDate} - ${endCustomDate}`
    ) {
      const customDateValueDayjs = [
        dayjs(customDateValue?.[0]?._d || customDateValue?.[0]),
        dayjs(customDateValue?.[1]?._d || customDateValue?.[1]),
      ];
      return (
        <Box pb={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomDatePicker
              disableHighlightToday
              displayStaticWrapperAs="desktop"
              value={customDateValueDayjs}
              maxDate={dayjs(eDate?._d)}
              minDate={
                props?.oldestDate
                  ? dayjs(moment(props?.oldestDate)?._d)
                  : dayjs.unix("330421949")
              }
              onChange={(value) => {
                const newValue = [value?.[0]?.$d, value?.[1]?.$d];
                setPickerState(PICKER_STATE.CUSTOM);
                newValue[1] == undefined
                  ? setCustomDateValue([newValue[0], newValue[0]])
                  : setCustomDateValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
              id={handleMonitorDatesId(`${customDateValue}-custom-date-value`)}
            />
          </LocalizationProvider>

          <Box pl={2} display="flex">
            <Button
              variant="contained"
              onClick={() => handleApply()}
              className="apply__date"
              id="date-range-picker-apply-monitor-active-tab-id-date"
            >
              {CheckValueLocale("apply", "", {}, intl)}
            </Button>

            <Box sx={{ mx: 1 }} />
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{
                backgroundColor: "#E2E8F0",
                color: "#64748B",
                borderColor: "#E2E8F0",
                fontWeight: 400,
              }}
              id={handleMonitorDatesId(`cancel-${props?.monitorActiveTabId}`)}
            >
              {CheckValueLocale("cancel", "", {}, intl)}
            </Button>
          </Box>
        </Box>
      );
    }
  };

  useEffect(() => {
    if (pickerState == "custom_dates" && isReduxCA) {
      handleApply();
    }
  }, [intl.locale]);

  return (
    <>
      <div
        className={`date-field ${isActive !== false ? "active-date" : ""}`}
        id={props?.dateRangePickerID ?? "date-range-picker-id"}
        onClick={isActive !== false && handleClick}
      >
        <FontAwesomeIcon icon={faCalendarRange} />

        <CustomField
          value={CheckValueLocale(pickerStateApply, "", {}, intl)}
          className="dateRange"
          inputProps={{ readOnly: true }}
          disabled={isActive === false ? true : false}
        />
      </div>

      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={
          "luc-date-picker " +
          props.smallBoxCustom +
          (pickerState == PICKER_STATE.CUSTOM && props.datePickerPosition) //this is extra className sent as a prop from main file to adjust position of menu opened
        }
      >
        <Box display="flex" className="main-date-list-container">
          {props.openCustomDateLeft === true && custemDate()}

          <Box width={170} className="date-list">
            <CustomMenuList>
              {Object.values(PICKER_STATE).map((key, index) => (
                <CustomMenuItem
                  key={index}
                  selected={
                    pickerState === `${startCustomDate} - ${endCustomDate}`
                      ? key == PICKER_STATE.CUSTOM
                      : key === pickerState
                  }
                  onClick={() => {
                    setPickerState(key);
                  }}
                  sx={{
                    height: "41px",
                  }}
                  id="date-range-picker-state-monitor-active-tab-id"
                >
                  <ListItemText id="date-range-item-monitor-active-tab-id">
                    {CheckValueLocale(key, "", {}, intl)}
                  </ListItemText>
                </CustomMenuItem>
              ))}
            </CustomMenuList>
          </Box>
          {props.openCustomDateRight === true && custemDate()}
        </Box>
      </CustomPopover>
    </>
  );
};

export default DateRangePicker;

const CustomMenuList = styled(MenuList)`
  .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    min-height: auto;
    border-bottom: 1px solid #e2e8f0 !important;
  }
  .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root:last-child {
    border-bottom: 0 !important;
  }
  .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.Mui-selected {
    background-color: #456eb2 !important;
    span {
      color: #fff;
    }
  }
`;
const CustomField = styled(TextField)`
  height: 41px;
  .MuiInputBase-root {
    font-size: 13px;
    background-color: #fff;
    border: 1px solid rgba(112, 112, 112, 0.2);
    font-weight: 400;
    color: #000;
    height: 41px;
    margin: 0px 20px;
    cursor: pointer;
    outline: 0;
    border: 0;
  }
  .MuiInputBase-root:focus {
    outline: 0;
    border: 0;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    cursor: pointer;
    outline: 0;
    border: 0;
  }
`;
const CustomMenuItem = styled(MenuItem)`
  .css-1uzh8eb-MuiTypography-root {
    color: #475569;
    font-size: 15px !important;
    font-weight: 400;
  }
  .ul {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
const CustomPopover = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: 0px 0px 4px 0px #e2e8f0;
    border: 1px solid #e2e8f0;
    /* top: 130px !important; */
  }
`;
const CustomDatePicker = styled(StaticDateRangePicker)`
  .css-mvmu1r {
    margin: 0;
  }
  .css-14hwa9t-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected,
  .css-xgq401-MuiDateRangePickerDay-root:last-of-type,
  .css-xgq401-MuiDateRangePickerDay-root:first-of-type {
    border-radius: 0;
    color: rgba(249, 245, 245, 0.87);
  }
  .css-14hwa9t-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
    background-color: #397fbb;
    color: #fff;
  }
  .css-1m9erir-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:hover {
    border: none;
    border-radius: 0;
    background: #ebf4f8;
  }
  .css-1mn81ta-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:hover {
    background-color: transparent;
  }
  .css-xgq401-MuiDateRangePickerDay-root,
  .css-vwcpoq-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected,
  .css-vwcpoq-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:focus.Mui-selected,
  .css-1m9erir-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:focus {
    background-color: #ebf4f8;
    border-radius: 0;
    border: none;
  }

  .css-1m9erir-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day,
  .css-1mn81ta-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day,
  .css-14hwa9t-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
  }
  .css-9ay3a3-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(
      .Mui-selected
    ),
  .css-14hwa9t-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:focus.Mui-selected {
    background-color: #397fbb;
    color: #fff;
    border: none;
    border-radius: 0;
  }
  .css-7fdq88-MuiTypography-root,
  .css-1o490ea-MuiDateRangePickerDay-rangeIntervalPreview {
  }
  .css-fcs31k-MuiTypography-root {
    color: #475569;
    font-size: 15px;
  }
  .css-7fdq88-MuiTypography-root {
    color: #475569;
    font-size: 13px;
    font-weight: 600;
  }
  .PrivatePickersSlideTransition-root.css-170k9md {
    min-height: 250px;
  }
  .css-1tape97:not(:last-of-type) {
    border: none;
  }
  .MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
    background-color: #ebf4f8;
    border-radius: 0;
  }
  .MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day.MuiDateRangePickerDay-notSelectedDate.MuiDateRangePickerDay-dayInsideRangeInterval {
    background-color: #ebf4f8;
    border-radius: 0;
  }
  .css-f7iyql {
    padding-bottom: 0px;
    /* height: 30px; */
  }
  .css-1n2mv2k {
    padding-bottom: 0px;
    /* height: 30px; */
  }
  /* .MuiDateRangePickerDay-root {
    width: 30px;
    height: 30px;
    .MuiButtonBase-root {
    }
  } */
`;
