import { Menu, MenuItem } from "@mui/material";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const AddKnowledgeBaseOptions = ({
  stats,
  handleClose,
  open,
  setIsAddURLKnowledgeBaseOpen,
  setIsAddFilesOpen,
  setIsSwaggerFilesOpen,
  hasSwagger,
}) => {
  const intl = useIntl();
  const { setAiAgentSnackBar } = useContext(AiAgentsContext);

  const handleSwaggerFileUploadModalOpen = () => {
    if (hasSwagger) {
      setAiAgentSnackBar({
        severity: "info",
        success: true,
        message: CheckValueLocale(
          "has_swagger_file_message",
          "",
          {
            fileName: hasSwagger,
          },
          intl,
        ),
      });
    } else {
      setIsSwaggerFilesOpen(true);
    }
    handleClose();
  };

  return (
    <>
      <Menu
        id="knowledge-base-options-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleClose}
        className="menu-ai-agent"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale == "en" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale == "en" ? "right" : "left",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setIsAddURLKnowledgeBaseOpen(true);
          }}
          disableRipple
          id="knowledge-base-options-menu-item"
        >
          {CheckValueLocale("url_capital", "", {}, intl)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setIsAddFilesOpen(true);
          }}
          disableRipple
          id="knowledge-base-options-menu-item"
        >
          {CheckValueLocale("text_file", "", {}, intl)}
        </MenuItem>
        <MenuItem
          onClick={handleSwaggerFileUploadModalOpen}
          disableRipple
          id="knowledge-base-options-menu-item"
        >
          {CheckValueLocale("swagger", "", {}, intl)}
        </MenuItem>
      </Menu>
    </>
  );
};

export default AddKnowledgeBaseOptions;
