import {
  changeFromObjectToArrayOfObject,
  handleSentimentResponse,
} from "utils/helpers";
import { handleCustomerCareStatsData } from "../statsDataHandling";

const widgetsMapping = {
  Facebook__AccountPage__volume_overtime: {
    value: (response) => ({
      data: response?.posts_over_time,
      total_count: response?.total_posts_count,
    }),
  },
  Facebook__EngagementsPage__volume_overtime: {
    value: (response) => ({
      data: response?.posts_over_time,
      total_count: response?.total_posts_count,
    }),
  },
  Facebook__EngagementsPage__reactions: {
    value: (response) => ({
      data: response?.posts_reactions?.posts_reactions_over_time,
      pie_data: response?.posts_reactions?.posts_reactions_piechart,
    }),
  },
  Facebook__AccountPage__reactions: {
    value: (response) => ({
      data: response?.posts_reactions?.posts_reactions_over_time,
      pie_data: response?.posts_reactions?.posts_reactions_piechart,
    }),
  },
  Facebook__EngagementsPage__sentiment_analysis: {
    value: (response) => ({
      data: handleSentimentResponse(response?.sentiment_overtime),
      pie_data: response?.sentiment_piechart,
    }),
  },
  Facebook__AccountPage__posts_interactions: {
    value: (response) => ({
      data: response?.posts_interactions?.posts_interactions_over_time,
      pie_data: response?.posts_interactions?.posts_interactions_legends,
      max_interactions: response?.posts_interactions?.max_interactions,
      min_interactions: response?.posts_interactions?.min_interactions,
      max_interaction_date: response?.posts_interactions?.max_interaction_date,
      min_interaction_date: response?.posts_interactions?.min_interaction_date,
      average_interactions: response?.posts_interactions?.average_interactions,
    }),
  },
  Facebook__EngagementsPage__posts_interactions: {
    value: (response) => ({
      data: response?.posts_interactions?.posts_interactions_over_time,
      pie_data: response?.posts_interactions?.posts_interactions_legends,
      max_interactions: response?.posts_interactions?.max_interactions,
      min_interactions: response?.posts_interactions?.min_interactions,
      max_interaction_date: response?.posts_interactions?.max_interaction_date,
      min_interaction_date: response?.posts_interactions?.min_interaction_date,
      average_interactions: response?.posts_interactions?.average_interactions,
    }),
  },
  Facebook__AccountPage__top_hashtags: {
    value: (response) => ({ pie_data: response?.top_hashtags }),
  },
  Facebook__EngagementsPage__top_hashtags: {
    value: (response) => ({ pie_data: response?.top_hashtags }),
  },
  Facebook__AccountPage__top_keywords: {
    value: (response) => ({ pie_data: response?.top_keywords }),
  },
  Facebook__EngagementsPage__top_keywords: {
    value: (response) => ({ pie_data: response?.top_keywords }),
  },
  Facebook__EngagementsPage__associated_topics: {
    value: (response) => ({ pie_data: response?.associated_topics }),
  },
  Facebook__AccountPage__content_type: {
    value: (response) => ({
      data: response?.posts_content_type?.posts_content_type,
      pie_data: response?.posts_content_type?.posts_content_types_legends,
    }),
  },
  Facebook__EngagementsPage__themes_and_sub_themes: {
    value: (response) => ({ data: response?.themes }),
  },
  Facebook__AccountPage__account_author_activity: {
    value: (response) => ({
      activity_data: response?.account_author_activity,
      min_value: response?.min_value,
      max_value: response?.max_value,
    }),
  },
  Facebook__EngagementsPage__dialects_and_sub_dialects: {
    value: (response) => ({
      pie_data: response?.dialects,
      pie_sub_data: response?.sub_dialects,
    }),
  },
  Facebook__EngagementsPage__account_author_activity: {
    value: (response) => ({
      data: response?.account_author_activity,
      min_value: response?.min_value,
      max_value: response?.max_value,
    }),
  },
  Facebook__CASingleDataSourceInsightsPage__customer_care_performance: {
    value: (response) => ({
      data: changeFromObjectToArrayOfObject(response?.response_time_segment),
      total_number_of_questions: response?.total_number_of_questions,
      avg_response_time_in_seconds: response?.avg_response_time_in_seconds,
      number_of_answered_questions: response?.number_of_answered_questions,
      stats_data: handleCustomerCareStatsData(response),
    }),
  },
  Facebook__AccountPage__top_images: {
    value: (response) => ({ media_data: response?.top_images }),
  },
  Facebook__AccountPage__top_videos: {
    value: (response) => ({ media_data: response?.top_videos }),
  },

  // -------------------------- Facebook Private --------------------------
  dialects: {
    value: (response) => ({
      pie_data: response?.dialects,
      pie_sub_data: response?.sub_dialects,
    }),
  },
  channel_accounts: {
    value: (response) => ({ pie_data: response?.channel_accounts }),
  },
  content_type: {
    value: (response) => ({ pie_data: response?.content_type_piechart }),
  },
  themes: {
    value: (response) => ({ data: response?.themes }),
  },
  sentiment: {
    value: (response) => ({
      data: handleSentimentResponse(response?.sentiment_overtime),
      pie_data: response?.sentiment_piechart,
    }),
  },
  interactions: {
    value: (response) => ({
      data: response?.interactions,
      total_count: response?.total_interactions,
    }),
  },
  top_keywords: {
    value: (response) => ({ pie_data: response?.top_keywords }),
  },
  genders: {
    value: (response) => ({ pie_data: response?.genders }),
  },
  top_engaged_authors: {
    value: (response) => ({ pie_data: response?.top_engaged_authors }),
  },
  channel_activity: {
    value: (response) => ({
      activity_data: response?.channel_activity?.client,
      min_value: response?.channel_activity?.min_value_client,
      max_value: response?.channel_activity?.max_value_client,
    }),
  },
};

export const FacebookOmniRespHandling = (
  response,
  handleWidgetResponse,
  dataSource,
) => {
  const widgetKey = widgetsMapping[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      response?.metric_id,
      response?.monitor_id,
      response?.eventName?.includes("__")
        ? dataSource
        : `${dataSource}_PRIVATE`,
      widgetKey?.value(response?.eventData),
      response?.customized_metric_id,
    );
};
