import { useQuery } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useRunAiAgentTest = (id, open) => {
  return useQuery({
    queryKey: "runAiAgentTest",
    queryFn: () => AiAgentsController.aiAgentRunTest(id),
    enabled: open && !!id,
  });
};
