const ManageSubscription = () => {
  const url = window.location.search.replace("?url=", "");

  return (
    <>
      <iframe style={{ height: "100vh" }} width="100%" src={url} />
    </>
  );
};

export default ManageSubscription;
