import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import CompaniesController from "services/controllers/companiesController";
import "./productsPermissions.scss";
import PermissionsTable from "./permissionsTable";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import APIIcon from "images/product-icons/api-icon.svg";
import EngagementIcon from "images/product-icons/engagement-icon.svg";
import DCPIcon from "images/product-icons/cdp-icon.svg";
import OCIcon from "images/product-icons/oc.icon.svg";
import SettingIcon from "images/product-icons/settings-icon.svg";
import SLIcon from "images/product-icons/sl-icon.svg";
import Surveys from "images/product-icons/surveys-icon.svg";
import AiAgentsIcon from "images/ai-agents/ai-agents-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faUserTimes } from "@fortawesome/pro-solid-svg-icons";

const ProductsPermissions = ({
  stepTwoData,
  setStepTwoData,
  isView,
  isEngagAcc,
}) => {
  const intl = useIntl();
  const [productsPermissions, setProductsPermissions] = useState([]);
  const [engagRemaining, setEngagRemaining] = useState(0);
  const [expanded, setExpanded] = useState(false);

  let greanState = ["team_leader", "agent_egagment", "moderator"];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    let activeProducts = JSON.parse(window?.localStorage?.activeProducts);
    let productsArr = [];
    let selectedArr = Object.keys(stepTwoData);
    activeProducts?.map((product) => {
      switch (product?.name) {
        case "SM":
          if (
            product?.active === true &&
            selectedArr?.includes("social_listening")
          ) {
            productsArr.push("social_listening");
          }
          break;
        case "CXM":
          if (
            product?.active === true &&
            selectedArr?.includes("channel_analytics")
          ) {
            productsArr.push("channel_analytics");
          }
          break;
        case "SURVEY":
          if (product?.active === true && selectedArr?.includes("surveys")) {
            productsArr.push("surveys");
          }
          break;
        case "CDP":
          if (product?.active === true && selectedArr?.includes("audience")) {
            productsArr.push("audience");
          }
          break;
        case "ENGAGEMENTS":
          if (product?.active === true && selectedArr?.includes("engagement")) {
            productsArr.push("engagement");
            CompaniesController.getCompanySubscriptions().then((result) => {
              let remaining =
                result?.data?.data?.attributes?.engagement_package?.attributes
                  ?.max_admins -
                result?.data?.data?.attributes?.engagement_package?.attributes
                  ?.package_used_admins;
              setEngagRemaining(remaining ? remaining : 0);
            });
          }
          break;
        case "AI_AGENT":
          if (product?.active === true && selectedArr?.includes("ai_agent")) {
            productsArr.push("ai_agent");
          }
          break;
        case "PUBLIC_API":
        case "AI_API":
          if (
            product?.active === true &&
            !productsArr?.includes("lucidya_api") &&
            selectedArr?.includes("lucidya_api")
          ) {
            productsArr.push("lucidya_api");
          }
          break;
        default:
          break;
      }
    });
    if (
      productsArr?.includes("lucidya_api") &&
      selectedArr?.includes("general_setting")
    ) {
      productsArr?.splice(productsArr?.length - 1, 0, "general_setting");
    } else {
      selectedArr?.includes("general_setting") &&
        productsArr.push("general_setting");
    }
    setProductsPermissions([...productsArr]);
    !isView && setExpanded(productsArr[0]);
    let selectedData = {};
    Object.keys(stepTwoData)?.map((item) => {
      if (productsArr?.includes(item)) {
        selectedData[item] = stepTwoData[item];
      }
    });
    setStepTwoData({ ...selectedData });
  }, []);

  const iconMapper = {
    engagement: EngagementIcon,
    social_listening: SLIcon,
    audience: DCPIcon,
    channel_analytics: OCIcon,
    lucidya_api: APIIcon,
    general_setting: SettingIcon,
    surveys: Surveys,
    ai_agent: AiAgentsIcon,
  };

  return (
    <Box className="main-perms-countainer">
      {productsPermissions?.map((product) => {
        return (
          <Accordion
            expanded={expanded === product}
            onChange={handleChange(product)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box className="main-collapse-header">
                <Box className="collapse-header-det">
                  <img src={iconMapper[product]} alt={"product"} />
                  <Box>
                    <Box className="collapse-header-title">
                      {CheckValueLocale(product, "", {}, intl)}
                      {product === "engagement" ? (
                        <Box className="header-title-engag">
                          {CheckValueLocale(
                            "remaining",
                            "",
                            { num: engagRemaining },
                            intl,
                          )}
                        </Box>
                      ) : null}
                    </Box>
                    <Box className="collapse-header-peag">
                      {CheckValueLocale(`${product}_prag`, "", {}, intl)}
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={`collapse-header-state ${
                    greanState?.includes(stepTwoData[product])
                      ? "green_state"
                      : `${stepTwoData[product]}`
                  }`}
                >
                  <FontAwesomeIcon
                    icon={
                      stepTwoData[product] != "no_acc"
                        ? faUserCheck
                        : faUserTimes
                    }
                  />
                  {CheckValueLocale(
                    stepTwoData[product] === "no_acc"
                      ? "no_acc_eng"
                      : stepTwoData[product],
                    "",
                    {},
                    intl,
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <PermissionsTable
                stepTwoData={stepTwoData}
                setStepTwoData={setStepTwoData}
                permName={product}
                engagRemaining={engagRemaining}
                isView={isView}
                isEngagAcc={isEngagAcc}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};
export default ProductsPermissions;
