import { useState } from "react";
import { Box } from "@mui/material";
import "./aiAgentsSideBar.scss";
import SideBarHeader from "./sideBarHeader";
import SideBarEmpty from "./sideBarEmpty";

import SideBarContent from "./sideBarContent";
import AiAgentsCardLoading from "./aiAgentsCardLoading";

const AiAgentsSideBar = ({ aiAgentList, aiAgentListLoading }) => {
  const [searchInputSideBar, setSearchInputSideBar] = useState("");

  return (
    <Box className="ai-agents-side-bar-container">
      <SideBarHeader
        searchInputSideBar={searchInputSideBar}
        setSearchInputSideBar={setSearchInputSideBar}
        aiAgentList={aiAgentList}
      />
      {aiAgentListLoading ? (
        <AiAgentsCardLoading number={[1, 2, 3, 4]} />
      ) : !aiAgentList?.length ? (
        <SideBarEmpty emptyText="empty_ai_agents" />
      ) : (
        <SideBarContent
          aiAgentList={aiAgentList}
          searchInputSideBar={searchInputSideBar}
        />
      )}
    </Box>
  );
};

export default AiAgentsSideBar;
