import { Typography, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "../../src/utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";

const PageHeader = (props) => {
  const intl = useIntl();
  /** Page Header props are: 
      boxMB: optional, 
      container: optional, --- discuss
      title: required,
      titleId: optional,
      titleAttributeName: optional,
      titleAttributeValue: optional,
      titleVariant:optional,
      subTitle: optional, 
      subTitleVariant:optional,
      subTitleStyle : optional,
      withBreadCrumb: optional
   */

  var titleWithInjectedAttributes = {};
  titleWithInjectedAttributes[props.titleAttributeName] =
    props.titleAttributeValue;

  let checkInjectedAttributes =
      props.titleAttributeName !== undefined &&
      props.titleAttributeValue !== undefined,
    titleVariant = props.titleVariant ? props.titleVariant : "h6",
    subTitleId = props.withBreadCrumb
      ? "main-pages-header-with-breadcrumb"
      : props.subTitle
        ? "main-pages-header-subtitles"
        : "main-pages-header",
    subTitleStyleClassName = props.subTitleStyle ? props.subTitleStyle : "",
    subTitleVariant = props.subTitleVariant ? props.subTitleVariant : "body2";

  const handleManageSubscriptionButton = () => {
    window.open(
      "/manage-subscription?url=" + props?.subscriptionLink,
      "_blank",
    );
  };

  return (
    <>
      <Box
        className={props?.hasEndSide ? "page-header-wrapper" : ""}
        xs={props?.boxXS}
        mb={props?.boxMB}
      >
        <Box className={props?.hasEndSide ? "page-header-text" : ""}>
          <Typography variant={titleVariant} id={subTitleId}>
            {checkInjectedAttributes
              ? CheckValueLocale(
                  props.title,
                  "",
                  titleWithInjectedAttributes,
                  intl,
                )
              : CheckValueLocale(props.title, "", {}, intl)}
          </Typography>

          {props.subTitle && (
            <Typography
              variant={subTitleVariant}
              className={subTitleStyleClassName}
            >
              {CheckValueLocale(props.subTitle, "", {}, intl)}
            </Typography>
          )}
        </Box>
        {props?.isCreditCard ? (
          <LucButton
            onClick={handleManageSubscriptionButton}
            endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
            id="manage-subscription-button"
          >
            {CheckValueLocale("manage_subscription", "", {}, intl)}
          </LucButton>
        ) : null}
      </Box>
    </>
  );
};

export default PageHeader;
