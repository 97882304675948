import { b64toBlob } from "pages/notifications/list/sharedFunctions/viewDownloadFunctions";
import yaml from "js-yaml";

const AiAgentFunction = () => {
  const parseSwaggerData = (swaggerData) => {
    const endpoints = [];

    const resolveRef = (ref, definitions) => {
      const refKey = ref?.replace("#/definitions/", "");
      return definitions?.[refKey];
    };

    const generateDummyData = (schema, definitions) => {
      if (schema?.$ref) {
        const resolvedSchema = resolveRef(schema?.$ref, definitions);
        return generateDummyData(resolvedSchema, definitions);
      } else if (schema?.type === "object") {
        const obj = {};
        for (const key of Object.keys(schema?.properties || {})) {
          const prop = schema?.properties?.[key];
          obj[key] = generateDummyData(prop, definitions);
        }
        return obj;
      } else if (schema?.type === "array") {
        return [generateDummyData(schema?.items, definitions)];
      } else if (schema?.type === "string") {
        return schema?.example || "string_example";
      } else if (schema?.type === "integer") {
        return schema?.example || 0;
      } else if (schema?.type === "boolean") {
        return schema?.example || true;
      }
      return null;
    };

    for (const [path, methods] of Object.entries(swaggerData?.paths)) {
      for (const [method, details] of Object.entries(methods)) {
        const overAllParams = details?.parameters
          ? details?.parameters?.map((param) => ({ ...param }))
          : [];

        let requestBody = null;
        for (const param of overAllParams) {
          if (param?.in === "body" && param?.schema) {
            const ref = param?.schema?.$ref;
            if (ref) {
              const schemaKey = ref?.replace("#/definitions/", "");
              const schema = swaggerData?.definitions?.[schemaKey];
              requestBody = generateDummyData(schema, swaggerData?.definitions);
            }
          }
        }

        endpoints?.push({
          path,
          method,
          summary: details?.summary || "",
          description: details?.description || "",
          operationId: details?.operationId || "",
          parameters: overAllParams,
          requestBody,
          id: details?.id,
        });
      }
    }

    return endpoints;
  };

  const convertToSwaggerFormat = (modifiedData) => {
    const swagger = {
      paths: {},
    };

    modifiedData?.forEach((endpoint) => {
      const {
        path,
        method,
        summary,
        description,
        operationId,
        parameters,
        headers,
      } = endpoint;

      if (!swagger?.paths?.[path]) {
        swagger.paths[path] = {};
      }

      swagger.paths[path][method] = {
        summary,
        description,
        operationId,
        parameters: parameters?.map((param) => {
          const {
            name,
            in: location,
            description,
            required,
            type,
            format,
            items,
            collectionFormat,
            maximum,
            minimum,
          } = param;
          const parameter = {
            name,
            in: location,
            description,
            required: required || false,
            type,
          };
          if (format) parameter.format = format;
          if (items) parameter.items = items;
          if (collectionFormat) parameter.collectionFormat = collectionFormat;
          if (maximum !== undefined) parameter.maximum = maximum;
          if (minimum !== undefined) parameter.minimum = minimum;
          return parameter;
        }),
      };

      if (headers && headers?.length > 0) {
        if (!swagger?.paths?.[path]?.[method]?.parameters) {
          swagger.paths[path][method].parameters = [];
        }
        headers?.forEach((header) => {
          swagger?.paths?.[path]?.[method]?.parameters?.push({
            name: header?.name,
            in: "header",
            description: header?.description,
            required: header?.required || false,
            type: header?.type,
          });
        });
      }
    });

    return swagger;
  };

  const converStrToArr = (str) => (str?.length ? JSON.parse(str || "") : []);

  const converArrToStr = (arr) =>
    arr?.length
      ? "[" + arr?.map((item) => '"' + item + '"')?.join(",") + "]"
      : "";

  const truncateFileName = (fileName, maxLength = 15) => {
    if (!fileName) return "";

    const [name, extension] = fileName?.split(/(?=\.[^.]+$)/);
    if (name?.length <= maxLength) return fileName;

    return `${name?.substring(0, maxLength)}...${extension}`;
  };

  const groupByYear = (data) => {
    return data?.reduce((acc, item) => {
      const year = new Date(item?.attributes?.created_at)?.getFullYear();
      if (!acc?.[year]) {
        acc[year] = [];
      }
      acc?.[year]?.push(item);
      return acc;
    }, {});
  };

  const extractAndTruncateFilename = (isNewFile, value, maxLength = 15) => {
    const newValue = isNewFile ? value?.name : value;
    if (!value) return "";
    const filename = newValue?.split("/")?.pop()?.split("?")?.[0];
    let [name, extension] = filename?.split(".");

    if (name?.length > maxLength) {
      name = name?.slice(0, maxLength) + "...";
    }

    return name + "." + extension;
  };

  const addUniqueIdsToJsonData = (jsonData) => {
    Object.keys(jsonData?.paths || {})?.forEach((path, i) => {
      Object.keys(jsonData?.paths?.[path] || {})?.forEach((method, j) => {
        jsonData.paths[path][method].id = `${i}-${j}`;
      });
    });
    return jsonData;
  };

  const processSwaggerFile = (encodedFile, isYAMLFile, callback) => {
    const contentType = isYAMLFile ? "application/yaml" : "application/json";
    const blob = b64toBlob(encodedFile, contentType);
    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event?.target?.result;
      let jsonData;

      if (isYAMLFile) {
        jsonData = yaml.load(text);
      } else {
        jsonData = JSON.parse(text);
      }

      jsonData = addUniqueIdsToJsonData(jsonData);
      callback(jsonData);
    };

    reader.readAsText(blob);
  };

  return {
    parseSwaggerData,
    converStrToArr,
    converArrToStr,
    truncateFileName,
    groupByYear,
    convertToSwaggerFormat,
    extractAndTruncateFilename,
    processSwaggerFile,
  };
};

export default AiAgentFunction;
