import { Box, TextField, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { TeamInformation } from "../components/teamInformation";
import { TeamMembers } from "../components/teamMembers";
import { ShowMoreButton } from "../components/showMoreButton";
import CompaniesController from "services/controllers/companiesController";
import { WorkingHours } from "../../userManagement/addNewUser/tabs/workingHours";

export const TeamsDetails = ({
  teamInformation,
  handleTeamInformationChange,
  members,
  handleSelectMember,
  selectedMembers,
  teamNameError,
  setMembers,
  showMoreMembersObj = {},
  isProductsDisabled,
  products,
  setWorkingHours,
  workingHours,
  tabsErrors,
  setTabsErrors,
  autoReplyMessages,
  setAutoReplyMessages,
  isMaxCharactersError,
}) => {
  const intl = useIntl();
  const {
    isSettled,
    setIsSettled,
    setShowedData: setShowedMembers,
    showedData: showedMembers,
    showMore,
    setShowMore,
    isLoading,
    setIsLoading,
    onShowLess,
    nextPage,
    hasPagination,
  } = showMoreMembersObj;

  const onShowMore = () => {
    if (isSettled) {
      setShowedMembers(members?.data);
      setShowMore(false);
      return;
    }
    setIsLoading(true);
    CompaniesController.getTeamMembers(nextPage).then((res) => {
      if (!res?.errorMsg) {
        const newMembers = res?.data?.users;
        const newMembersData = [...members?.data, ...newMembers?.data];
        const nextPage = newMembers?.pagination?.next;
        setMembers({
          data: newMembersData,
          pagination: newMembers?.pagination,
        });
        setShowedMembers(newMembersData);
        if (!nextPage) {
          setIsSettled(true);
          setShowMore(false);
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <Box className="add-team-section add-team-details">
      <Typography variant="h6" className="add-team-title">
        {CheckValueLocale("add_team_details", "", {}, intl)}
      </Typography>
      <TeamInformation
        isProductsDisabled={isProductsDisabled}
        teamNameError={teamNameError}
        options={products}
        setTeamName={(teamName) => {
          handleTeamInformationChange({ teamName });
        }}
        teamName={teamInformation?.teamName}
        selectedProduct={teamInformation?.selectedProduct}
        setSelectedProduct={(selectedProduct) => {
          handleTeamInformationChange({ selectedProduct });
        }}
      />
      <Box className="team-members-section-block">
        <Box className="tabs-section-container tabs-divider">
          <Box className="user-tabs-container">
            <Box className="user-tabs-content create-team-working-hours-row-container">
              <Box className="create-team-working-hours-container">
                <WorkingHours
                  tabsData={workingHours}
                  setTabsData={setWorkingHours}
                  setTabsErrors={setTabsErrors}
                  tabsErrors={tabsErrors}
                  workingHoursTimezoneText={
                    <Typography
                      onClick={() => window.open("/settings", "_blank")}
                      className="create-team-working-hours-time-zone-title"
                    >
                      {CheckValueLocale(
                        "team_working_hours_company_timezone",
                        "",
                        {},
                        intl,
                      )}
                      <Typography
                        className="create-team-working-hours-time-zone-title view-settings"
                        component="span"
                      >
                        {CheckValueLocale("view_settings", "", {}, intl)}
                      </Typography>
                    </Typography>
                  }
                  title={
                    <Typography className="create-team-working-hours-extra-title">
                      {CheckValueLocale("working_hours_days", "", {}, intl)}
                    </Typography>
                  }
                  subtitle="set_team_working_days_hours"
                  type="team"
                />
              </Box>
              <Box className="team-auto-complete-column-container">
                <Typography className="create-team-auto-reply-text-area-label">
                  {CheckValueLocale(
                    "create_team_auto_reply_label",
                    "",
                    {},
                    intl,
                  )}
                </Typography>
                <TextField
                  fullWidth
                  className="create-team-auto-reply-text-area"
                  multiline
                  rows={4.7}
                  value={autoReplyMessages}
                  onChange={(e) => setAutoReplyMessages(e.target.value)}
                  placeholder={CheckValueLocale(
                    "auto_reply_placeholder",
                    "",
                    {},
                    intl,
                  )}
                  error={isMaxCharactersError}
                  helperText={
                    isMaxCharactersError
                      ? CheckValueLocale("auto_reply_error", "", {}, intl)
                      : ""
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <TeamMembers
          members={showedMembers}
          selectedMembers={selectedMembers}
          handleSelectMember={handleSelectMember}
          overallCount={members?.pagination?.count}
        />
        <ShowMoreButton
          show={hasPagination}
          loading={isLoading}
          hasMore={showMore}
          onShowLess={onShowLess}
          onShowMore={onShowMore}
        />
      </Box>
    </Box>
  );
};
