import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatMessages: {},
};

const aiAgentChatSlice = createSlice({
  name: "aiAgentChat",
  initialState,
  reducers: {
    addChatMessage: (state, action) => {
      const { text, isSent, agentId, versionId } = action.payload;

      const accessKey = String(versionId ? `${agentId}_${versionId}` : agentId);
      if (state.chatMessages[accessKey]) {
        state.chatMessages[accessKey].push({ text, isSent });
      } else {
        state.chatMessages[accessKey] = [{ text, isSent }];
      }
    },
    resetChatMessages: (state) => {
      state.chatMessages = {};
    },
  },
});

export const { addChatMessage, resetChatMessages } = aiAgentChatSlice.actions;

export default aiAgentChatSlice.reducer;
