import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { getSocialUrlImg } from "./dataSourceImage";

const WidgetHeaderMonitorsCard = (props) => {
  const { isControlWidget, customDashboardData } = props;
  const intl = useIntl();

  const [monitorData, setMonitorData] = useState({});

  // Check if the widget is customized or not (True, False)
  const isCustomized = !!customDashboardData?.widget?.customized_widget;

  // Check if customized widget and list the monitors name and datasources customized with
  const customizedMonitors = customDashboardData?.widget?.customized_widget
    ?.attributes?.monitors
    ? customDashboardData?.widget?.customized_widget?.attributes?.monitors
    : [];

  useEffect(() => {
    if (customDashboardData?.widget) {
      if (customDashboardData?.dashboardInfo?.product === "cxm") {
        setMonitorData({
          name: customDashboardData?.widget?.account_identifier,
          type: customDashboardData?.widget?.data_category,
          data_source_name: customDashboardData?.widget?.data_source,
          is_dm: customDashboardData?.widget?.is_dm,
        });
      } else {
        setMonitorData({
          name: customDashboardData?.widget?.monitor_name,
          type: customDashboardData?.widget?.monitor_type?.name,
          data_source_name: customDashboardData?.widget?.data_source,
          is_dm: customDashboardData?.widget?.is_dm,
        });
      }
    }
  }, [customDashboardData]);

  return (
    <Box id="custom-dashboard-widget-header-monitors">
      {!isCustomized ? (
        <Box
          className={`monitor-details ${isControlWidget ? "sidebar-widget" : ""}`}
        >
          <Typography variant="p" className="monitor-name">
            {monitorData?.name ? monitorData?.name : ""}
          </Typography>
          <Box className="monitor-type-wrapper">
            <Typography variant="p" className="monitor-type-text">
              {monitorData?.type
                ? CheckValueLocale(monitorData?.type, "", {}, intl)
                : ""}
            </Typography>
            {monitorData?.data_source_name ? (
              <Box
                component={"img"}
                src={getSocialUrlImg(
                  monitorData?.is_dm
                    ? `${monitorData?.data_source_name}_PRIVATE`
                    : monitorData?.data_source_name,
                )}
                alt="dataSource-monitor-icon"
              />
            ) : null}
          </Box>
        </Box>
      ) : (
        <Box
          className={`customized-monitors-wrapper ${isControlWidget ? "sidebar-widget" : ""}`}
        >
          {[monitorData, ...customizedMonitors]?.map((monitor) => {
            return (
              <Box component={"span"} className="customized-monitor-details">
                <Typography variant="p" className="customized-monitor-name">
                  {monitor?.name ? monitor?.name : ""}
                </Typography>
                <Typography className="social-icon-box">
                  {monitor?.data_source_name ? (
                    <Box
                      component={"img"}
                      src={getSocialUrlImg(
                        monitor?.is_dm
                          ? `${monitor?.data_source_name}_PRIVATE`
                          : monitor?.data_source_name,
                      )}
                      alt="dataSource-monitor-icon"
                    />
                  ) : null}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default WidgetHeaderMonitorsCard;
