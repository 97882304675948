import { useState, useEffect } from "react";
import VolumeOverTimeChart from "./widgets/oldVolumeOverTimeChart";
import moment from "moment";
import DaysSlider from "./widgets/daysSlider";
// import ReactTooltip from "react-tooltip";
import { Select, MenuItem, Box } from "@mui/material";
import "./charts-style.scss";
import { CheckValueLocale } from "utils/helpers";

const VolumeOverTime = (props) => {
  const [stats, setStats] = useState({
    cumulative_over_time: props?.cumulative_over_time,
    engagers_over_time: props?.engagers_over_time,
    interaction_over_time: props?.interaction_over_time,
    noncumulative_over_time: props?.noncumulative_over_time,
    timeGroups: {
      month: "Group_by_Month",
      week: "Group_by_Week",
      day: "Group_by_Day",
      hour: "Group_by_Hour",
    },
    activeTimeGroup: "day",
    chartTabs: {
      noncumulative: "noncumulative_over_time",
      cumulative: "cumulative_over_time",
      interaction: "interaction_over_time",
      engagers: "engagers_over_time",
    },
    activeChartTab: "noncumulative",

    showDays: false,

    chartData: {},
    xAxisData: [],
    activeDay: moment(props?.analysis_created_at)?.format("YYYY-MM-DD"),
  });
  const [days, setDays] = useState([]);

  // useEffect(() => {
  //   formatChartData();
  // }, [stats.activeTimeGroup]);

  const changeTimeGroup = (type) => {
    setStats(
      {
        ...stats,
        activeTimeGroup: type,
      },
      () => {
        formatChartData();
      },
    );
  };

  const changeChartData = (type) => {
    setStats(
      {
        ...stats,
        activeChartTab: type,
      },
      () => {
        formatChartData();
      },
    );
  };

  const formatAxisData = () => {
    let { tweet_datetime, analysis_created_at } = props || {};

    const endDate = moment(analysis_created_at)?.format("YYYY-MM-DD");
    tweet_datetime = moment(tweet_datetime)?.format("YYYY-MM-DD");

    let startDate = "";
    let datetimeArr = [];
    switch (stats?.activeTimeGroup) {
      case "month":
        startDate = tweet_datetime;

        while (moment(startDate)?.isSameOrBefore(endDate)) {
          datetimeArr?.push(moment(startDate)?.format("YYYY-M"));
          startDate = moment(startDate)?.add(30, "days")?.format("YYYY-MM-DD");
        }
        break;
      case "week":
        startDate = tweet_datetime;

        while (moment(startDate)?.isSameOrBefore(endDate)) {
          let beginningOfWeek =
            moment(startDate)?.year() +
            "-" +
            moment(startDate)
              ?.week(startDate)
              ?.startOf("week")
              ?.format("MM-DD");
          let endOfWeek =
            moment(startDate)?.year() +
            "-" +
            moment(startDate)
              ?.week(startDate)
              ?.startOf("week")
              ?.add(6, "days")
              ?.format("MM-DD");
          let dateKey = beginningOfWeek + "~" + endOfWeek;

          datetimeArr?.push(dateKey);
          startDate = moment(beginningOfWeek)
            ?.add(7, "days")
            ?.format("YYYY-MM-DD");
        }

        break;
      case "hour":
        datetimeArr = [
          "12 AM",
          "1 AM",
          "2 AM",
          "3 AM",
          "4 AM",
          "5 AM",
          "6 AM",
          "7 AM",
          "8 AM",
          "9 AM",
          "10 AM",
          "11 AM",
          "12 PM",
          "1 PM",
          "2 PM",
          "3 PM",
          "4 PM",
          "5 PM",
          "6 PM",
          "7 PM",
          "8 PM",
          "9 PM",
          "10 PM",
          "11 PM",
        ];
        break;
      default:
        startDate = tweet_datetime;

        while (moment(startDate)?.isSameOrBefore(endDate)) {
          datetimeArr?.push(moment(startDate)?.format("YYYY-MM-DD"));
          startDate = moment(startDate)?.add(1, "days")?.format("YYYY-MM-DD");
        }
        break;
    }
    return datetimeArr;
  };

  const formatChartData = () => {
    const activeChartData = stats?.chartTabs?.[activeChartTab];
    const volumeData = stats?.[activeChartData];
    const { tweets_day_over_time, tweets_hour_over_time } = volumeData || {};
    let chartData = [];
    setStats({
      ...stats,
      showDays: false,
    });
    switch (stats?.activeTimeGroup) {
      case "hour":
        chartData = getSummaryData(tweets_hour_over_time, "hourly");
        setStats({ ...stats, showDays: true });

        if (days?.length === 0) {
          handleNavigateDays(stats?.activeDay, "prev");
        }
        break;
      case "day":
        chartData = getSummaryData(tweets_day_over_time, "daily");
        break;
      case "week":
        chartData = getSummaryData(tweets_day_over_time, "weekly");
        break;
      case "month":
        chartData = getSummaryData(tweets_day_over_time, "monthly");
        break;
      default:
        chartData = getSummaryData(tweets_day_over_time, "daily");
        break;
    }

    let xAxisData = formatAxisData();
    if (stats?.activeTimeGroup == "hour") {
      setStats({
        ...stats,
        chartData: chartData,
        xAxisData: xAxisData,
        showDays: true,
      });
    } else {
      setStats({
        ...stats,
        chartData: chartData,
        xAxisData: xAxisData,
        showDays: false,
      });
    }
  };
  const getSummaryData = (data, duration) => {
    let data2 = data;
    const { activeDay } = stats;
    let groups = data2?.reduce(function (acc, data2) {
      let date_id = data2?.id;
      let dateKey = moment(date_id)?.format("YYYY-MM-DD");

      const beginningOfWeek = moment(date_id)
        ?.week(date_id)
        ?.startOf("week")
        ?.format("MM-DD");
      const endOfWeek = moment(date_id)
        ?.week(date_id)
        ?.startOf("week")
        ?.add(6, "days")
        ?.format("MM-DD");

      if (duration === "weekly") {
        dateKey =
          moment(date_id)?.year() +
          "-" +
          beginningOfWeek +
          "~" +
          moment(date_id)?.year() +
          "-" +
          endOfWeek;
      } else if (duration === "monthly") {
        dateKey =
          moment(date_id)?.year() + "-" + (moment(date_id)?.month() + 1);
      } else if (duration === "hourly") {
        if (activeDay === moment(date_id)?.format("YYYY-MM-DD")) {
          dateKey = moment(date_id)?.format("h A");
        } else {
          dateKey = false;
        }
      }

      if (dateKey) {
        acc[dateKey] =
          (acc?.[dateKey] ? acc?.[dateKey] : 0) + data2?.stats_count;
      }

      return acc;
    }, {});

    return groups;
  };

  const handleSelectDay = (day) => {
    setStats(
      {
        ...stats,
        activeDay: day,
      },
      () => {
        formatChartData();
      },
    );
  };

  const handleNavigateDays = (date, direction) => {
    //return days empty data
    let startDate = "";
    let endDate = "";

    if (direction === "next") {
      startDate = moment(date)?.format("YYYY-MM-DD");
      endDate = moment(date)?.add(10, "days")?.format("YYYY-MM-DD");
    } else {
      startDate = moment(date)?.subtract(10, "days")?.format("YYYY-MM-DD");
      endDate = moment(date)?.format("YYYY-MM-DD");
    }

    let daysList = [];

    while (moment(startDate)?.isBefore(endDate)) {
      daysList.push(moment(startDate)?.format("YYYY-MM-DD"));
      startDate = moment(startDate)?.add(1, "days")?.format("YYYY-MM-DD");
    }

    daysList?.push(endDate);
    setDays(daysList);
  };

  useEffect(() => {
    formatChartData();
  }, [stats?.activeTimeGroup]);
  useEffect(() => {
    formatChartData();
  }, [stats?.activeChartTab]);

  useEffect(() => {
    formatChartData();
  }, [stats?.activeDay]);

  const { intl } = props || {};

  const { analysis_created_at, tweet_datetime } = props || {};

  const {
    chartData,
    xAxisData,
    activeTimeGroup,
    activeChartTab,
    chartTabs,
    showDays,
    activeDay,
  } = stats;
  const activeChartData = chartTabs?.[activeChartTab];
  const volumeData = stats?.[activeChartData];
  return (
    <div className="panel-card no-pad mb-40">
      {/* <ReactTooltip multiline={true} /> */}
      <div className="panel-card-heading">
        <p className="panel-card-title">
          <span>{CheckValueLocale("volume_overtime", "", {}, intl)}</span>
          <i
            className="fas fa-question informativeIcon"
            data-tip={CheckValueLocale("help_volume_over_time", "", {}, intl)}
            aria-hidden="true"
          ></i>
        </p>
      </div>
      <div className="panel-card-body">
        <div className="flex-row">
          <div className="chart-data">
            <div className="chart-nav-list">
              <Box component="div" className="groupby_wrapper">
                <Box component="span" className="groupby_title">
                  {CheckValueLocale("group_by", "", {}, intl)}:
                </Box>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeTimeGroup}
                  onChange={(e) => changeTimeGroup(e?.target?.value)}
                  className="select"
                >
                  <MenuItem value="day" id="single-tweets-day">
                    {" "}
                    {CheckValueLocale("day", "", {}, intl)}
                  </MenuItem>
                  <MenuItem value="week" id="single-tweets-week">
                    {CheckValueLocale("week", "", {}, intl)}
                  </MenuItem>
                  <MenuItem value="month" id="single-tweets-month">
                    {CheckValueLocale("month", "", {}, intl)}
                  </MenuItem>
                  <MenuItem value="hour" id="single-tweets-group">
                    {CheckValueLocale("hour_group", "", {}, intl)}
                  </MenuItem>
                </Select>
              </Box>
            </div>

            <VolumeOverTimeChart
              chartData={chartData}
              xAxisData={xAxisData}
              activeTimeGroup={activeTimeGroup}
              volumeData={volumeData}
              // setRepliesFilterData={this.setRepliesFilterData}
              activeDay={activeDay}
            />
            {showDays ? (
              <DaysSlider
                days={days}
                activeDay={activeDay}
                selectDay={handleSelectDay}
                navigateDays={handleNavigateDays}
                analysis_created_at={analysis_created_at}
                tweet_datetime={tweet_datetime}
              />
            ) : null}
          </div>
          <div className="chart-tabs">
            {Object.keys(stats?.chartTabs || {}).map((key, i) => {
              return (
                <a
                  key={i}
                  onClick={() => changeChartData(key)}
                  className={
                    stats?.activeChartTab === key
                      ? "chart-tab active"
                      : "chart-tab"
                  }
                >
                  {CheckValueLocale(stats?.chartTabs?.[key], "", {}, intl)}
                  <i
                    className="fas fa-question informativeIcon"
                    data-place={localStorage?.lang === "ar" ? "right" : "left"}
                    data-tip={CheckValueLocale(
                      "help_" + stats?.chartTabs?.[key],
                      "",
                      {},
                      intl,
                    )}
                    aria-hidden="true"
                  ></i>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default VolumeOverTime;
