import { Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import AiAgentsFields from "../aiAgentsFields";
import SurveyRequest from "./surveyRequest";
import SurveyField from "./surveyField";

const EndConversation = ({
  formik,
  aiAgentshandleChange,
  customHandleChange,
  isFormDisabled,
}) => {
  const intl = useIntl();

  var activeProducts = JSON.parse(window?.localStorage?.activeProducts || null);
  let surveyActive = activeProducts?.find(
    (product) => product?.name == "SURVEY",
  );

  const endTheConversationAfterOptions = [
    {
      name: CheckValueLocale("end_after_mins", "", { mins: 5 }, intl),
      id: "after_5_minutes",
    },
    {
      name: CheckValueLocale("end_after_mins", "", { mins: 10 }, intl),
      id: "after_10_minutes",
    },
    {
      name: CheckValueLocale("end_after_mins", "", { mins: 20 }, intl),
      id: "after_20_minutes",
    },
    {
      name: CheckValueLocale("end_after_mins", "", { mins: 30 }, intl),
      id: "after_30_minutes",
    },
    {
      name: CheckValueLocale("end_after_mins", "", { mins: 40 }, intl),
      id: "after_40_minutes",
    },
    {
      name: CheckValueLocale("end_after_mins", "", { mins: 50 }, intl),
      id: "after_50_minutes",
    },
    {
      name: CheckValueLocale("end_after_mins", "", { mins: 60 }, intl),
      id: "after_60_minutes",
    },
  ];

  return (
    <Box className="ai-agents-form-box-wrapper">
      <Box className="ai-agents-form-box-title" component="h4">
        {CheckValueLocale("end_the_conversation", "", {}, intl)}
      </Box>
      <Box className="ai-agents-form-box">
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale(
                "end_ai_agent_conversation_description",
                "",
                {},
                intl,
              )}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box className="ai-agent-end-conversation-spacer">
              <AiAgentsFields
                disabled={isFormDisabled}
                type="select"
                id="ai-agent-end-conversation-select"
                selectOptions={endTheConversationAfterOptions}
                label={CheckValueLocale(
                  "end_the_conversation_after",
                  "",
                  {},
                  intl,
                )}
                name="conversation_end_time"
                value={formik?.values?.conversation_end_time}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.conversation_end_time}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.conversation_end_time,
                  "",
                  {},
                  intl,
                )}
                required
              />
            </Box>
            <Box className="ai-agent-end-conversation-spacer">
              {surveyActive ? (
                <SurveyField
                  disabled={isFormDisabled}
                  formik={formik}
                  surveyActive={surveyActive}
                  customHandleChange={customHandleChange}
                />
              ) : (
                <SurveyRequest />
              )}
            </Box>
            <Box className="ai-agent-end-conversation-spacer">
              <AiAgentsFields
                disabled={isFormDisabled}
                type="textarea"
                id="end-conversation-auto-reply"
                label={CheckValueLocale(
                  "ai_agent_end_conversation_auto_reply_label",
                  "",
                  {},
                  intl,
                )}
                labelHint={CheckValueLocale("option", "", {}, intl)}
                placeholder={CheckValueLocale("write_here", "", {}, intl)}
                name="auto_reply"
                value={formik?.values?.auto_reply}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.auto_reply}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.auto_reply,
                  "",
                  {},
                  intl,
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EndConversation;
