import {
  changeFromObjectToArrayOfObject,
  handleGenderAgeDistResponse,
  handleSentimentResponse,
} from "utils/helpers";
import {
  handleAverageEngagementStatsData,
  handleCustomerCareStatsData,
  handleFollowersGrowthStatsData,
} from "../statsDataHandling";

export const instagramRespHandling = (
  response,
  handleWidgetResponse,
  dataSource,
) => {
  const eventNameObj = {
    Instagram__EngagementsPage__associated_topics: {
      value: (response) => ({
        pie_data: response?.associated_topics,
      }),
    },
    Instagram__EngagementsPage__volume_overtime: {
      value: (response) => ({
        data: response?.posts_over_time,
        total_count: response?.total_posts_count,
      }),
    },
    Instagram__EngagementsPage__sentiment_analysis: {
      value: (response) => ({
        data: handleSentimentResponse(response?.sentiment_overtime),
        pie_data: response?.sentiment_piechart,
      }),
    },
    Instagram__EngagementsPage__posts_interactions: {
      value: (response) => ({
        data: response?.posts_interactions?.posts_interactions_over_time,
        pie_data: response?.posts_interactions?.posts_interactions_legends,
        average_interactions:
          response?.posts_interactions?.average_interactions,
        max_interaction_date:
          response?.posts_interactions?.max_interaction_date,
        max_interactions: response?.posts_interactions?.max_interactions,
        min_interaction_date:
          response?.posts_interactions?.min_interaction_date,
        min_interactions: response?.posts_interactions?.min_interactions,
        total_interactions: response?.posts_interactions?.total_interactions,
      }),
    },
    Instagram__EngagementsPage__top_videos: {
      value: (response) => ({
        media_data: response?.top_videos,
      }),
    },
    Instagram__EngagementsPage__top_images: {
      value: (response) => ({
        media_data: response?.top_images,
      }),
    },
    Instagram__EngagementsPage__dialects_and_sub_dialects: {
      value: (response) => ({
        pie_data: response?.dialects,
        pie_sub_data: response?.sub_dialects,
      }),
    },
    Instagram__EngagementsPage__content_type: {
      value: (response) => ({
        data: response?.posts_content_type,
        pie_data: response?.posts_content_types_legends,
      }),
    },
    Instagram__EngagementsPage__top_hashtags: {
      value: (response) => ({
        pie_data: response?.top_hashtags,
      }),
    },
    Instagram__EngagementsPage__top_keywords: {
      value: (response) => ({
        pie_data: response?.top_keywords,
      }),
    },
    Instagram__AccountPage__posts_interactions: {
      value: (response) => ({
        data: response?.posts_interactions?.posts_interactions_over_time,
        pie_data: response?.posts_interactions?.posts_interactions_legends,
        average_interactions:
          response?.posts_interactions?.average_interactions,
        max_interaction_date:
          response?.posts_interactions?.max_interaction_date,
        max_interactions: response?.posts_interactions?.max_interactions,
        min_interaction_date:
          response?.posts_interactions?.min_interaction_date,
        min_interactions: response?.posts_interactions?.min_interactions,
        total_interactions: response?.posts_interactions?.total_interactions,
      }),
    },
    Instagram__AccountPage__volume_overtime: {
      value: (response) => ({
        data: response?.posts_over_time,
        total_count: response?.total_posts_count,
      }),
    },
    Instagram__AccountPage__followers_growth: {
      value: (response) => ({
        data: response?.followers_change_over_time,
        total_followers: response?.total_followers?.[0],
        avg_chage_of_day: response?.avg_chage_of_day,
        max_change_of_followers: response?.max_change_of_followers,
        stats_data: handleFollowersGrowthStatsData(response),
      }),
    },
    Instagram__AccountPage__reach_funnel: {
      value: (response) => ({
        data: response?.reach_funnel_overtime,
        pie_data: response?.reach_funnel_legends,
      }),
    },
    Instagram__AccountPage__page_contact_details_interactions: {
      value: (response) => ({
        data: response?.page_contact_details_interactions_overtime,
        pie_data: response?.pie_chart_page_contact_details_interactions,
      }),
    },
    Instagram__AccountPage__average_engagement_per_post: {
      value: (response) => ({
        data: response?.posts_interactions?.posts_engagements_over_time,
        average_interactions:
          response?.posts_interactions?.average_interactions,
        max_engagement_date: response?.posts_interactions?.max_engagement_date,
        max_engagements: response?.posts_interactions?.max_engagements,
        min_engagement_date: response?.posts_interactions?.min_engagement_date,
        min_engagements: response?.posts_interactions?.min_engagements,
        stats_data: handleAverageEngagementStatsData(
          response?.posts_interactions,
        ),
      }),
    },
    Instagram__AccountPage__account_author_activity: {
      value: (response) => ({
        activity_data: response?.account_author_activity,
        min_value: response?.min_value,
        max_value: response?.max_value,
      }),
    },
    Instagram__AccountPage__top_videos: {
      value: (response) => ({
        media_data: response?.top_videos,
      }),
    },
    Instagram__AccountPage__top_images: {
      value: (response) => ({
        media_data: response?.top_images,
      }),
    },
    Instagram__AccountPage__content_type: {
      value: (response) => ({
        data: response?.posts_content_type,
        pie_data: response?.posts_content_types_legends,
      }),
    },
    Instagram__EngagementsPage__gender_distribution: {
      value: (response) => ({
        data: handleGenderAgeDistResponse(response?.gender_distribution),
        pie_data: response?.gender_piechart,
      }),
    },
    Instagram__EngagementsPage__comments_interactions: {
      value: (response) => ({
        data: response?.comments_interactions,
        pie_data: response?.comments_interactions_legends,
        total_interactions: response?.total_interactions,
        average_interactions: response?.average_interactions,
      }),
    },
    Instagram__EngagementsPage__top_engagers: {
      value: (response) => ({
        pie_data: response?.top_engagers,
      }),
    },
    Instagram__EngagementsPage__top_cities: {
      value: (response) => ({
        pie_data: response?.top_cities,
      }),
    },
    Instagram__EngagementsPage__top_countries: {
      value: (response) => ({
        pie_data: response?.top_countries,
      }),
    },
    Instagram__CustomerCarePage__customer_care_performance: {
      value: (response) => ({
        data: changeFromObjectToArrayOfObject(response?.response_time_segment),
        total_number_of_questions: response?.total_number_of_questions,
        avg_response_time_in_seconds: response?.avg_response_time_in_seconds,
        number_of_answered_questions: response?.number_of_answered_questions,
        stats_data: handleCustomerCareStatsData(response),
      }),
    },
    Instagram__AccountPage__top_hashtags: {
      value: (response) => ({
        pie_data: response?.top_hashtags,
      }),
    },
    Instagram__AccountPage__top_keywords: {
      value: (response) => ({
        pie_data: response?.top_keywords,
      }),
    },
  };

  const eventNameKey = eventNameObj?.[response?.eventName];

  if (eventNameKey) {
    handleWidgetResponse(
      response?.metric_id,
      response?.monitor_id,
      dataSource,
      eventNameKey?.value(response?.eventData),
      response?.customized_metric_id,
    );
  }
};
