import React from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";

const WidgetControlHeader = (props) => {
  const { handleChangeContolSidebar } = props;

  const intl = useIntl();

  return (
    <Box className="widget-control-sidebar-header">
      <Typography variant="h6">
        {CheckValueLocale("manage_widget", "", {}, intl)}
      </Typography>
      <LucButton
        id="widget-control-sidebar-close-btn"
        type="secondary"
        variant="flat"
        minWidth={42}
        onClick={handleChangeContolSidebar}
      >
        <FontAwesomeIcon
          className="widget-control-sidebar-close-btn-icon"
          icon={faClose}
          fixedWidth
        />
      </LucButton>
    </Box>
  );
};

export default WidgetControlHeader;
