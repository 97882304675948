import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import CustomDashboardController from "services/controllers/customDashboardController";
import InteractionsController from "services/controllers/interactionsController";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import { isEmptyObj } from "utils/helpers";
import { facebookRespHandling } from "./responseHandling/socialListening/facebookRespHandling";
import { instagramRespHandling } from "./responseHandling/socialListening/instagramRespHandling";
import { twitterRespHandling } from "./responseHandling/socialListening/twitterRespHandling";
import { newsBlogsRespHandling } from "./responseHandling/socialListening/newsBlogsRespHandling";
import { TwitterOmniRespHandling } from "./responseHandling/omnichannel/twitterOmniRespHandling";
import { FacebookOmniRespHandling } from "./responseHandling/omnichannel/facebookOmniRespHandling";
import { TiktokOmniRespHandling } from "./responseHandling/omnichannel/tiktokOmniRespHandling";
import { InstagramOmniRespHandling } from "./responseHandling/omnichannel/instagramOmniRespHandling";
import { LinkedinOmniRespHandling } from "./responseHandling/omnichannel/linkedinOmniRespHandling";

const CustomDashboardFunctions = (params) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notFoundDashboard, setnotFoundDashboard] = useState(false);
  const [dashboardInfo, setDashboardInfo] = useState({
    id: params?.dashboardId,
  });

  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());

  const [response, setResponse] = useState([]);
  const [dashboardWidgets, setDashboardWidgets] = useState([]);
  const [widgetsMqttResponses, setWidgetsMqttResponses] = useState({});
  const [customizedWidgets, setCustomizedWidgets] = useState({});
  const [dataSources, setDataSources] = useState([]);
  const [isLiveData, setIsLiveData] = useState(false);
  const [disabledLive, setDisabledLive] = useState(false);
  const [snackBarData, setSnackBarData] = useState({
    open: false,
    severity: "",
    title: "",
    message: "",
  });

  // RabbitMQ configuration
  const clientMQTT = useRef();
  const mqttConnectionClosed = useRef(false);

  // Get datasources ids to check datasource id in case of omnichannel
  const getDataSources = () => {
    InteractionsController.getDatasources().then((data) => {
      setDataSources(data?.data?.data);
    });
  };

  const getCustomDashboardView = (dashboardId, start_date, end_date) => {
    let queryData = {
      start_date: start_date ? start_date : startDate,
      end_date: end_date ? end_date : endDate,
    };

    if (!isLiveData) setIsLoading(true);
    setResponse([]);
    setWidgetsMqttResponses({});
    setCustomizedWidgets({});
    CustomDashboardController?.getCustomDashboardView(
      dashboardId,
      queryData,
    )?.then((data) => {
      if (data?.status == 404 || data?.errorMsg === "RECORD_NOT_FOUND") {
        setnotFoundDashboard(true);
      }
      if (!isEmptyObj(data?.data)) {
        // Call Data Sources API to get DataSources IDs in Case of Omnichannel Dashboard only
        if (data?.data?.product_id == localStorage?.getItem("cxm_id"))
          getDataSources();

        setDashboardWidgets(data?.data?.metrics); // All Widgets from BE and its order

        // Get some info like (dashboard name, product, product id, dashboard owner [true, false])
        setDashboardInfo({
          ...dashboardInfo,
          name: data?.data?.name,
          dashboard_owner: data?.data?.dashboard_owner,
          product_id: +data?.data?.product_id,
          product:
            data?.data?.product_id == localStorage?.getItem("sm_id")
              ? "sm"
              : "cxm",
        });

        // To start connection in case of has widgets
        if (data?.data?.metrics?.length)
          mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else {
        mqttConnectionClosed.current = true;
      }
      setIsLoading(false);
    });
  };

  const handleChangeLiveData = async (e) => {
    setDisabledLive(true);
    const checked = e?.target?.checked;
    const queryData = {
      id: +dashboardInfo?.id,
      is_enabled: checked,
    };
    const response =
      await CustomDashboardController?.updateLiveCustomDashboard(queryData);
    if (response?.status == 200) setIsLiveData(checked);
    setDisabledLive(false);
  };

  // *** This useEffect related to Live Data Button only *** //
  useEffect(() => {
    let intervalId;
    // Start interval when isLiveData == True to reload every (ONE MINUTE == 60000 milliseconds)
    if (isLiveData) {
      intervalId = setInterval(() => {
        getCustomDashboardView(dashboardInfo?.id, startDate, endDate);
      }, 60000);
    }

    // Clear interval when isLiveData changes or set as False
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isLiveData, dashboardInfo?.id, startDate, endDate]);

  const applyFilter = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    getCustomDashboardView(dashboardInfo?.id, startDate, endDate);
  };

  // Function to set widget rabbitMQ response in main state
  const handleWidgetResponse = (
    widgetKey,
    monitorId,
    dataSource,
    eventData,
    customizedId,
  ) => {
    //this block run if response have customizedId.
    if (customizedId) {
      setCustomizedWidgets((prevCustomizedWidgets) => {
        //to handle new response with this format to handle comparison
        let newEventDataList = [
            {
              ...eventData,
              monitorId: monitorId?.[0],
              dataSource: dataSource,
            },
          ],
          newMonitorsIdList = [...monitorId]; //to add new monitor id from response
        //to handel old response with new.
        if (prevCustomizedWidgets?.[customizedId]?.eventDataList?.length) {
          newEventDataList?.push(
            ...prevCustomizedWidgets?.[customizedId]?.eventDataList,
          );
          newMonitorsIdList?.push(
            ...prevCustomizedWidgets?.[customizedId]?.preLoaderMonitorsIdList,
          );
        }
        return {
          ...prevCustomizedWidgets,
          [customizedId]: {
            eventDataList: newEventDataList,
            preLoaderMonitorsIdList: newMonitorsIdList,
          },
        };
      });
    } else {
      setWidgetsMqttResponses({
        ...widgetsMqttResponses,
        [widgetKey]: {
          ...widgetsMqttResponses?.[widgetKey],
          [monitorId]: {
            ...widgetsMqttResponses?.[widgetKey]?.[monitorId],
            [dataSource]: eventData,
          },
        },
        [`${widgetKey}_pre_loader_data_sources`]: {
          ...widgetsMqttResponses?.[`${widgetKey}_pre_loader_data_sources`],
          [monitorId]: {
            ...widgetsMqttResponses?.[`${widgetKey}_pre_loader_data_sources`]?.[
              monitorId
            ],
            [dataSource]: monitorId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (dashboardInfo?.product === "sm") {
      if (response?.data_source === "FACEBOOK")
        facebookRespHandling(response, handleWidgetResponse, "FACEBOOK");
      else if (response?.data_source === "INSTAGRAM")
        instagramRespHandling(response, handleWidgetResponse, "INSTAGRAM");
      else if (response?.data_source === "TWITTER")
        twitterRespHandling(response, handleWidgetResponse, "TWITTER");
      else if (response?.data_source === "NEWSBLOGS")
        newsBlogsRespHandling(response, handleWidgetResponse, "TALKWALKER");
    } else {
      // To avoid returning the data source from Publisher as ID
      let dataSource = dataSources?.filter(
        (item) => item?.id == response?.data_source,
      )?.[0];

      if (dataSource?.source === "TWITTER")
        TwitterOmniRespHandling(response, handleWidgetResponse, "TWITTER");
      else if (dataSource?.source === "FACEBOOK")
        FacebookOmniRespHandling(response, handleWidgetResponse, "FACEBOOK");
      else if (
        dataSource?.source === "TIKTOK" ||
        response?.data_source === "tiktok_public"
      )
        TiktokOmniRespHandling(response, handleWidgetResponse, "TIKTOK");
      else if (
        dataSource?.source === "INSTAGRAM" ||
        response?.data_source === "instagram_private"
      )
        InstagramOmniRespHandling(response, handleWidgetResponse, "INSTAGRAM");
      else if (dataSource?.source === "LINKEDIN") {
        LinkedinOmniRespHandling(response, handleWidgetResponse, "LINKEDIN");
      }
    }
  }, [response]);

  return {
    isLoading,
    dashboardInfo,
    startDate,
    endDate,
    applyFilter,
    dashboardWidgets,
    widgetsMqttResponses,
    isLiveData,
    disabledLive,
    handleChangeLiveData,
    snackBarData,
    setSnackBarData,
    getCustomDashboardView,
    customizedWidgets,
    notFoundDashboard,
  };
};

export default CustomDashboardFunctions;
