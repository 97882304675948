import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { useState } from "react";
import DiscardChangesAiAgentModal from "./discardChangesAiAgentModal";

const AiAgentsFormFooter = ({
  formik,
  setAiAgentEditData,
  editAiAgentLoading,
}) => {
  const intl = useIntl();

  const [openPopupDiscardChanges, setOpenPopupDiscardChanges] = useState(false);

  return (
    <>
      <Box className="ai-agents-form-footer-container">
        <LucButton
          variant="outline"
          onClick={() => setOpenPopupDiscardChanges(true)}
          id="ai-agents-form-footer-cancel-btn"
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </LucButton>
        <LucButton
          onClick={() => formik?.handleSubmit()}
          loading={editAiAgentLoading}
          disabled={editAiAgentLoading}
          id="ai-agents-form-footer-cancel-btn"
        >
          {CheckValueLocale("save", "", {}, intl)}
        </LucButton>
      </Box>
      <DiscardChangesAiAgentModal
        formik={formik}
        openPopupDiscardChanges={openPopupDiscardChanges}
        setOpenPopupDiscardChanges={setOpenPopupDiscardChanges}
        setAiAgentEditData={setAiAgentEditData}
      />
    </>
  );
};

export default AiAgentsFormFooter;
