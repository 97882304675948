import { Box } from "@mui/material";
import "./slaDetailsSideBar.scss";
import SlaInfo from "./slaInfo";
import SlaAssigne from "./slaAssigne";
import SlaCounters from "./slaCounters";
import SlaLogs from "./slaLogs";

const SlaDetailsContant = ({
  selectedCard,
  headerData,
  slaLogs,
  aiAgentId,
}) => {
  return (
    <Box className="main-sla-container">
      <SlaInfo selectedCard={selectedCard} headerData={headerData} />
      {!aiAgentId ? (
        <SlaAssigne selectedCard={selectedCard} headerData={headerData} />
      ) : null}
      <SlaCounters
        selectedCard={selectedCard}
        slaTimes={headerData?.sla_info?.data?.attributes}
      />
      <SlaLogs slaLogs={slaLogs} />
    </Box>
  );
};
export default SlaDetailsContant;
