import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, SwipeableDrawer, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import WidgetControlFunctions from "./widgetControlFunctions";
import WidgetControlHeader from "./components/widgetControlHeader";
import WidgetControlFooter from "./components/widgetControlFooter";
import WidgetControlContent from "./components/widgetControlContent";
import "./style.scss";

const WidgetControl = (props) => {
  const intl = useIntl();

  const { customDashboardData } = props;

  const {
    controlSidebarOpened,
    sideBarDirection,
    handleChangeContolSidebar,
    viewTypes,
    viewType,
    handleViewTypeChange,
    monitorsLoading,
    availableMonitors,
    selectedMonitors,
    setSelectedMonitors,
    changesFlag,
    applyLoading,
    handleApplyCustomize,
  } = WidgetControlFunctions(props);

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        !controlSidebarOpened
          ? CheckValueLocale("manage_widget", "", {}, intl)
          : ""
      }
    >
      <Box>
        <LucButton
          id="widget-header-widget-control-btn"
          className="widget-control-btn"
          type="secondary"
          variant="flat"
          minWidth={42}
          onClick={handleChangeContolSidebar}
        >
          <FontAwesomeIcon className="control-widget-icon" icon={faSliders} />
        </LucButton>
        {controlSidebarOpened ? (
          <SwipeableDrawer
            anchor={sideBarDirection}
            open={controlSidebarOpened}
            onClose={handleChangeContolSidebar}
            onOpen={handleChangeContolSidebar}
            className="side-bar"
          >
            {controlSidebarOpened ? (
              <Box id="custom-dashboard-control-widget-sidebar">
                <WidgetControlHeader
                  handleChangeContolSidebar={handleChangeContolSidebar}
                />
                <WidgetControlContent
                  controlSidebarOpened={controlSidebarOpened}
                  customDashboardData={customDashboardData}
                  viewTypes={viewTypes}
                  viewType={viewType}
                  handleViewTypeChange={handleViewTypeChange}
                  monitorsLoading={monitorsLoading}
                  availableMonitors={availableMonitors}
                  selectedMonitors={selectedMonitors}
                  setSelectedMonitors={setSelectedMonitors}
                />
                <WidgetControlFooter
                  handleChangeContolSidebar={handleChangeContolSidebar}
                  handleApplyCustomize={handleApplyCustomize}
                  viewType={viewType}
                  selectedMonitors={selectedMonitors}
                  changesFlag={changesFlag}
                  applyLoading={applyLoading}
                />
              </Box>
            ) : null}
          </SwipeableDrawer>
        ) : null}
      </Box>
    </Tooltip>
  );
};

export default WidgetControl;
