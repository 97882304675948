import React, { useEffect, useState } from "react";
import PieChartWidget from "components/widgets/pieChartWidget";
import handleWidgetColors from "./handleWidgetColors";

const CustomDashboardPieChart = (props) => {
  const {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    widgetsMqttResponses,
    customizedWidgets,
    finalWidgetData,
    dashboardInfo,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    allMonitorsIdWidget,
    customizedType,
    showControl,
  } = props;

  const [colors, setColors] = useState(null);

  useEffect(() => {
    if (finalWidgetData?.pie_data) {
      const labelsColors = handleWidgetColors(finalWidgetData?.pie_data);
      if (labelsColors) setColors(labelsColors?.colors);
    }
  }, [finalWidgetData]);

  let isDialectsSubdialects = [
    "dialects_subdialects",
    "comments_dialects_sub_dialects",
    "dialects",
    "dialects_sub_dialects",
    "custom_omni_dialects_subdialects",
    "custom_omni_comments_dialects_sub_dialects",
    "custom_omni_dialects",
    "custom_omni_dialects_sub_dialects",
  ]?.includes(handledWidgetName);

  return (
    <PieChartWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={widgetTitleTooltip || ""}
      data={finalWidgetData?.pie_data || []}
      subData={finalWidgetData?.pie_sub_data || []}
      customStyle={
        isDialectsSubdialects ? "dialects-sub-dialects-auto-height chart" : ""
      }
      color={colors}
      areaStyle={true}
      toolTipLabel={"toolTipLabel"}
      showAI={!isControlWidget && customizedType !== "comparison"}
      showDownloadIcon={!isControlWidget}
      pieChartPreLoaderDataSources={finalWidgetData?.preLoaderMonitorsId || []}
      isDialectsSubDialects={isDialectsSubdialects ? true : undefined}
      preLoaderTrackerDataSources={allMonitorsIdWidget || []}
      isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
      isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
      // The needed data in widget component for case of Custom Dashboard only
      customDashboardData={{
        dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
        handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
        widget: widget, // the widget metric returned from backend in view API
        customizedType: customizedType, // null, aggregate, comparison
        widgetsMqttResponses, // the main state for RabbitMQ responses
        customizedWidgets, // cuztomized widget state for RabbitMQ responses
        getCustomDashboardView, // call back view widgets after any update in page
        setSnackBarData, // set snackbar related to main index view
        showControl, // show widget control icon
      }}
    />
  );
};

export default CustomDashboardPieChart;
