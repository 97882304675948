import { Box, Typography } from "@mui/material";
import ImproveAiResponses from "./component/improveAiResponses";
import AutoAssignment from "./component/autoAssignment/autoAssignment";
import BasicInformation from "./component/basicInformation/basicInformation";
import EndConversation from "./component/endConversation/endConversation";
import AddKnowledgeBase from "./component/addKnowledgeBase/addKnowledgeBase";
import AiAgentCircleInfo from "images/ai-agents/ai-agent-circle-info.svg";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import AgentIdentity from "./component/agentIdentity/agentIdentity";

const AiAgentsFormContent = ({
  formik,
  aiAgentshandleChange,
  customHandleChange,
  aiAgentDetails,
  isEditPublishedVersionShown,
}) => {
  const intl = useIntl();
  const isPublishing = aiAgentDetails?.status === "publishing";
  const isFormDisabled =
    isPublishing ||
    isEditPublishedVersionShown ||
    !handlelUserRoles("AI_AGENT", "EDIT_AI_AGENT");

  return (
    <Box className="ai-agents-form-content">
      {isPublishing ? (
        <Box className="ai-agent-form-disabled-container">
          <img src={AiAgentCircleInfo} alt="ai-agent-circle-info" />
          <Typography className="ai-agent-form-disabled-text">
            {CheckValueLocale("ai_agent_disabled_form_title", "", {}, intl)}
          </Typography>
        </Box>
      ) : null}
      {isEditPublishedVersionShown ? (
        <Box className="ai-agent-form-disabled-container">
          <img src={AiAgentCircleInfo} alt="ai-agent-circle-info" />
          <Typography className="ai-agent-form-disabled-text">
            {CheckValueLocale("old_ai_agent_version_view_text", "", {}, intl)}
          </Typography>
        </Box>
      ) : null}
      <BasicInformation
        isFormDisabled={isFormDisabled}
        formik={formik}
        aiAgentshandleChange={aiAgentshandleChange}
        customHandleChange={customHandleChange}
      />
      <AgentIdentity
        isFormDisabled={isFormDisabled}
        formik={formik}
        aiAgentshandleChange={aiAgentshandleChange}
        customHandleChange={customHandleChange}
      />
      <AutoAssignment
        isFormDisabled={isFormDisabled}
        formik={formik}
        aiAgentshandleChange={aiAgentshandleChange}
        customHandleChange={customHandleChange}
        aiAgentDetails={aiAgentDetails}
      />
      <EndConversation
        isFormDisabled={isFormDisabled}
        formik={formik}
        aiAgentshandleChange={aiAgentshandleChange}
        customHandleChange={customHandleChange}
      />
      <ImproveAiResponses
        isFormDisabled={isFormDisabled}
        formik={formik}
        aiAgentshandleChange={aiAgentshandleChange}
        customHandleChange={customHandleChange}
      />
      <AddKnowledgeBase
        isFormDisabled={isFormDisabled}
        formik={formik}
        aiAgentDetails={aiAgentDetails}
        helperText={formik?.errors?.resources}
      />
    </Box>
  );
};

export default AiAgentsFormContent;
