import { useQuery } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useAiAgentPublishedVersionDetails = (publishedVersionId) => {
  return useQuery({
    queryKey: ["publishedVersionDetails", publishedVersionId],
    queryFn: () =>
      AiAgentsController.getPublishedVersionDetails(publishedVersionId),
    enabled: !!publishedVersionId,
  });
};
