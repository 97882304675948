import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import InsightsPageHeader from "../../insightsPageHeader.js";
import FiltersController from "services/controllers/filtersController";
import InsightsController from "services/controllers/insightsController.js";
import moment from "moment";
import {
  isEmptyObj,
  handleCAFiltersIds,
  handleCAFiltersVals,
  handleApplicableFiltersCA,
  checkEmptyValuesinObjectOfArrays,
  getIdsFromFilter,
  handleFiltersParamsIds,
} from "utils/helpers";
import { connectWithRabbitMQ } from "../../insightsCommonFunctions";
import {
  getDataSources,
  getChannelsUsage,
} from "../../../channelAnalytics.hooks.js";
import { useParams } from "react-router-dom";
import styles from "../../insightsPage.module.css";
import GoogleMyBusinessBody from "./googleMyBusinessBody";
import GoogleMyBusiness from "../../../../../images/shared-images/gmb.svg";
import AlertMessage from "shared/alert/alertMessage.js";
import { getActiveParams } from "shared/channel-analytics-filter/activeFilters/activeFilters.js";
import { useSelector, useDispatch } from "react-redux";
import {
  filtersDropDownToDisplay,
  reduxApplicableFilters,
} from "utils/redux/features/ChannelAnalytics/channelAnalyticsSlice.js";
import { useIntl } from "react-intl";

const GoogleMyBusinessPage = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  let pathName = window.location.pathname?.slice(1);
  const links = [
    {
      name: "analytics",
      url: "insights/main",
    },
    {
      name: "rating",
      url: hasDatasourceOnly === "true" ? pathName : "insights/ratings",
    },
  ];

  /* Start Redux Declarations */
  const reduxStartDateUx = useSelector(
    (state) => state?.channelAnalytics?.startDateUnix,
  );

  const reduxEndDateUx = useSelector(
    (state) => state?.channelAnalytics?.endDateUnix,
  );

  const reduxFilterParams = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownParams,
  );

  const reduxApplicableFiltersCA = useSelector(
    (state) => state?.channelAnalytics?.applicableFilters,
  );

  const withComment = useSelector(
    (state) =>
      state?.channelAnalytics?.filterDropDownParams?.with_comment || [],
  );
  /* End Redux Declarations */

  const [response, setResponse] = useState([]);
  const [filterFields, setFilterFields] = useState({});
  const [dataSources, setDataSources] = useState([]);
  let startDate = reduxStartDateUx;
  let endDate = reduxEndDateUx;
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const urlParams = useParams();
  const dataSourceId = urlParams.data_source_id;
  const [resetAllFields, setResetAllFields] = useState(0);
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  const [reachInteractionsLimit, setReachInteractionsLimit] = useState(false);
  const [reachInteractionsLimitAlert, setReachInteractionsLimitAlert] =
    useState(true);
  const [companyCanCreateChannel, setCompanyCanCreateChannel] = useState(false);
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);

  const [isApplicableFilterCalculated, setIsApplicableFilterCalculated] =
    useState(false);
  //export btn
  const [checkAllDataFetchingExport, setCheckAllDataFetchingExport] =
    useState(false);
  const [excelSheetData, setExcelSheetData] = useState([]);

  const getCredentials = (startDate, endDate, filterParams) => {
    setResponse([]);
    InsightsController.getGMBCredentials(
      startDate,
      endDate,
      filterParams,
      window.localStorage.cxm_id,
      dataSourceId,
    ).then((data) => {
      if (!isEmptyObj(data.data)) {
        setReachInteractionsLimit(data?.data?.interactions_limit_reach);
        setPreLoaderTrackerDataSources(data?.data?.data_sources);
        connectWithRabbitMQ(
          mqttConnectionClosed,
          clientMQTT,
          data.data,
          setResponse,
        );
      } else {
        mqttConnectionClosed.current = true;
      }
    });
  };

  const applyFilter = (
    startDate,
    endDate,
    applicableFilters,
    applicableFilterCalculated,
  ) => {
    if (isApplicableFilterCalculated || applicableFilterCalculated) {
      let selectedFilterParams = applicableFilters
        ? applicableFilters
        : !checkEmptyValuesinObjectOfArrays(reduxApplicableFiltersCA)
          ? reduxApplicableFiltersCA
          : assignActiveFilters;

      if (
        applicableFilters === undefined &&
        !checkEmptyValuesinObjectOfArrays(reduxApplicableFiltersCA)
      ) {
        const commonObject = {};
        const obj = {
          ...filterFields?.by_interactions,
          ...filterFields?.by_user,
        };

        const finalArr = getIdsFromFilter(obj);
        Object.keys(reduxApplicableFiltersCA).forEach((key) => {
          if (
            Array.isArray(reduxApplicableFiltersCA[key]) &&
            Array.isArray(finalArr[key])
          ) {
            const commonValues = reduxApplicableFiltersCA[key].filter(
              (value) => {
                return (
                  finalArr[key].findIndex(
                    (item) =>
                      item?.toString()?.toLowerCase() ===
                      value?.toString()?.toLowerCase(),
                  ) !== -1
                );
              },
            );
            commonObject[key] = commonValues;
          } else {
            commonObject[key] = [];
          }
        });
        selectedFilterParams = { ...commonObject };
        selectedFilterParams = handleFiltersParamsIds(
          selectedFilterParams,
          filterFields,
        );
      }
      // inject with comment manually
      const resp = handleCAFiltersIds(
        { ...selectedFilterParams, with_comment: withComment },
        filterFields,
      );
      const {
        dmMonitorListFullName,
        waitingFilterFullName,
        monitorListFullName,
      } = resp;
      selectedFilterParams = resp?.selectedFilterParams;
      setResetAllFields((prev) => prev + 1);

      selectedFilterParams = handleCAFiltersVals(
        dmMonitorListFullName,
        monitorListFullName,
        waitingFilterFullName,
        selectedFilterParams,
      );
      setAssignActiveFilters(selectedFilterParams);
      getCredentials(startDate, endDate, selectedFilterParams);
    }
  };

  const getFiltersFileds = () => {
    FiltersController.getGmbFiltersFields(
      window.localStorage.cxm_id,
      dataSourceId,
    ).then((data) => {
      setFilterFields(data.data);
    });
  };

  useEffect(() => {
    getDataSources(setDataSources);
    getFiltersFileds();
  }, []);

  useEffect(() => {
    getChannelsUsage(setCompanyCanCreateChannel);
  }, []);

  let activeFiltersArr = getActiveParams(assignActiveFilters);

  useEffect(() => {
    if (!isEmptyObj(filterFields)) {
      let applicableFilters = handleApplicableFiltersCA(
        filterFields,
        reduxFilterParams,
      );
      let commonObject = {};
      const obj = {
        ...filterFields?.by_interactions,
        ...filterFields?.by_user,
      };

      const finalArr = getIdsFromFilter(obj);

      Object.keys(applicableFilters).forEach((key) => {
        if (
          Array.isArray(applicableFilters[key]) &&
          Array.isArray(finalArr[key])
        ) {
          const commonValues = applicableFilters[key].filter((value) => {
            return (
              finalArr[key].findIndex(
                (item) =>
                  item?.toString()?.toLowerCase() ===
                  value?.toString()?.toLowerCase(),
              ) !== -1
            );
          });
          commonObject[key] = commonValues;
        } else {
          commonObject[key] = [];
        }
      });
      setIsApplicableFilterCalculated(true);
      applyFilter(
        startDate,
        endDate,
        handleFiltersParamsIds(commonObject, filterFields),
        true,
      );
      dispatch(reduxApplicableFilters(applicableFilters));
      dispatch(filtersDropDownToDisplay(commonObject));
    }
  }, [filterFields]);

  const icon = (
    <img
      src={GoogleMyBusiness}
      className="icon-after-title"
      alt="icon-google-my-business"
      width={"18px"}
    />
  );

  return (
    <Container
      maxWidth="xl"
      mb={2}
      className={`${styles.insightsStyle} bread-crumb-page-style`}
    >
      <InsightsPageHeader
        title="google_my_business"
        showNewChannelBtn={true}
        showDatePicker={true}
        showFiltersBtn={true}
        filterFields={filterFields}
        applyFilter={applyFilter}
        startDate={startDate}
        endDate={endDate}
        breadCrumbLinks={links}
        assignActiveFilters={assignActiveFilters}
        allActiveFilters={activeFiltersArr}
        showCheckbox
        companyCanCreateChannel={companyCanCreateChannel}
        sectionName="googlemybusiness"
        luciFeature="Analytics"
        dataSourceId={dataSourceId}
        dataLoaded={checkAllDataExist}
        dataEmpty={checkDataIsEmpty}
        showExportBtn
        isExportLoaded={checkAllDataFetchingExport}
        excelSheetData={excelSheetData}
        monitorDataSource={"GMB"}
        datasourceName={"Googlemybusiness"}
        datasourcePath={"ratings/gmb/insights"}
      />

      <AlertMessage
        mainFlag={reachInteractionsLimit}
        alertMainFlag={reachInteractionsLimitAlert}
        setAlertMainFlag={setReachInteractionsLimitAlert}
        alertMessageId={"interactions_limit_alert"}
      />

      <GoogleMyBusinessBody
        response={response}
        dataSources={dataSources}
        resetAllFields={resetAllFields}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        assignActiveFilters={assignActiveFilters}
        setAssignActiveFilters={setAssignActiveFilters}
        startDate={startDate}
        endDate={endDate}
        applyFilter={applyFilter}
        allActiveFilters={activeFiltersArr}
        filterFields={filterFields}
        icon={icon}
        setCheckAllDataExist={setCheckAllDataExist}
        setCheckDataIsEmpty={setCheckDataIsEmpty}
        setCheckAllDataFetchingExport={setCheckAllDataFetchingExport}
        setExcelSheetData={setExcelSheetData}
      />
    </Container>
  );
};

export default GoogleMyBusinessPage;
