import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faArrowDownToLine } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography } from "@mui/material";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import { useState } from "react";
import { useIntl } from "react-intl";
import EngagementsController from "services/controllers/engagementsController";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

const FileButton = ({ title, onClick, icon, id, isLoading }) => {
  return (
    <Tooltip
      title={title}
      arrow
      placement="bottom"
      classes={{ tooltip: "word-file-buttons-tooltip" }}
    >
      <Box>
        <LucButton
          type="secondary"
          variant="text"
          onClick={onClick}
          minWidth={36} // to make the button square as the height is 36 for small
          size="small"
          className="word-file-buttons-item"
          id={id}
          loading={isLoading}
        >
          <FontAwesomeIcon icon={icon} />
        </LucButton>
      </Box>
    </Tooltip>
  );
};

const DeleteModal = ({ open, onClose, onConfirm, fileName }) => {
  const intl = useIntl();
  return (
    <PopupModal
      title={CheckValueLocale("delete_file_title", "", {}, intl)}
      body={
        <Box className="shared-dialog-body">
          <Typography component="p">
            {CheckValueLocale(
              "delete_file_body",
              "",
              {
                fileName,
              },
              intl,
            )}
          </Typography>
          <Typography component="h2" className="sub-text-desc">
            {CheckValueLocale("deleting_msg", "", {}, intl)}
          </Typography>
        </Box>
      }
      open={open}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
      close={onClose}
      accept={onConfirm}
      warning
      classeName="actions-buttons-shared"
      minWidth="600px"
    />
  );
};

const WordFileButtons = ({ selectedData, setSelectedData }) => {
  const intl = useIntl();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "",
    title: "",
    message: "",
  });

  const handleCloseSnackBar = () => {
    setSnackbarData({
      open: false,
    });
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleFileDownload = () => {
    setIsLoading(true);
    EngagementsController.getKnowledgeBase(
      window.localStorage.getItem("engagementsProductId"),
    ).then((res) => {
      const fileURL = res?.data?.data?.attributes?.knowledge_base_file?.content;
      if (fileURL) {
        // if the file exists then open it in a new tab this will download the file
        window.open(fileURL, "_blank");
        setSnackbarData({
          open: true,
          message: "download_success_message_content",
          severity: "success",
        });
      } else {
        setSnackbarData({
          open: true,
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
        });
      }
      setIsLoading(false);
    });
  };

  const handleConfirmDelete = () => {
    setSelectedData({
      ...selectedData,
      stepTwo: {
        ...selectedData?.stepTwo,
        files: [],
        knowledge_base_url: "",
        isFileChanged: true,
        isFileUploaded: false,
      },
    });
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Box className="word-file-buttons">
        <FileButton
          title={CheckValueLocale("delete_file", "", {}, intl)}
          onClick={handleOpenDeleteModal}
          icon={faTrash}
          id="engagement-settings-remove-file-btn"
        />
        <FileButton
          title={CheckValueLocale("download_file", "", {}, intl)}
          onClick={handleFileDownload}
          icon={faArrowDownToLine}
          id="engagement-settings-download-file-btn"
          isLoading={isLoading}
        />
      </Box>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        fileName={selectedData?.stepTwo?.fileName}
      />
      <SnackBar
        open={snackbarData?.open}
        severity={snackbarData?.severity}
        message={CheckValueLocale(snackbarData?.message, "", {}, intl)}
        title={CheckValueLocale(snackbarData?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};

export default WordFileButtons;
