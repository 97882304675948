import AiAgents from "../api/aiAgents.js";

var AiAgentsController = {};

AiAgentsController.getAiAgentList = () => {
  return new Promise(function (resolve) {
    AiAgents.getAiAgentList()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.createAiAgent = (queryData) => {
  return new Promise(function (resolve) {
    AiAgents.createAiAgent(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.getAiAgentDetails = (id) => {
  return new Promise(function (resolve) {
    AiAgents.getAiAgentDetails(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.deleteAiAgent = (data) => {
  return new Promise(function (resolve) {
    AiAgents.deleteAiAgent(data)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.dublicateAiAgent = (id) => {
  return new Promise(function (resolve) {
    AiAgents.dublicateAiAgent(id)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.resumeAiAgent = (queryData) => {
  return new Promise(function (resolve) {
    AiAgents.resumeAiAgent(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.pauseAiAgent = (queryData) => {
  return new Promise(function (resolve) {
    AiAgents.pauseAiAgent(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.editAiAgent = (data) => {
  return new Promise(function (resolve) {
    AiAgents.editAiAgent(data)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.getSurveysPublishedList = () => {
  return new Promise(function (resolve) {
    AiAgents.getSurveysPublishedList()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.getPublishedVersions = (id, queryData) => {
  return new Promise(function (resolve) {
    AiAgents.getPublishedVersions(id, queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.getSurveysPublishedList = () => {
  return new Promise(function (resolve) {
    AiAgents.getSurveysPublishedList()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.getAiAgentImage = (id) => {
  return new Promise(function (resolve) {
    AiAgents.getAiAgentImage(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.getResourcesList = (queryData) => {
  return new Promise(function (resolve) {
    AiAgents.getResourcesList(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.addResource = (data) => {
  return new Promise(function (resolve) {
    AiAgents.addResource(data)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.deleteResource = (data) => {
  return new Promise(function (resolve) {
    AiAgents.deleteResource(data)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.forceRefresh = (id) => {
  return new Promise(function (resolve) {
    AiAgents.forceRefreshResource(id)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.downloadResource = (id, queryData) => {
  return new Promise(function (resolve) {
    AiAgents.downloadResource(id, queryData)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.editAiAgentFile = (id, queryData) => {
  return new Promise(function (resolve) {
    AiAgents.editResourceFile(id, queryData)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.publishAiAgent = (id) => {
  return new Promise(function (resolve) {
    AiAgents.publishAiAgent(id)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.getPublishedVersionDetails = (id) => {
  return new Promise(function (resolve) {
    AiAgents.getPublishedVersionDetails(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.editPublishedVersion = (queryData) => {
  return new Promise(function (resolve) {
    AiAgents.editPublishedVersion(queryData)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.autoAssignAiAgent = (queryData) => {
  return new Promise(function (resolve) {
    AiAgents.autoAssignAiAgent(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.unassignedConversationAiAgent = (queryData) => {
  return new Promise(function (resolve) {
    AiAgents.unassignedConversationAiAgent(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.getAiAgentsLightList = () => {
  return new Promise(function (resolve) {
    AiAgents.getAiAgentsLightList()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.aiAgentRunTest = (id) => {
  return new Promise(function (resolve) {
    AiAgents.runAiAgentTest(id)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AiAgentsController.downloadSwaggerTemplate = () => {
  return new Promise(function (resolve) {
    AiAgents.downloadSwaggerTemplate()
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default AiAgentsController;
