import { Box } from "@mui/material";
import SideBarBox from "./sideBarBox";

const SideBarContent = ({ aiAgentList, searchInputSideBar }) => {
  const showAiAgent = (name) =>
    searchInputSideBar?.trim()?.length
      ? name?.toLowerCase()?.includes(searchInputSideBar?.trim()?.toLowerCase())
        ? true
        : false
      : true;

  return (
    <Box
      className="ai-agents-side-bar-content"
      id="ai-agents-side-bar-container"
    >
      {aiAgentList?.map((aiAgent) =>
        showAiAgent(aiAgent?.name) ? <SideBarBox aiAgent={aiAgent} /> : null,
      )}
    </Box>
  );
};

export default SideBarContent;
