import { useEffect, useState } from "react";
import moment from "moment";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import handleWidgetColors from "./handleWidgetColors";

const CustomDashboardStackedBarChart = (props) => {
  const {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    widgetsMqttResponses,
    customizedWidgets,
    finalWidgetData,
    dashboardInfo,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    allMonitorsIdWidget,
    isComparison,
    customizedType,
    showControl,
  } = props;

  const [statsDetails, setStatsDetails] = useState([]);
  const [labelsColors, setLabelsColors] = useState(null);
  const [widgetFlags, setWidgetFlags] = useState({ hideLegend: true });
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    if (widget && finalWidgetData) {
      const labelsColorsObj = handleWidgetColors(
        widgetFlags?.reachFunnel || widgetFlags?.themesComparison
          ? finalWidgetData?.[selectedTab]?.pie_data
          : finalWidgetData?.pie_data,
      );

      switch (widget?.widget_name) {
        case "comments_posts_interactions":
        case "posts_posts_interactions":
        case "comments_comments_interactions":
          setLabelsColors(labelsColorsObj);
          setStatsDetails([
            {
              name: "max_interaction",
              value: finalWidgetData?.max_interactions,
              date: moment
                ?.unix(finalWidgetData?.[`max_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "min_interaction",
              value: finalWidgetData?.min_interactions,
              date: moment
                ?.unix(finalWidgetData?.[`min_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "avg_interaction_per_day",
              value: finalWidgetData?.average_interactions,
            },
          ]);
          break;

        case "interactions":
        case "videos_interactions":
          setLabelsColors(labelsColorsObj);
          setStatsDetails([
            {
              name: "max_interaction",
              value: finalWidgetData?.max_interactions,
              date: moment
                ?.unix(finalWidgetData?.[`max_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "min_interaction",
              value: finalWidgetData?.min_interactions,
              date: moment
                ?.unix(finalWidgetData?.[`min_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "avg_interaction_per_day",
              value: finalWidgetData?.average_interactions,
            },
          ]);
          setWidgetFlags({
            ...widgetFlags,
            showChartDetailsWidget: true,
          });
          break;

        case "reach_funnel":
        case "posts_reach_funnel":
          setTabs(["authors", "potential_reach", "potential_impression"]);
          setSelectedTab("authors");
          setLabelsColors({
            colors: ["#64748B", "#2AAAE2"],
            labels: ["Retweets", "original"],
          });
          setWidgetFlags({ reachFunnel: true });
          break;

        case "gender_age_distribution":
          setLabelsColors(labelsColorsObj);
          setWidgetFlags({ isGenderAgeSorted: true, hideLegend: true });
          break;

        case "posts_reach":
          setTabs(["authors", "potential_reach", "potential_impression"]);
          setSelectedTab("authors");
          setLabelsColors({ labels: ["original"], colors: ["#2AAAE2"] });
          setWidgetFlags({ reachFunnel: true });
          break;

        case "themes":
        case "comments_themes":
          setLabelsColors(labelsColorsObj);
          setTabs(["themes", "sub_themes"]);
          setSelectedTab("themes");
          setWidgetFlags({ reachFunnel: true, themesComparison: true });
          break;

        default:
          setLabelsColors(labelsColorsObj);
          break;
      }
    }
  }, [widget, finalWidgetData]);

  return (
    <StackedBarWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      reachFunnel={widgetFlags?.reachFunnel}
      isGenderAgeSorted={widgetFlags?.isGenderAgeSorted}
      titleToolTip={widgetTitleTooltip || ""}
      showDownloadIcon={!isControlWidget}
      bigImg
      posts={
        isComparison && widgetFlags?.themesComparison
          ? finalWidgetData?.[selectedTab]?.data
          : isComparison && widgetFlags?.reachFunnel
            ? finalWidgetData?.[selectedTab]
            : finalWidgetData?.data || []
      }
      showChartDetailsWidget={
        !isComparison && !isControlWidget && widgetFlags?.showChartDetailsWidget
      }
      showScales={!isControlWidget && !isComparison && true}
      showGroupBy={false}
      statsDetailsData={statsDetails || []}
      colors={labelsColors?.colors || []}
      labels={labelsColors?.labels || []}
      showPostsInteractionStats
      showZoomPercentage
      hideLegend={isControlWidget ? undefined : widgetFlags?.hideLegend}
      dataPiechart={
        isComparison && widgetFlags?.themesComparison
          ? finalWidgetData?.[selectedTab]?.pie_data
          : finalWidgetData?.pie_data || []
      }
      postsColumnPreLoaderDataSources={
        finalWidgetData?.preLoaderMonitorsId || []
      }
      preLoaderTrackerDataSources={allMonitorsIdWidget || []}
      isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
      isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
      // The needed data in widget component for case of Custom Dashboard only
      customDashboardData={{
        dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
        handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
        widget: widget, // the widget metric returned from backend in view API
        customizedType: customizedType, // null, aggregate, comparison
        widgetsMqttResponses, // the main state for RabbitMQ responses
        customizedWidgets, // cuztomized widget state for RabbitMQ responses
        getCustomDashboardView, // call back view widgets after any update in page
        setSnackBarData, // set snackbar related to main index view
        showControl, // show widget control icon
      }}
      isComparison={isComparison}
      comparisonReachFunnel={{
        tabs: tabs,
        selectedTab: selectedTab,
        handleChangeTab: setSelectedTab,
      }}
    />
  );
};

export default CustomDashboardStackedBarChart;
