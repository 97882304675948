import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";

const AiAgentsWarning = ({ title, buttonText, buttonClick }) => {
  const intl = useIntl();
  return (
    <Box className="ai-agents-Warning-wrapper">
      <Box className="ai-Warning-title-wrapper">
        <FontAwesomeIcon
          className="ai-Warning-icon"
          icon={faTriangleExclamation}
        />
        <Box component="p" className="ai-Warning-title">
          {title}
        </Box>
      </Box>
      <Box className="ai-Warning-hint-wrapper">
        <LucButton
          className="ai-Warning-button"
          variant="text"
          size="small"
          onClick={buttonClick}
          id="ai-agent-button-warning-button"
        >
          {buttonText}
        </LucButton>
      </Box>
    </Box>
  );
};

export default AiAgentsWarning;
