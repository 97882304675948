import { Box } from "@mui/material";
import aiAgentsEmpty from "images/ai-agents/ai-agents-empty-content.png";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const ContentEmpty = () => {
  const intl = useIntl();
  return (
    <Box className="ai-agents-content-empty-wrapper">
      <Box className="ai-agents-content-empty">
        <img src={aiAgentsEmpty} alt="ai-agents-empty" />
        <Box component={"h2"} className="ai-agents-empty-heading">
          {CheckValueLocale("empty_content_ai_agent_heading", "", {}, intl)}
        </Box>
        <Box component={"p"} className="ai-agents-empty-description">
          {CheckValueLocale("empty_content_ai_agent_description", "", {}, intl)}
        </Box>
      </Box>
    </Box>
  );
};

export default ContentEmpty;
