import {
  Alert,
  Box,
  Button,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import "./suggestionTabs.scss";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";
import saveMark from "images/engagements-images/book-mark.svg";
import ezgif from "images/engagements-images/ezgif.gif";
import NoData from "images/engagements-images/book-mark2.svg";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import MonitorsController from "services/controllers/monitorsController";
import NewReplayPopup from "../../setup-flow/setup/newReplayPopup";
import { useHistory } from "react-router-dom";

import {
  faArrowDown,
  faCommentAltLines,
  faPlus,
  faSpinnerThird,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import SnackBar from "components/snackBar";
import EngagementsController from "services/controllers/engagementsController";
import PopupModal from "components/popupModal";
import LucButton from "shared/lucButton/lucButton";

const SuggestionTabs = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const {
    suggestionsData,
    setText,
    saveData,
    setSaveData,
    aiSuggestionsData,
    AiSavedResponses,
    loading,
    setSuggestionsData,
    setOpenDetailsSideBarReplay,
    setOpenSidebar,
    suggestionAiData,
    insertedReplyId,
    setInsertedReplyId,
    ToggleState,
    setToggleState,
    replyBoxInputTextRef,
  } = props;
  const [newRepPopup, setNewRepPopup] = useState({
    open: false,
    id: null,
    isDuplicate: false,
    title: "",
    contant: "",
    ai: false,
    loading: false,
    done: false,
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const [delPopup, setDelPopup] = useState({
    open: false,
    item: {},
    loading: false,
    done: false,
  });
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  const hadleRefresh = () => {
    aiSuggestionsData();
  };
  const hadleRefreshReply = () => {
    AiSavedResponses();
  };
  const handleSetText = (text) => {
    const pointerPosition =
      replyBoxInputTextRef?.current?.getCaretPosition?.() || 0;
    setText((prevText) => {
      let newText =
        prevText?.slice(0, pointerPosition) +
        text +
        prevText?.slice(pointerPosition);
      return newText;
    });
    replyBoxInputTextRef?.current?.focus?.(pointerPosition + text?.length);
  };

  const handleCreateReplies = () => {
    MonitorsController.createSavedReplies(
      window?.localStorage?.engagements_id,
      newRepPopup?.title?.trim(),
      newRepPopup?.contant?.trim(),
    ).then((data) => {
      if (data?.errorMsg) {
        if (
          data?.errorMsg?.response?.data?.exception?.message ==
          "DUPLICATE_REPLY_FOUND"
        ) {
          setNewRepPopup({
            ...newRepPopup,
            isDuplicate: true,
            loading: false,
            done: false,
          });
        } else {
          setNewRepPopup({
            open: false,
            id: null,
            isDuplicate: false,
            title: "",
            contant: "",
            ai: false,
            loading: false,
            done: false,
          });
          setSnackBar({
            open: true,
            title: "wrong_request_parameter",
            message: "please_try_again",
            severity: "error",
          });
        }
      } else {
        let array = suggestionsData?.filter((item, i) => i !== newRepPopup?.id);

        setSuggestionsData([...array]);
        setNewRepPopup({
          open: false,
          id: null,
          isDuplicate: false,
          title: "",
          contant: "",
          ai: false,
          loading: false,
          done: false,
        });
        setSnackBar({
          open: true,
          title: "",
          message: "new_rep_added",
          severity: "success",
        });
      }
    });
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };
  useEffect(() => {
    if (newRepPopup?.done) {
      handleCreateReplies();
    }
  }, [newRepPopup]);
  const deleteReply = () => {
    EngagementsController.deleteReply(
      delPopup?.item?.id,
      window?.localStorage?.getItem("engagementsProductId"),
    ).then((data) => {
      if (data.errorMsg) {
        setDelPopup({ open: false, item: {}, loading: false, done: false });
        setSnackBar({
          open: true,
          title: "wrong_request_parameter",
          message: "please_try_again",
          severity: "error",
        });
      } else {
        hadleRefreshReply();
        setDelPopup({ open: false, item: {}, loading: false, done: false });
        setSnackBar({
          open: true,
          title: "",
          message: "rep_deleted",
          severity: "success",
        });
      }
    });
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const delBody = () => {
    return (
      <Box className="main-del-popup">
        <Box className="del-popup-header">
          {CheckValueLocale("delete_saved_replies", "", {}, intl)}
        </Box>
        <Box className="del-popup-contant">
          <Box className="about-del">
            <Box>
              {CheckValueLocale("delete_saved_replies_body", "", {}, intl)}
            </Box>
            <Box className="about-del-title">{`”${delPopup?.item?.title}”`}</Box>
          </Box>
          <Box>
            {CheckValueLocale("delete_saved_replies_description", "", {}, intl)}
          </Box>
        </Box>
        <Box className="del-popup-footer">
          <Button
            className="cancel-rep-btn"
            disabled={delPopup?.loading}
            onClick={() =>
              setDelPopup({
                item: {},
                open: false,
                loading: false,
              })
            }
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </Button>
          <Button
            disabled={delPopup?.loading}
            className="del-rep-btn"
            onClick={() => {
              setDelPopup({
                ...delPopup,
                loading: true,
              });
              deleteReply();
            }}
          >
            {CheckValueLocale("dashboard_delete", "", {}, intl)}
            {delPopup?.loading ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            ) : null}
          </Button>
        </Box>
      </Box>
    );
  };
  const handelOpenSavedReply = () => {
    setOpenDetailsSideBarReplay(true);
    setOpenSidebar(false);
  };
  return (
    <Box className="details-side-bar-tabs">
      <Box className={loading ? "tab-list loading-color" : "tab-list"}>
        <Button
          disabled={loading ? true : false}
          id="saved-suggestions-tab-btn"
          className={`tabs ${getActiveClass(1, "active-tabs")}`}
          onClick={() => toggleTab(1)}
        >
          {CheckValueLocale("saved_suggestions", "", {}, intl)}
        </Button>
        <Button
          id="ai-replies-tab-btn"
          disabled={loading ? true : false}
          className={`tabs ${getActiveClass(2, "active-tabs")}`}
          onClick={() => toggleTab(2)}
        >
          {CheckValueLocale("ai_replies", "", {}, intl)}
        </Button>
      </Box>
      <Box className="content-container">
        <Box className={`content ${getActiveClass(1, "active-content")}`}>
          {!loading ? (
            saveData?.length ? (
              <Box>
                <Box className="action-button">
                  <Button
                    id="suggestion-ai-settings"
                    onClick={() => history.push("/engagements/setup")}
                  >
                    <SettingsIcon />
                    {CheckValueLocale("suggestion_ai_settings", "", {}, intl)}
                  </Button>
                  <Button
                    onClick={() => hadleRefreshReply()}
                    id="refresh-ai-replies-btn"
                  >
                    <RefreshIcon />
                    {CheckValueLocale("suggestion_refresh", "", {}, intl)}
                  </Button>
                </Box>
                {suggestionAiData?.above_80 == false ? (
                  <Box className="saved-replies-above-80-content">
                    <Alert open={true} severity="info" icon={false}>
                      <Typography component="span">
                        {CheckValueLocale(
                          "saved_replies_above_80_content",
                          "",
                          {},
                          intl,
                        )}
                        <Typography component="h1" onClick={() => toggleTab(2)}>
                          {CheckValueLocale(
                            "show_ai_replies_btn",
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                      </Typography>
                    </Alert>
                  </Box>
                ) : null}

                {saveData?.map((item, index) => {
                  const identifier = item?.id;
                  const isInserted = insertedReplyId === identifier;
                  return (
                    <Box className="content-box content-save-reply">
                      <Box>
                        <Box className="body-content">
                          <Typography component={"span"}>
                            {item?.attributes?.title}
                          </Typography>
                          <Typography>{item?.attributes?.text}</Typography>
                        </Box>
                        <Box className="footer-content">
                          <Box className="start-box">
                            <Button
                              onClick={() => {
                                handleSetText(item?.attributes?.text);
                                setInsertedReplyId(identifier);
                              }}
                              id="insert-ai-replies-btn"
                              className={
                                isInserted ? "inserted-replay-button" : ""
                              }
                              disabled={isInserted}
                            >
                              <FontAwesomeIcon icon={faArrowDown} />

                              {CheckValueLocale(
                                isInserted
                                  ? "suggestion_inserted"
                                  : "suggestion_insert",
                                "",
                                {},
                                intl,
                              )}
                            </Button>
                            <Tooltip
                              title={CheckValueLocale(
                                "confidence_rate",
                                "",
                                {},
                                intl,
                              )}
                              placement="bottom"
                              arrow
                            >
                              <Box
                                component={"span"}
                                className={
                                  item?.attributes?.score * 100 >= 90
                                    ? "score-green"
                                    : item?.attributes?.score * 100 >= 60
                                      ? "score-Warning"
                                      : "score-red"
                                }
                              >
                                <Typography>
                                  {(item?.attributes?.score * 100).toFixed()}%
                                </Typography>
                              </Box>
                            </Tooltip>
                          </Box>
                          <Box className="end-box">
                            {handlelUserRoles(
                              "SAVED_REPLIES",
                              "DELETE_SAVED_REPLY",
                            ) ||
                            handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
                              <Tooltip
                                title={CheckValueLocale(
                                  "delete_btn",
                                  "",
                                  {},
                                  intl,
                                )}
                                placement="bottom"
                                arrow
                              >
                                <Button
                                  onClick={() =>
                                    setDelPopup({
                                      open: true,
                                      item: { ...item?.attributes },
                                    })
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ) : suggestionAiData?.has_saved_replies ? (
              <Box className="no-data-list-above-cases-ai">
                <img src={NoData} alt="" />
                <Typography>
                  {CheckValueLocale(
                    "no_data_ai_saved_suggestion_above_50",
                    "",
                    {},
                    intl,
                  )}
                </Typography>

                <LucButton
                  variant="flat"
                  type="primary"
                  id="view-replies-suggestion-tab-btn"
                  onClick={() => toggleTab(2)}
                >
                  {CheckValueLocale("view_ai_replies", "", {}, intl)}
                </LucButton>
              </Box>
            ) : (
              <Box className="no-data">
                <img src={NoData} alt="" />
                <Typography>
                  {CheckValueLocale("no_data_ai_saved_sugge", "", {}, intl)}
                  <Typography
                    component={"span"}
                    onClick={() => history.push("/engagements/setup")}
                  >
                    '{CheckValueLocale("suggestion_ai_settings", "", {}, intl)}'
                  </Typography>
                  {CheckValueLocale("no_data_or_saved_sugge", "", {}, intl)}
                  <Typography
                    component={"span"}
                    onClick={() => handelOpenSavedReply()}
                  >
                    '{CheckValueLocale("saved_replies", "", {}, intl)}'
                  </Typography>
                </Typography>
              </Box>
            )
          ) : (
            <Box className="replies-way">
              <img src={ezgif} />
              <Typography>
                {CheckValueLocale("replies_way", "", {}, intl)}
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={`content ${getActiveClass(2, "active-content")}`}>
          {!loading ? (
            suggestionsData?.length ? (
              <Box>
                <Box className="action-button">
                  <Button
                    id="suggestion-ai-settings"
                    onClick={() => history.push("/engagements/setup")}
                  >
                    <SettingsIcon />
                    {CheckValueLocale("suggestion_ai_settings", "", {}, intl)}
                  </Button>
                  <Button onClick={() => hadleRefresh()}>
                    <RefreshIcon />
                    {CheckValueLocale("suggestion_refresh", "", {}, intl)}
                  </Button>
                </Box>
                <Box className="item-group">
                  {suggestionsData?.map((item, index) => {
                    // because of the id doesn't exist in the response, we use the index as an identifier
                    const identifier = `${index}-${item?.text}`;
                    const isInserted = insertedReplyId === identifier;
                    return (
                      <Box className="content-box">
                        <Box className="head-content">
                          <Box className="ai-icon">
                            <img src={ezgif} />

                            {CheckValueLocale("ai", "", {}, intl)}
                          </Box>
                        </Box>
                        <Box className="body-content">
                          <Typography component={"span"}></Typography>
                          <Typography id={index}>{item?.text}</Typography>
                        </Box>
                        <Box className="footer-content">
                          <Box className="start-box">
                            <Button
                              onClick={() => {
                                handleSetText(item?.text);
                                setInsertedReplyId(identifier);
                              }}
                              className={
                                isInserted ? "inserted-replay-button" : ""
                              }
                              disabled={isInserted}
                            >
                              <FontAwesomeIcon icon={faArrowDown} />
                              {CheckValueLocale(
                                isInserted
                                  ? "suggestion_inserted"
                                  : "suggestion_insert",
                                "",
                                {},
                                intl,
                              )}
                            </Button>
                            <Tooltip
                              title={CheckValueLocale(
                                "confidence_rate",
                                "",
                                {},
                                intl,
                              )}
                              placement="bottom"
                              arrow
                            >
                              <Box
                                component={"span"}
                                className={
                                  item?.score * 100 >= 90
                                    ? "score-green"
                                    : item?.score * 100 >= 60
                                      ? "score-Warning"
                                      : "score-red"
                                }
                              >
                                <Typography>
                                  {(item?.score * 100).toFixed()}%
                                </Typography>
                              </Box>
                            </Tooltip>
                          </Box>
                          <Box className="end-box">
                            {handlelUserRoles(
                              "SAVED_REPLIES",
                              "CREATE_SAVED_REPLY",
                            ) ||
                            handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
                              <Tooltip
                                title={CheckValueLocale(
                                  "save_reply",
                                  "",
                                  {},
                                  intl,
                                )}
                                placement="bottom"
                                arrow
                              >
                                <Button
                                  onClick={() =>
                                    setNewRepPopup({
                                      open: true,
                                      id: index,
                                      isDuplicate: false,
                                      title: "",
                                      contant: item?.text,
                                      ai: false,
                                      loading: false,
                                      done: false,
                                    })
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="awesome-icon"
                                    icon={faCommentAltLines}
                                  />
                                </Button>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ) : (
              <Box className="no-data">
                <img src={NoData} alt="" />
                <Typography>
                  {CheckValueLocale("no_data_ai_suggestions", "", {}, intl)}
                </Typography>
                <Button
                  id="suggestion-ai-settings"
                  onClick={() => history.push("/engagements/setup")}
                >
                  <SettingsIcon />
                  {CheckValueLocale("suggestion_ai_settings", "", {}, intl)}
                </Button>
              </Box>
            )
          ) : (
            <Box className="replies-way">
              <img src={ezgif} />
              <Typography>
                {CheckValueLocale("replies_way", "", {}, intl)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {newRepPopup?.open ? (
        <NewReplayPopup
          newRepPopup={newRepPopup}
          setNewRepPopup={setNewRepPopup}
        />
      ) : null}
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
      />
      <PopupModal
        body={delBody()}
        open={delPopup?.open}
        fixPadding={true}
        close={() =>
          !delPopup?.loading &&
          setDelPopup({
            open: false,
            item: {},
            loading: false,
          })
        }
      />
    </Box>
  );
};
export default SuggestionTabs;
