import axios from "axios";
import { setCookie, deleteCookie } from "utils/helpers";
import jwt_decode from "jwt-decode";
import store from "utils/redux/store/store";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";

let Services;
switch (process.env.REACT_APP_ENV) {
  case "production":
    Services = {
      baseRoute: "https://backend2-lb.lucidya.com/",
      nodeRoute: "https://ppt.lucidya.com:443/",
      switchRoute: "https://backend.lucidya.com/",
      switchUrl: "https://sm.lucidya.com/",
      googleTrackingId: "UA-219742565-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "livedashboard3.lucidya.com",
      wsport: 443,
      socketUrl: "https://liveservice.lucidya.com/",
    };
    break;
  case "production_local":
    Services = {
      baseRoute: "https://backend2-lb.lucidya.com/",
      nodeRoute: "https://ppt.lucidya.com:443/",
      switchRoute: "https://backend.lucidya.com/",
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-219742565-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "livedashboard3.lucidya.com",
      wsport: 443,
      socketUrl: "https://liveservice.lucidya.com/",
    };
    break;
  case "staging_survey":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-survey-backend.lucidya.com/`,
      switchRoute: `https://stg-survey-backend.lucidya.com/`,
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
    };
    break;
  case "testing":
    Services = {
      baseRoute: "https://testing-backend2.lucidya.com/",
      switchRoute: "https://testing-backend.lucidya.com/",
      switchUrl: "https://sm-testing.lucidya.com/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "livedashboard3.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
    };
    break;
  case "testing_local":
    Services = {
      baseRoute: "https://testing-backend2.lucidya.com/",
      switchRoute: "https://testing-backend.lucidya.com/",
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "livedashboard3.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
    };
    break;
  case "staging_port":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
      nodeRoute: "https://stg-ppt.lucidya.com/",
      switchRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
      switchUrl: "http://stg-sm.lucidya.com/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
    };
    break;
  case "staging_port_local":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
      nodeRoute: "https://stg-ppt.lucidya.com/",
      switchRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
    };
    break;
  case "staging":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-backend2.lucidya.com/`,
      nodeRoute: "https://stg-ppt.lucidya.com/",
      switchRoute: "https://stg-backend.lucidya.com/",
      switchUrl: "http://stg-sm.lucidya.com/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
    };
    break;
  case "staging_local":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-backend2.lucidya.com/`,
      nodeRoute: "https://stg-ppt.lucidya.com/",
      switchRoute: "https://stg-backend.lucidya.com/",
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
    };
    break;
  default:
    //MAIN STG PORT
    Services = {
      baseRoute: `https://stg-backend2.lucidya.com/`,
      switchRoute: "https://stg-backend.lucidya.com/",
      switchUrl: "http://stg-sm.lucidya.com/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
    };
    break;
}

Services.getData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "get",
    url: Services.baseRoute + queryURL,
    params: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      }
      if (
        response.statuss === 401 &&
        window.location.href.includes("redirect_url")
      ) {
        window.location.href =
          "/login?redirect_url=https://knowledge.lucidya.com/";
      } else if (response.status === 401) {
        window.location.href = "/login";
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      if (
        (err.response.status === 401 || err.response.status === 500) &&
        window.location.href.includes("redirect_url")
      ) {
        window.location.href =
          "/login?redirect_url=https://knowledge.lucidya.com/";
      } else if (err.response.status === 401) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("loginRedirection", true);
        document.location.pathname !== "/login" &&
          (window.location.href = "/login");
      } else if (err.response.status === 500) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("sessionExpired", true);
        window.location.href = "/login";
      } else {
        return Promise.reject(err);
      }
    });
};

Services.getDataBuffer = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "get",
    url: Services.baseRoute + queryURL,
    responseType: "arraybuffer",
    params: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        window.location.href = "/login";
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("loginRedirection", true);
        document.location.pathname !== "/login" &&
          (window.location.href = "/login");
      } else if (err.response.status === 500) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("sessionExpired", true);
        window.location.href = "/login";
      }
    });
};

Services.postData = (queryURL, queryData, pathData, cancelToken, headers) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "post",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
      ...headers,
    },
  })
    .then((response) => {
      for (const [key, value] of Object.entries(response.headers)) {
        if (key === "luc-authorization") {
          window.localStorage.setItem("user_token", value);
          setCookie("email", response?.data?.data?.attributes?.email);
          let decodeAccessToken = jwt_decode(value);
          window.localStorage.setItem(
            "user_roles",
            JSON.stringify(decodeAccessToken.permissions),
          );
          if (decodeAccessToken?.product_feature_permissions) {
            window.localStorage.setItem(
              "advansed_roles",
              JSON.stringify(decodeAccessToken?.product_feature_permissions),
            );
          }
          window.localStorage.setItem(
            "active_features",
            JSON.stringify(decodeAccessToken.active_features),
          );
        }
      }
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        // return <Redirect to="/login" />
        return (window.location.href = "/");
        // return useHistory().replace('/login')
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("loginRedirection", true);
        document.location.pathname !== "/login" &&
          (window.location.href = "/login");
        return Promise.resolve(err);
      } else if (err.response.status === 500) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("sessionExpired", true);
        window.location.href = "/login";
      } else {
        return Promise.reject(err);
      }
    });
};

Services.deleteData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "DELETE",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        return (window.location.href = "./login");
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

Services.putData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "put",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        return (window.location.href = "./login");
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

Services.patchData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "patch",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        return (window.location.href = "./login");
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
let smRoute = Services.switchRoute + "exchange_token";
// let smRoute = "https://stg-backend-p17.lucidya.com/"+"exchange_token";

Services.getSmData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = smRoute;
  return axios({
    method: "get",
    url: queryURL,
    params: queryData,
    cancelToken: cancelToken,
    headers: {
      authorization: window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        return (window.location.href = "./login");
      } else {
        return Promise.reject(response);
      }
    })
    .catch((thrown) => {
      return (window.location.href = "/login");
    })
    .catch((err) => {
      console.log(err);
    });
};
Services.getDataById = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "get",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      for (const [key, value] of Object.entries(response.headers)) {
        if (key === "luc-authorization") {
          window.localStorage.setItem("user_token", value);
          setCookie("email", response?.data?.data?.attributes?.email);
          let decodeAccessToken = jwt_decode(value);
          window.localStorage.setItem(
            "user_roles",
            JSON.stringify(decodeAccessToken.permissions),
          );
          window.localStorage.setItem(
            "active_features",
            JSON.stringify(decodeAccessToken.active_features),
          );
        }
      }
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        // return <Redirect to="/login" />
        return (window.location.href = "/");
        // return useHistory().replace('/login')
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("loginRedirection", true);
        document.location.pathname !== "/login" &&
          (window.location.href = "/login");
      } else if (err.response.status === 500) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("sessionExpired", true);
        window.location.href = "/login";
      } else {
        return Promise.reject(err);
      }
    });
};

// convert arabic number to english number
const arabic2englishNumber = (s) =>
  s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
var serializeJSON = function (data) {
  var queryData = {};

  if (data != undefined) {
    queryData = data;
  }

  return Object.keys(queryData)
    .map(function (keyName) {
      if (keyName === "start_date" || keyName === "end_date") {
        return (
          encodeURIComponent(keyName) +
          "=" +
          encodeURIComponent(
            arabic2englishNumber(queryData[keyName].toString()),
          )
        );
      }
      return (
        encodeURIComponent(keyName) +
        "=" +
        encodeURIComponent(queryData[keyName])
      );
    })
    .join("&");
};

Services.downloadTwitterReport = function (queryUrl, queryData) {
  delete queryData.user_token;
  delete queryData.user_id;
  let downloadLink =
    Services.nodeRoute + queryUrl + "?" + serializeJSON(queryData);
  return fetch(downloadLink, {
    method: "GET",
    withCredentials: true,
    headers: {
      Authorization: localStorage.getItem("user_token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then(function (res) {
      if (res.status >= 200 && res.status < 400) {
        res.blob().then((file) => {
          var file = window.URL.createObjectURL(file);

          // window.location.assign(file);
          window.open(file, "_blank");
        });
        return Promise.resolve({
          success: true,
          status: res.status,
        });
      } else {
        return Promise.reject(res);
      }
    })
    .catch(function (err) {
      var fireErr = { status: 401 };
      if (err.message == "NetworkError when attempting to fetch resource.") {
        throw fireErr;
      } else {
        throw err;
      }
    });
};

Services.nodeGetData = function (queryUrl, queryData) {
  delete queryData.user_token;
  delete queryData.user_id;
  let downloadLink =
    Services.nodeRoute + queryUrl + "?" + serializeJSON(queryData);
  return fetch(downloadLink, {
    method: "GET",
    withCredentials: true,
    headers: {
      Authorization: localStorage.getItem("user_token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then(function (response) {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    })
    .catch(function (err) {
      var fireErr = { status: 401 };
      if (err.message == "NetworkError when attempting to fetch resource.") {
        throw fireErr;
      } else {
        throw err;
      }
    });
};

export default Services;
