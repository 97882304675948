import moment from "moment";
import AddKnowledgeBaseTableName from "./addKnowledgeBaseTableName";
import AddKnowledgeBaseStatusCell from "./addKnowledgeBaseStatusCell";
import KnowledgeBaseOptionsCell from "./knowledgeBaseOptionsCell";

const mapResourceType = {
  url: "link",
  text_file: "docs",
  swagger: "swagger",
};

export const renderAiAgentTableBody = (
  data,
  isPublishing,
  handleCloseMenu,
  formik,
  selectedAiAgentPublishedVersion,
) => {
  return data?.map((resource) => {
    const accessResourceIfPublishedVersion = selectedAiAgentPublishedVersion
      ? resource
      : resource?.attributes;
    const type =
      mapResourceType?.[accessResourceIfPublishedVersion.source_type];
    return {
      id: resource?.id,
      data: [
        {
          body: (
            <AddKnowledgeBaseTableName
              name={accessResourceIfPublishedVersion.title}
              fileSize={accessResourceIfPublishedVersion.file_size}
              type={type}
              url={accessResourceIfPublishedVersion?.url || ""}
            />
          ),
        },
        {
          body: moment(accessResourceIfPublishedVersion.created_at).format(
            "YYYY-MM-DD",
          ),
        },
        {
          body: accessResourceIfPublishedVersion.user_name,
        },
        {
          body: (
            <AddKnowledgeBaseStatusCell
              status={accessResourceIfPublishedVersion.status}
            />
          ),
        },
        {
          body: (
            <KnowledgeBaseOptionsCell
              handleCloseMenu={handleCloseMenu}
              disabled={isPublishing}
              formik={formik}
              resource={accessResourceIfPublishedVersion}
              id={resource?.id}
            />
          ),
        },
      ],
    };
  });
};
