import { CircularProgress, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const AIAgentTestModalLoading = () => {
  const intl = useIntl();
  return (
    <Stack className="ai-agent-chat-loading-stack">
      <CircularProgress />
      <Typography className="ai-agent-chat-loading-text-center ai-agent-chat-loading-text-bold">
        {CheckValueLocale("ai-agent-modal-loading-title", "", {}, intl)}
      </Typography>
      <Typography className="ai-agent-chat-loading-text-center ai-agent-chat-loading-text-normal">
        {CheckValueLocale("ai-agent-modal-loading-subtitle", "", {}, intl)}
      </Typography>
    </Stack>
  );
};

export default AIAgentTestModalLoading;
