import { Box, Checkbox, Typography } from "@mui/material";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownToLine,
  faCircleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { useContext, useEffect, useMemo, useState } from "react";
import AddKnowledgeBaseFilesDropZone from "./addKnowledgeBaseFilesDropZone";
import AddKnowledgeBaseFileItem from "./addKnowledgeBaseFileItem";
import LucButton from "shared/lucButton/lucButton";
import { useAddAiAgentResource } from "pages/engagements/components/aiAgents/aiAgentsHooks/useAddAiAgentResource";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import { useDownloadSwaggerTemplate } from "pages/engagements/components/aiAgents/aiAgentsHooks/useDownloadSwaggerTemplate";

const AddKnowledgeBaseFiles = ({
  isAddFilesOpen,
  setIsAddFilesOpen,
  type,
  handleClose,
}) => {
  const intl = useIntl();
  const [files, setFiles] = useState([]);
  const { aiAgentSelected, setAiAgentSnackBar } = useContext(AiAgentsContext);
  const [consentAccepted, setConsentAccepted] = useState(false);
  const [progresses, setProgresses] = useState([]);

  const { mutate: addFiles, isPending } = useAddAiAgentResource();
  const {
    mutate: downloadSwaggerTemplate,
    isPending: isDownloadSwaggerTemplatePending,
  } = useDownloadSwaggerTemplate();

  const handleDeleteFile = (index) => {
    const newFiles = files?.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  useEffect(() => {
    if (files?.length > 0) {
      const timers = files?.map((_, index) => {
        return setInterval(() => {
          setProgresses((oldProgresses) => {
            const newProgresses = [...oldProgresses];
            const diff = Math.random() * 135;
            newProgresses[index] = Math.min(
              (newProgresses?.[index] || 0) + diff,
              100,
            );
            return newProgresses;
          });
        }, 500);
      });

      return () => {
        timers?.forEach((timer) => clearInterval(timer));
      };
    }
  }, [files?.length]);

  const isAllFilesUploaded = useMemo(() => {
    return (
      progresses?.length > 0 &&
      progresses?.every((progress) => progress === 100)
    );
  }, [progresses]);

  const isTextFiles = type === "text_file";

  const handleUploadFiles = () => {
    if (files?.length === 0) return;
    const formData = new FormData();
    files?.forEach((file) => {
      formData.append("files[]", file);
    });
    formData.append("ai_agent_id", aiAgentSelected);
    formData.append("source_type", type);
    formData.append("user_id", localStorage.getItem("user_id"));
    addFiles(formData, {
      onSuccess: (data) => {
        const hasMultipleFiles = files?.length > 1;
        const hasError = data?.errorMsg;
        const invalidPdfFile =
          hasError?.response?.data?.error === "Invalid pdf file";
        setAiAgentSnackBar({
          success: !hasError,
          message: invalidPdfFile
            ? CheckValueLocale("ai_agent_unable_process_pdf", "", {}, intl)
            : hasError
              ? CheckValueLocale("try_again_error_message", "", {}, intl)
              : CheckValueLocale(
                  hasMultipleFiles
                    ? isTextFiles
                      ? "text_files_uploaded_successfully"
                      : "swagger_files_uploaded_successfully"
                    : isTextFiles
                      ? "text_file_uploaded_successfully"
                      : "swagger_file_uploaded_successfully",
                  "",
                  {},
                  intl,
                ),
        });
        setIsAddFilesOpen(hasError);
        setFiles([]);
        handleClose();
      },
    });
  };
  const isDisabled = (!isTextFiles && !consentAccepted) || !isAllFilesUploaded;

  const modalBody = (
    <Box className="knowledge-base-file-upload">
      <Box className="files-upload-knowledge-base-hint-box-wrapper">
        <FontAwesomeIcon icon={faCircleExclamation} />
        <Box className="files-upload-knowledge-base-hint">
          {CheckValueLocale(
            isTextFiles ? "upload_knowledge_base_note" : "",
            "upload_swagger_note",
            {},
            intl,
          )}
        </Box>
      </Box>
      <AddKnowledgeBaseFilesDropZone
        acceptType={type}
        files={files}
        setFiles={setFiles}
      />
      <Box className="ai-agent-uploaded-files-list">
        {files?.length > 0
          ? files?.map((file, index) => (
              <AddKnowledgeBaseFileItem
                file={file}
                handleDelete={handleDeleteFile}
                index={index}
                progress={progresses?.[index] || 0}
              />
            ))
          : null}
      </Box>
      {!isTextFiles ? (
        <Box className="ai-agent-swagger-file-consent-container">
          <Checkbox
            size="small"
            checked={consentAccepted}
            onChange={(e) => setConsentAccepted(e?.target?.checked)}
          />
          <Typography className="ai-agent-swagger-file-consent-text">
            {CheckValueLocale(
              "swagger_files_upload_consent_text",
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );

  const handleDownloadSwaggerTemplate = () => {
    downloadSwaggerTemplate(undefined, {
      onSuccess: (data) => {
        const result = data?.data;
        const hasError = data?.errorMsg;
        if (hasError) {
          setAiAgentSnackBar({
            success: false,
            message: CheckValueLocale("try_again_error_message", "", {}, intl),
          });
          return;
        }
        const linkElement = document.createElement("a");
        linkElement.href = result?.link;
        linkElement.download = result?.file_name;
        linkElement.click();
      },
    });
  };

  const popupTitle = (
    <Box className="add-knowledge-base-files-popup-title-container">
      <Typography variant="h6">
        {CheckValueLocale(
          isTextFiles ? "upload_text_files" : "upload_swagger_files",
          "",
          {},
          intl,
        )}
      </Typography>
      {!isTextFiles && (
        <LucButton
          variant="text"
          onClick={handleDownloadSwaggerTemplate}
          disabled={isDownloadSwaggerTemplatePending}
          loading={isDownloadSwaggerTemplatePending}
          id="ai-agent-button-download-example-swagger"
          endIcon={
            <FontAwesomeIcon
              className="ai-agent-download-file-sample"
              icon={faArrowDownToLine}
            />
          }
        >
          {CheckValueLocale("download_an_example", "", {}, intl)}
        </LucButton>
      )}
    </Box>
  );

  const hasFiles = files?.length > 0;

  return (
    <PopupModal
      title={popupTitle}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("ai_agent_text_files_upload", "", {}, intl)}
      disabled={isDisabled || isPending || !hasFiles}
      isRightBtnLoading={isPending}
      open={isAddFilesOpen}
      close={() => {
        if (!isPending) {
          handleClose();
          setIsAddFilesOpen(false);
          setFiles([]);
          setConsentAccepted(false);
        }
      }}
      accept={handleUploadFiles}
      classeName="ai-agent-popupModal"
    />
  );
};

export default AddKnowledgeBaseFiles;
