import { Box } from "@mui/material";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const PublishAiAgentModal = ({
  aiAgentDetails,
  openPopupPublish,
  setOpenPopupPublish,
  publishAiAgentLoading,
  type,
  accept,
}) => {
  const intl = useIntl();

  const isInboundRoutingUsed = type === "inboundRoutingUsed";
  const otherName = isInboundRoutingUsed
    ? aiAgentDetails?.inbound_routing_used?.name
    : aiAgentDetails?.unassigned_used?.name;

  const routingName = aiAgentDetails?.inbound_routing?.name;

  let modalBody = (
    <Box>
      <Box
        component="p"
        className="ai-agent-modal-hint ai-agent-resume-modal-hint"
      >
        {CheckValueLocale(
          isInboundRoutingUsed
            ? "inbound_routing_auto_assigned_to_the_ai_agent"
            : "unassigned_auto_assigned_to_the_ai_agent",
          "",
          {
            name: aiAgentDetails?.name,
            otherName: otherName,
            routingName: routingName,
          },
          intl,
        )}
      </Box>
      <Box
        component="p"
        className="ai-agent-modal-hint ai-agent-resume-modal-hint"
      >
        <Box component="span">
          {CheckValueLocale(
            "are_you_sure_publish_to_the_ai_agent",
            "",
            { name: aiAgentDetails?.name },
            intl,
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("confirm_publishing", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("publish_anyway", "", {}, intl)}
      rightBtnClassName="ai-agents-publish-btn"
      disabled={publishAiAgentLoading}
      isRightBtnLoading={publishAiAgentLoading}
      open={openPopupPublish || false}
      close={!publishAiAgentLoading ? () => setOpenPopupPublish(null) : null}
      accept={accept}
      classeName={"ai-agent-popupModal"}
      dialogContentId="resume-ai-agent"
    />
  );
};
export default PublishAiAgentModal;
