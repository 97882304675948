import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import MediaInsta from "components/media/media-insta/mediaInsta";
import MediaTw from "components/media/media-tw/mediaTw";
import MediaFb from "components/media/media-fb/mediaFb";
import LinkedInMedia from "components/media/media-linkedin/linkedInMedia";

const CustomDashboardMediaComponent = (props) => {
  const {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    dashboardInfo,
    isControlWidget,
    finalWidgetData,
    allMonitorsIdWidget,
  } = props;

  const intl = useIntl();

  return (
    <>
      {widget?.data_source === "INSTAGRAM" ? (
        <MediaInsta
          title={CheckValueLocale(
            dashboardInfo?.product == "sm"
              ? handledWidgetName
              : `custom_omni_${handledWidgetName}`,
            "",
            {},
            intl,
          )}
          titleToolTip={widgetTitleTooltip || ""}
          data={finalWidgetData?.media_data || []}
          mediaInstaPreLoaderDataSources={
            finalWidgetData?.preLoaderMonitorsId || []
          }
          preLoaderTrackerDataSources={allMonitorsIdWidget || []}
          hideMoreInsights
          monitorInstagramMedia
          isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
          isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
          // The needed data in widget component for case of Custom Dashboard only
          customDashboardData={{
            dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
            handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
            widget: widget, // the widget metric returned from backend in view API
            noControl: true, // To hide ControlWidget button from WidgetHeader
          }}
        />
      ) : widget?.data_source === "FACEBOOK" ? (
        <MediaFb
          title={CheckValueLocale(
            dashboardInfo?.product == "sm"
              ? handledWidgetName
              : `custom_omni_${handledWidgetName}`,
            "",
            {},
            intl,
          )}
          titleToolTip={widgetTitleTooltip || ""}
          data={finalWidgetData?.media_data || []}
          mediaFbPreLoaderDataSources={
            finalWidgetData?.preLoaderMonitorsId || []
          }
          preLoaderTrackerDataSources={allMonitorsIdWidget || []}
          questions={finalWidgetData?.media_data || []}
          insightsID={[]}
          hideMoreInsights
          hideTotalInteractions
          dates={[]}
          isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
          isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
          // The needed data in widget component for case of Custom Dashboard only
          customDashboardData={{
            dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
            handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
            widget: widget, // the widget metric returned from backend in view API
            noControl: true, // To hide ControlWidget button from WidgetHeader
          }}
        />
      ) : widget?.data_source === "TWITTER" ||
        widget?.data_source === "TIKTOK" ? (
        <MediaTw
          title={CheckValueLocale(
            dashboardInfo?.product == "sm"
              ? handledWidgetName
              : `custom_omni_${handledWidgetName}`,
            "",
            {},
            intl,
          )}
          titleToolTip={widgetTitleTooltip || ""}
          data={finalWidgetData?.media_data || []}
          mediaTwPreLoaderDataSources={
            finalWidgetData?.preLoaderMonitorsId || []
          }
          preLoaderTrackerDataSources={allMonitorsIdWidget || []}
          isTiktok={widget?.data_source === "TIKTOK" ? true : false}
          isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
          isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
          // The needed data in widget component for case of Custom Dashboard only
          customDashboardData={{
            dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
            handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
            widget: widget, // the widget metric returned from backend in view API
            noControl: true, // To hide ControlWidget button from WidgetHeader
          }}
        />
      ) : widget?.data_source === "LINKEDIN" ? (
        <LinkedInMedia
          isLinkedInVideo
          title={handledWidgetName}
          titleToolTip={widgetTitleTooltip || ""}
          data={finalWidgetData?.media_data || []}
          mediaTwPreLoaderDataSources={
            finalWidgetData?.preLoaderMonitorsId || []
          }
          preLoaderTrackerDataSources={allMonitorsIdWidget || []}
          bigImg
          isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
          isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
          // The needed data in widget component for case of Custom Dashboard only
          customDashboardData={{
            dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
            handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
            widget: widget, // the widget metric returned from backend in view API
            noControl: true, // To hide ControlWidget button from WidgetHeader
          }}
        />
      ) : null}
    </>
  );
};

export default CustomDashboardMediaComponent;
