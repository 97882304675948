import {
  changeFromObjectToArrayOfObject,
  handleSentimentResponse,
} from "utils/helpers";
import {
  handleCustomerCareStatsData,
  handleFollowersGrowthStatsData,
} from "../statsDataHandling";

const widgetsMapping = {
  Tiktok__Single_Source__top_videos: {
    value: (response) => ({
      pie_data: response?.top_videos,
    }),
  },
  Tiktok__Single_Source__comments_overtime: {
    value: (response) => ({
      data: response?.interactions,
      total_count: response?.total_interactions,
    }),
  },
  Tiktok__Single_Source__volume_overtime: {
    value: (response) => ({
      data: response?.posts_over_time,
      total_count: response?.total_posts_count,
    }),
  },
  Tiktok__Single_Source__follower_growth_overtime: {
    value: (response) => ({
      data: response?.follower_growth_overtime,
      avg_chage_of_day: response?.avg_chage_of_day,
      max_change_of_followers: response?.max_change_of_followers,
      total_followers: response?.total_followers?.[0],
      stats_data: handleFollowersGrowthStatsData(response),
    }),
  },
  Tiktok__Single_Source__account_author_activity: {
    value: (response) => ({
      activity_data: response?.account_author_activity,
      min_value: response?.min_value,
      max_value: response?.max_value,
    }),
  },
  Tiktok__Single_Source__videos_interactions: {
    value: (response) => ({
      data: response?.videos_interactions_over_time,
      pie_data: response?.videos_interactions_over_time_legends,
      average_interactions: response?.average_interactions,
      max_interaction_date: response?.max_interaction_date,
      max_interactions: response?.max_interactions,
      min_interaction_date: response?.min_interaction_date,
      min_interactions: response?.min_interactions,
      total_interactions: response?.total_interactions,
    }),
  },
  tiktok__Single_Source__themes_and_sub_themes: {
    value: (response) => ({
      data: response?.themes,
    }),
  },
  Tiktok__Single_Source__top_keywords: {
    value: (response) => ({
      pie_data: response?.top_keywords,
    }),
  },
  Tiktok__Single_Source__sentiment_analysis: {
    value: (response) => ({
      data: handleSentimentResponse(response?.sentiment_overtime),
      pie_data: response?.sentiment_piechart,
    }),
  },
  Tiktok__Single_Source__top_hashtags: {
    value: (response) => ({
      pie_data: response?.top_hashtags,
    }),
  },
  Tiktok__Single_Source__gender_distribution: {
    value: (response) => ({
      pie_data: response?.genders,
    }),
  },
  Tiktok__Single_Source__top_languages: {
    value: (response) => ({
      pie_data: response?.top_languages,
    }),
  },
  Tiktok__Single_Source__associated_topics: {
    value: (response) => ({
      pie_data: response?.associated_topics,
    }),
  },
  Tiktok__Single_Source__top_engagers: {
    value: (response) => ({
      pie_data: response?.top_engagers,
    }),
  },
  Tiktok__Single_Source__top_dialects: {
    value: (response) => ({
      pie_data: response?.dialects,
      pie_sub_data: response?.sub_dialects,
    }),
  },
  Tiktok__Single_Source__customer_care_performance: {
    value: (response) => ({
      data: changeFromObjectToArrayOfObject(response?.response_time_segment),
      total_number_of_questions: response?.total_number_of_questions,
      avg_response_time_in_seconds: response?.avg_response_time_in_seconds,
      number_of_answered_questions: response?.number_of_answered_questions,
      stats_data: handleCustomerCareStatsData(response),
    }),
  },
};

export const TiktokOmniRespHandling = (
  response,
  handleWidgetResponse,
  dataSource,
) => {
  const widgetKey = widgetsMapping?.[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      response?.metric_id,
      response?.monitor_id,
      dataSource,
      widgetKey?.value(response?.eventData),
      response?.customized_metric_id,
    );
};
