import { Box } from "@mui/material";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { AiAgentsContext } from "../../../aiAgentsContext";

const DiscardChangesAiAgentModal = ({
  formik,
  openPopupDiscardChanges,
  setOpenPopupDiscardChanges,
  setAiAgentEditData,
}) => {
  const intl = useIntl();
  const { setResetForm } = useContext(AiAgentsContext);

  const discardChangesAiAgentHandler = () => {
    setResetForm((oldReset) => oldReset + 1);
    setAiAgentEditData({});
    setOpenPopupDiscardChanges(null);
  };

  let modalBody = (
    <Box>
      <Box component="p" className="ai-agent-modal-hint">
        {CheckValueLocale("all_unsaved_changes_lost", "", {}, intl)}
      </Box>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("routing_discard_changes_title", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("routing_keep_editing", "", {}, intl)}
      rightBtn={CheckValueLocale("routing_discard_changes", "", {}, intl)}
      open={openPopupDiscardChanges || false}
      close={() => setOpenPopupDiscardChanges(null)}
      accept={discardChangesAiAgentHandler}
      classeName={"ai-agent-popupModal"}
      dialogContentId="discard-changes-ai-agent"
    />
  );
};
export default DiscardChangesAiAgentModal;
