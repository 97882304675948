import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import ManageDialog from "./components/manageDialog";
import avatarImg from "images/custom-dashboard/avatar.svg";
import SidebarWidgetCard from "./components/sidebarWidgetCard";
import { getSocialUrlImg } from "../../customDashboardView/components/controlWidget/components/dataSourceImage";

const CustomDashboardSidebar = (props) => {
  const intl = useIntl();
  const {
    isLoading,
    contentLoading,
    monitorsLoading,
    dashboardInfo,
    sidebarWidgets,
    getCustomDashboardMonitors,
    getCustomDashboardAccounts,
    availableMonitors,
    setAvailableMonitors,
    selectedMonitors,
    setSelectedMonitors,
    updateCustomDashboard,
    monitorsPagination,
    setMonitorsPagination,
    handleAddWidget,
    monitorsChanges,
    setMonitorsChanges,
    handleChangeMonitors,
    contentWidgets,
    handleWidgetImage,
    manageDialog,
    setManageDialog,
    expandedAccordion,
    setExpandedAccordion,
  } = props;

  const handleOpenManageDialog = () => setManageDialog(true);
  const handleCloseManageDialog = () => {
    setSelectedMonitors(dashboardInfo?.monitor_ids);
    setManageDialog(false);
    setMonitorsChanges(false);
    setAvailableMonitors([]);
    setMonitorsPagination({});
  };

  const loadingRows = [1, 2, 3, 4];

  const handleChangeAccordion = (index) => {
    if (expandedAccordion == index) setExpandedAccordion(null);
    else setExpandedAccordion(index);
  };

  return (
    <Box className="editor-sidebar">
      <Box className="sidebar-header">
        {isLoading ? (
          <Box>
            <Skeleton
              variant="text"
              animation="wave"
              className="title-skelton"
            />
            <Skeleton
              variant="text"
              animation="wave"
              className="desc-skelton"
            />
          </Box>
        ) : (
          <Box>
            <Typography className="title">
              {CheckValueLocale(
                dashboardInfo?.product === "sm"
                  ? "custom_dashboard_monitors"
                  : "custom_dashboard_accounts",
                "",
                {},
                intl,
              )}
              :
            </Typography>
            <Typography className="desc">
              {`${sidebarWidgets?.length} ${CheckValueLocale(
                dashboardInfo?.product === "sm"
                  ? "custom_dashboard_monitors_selected"
                  : "custom_dashboard_accounts_selected",
                "",
                {},
                intl,
              )}`}
            </Typography>
          </Box>
        )}
        <Tooltip
          arrow
          placement="top"
          title={CheckValueLocale(
            `custom_dashboard_manage_${dashboardInfo?.product === "sm" ? "monitors" : "accounts"}_btn_tooltip`,
            "",
            {},
            intl,
          )}
        >
          <Box>
            <LucButton
              id="custom-dashboard-editor-manage-monitors-btn"
              type="secondary"
              variant="outline"
              loading={isLoading}
              onClick={handleOpenManageDialog}
            >
              {CheckValueLocale(
                `custom_dashboard_manage_${dashboardInfo?.product === "sm" ? "monitors" : "accounts"}`,
                "",
                {},
                intl,
              )}
            </LucButton>
          </Box>
        </Tooltip>
      </Box>
      {isLoading ? (
        <Box className="loading-container">
          {loadingRows?.map((item) => {
            return (
              <Box key={item}>
                <Box className="skelton-box">
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    className="title-skelton"
                  />
                  <Box className="social-skeltons">
                    {loadingRows?.map((social) => {
                      return (
                        <Skeleton
                          className="item"
                          variant="square"
                          animation="wave"
                        />
                      );
                    })}
                  </Box>
                </Box>
                <Divider />
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box>
          {sidebarWidgets?.map((item, index) => {
            return (
              <Accordion
                id="custom-dashboard-sidebar-accordion"
                expanded={expandedAccordion == index}
                onChange={() => handleChangeAccordion(index)}
                key={item?.id}
              >
                <AccordionSummary
                  id={`accordion-header-${index}`}
                  className="custom-dashboard-accordion-header"
                  aria-controls={`panel-content-${index}`}
                  expandIcon={
                    <FontAwesomeIcon icon={faAngleDown} size="small" />
                  }
                >
                  {dashboardInfo?.product == "sm" ? null : (
                    <img src={avatarImg} alt="avatar" />
                  )}
                  <Typography className="account-data">
                    {item?.name}
                    <Box component={"span"}>
                      {item?.data_sources?.map((dataSource) => {
                        const urlImage = getSocialUrlImg(
                          item?.is_dm ? `${dataSource}_PRIVATE` : dataSource,
                        );
                        return (
                          <Box
                            component={"img"}
                            src={urlImage}
                            alt="dataSource-monitor-icon"
                          />
                        );
                      })}
                      {dashboardInfo?.product == "sm"
                        ? null
                        : CheckValueLocale(
                            item?.is_dm
                              ? `${item?.data_sources?.[0]?.toLowerCase()}_dm`
                              : item?.data_sources?.[0],
                            "",
                            {},
                            intl,
                          )}
                    </Box>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="sidebar-widgets-container">
                    {item?.widgets?.map((widget) => {
                      return (
                        <SidebarWidgetCard
                          dashboardInfo={dashboardInfo}
                          monitorId={item?.id}
                          widgetData={widget}
                          handleAddWidget={handleAddWidget}
                          contentWidgets={contentWidgets}
                          isDm={item?.is_dm}
                          handleWidgetImage={handleWidgetImage}
                        />
                      );
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}
      {manageDialog ? (
        <ManageDialog
          isLoading={contentLoading}
          monitorsLoading={monitorsLoading}
          manageDialog={manageDialog}
          dashboardInfo={dashboardInfo}
          handleCloseManageDialog={handleCloseManageDialog}
          sidebarWidgets={sidebarWidgets}
          availableMonitors={availableMonitors}
          selectedMonitors={selectedMonitors}
          getCustomDashboardMonitors={getCustomDashboardMonitors}
          getCustomDashboardAccounts={getCustomDashboardAccounts}
          updateCustomDashboard={updateCustomDashboard}
          monitorsPagination={monitorsPagination}
          monitorsChanges={monitorsChanges}
          handleChangeMonitors={handleChangeMonitors}
        />
      ) : null}
    </Box>
  );
};

export default CustomDashboardSidebar;
