import React from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const WidgetControlFooter = (props) => {
  const {
    handleChangeContolSidebar,
    handleApplyCustomize,
    viewType,
    selectedMonitors,
    changesFlag,
    applyLoading,
  } = props;

  const intl = useIntl();

  return (
    <Box className="widget-control-sidebar-footer">
      <LucButton
        id="widget-control-sidebar-apply-btn"
        type="primary"
        onClick={handleApplyCustomize}
        loading={applyLoading}
        disabled={!changesFlag || !viewType || !selectedMonitors?.length}
      >
        {CheckValueLocale("custom_dashboard_apply", "", {}, intl)}
      </LucButton>
      <LucButton
        id="widget-control-sidebar-cancel-btn"
        type="secondary"
        variant="outline"
        onClick={handleChangeContolSidebar}
      >
        {CheckValueLocale("cancel", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default WidgetControlFooter;
