import "./mainSavedReply.scss";
import { Box, Button, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  CheckValueLocale,
  classNames,
  handlelUserRoles,
} from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import {
  faTimes,
  faSpinnerThird,
  faTrash,
  faArrowDown,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emptyReply from "images/engagements-images/empty-reply-new.svg";
import MonitorsController from "services/controllers/monitorsController";
import NewReplayPopup from "../../setup-flow/setup/newReplayPopup";
import InputAdornment from "@mui/material/InputAdornment";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";

const MainSavedReply = ({
  setOpenDetailsSideBarReplay,
  text,
  setText,
  replyBoxInputTextRef,
}) => {
  const intl = useIntl();
  const [repsPage, setRepsPage] = useState(1);
  const [repsCount, setRepsCount] = useState(0);
  const [repsPages, setRepsPages] = useState(1);
  const [repsList, setRepsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [repsLoading, setRepsLoading] = useState(true);
  const [insertedReplyId, setInsertedReplyId] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const [newRepPopup, setNewRepPopup] = useState({
    open: false,
    id: null,
    isDuplicate: false,
    title: "",
    contant: "",
    ai: false,
    loading: false,
    done: false,
  });
  const [delPopup, setDelPopup] = useState({
    open: false,
    item: {},
    loading: false,
    done: false,
  });

  const getAllSavedReplies = (page) => {
    setRepsLoading(true);
    MonitorsController.getAllSavedReplies(
      window?.localStorage?.engagements_id,
      page ? page : repsPage,
    )
      .then((data) => {
        if (data?.data?.pagination) {
          setRepsCount(data?.data?.pagination?.count);
          setRepsPages(data?.data?.pagination?.pages);
        }
        if (page == 1) {
          setRepsList([...data?.data?.data]);
        } else {
          setRepsList([...repsList, ...data?.data?.data]);
        }
        setRepsLoading(false);
        setDelPopup({
          item: {},
          open: false,
          loading: false,
        });
        setNewRepPopup({
          open: false,
          id: null,
          isDuplicate: false,
          title: "",
          contant: "",
          ai: false,
          loading: false,
          done: false,
        });
      })
      .catch((err) => {
        setRepsPage(1);
        setRepsCount(0);
        setRepsPages(1);
        setRepsList([]);
        setRepsLoading(false);
        setDelPopup({
          item: {},
          open: false,
          loading: false,
        });
        setNewRepPopup({
          open: false,
          id: null,
          isDuplicate: false,
          title: "",
          contant: "",
          ai: false,
          loading: false,
          done: false,
        });
      });
  };

  const handleDeleteSavedReplies = () => {
    MonitorsController.deleteSavedReplies(
      delPopup?.item?.id,
      window?.localStorage?.engagements_id,
    )
      .then((data) => {
        setRepsPage(1);
        getAllSavedReplies(1);
        setSnackBar({
          open: true,
          title: "",
          message: "rep_deleted",
          severity: "success",
        });
      })
      .catch((error) => {
        setRepsPage(1);
        getAllSavedReplies(1);
        setSnackBar({
          open: true,
          title: "wrong_request_parameter",
          message: "please_try_again",
          severity: "error",
        });
      });
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const handleCreateReplies = () => {
    MonitorsController.createSavedReplies(
      window?.localStorage?.engagements_id,
      newRepPopup?.title?.trim(),
      newRepPopup?.contant?.trim(),
    ).then((data) => {
      if (data?.errorMsg) {
        if (
          data?.errorMsg?.response?.data?.exception?.message ==
          "DUPLICATE_REPLY_FOUND"
        ) {
          setNewRepPopup({
            ...newRepPopup,
            isDuplicate: true,
            loading: false,
            done: false,
          });
        } else {
          setNewRepPopup({
            open: false,
            id: null,
            isDuplicate: false,
            title: "",
            contant: "",
            ai: false,
            loading: false,
            done: false,
          });
          setSnackBar({
            open: true,
            title: "wrong_request_parameter",
            message: "please_try_again",
            severity: "error",
          });
        }
      } else {
        setRepsPage(1);
        getAllSavedReplies(1);
        setSnackBar({
          open: true,
          title: "",
          message: "new_rep_added",
          severity: "success",
        });
      }
    });
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };
  useEffect(() => {
    getAllSavedReplies();
  }, []);
  useEffect(() => {
    if (newRepPopup?.done) {
      handleCreateReplies();
    }
  }, [newRepPopup]);
  const delBody = () => {
    return (
      <Box className="shared-dialog-body">
        <p>
          {`${CheckValueLocale("delete_saved_replies_body_engag", "", { name: delPopup?.item?.title }, intl)}`}
        </p>
        <h2 className="sub-text-desc">
          {CheckValueLocale("deleting_msg", "", {}, intl)}
        </h2>
      </Box>
    );
  };

  const handleOnInsert = (item) => {
    const pointerPosition =
      replyBoxInputTextRef?.current?.getCaretPosition?.() || 0;
    const insertText = item?.attributes?.text;
    setText((prevText) => {
      return (
        prevText.slice(0, pointerPosition) +
        insertText +
        prevText.slice(pointerPosition)
      );
    });
    setInsertedReplyId(item?.id);
    replyBoxInputTextRef?.current?.focus?.(
      pointerPosition + insertText?.length,
    );
  };

  const handleScroll = (event) => {
    const target = event.target;
    if (
      Math.trunc(target.scrollHeight) -
        Math.trunc(target.scrollTop) -
        Math.trunc(target.clientHeight) <=
      4
    ) {
      if (!repsLoading && repsList?.length && repsPages >= repsPage + 1) {
        setRepsPage(repsPage + 1);
        getAllSavedReplies(repsPage + 1);
      }
    }
  };
  return (
    <Box className="main-rep-bar">
      <Box className="rep-bar-header">
        {CheckValueLocale("saved_replies", "", {}, intl)}
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => setOpenDetailsSideBarReplay(false)}
        />
      </Box>
      {repsList?.length ? (
        <Box className="rep-bar-search">
          <OutlinedInput
            onChange={(e) => setSearchText(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faSearch}
                  flip={intl.locale == "ar" && "horizontal"}
                />
              </InputAdornment>
            }
          />
        </Box>
      ) : null}
      <Box
        className={
          repsPages > 1 || repsList?.length
            ? "rep-bar-contant scroll"
            : "rep-bar-contant"
        }
        onScroll={handleScroll}
      >
        {repsLoading && !repsList?.length ? (
          <Box className="fetching-loader">
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </Box>
        ) : !repsList?.length ? (
          <Box className="rep-bar-contant-empty saved-empty ">
            <img className="contant-empty-img" src={emptyReply} />
            <Box component={"h3"} className="empty-title">
              {CheckValueLocale("create_new_reply", "", {}, intl)}
            </Box>
            {CheckValueLocale("empty_reply", "", {}, intl)}
          </Box>
        ) : (
          <Box className="rep-bar-contant-container">
            {repsList?.map((item) => {
              let show = searchText?.trim()?.length
                ? item?.attributes?.title
                    ?.toLowerCase()
                    ?.includes(searchText.trim()?.toLowerCase()) ||
                  item?.attributes?.text
                    ?.toLowerCase()
                    ?.includes(searchText.trim()?.toLowerCase())
                : true;
              const isInserted = insertedReplyId == item?.id;
              return show ? (
                <Box
                  key={item?.attributes?.id}
                  className="rep-bar-contant-card"
                >
                  <Box className="contant-title">{item?.attributes?.title}</Box>
                  <Box className="contant-contant">
                    {item?.attributes?.text}
                  </Box>
                  <Box className="contant-footer">
                    <Button
                      className={classNames(
                        "inset-btn",
                        isInserted && "inserted-replay-button",
                      )}
                      onClick={() => handleOnInsert(item)}
                      disabled={isInserted}
                      id="insert-saved-replies-btn"
                    >
                      <FontAwesomeIcon icon={faArrowDown} />
                      {CheckValueLocale(
                        isInserted ? "suggestion_inserted" : "insert",
                        "",
                        {},
                        intl,
                      )}
                    </Button>
                    {handlelUserRoles("SAVED_REPLIES", "DELETE_SAVED_REPLY") ||
                    handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
                      <Tooltip
                        title={CheckValueLocale(
                          "dashboard_delete",
                          "",
                          {},
                          intl,
                        )}
                        placement="bottom"
                        arrow
                      >
                        <Box>
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() =>
                              setDelPopup({
                                open: true,
                                item: { ...item?.attributes },
                              })
                            }
                          />
                        </Box>
                      </Tooltip>
                    ) : null}
                  </Box>
                </Box>
              ) : null;
            })}
            {repsLoading ? (
              <Box className="pagination-loader">
                {CheckValueLocale("loading", "", {}, intl)}
                <FontAwesomeIcon icon={faSpinnerThird} spin />
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
      {handlelUserRoles("SAVED_REPLIES", "CREATE_SAVED_REPLY") ||
      handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
        <Box className="rep-bar-footer">
          <Button
            id="engagements-new-reply-btn"
            className="new-rep-btn"
            onClick={() =>
              setNewRepPopup({
                open: true,
                id: null,
                isDuplicate: false,
                title: "",
                contant: "",
                ai: false,
                loading: false,
                done: false,
              })
            }
          >
            <span>+</span>
            {CheckValueLocale("new_rep", "", {}, intl)}
          </Button>
        </Box>
      ) : null}
      <PopupModal
        title={CheckValueLocale("delete_saved_replies_engag", "", {}, intl)}
        body={delBody()}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
        open={delPopup?.open}
        close={() =>
          !delPopup?.loading &&
          setDelPopup({
            open: false,
            item: {},
            loading: false,
          })
        }
        disableLeftBtn={delPopup?.loading}
        disabled={delPopup?.loading}
        acceptOtherOption={() =>
          setDelPopup({
            item: {},
            open: false,
            loading: false,
          })
        }
        accept={() => {
          setDelPopup({
            ...delPopup,
            loading: true,
          });
          handleDeleteSavedReplies();
        }}
        rightBtnId="delete-saved-reply-engag"
        leftBtnId="cancel-saved-reply-engag"
        warning
        minWidth="600px"
        classeName="actions-buttons-shared"
      />
      {newRepPopup?.open ? (
        <NewReplayPopup
          newRepPopup={newRepPopup}
          setNewRepPopup={setNewRepPopup}
        />
      ) : null}
      <SnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={CheckValueLocale(snackBar.message, "", {}, intl)}
        title={CheckValueLocale(snackBar.title, "", {}, intl)}
      />
    </Box>
  );
};
export default MainSavedReply;
