import { CheckValueLocale } from "utils/helpers";

export const dialectsData = (intl) => [
  {
    id: "najdi saudi",
    name: CheckValueLocale("ai_agent_dialect_najdi", "", {}, intl),
  },
  {
    id: "hijaze saudi",
    name: CheckValueLocale("ai_agent_dialect_hijaze", "", {}, intl),
  },
  {
    id: "other saudi",
    name: CheckValueLocale("ai_agent_dialect_other", "", {}, intl),
  },
  {
    id: "emirati",
    name: CheckValueLocale("ai_agent_dialect_emirati", "", {}, intl),
  },
  {
    id: "bahrani",
    name: CheckValueLocale("ai_agent_dialect_bahrani", "", {}, intl),
  },
  {
    id: "kuwaiti",
    name: CheckValueLocale("ai_agent_dialect_kuwaiti", "", {}, intl),
  },
  {
    id: "egyptian",
    name: CheckValueLocale("ai_agent_dialect_egyptian", "", {}, intl),
  },
  {
    id: "iraqi",
    name: CheckValueLocale("ai_agent_dialect_iraqi", "", {}, intl),
  },
  {
    id: "syrian",
    name: CheckValueLocale("ai_agent_dialect_syrian", "", {}, intl),
  },
  {
    id: "lebanese",
    name: CheckValueLocale("ai_agent_dialect_lebanese", "", {}, intl),
  },
  {
    id: "palestinian",
    name: CheckValueLocale("ai_agent_dialect_palestinian", "", {}, intl),
  },
  {
    id: "algerian",
    name: CheckValueLocale("ai_agent_dialect_algerian", "", {}, intl),
  },
  {
    id: "moroccan",
    name: CheckValueLocale("ai_agent_dialect_moroccan", "", {}, intl),
  },
  {
    id: "libyan",
    name: CheckValueLocale("ai_agent_dialect_libyan", "", {}, intl),
  },
  {
    id: "white dialect",
    name: CheckValueLocale("ai_agent_dialect_white", "", {}, intl),
  },
  {
    id: "modern arabic",
    name: CheckValueLocale("ai_agent_dialect_modern_arabic", "", {}, intl),
  },
];
