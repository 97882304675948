import { CheckValueLocale } from "utils/helpers";
import * as yup from "yup";

export const editSwaggerValidationSchema = (intl) => {
  return yup.object().shape({
    description: yup
      .string()
      .required(CheckValueLocale("required", "", {}, intl)),
    summary: yup.string().required(CheckValueLocale("required", "", {}, intl)),
    operationId: yup
      .string()
      .required(CheckValueLocale("required", "", {}, intl)),
    headers: yup.array().of(
      yup.object().shape(
        {
          name: yup.string().when("description", {
            is: (description) => description && description.trim() !== "",
            then: () =>
              yup
                .string()
                .required(
                  CheckValueLocale(
                    "Key is required when value is provided",
                    "",
                    {},
                    intl,
                  ),
                ),
            otherwise: () => yup.string(),
          }),
          description: yup.string().when("name", {
            is: (name) => name && name.trim() !== "",
            then: () =>
              yup
                .string()
                .required(
                  CheckValueLocale(
                    "Value is required when key is provided",
                    "",
                    {},
                    intl,
                  ),
                ),
            otherwise: () => yup.string(),
          }),
        },
        [["name", "description"]],
      ),
    ),
    parameters: yup.array().of(
      yup.object().shape(
        {
          name: yup.string().when("description", {
            is: (description) => description && description.trim() !== "",
            then: () =>
              yup
                .string()
                .required(
                  CheckValueLocale(
                    "Key is required when value is provided",
                    "",
                    {},
                    intl,
                  ),
                ),
            otherwise: () => yup.string(),
          }),
          description: yup.string().when("name", {
            is: (name) => name && name.trim() !== "",
            then: () =>
              yup
                .string()
                .required(
                  CheckValueLocale(
                    "Value is required when key is provided",
                    "",
                    {},
                    intl,
                  ),
                ),
            otherwise: () => yup.string(),
          }),
        },
        [["name", "description"]],
      ),
    ),
  });
};
