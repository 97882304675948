import { useEffect, useState, useRef } from "react";
import VideoFrame from "react-video-thumbnail";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useIntl } from "react-intl";
import {
  TextField,
  Breadcrumbs,
  Link,
  Box,
  MenuItem,
  Select,
  Avatar,
  Divider,
  Popover,
  Tooltip,
  Button,
  Skeleton,
  CircularProgress,
  Menu,
  Typography,
} from "@mui/material";
import "./reply.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faSpinner,
  faCheckCircle,
  faAt,
  faTrash,
  faPlay,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faCheckCircle as faCheckCircleRegular,
  faEllipsis,
} from "@fortawesome/pro-regular-svg-icons";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import listenForOutsideClicks, {
  CheckValueLocale,
  getNumberOfChar,
  getDatasourceIconName,
  isEmptyObj,
} from "utils/helpers";
import MonitorsController from "services/controllers/monitorsController";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DefaultAccountImg from "../../../Monitors/Components/replyModal/defaultAccountImg";
import SavedRepliesModal from "../../../Monitors/Components/replyModal/savedRepliesModal";
import CreateRepliesModal from "../../../Monitors/Components/replyModal/createRepliesModal";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar.js";
import io from "socket.io-client";
import Services from "services/api/Services.js";
import {
  faSpinnerThird,
  faBookmark,
  faGrinAlt,
  faCommentAltLines,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCommentAltLines as faCommentAltLinesSolid,
  faPaperPlaneTop,
} from "@fortawesome/pro-solid-svg-icons";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import gif from "images/engagements-images/gif.svg";
import video from "images/engagements-images/video.svg";
import image from "images/engagements-images/image.svg";
import mention from "images/engagements-images/mention.svg";
import translateButton from "images/engagements-images/translateButton.svg";
import translate from "images/engagements-images/translate.svg";
import gifSolid from "images/engagements-images/gif-solid.svg";
import activeTranselation from "images/engagements-images/transelate-active.svg";
import update from "images/engagements-images/update.svg";
import EngagementTranslation from "../details/engagementTranslation/engagementTranslation";
import EngagementsController from "services/controllers/engagementsController";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import { sanitize } from "dompurify";
import twitterText from "twitter-text";
import LucButton from "shared/lucButton/lucButton";
import AssignHumanAiAgentModal from "./assignHumanAiAgentModal";
import TeamUnavailableAiAgentModal from "./teamUnavailableAiAgentModal";
import { useAutoAssignAiAgent } from "../aiAgents/aiAgentsHooks/useAutoAssignAiAgent";

// stg env
///engagements
const socket = io(Services.socketUrl + "engagements", {
  path: "/ws/",
  transports: ["websocket"],
  auth: {
    "luc-authorization": localStorage.getItem("user_token"),
  },
});

const getDefaultMaxCharForDMDatasources = (selectedCard = {}) => {
  const { isDm, data_source } = selectedCard;
  if (!isDm) {
    return 280;
  }
  switch (data_source) {
    case "TWITTER":
      return 10000;
    case "FACEBOOK":
      return 2000;
    case "INSTAGRAM":
      return 1000;
    default:
      return 280;
  }
};

const IconReplyEllipsis = ({
  attachments,
  handleAttachmentsUpload,
  disabledStyleFirst,
  disabledCondition,
  mediaLength,
  inputRefGif,
  inputRefVideo,
  inputRefImg,
  handleClickEmoji,
  aiAgentId,
}) => {
  const intl = useIntl();
  return (
    <>
      <Tooltip
        title={
          !aiAgentId
            ? attachments?.length === 4
              ? CheckValueLocale("max_media_msg", "", {}, intl)
              : CheckValueLocale("gif_tooltip", "", {}, intl)
            : null
        }
        placement="top"
        arrow
      >
        <label
          htmlFor="gif-input"
          className={`reply-footer-icon ${
            disabledStyleFirst || aiAgentId ? "reply-footer-icon-disabled" : ""
          }`}
        >
          <img src={gif} />
          <input
            id="gif-input"
            type="file"
            accept=".gif, image/gif"
            onChange={handleAttachmentsUpload}
            className="modal-input-style"
            disabled={disabledCondition || mediaLength || aiAgentId}
            ref={inputRefGif}
          />
        </label>
      </Tooltip>
      <Tooltip
        title={
          !aiAgentId
            ? attachments?.length === 4
              ? CheckValueLocale("max_media_msg", "", {}, intl)
              : CheckValueLocale("video", "", {}, intl)
            : null
        }
        placement="top"
        arrow
      >
        <label
          htmlFor="video-input"
          className={`reply-footer-icon ${
            disabledStyleFirst || aiAgentId ? "reply-footer-icon-disabled" : ""
          }`}
        >
          <img src={video} />
          <input
            id="video-input"
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={handleAttachmentsUpload}
            className="modal-input-style"
            disabled={disabledCondition || mediaLength || aiAgentId}
            ref={inputRefVideo}
          />
        </label>
      </Tooltip>
      <Tooltip
        title={
          !aiAgentId
            ? attachments?.length === 4
              ? CheckValueLocale("max_media_msg", "", {}, intl)
              : CheckValueLocale("image", "", {}, intl)
            : null
        }
        placement="top"
        arrow
      >
        <label
          htmlFor="image-input"
          className={`reply-footer-icon ${
            disabledStyleFirst || aiAgentId ? "reply-footer-icon-disabled" : ""
          }`}
        >
          <img src={image} />
          <input
            id="image-input"
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            onChange={handleAttachmentsUpload}
            className="modal-input-style"
            disabled={disabledCondition || mediaLength || aiAgentId}
            ref={inputRefImg}
          />
        </label>
      </Tooltip>
    </>
  );
};

const Reply = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const inputRefGif = useRef(null);
  const inputRefVideo = useRef(null);
  const inputRefImg = useRef(null);
  const replyInputRef = useRef(null);
  const {
    replyData,
    showSuccessModal,
    selectedCard,
    setIsDeletedResponse,
    replyType,
    setLimitRateDate,
    limitRateDate,
    showSnackBar,
    setShowSnackBar,
    snackBarSeverity,
    setSnackbarSeverity,
    snackBarMessage,
    setSnackbarMessage,
    snackBarTitle,
    setSnackbarTitle,
    selectedFile,
    setSelectedFile,
    base64Code,
    setBase64Code,
    attachments,
    setAttachments,
    text,
    setText,
    mediaFile,
    setMediaFile,
    mediaLoader,
    setMediaLoader,
    btnLoading,
    setBtnLoading,
    selectedAccountImg,
    setSelectedAccountImg,
    selectedValue,
    setSelectedValue,
    loading,
    setLoading,
    postData = {},
    setPostData = () => {},
    setNewMsg = () => {},
    setIsReplied = () => {},
    translationActive,
    setTranslationActive,
    translationSource,
    setTranslationSource,
    translationTarget,
    setTranslationTarget,
    stats,
    setStats,
    languagesArraySource,
    languagesArrayTarget,
    translateStatus,
    setTranslateStatus,
    setOpenDetailsSideBar,
    setOpenDetailsSideBarReplay,
    setOpenSlaSideBar,
    setCheckAiSuggestions,
    setOpenSidebar,
    headerData,
    chatData = [],
    setOriginalReply = () => {},
    setDeletedMesssagesIds,
    openSidebar,
    openDetailsSideBarReplay,
    isSidebarsOpened,
    showAllIcon,
    setSelectedCardIteractionID,
    aiAgentList,
    setRefresh,
    setOpenSnackBarAiAgent,
    setSnackBarDataAiAgent,
    setInjectedMessage,
    aiAgentId,
    replyBoxInputTextRef,
  } = props;
  const repliedDate = replyData?.system_replies?.created_at;
  const replyBy = replyData?.system_replies?.replier_info?.email;

  const [maxChar, setMaxChar] = useState(
    getDefaultMaxCharForDMDatasources(selectedCard),
  );
  const [textLength, setTextLength] = useState(0);
  const [savedRepliesloader, setSavedRepliesLoader] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [haveAccounts, setHaveAccounts] = useState(false);
  const [allawedAccounts, setAllawedAccounts] = useState([]);
  const [accountsImg, setAccountsImg] = useState([]);
  const [accountsPageIds, setAccountsPageIds] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [accountsIds, setAccountsIds] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [iconToDisplay, setIconToDisplay] = useState(null);
  const [accountsStatus, setAccountsStatus] = useState([]);
  const [outboundDMData, setOutboundDMData] = useState([]);
  const [showCreateRepliesModal, setShowCreateRepliesModal] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [replyToDelete, setReplyToDelete] = useState("");
  const [savedReplies, setSavedReplies] = useState([]);
  const [savedReplyIds, setSavedReplyIds] = useState([]);
  const [injectedWord, setInjectedWord] = useState({});
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
  const [translateReplyStatus, setTranslateReplyStatus] =
    useState("translateReply");
  const [translationText, setTranslationText] = useState("");
  const [translationSourceText, setTranslationSourceText] = useState("");
  const [translationSourceTextLoading, setTranslationSourceTextLoading] =
    useState(false);
  const [translationTextLoading, setTranslationTextLoading] = useState(false);
  const [replyModalData, setReplyModalData] = useState([
    {
      selectedValue: "",
      replyTime: "",
    },
  ]);
  const [textareaState, setTextareaState] = useState(null);

  const handleCloseSnack = () => {
    setShowSnackBar(false);
  };
  const [viewMoreIconsEl, setViewMoreIconsEl] = useState(null);
  const isViewMoreIconsOpen = Boolean(viewMoreIconsEl);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEmoji, setAnchorElEmoji] = useState(null);
  const [mediaErr, setMediaErr] = useState("");
  const [anchorElTranslation, setAnchorElTranslation] = useState(null);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [activeSelectedAccount, setActiveSelectedAccount] = useState("");
  const [getAllAccounts, setGetAllAccounts] = useState([]);
  const [tempUserName, setTempUserName] = useState("");

  const [openPopupAssignHuman, setOpenPopupAssignHuman] = useState(false);
  const [openPopupTeamUnavailable, setOpenPopupTeamUnavailable] =
    useState(false);
  const [aiAgentDetails, setAiAgentDetails] = useState({});
  const [autoReplyMessage, setAutoReplyMessage] = useState(null);

  const { mutate: autoAssignAiAgent, isPending: autoAssignAiAgentLoading } =
    useAutoAssignAiAgent();
  const handleAppendText = (text) => {
    const currentPosition = replyInputRef?.current?.selectionStart || 0;
    const textLength = text?.length || 0;

    setText((prev) => {
      return (
        prev.substring(0, currentPosition) +
        text +
        prev.substring(currentPosition)
      );
    });
    const currentCaretPosition = currentPosition + textLength;
    replyInputRef?.current?.setSelectionRange(
      currentCaretPosition,
      currentCaretPosition,
    );
  };
  const handleClick = (event) => {
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
    setOpenSlaSideBar(false);
    setOpenDetailsSideBarReplay(true);
    setOpenSidebar(false);
    //setAnchorEl(event.currentTarget);
  };
  const handleViewMoreIconsClick = (event) => {
    setViewMoreIconsEl(event.currentTarget);
  };
  const handleViewMoreIconsClose = () => {
    setViewMoreIconsEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowCreateRepliesModal(false);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (aiAgentId && aiAgentList?.length) {
      setAiAgentDetails(
        aiAgentList?.find((item) => +item?.id == +aiAgentId) || {},
      );
    }
  }, [aiAgentList, aiAgentId]);

  useEffect(() => {
    let realText = translateStatus ? translationText?.trim() : text?.trim();
    setTextLength(realText?.length || 0);
    if (selectedCard.data_source === "TWITTER" && realText?.length) {
      const parsedTweet = twitterText.parseTweet(realText);
      setTextLength(parsedTweet?.weightedLength || 0);
    }
  }, [text, translateStatus, translationText]);

  const handleChange = (event) => {
    setText(event.target.value);
    if (replyType === "dm") {
      setOriginalReply(event.target.value);
    }
  };

  const handleAttachmentsUpload = async (event) => {
    const file = event.target?.files[0];
    if (file) {
      if (Math.round(file?.size / (1024 * 1024)) > 10) {
        setMediaErr("size_media_err");
      } else if (
        event.target.id === "gif-input" &&
        file?.type !== "image/gif"
      ) {
        setMediaErr("gif_media_err");
      } else if (
        event.target.id === "video-input" &&
        file?.type !== "video/mp4"
      ) {
        setMediaErr("vid_media_err");
      } else if (
        event.target.id === "image-input" &&
        file?.type !== "image/png" &&
        file?.type !== "image/jpeg"
      ) {
        setMediaErr("img_media_err");
      } else {
        setMediaErr("");
        const reader = new FileReader();
        reader.onload = () => {
          setBase64Code([...base64Code, reader.result]);
        };
        reader.readAsDataURL(file);
        setSelectedFile([...selectedFile, file]);
        setMediaFile([...mediaFile, URL.createObjectURL(file)]);
      }

      try {
        setMediaLoader(true);
        socket.emit("upload_media", {
          upload_file: file,
          publisher_name: selectedValue,
          account_info_id: selectedAccountId,
          monitor_id: selectedCard?.tracker_id,
          data_source_name: selectedCard?.data_source,
          product_id: window.localStorage.getItem("engagementsProductId"),
          media_type: file?.type,
          "luc-authorization": localStorage.getItem("user_token"),
        });
      } catch (error) {}
    }
    replyInputRef.current?.focus?.();
  };
  const handleRemoveAttachments = (index) => {
    const attachments = [...selectedFile];
    attachments.splice(index, 1);
    setSelectedFile(attachments);

    mediaFile.splice(index, 1);
    setMediaFile(mediaFile);

    const base64 = [...base64Code];
    base64.splice(index, 1);
    setBase64Code(base64);

    const attachment = [...attachments];
    attachment.splice(index, 1);
    setAttachments(attachment);

    if (inputRefGif?.current) {
      inputRefGif.current.value = null;
    }
    if (inputRefVideo?.current) {
      inputRefVideo.current.value = null;
    }
    if (inputRefImg?.current) {
      inputRefImg.current.value = null;
    }
    replyInputRef.current?.focus?.();
  };

  const handleChangeDropdown = (event) => {
    setSelectedValue(event.target.value);
    setReplyModalData({
      ...replyModalData,
      selectedValue: event.target.value,
    });
  };
  const getLastUsedAccount = () => {
    setSelectedValue("");
    setActiveSelectedAccount("");
    let queryData = {
      product_id: window.localStorage.engagements_id,
      data_source_name: selectedCard?.data_source,
      monitor_id: selectedCard?.tracker_id,
    };
    EngagementsController.getLastUsedAccount(queryData).then((res) => {
      if (!res?.errorMsg) {
        setActiveSelectedAccount(res?.data?.last_used_account);
      }
    });
  };
  useEffect(() => {
    let dataSource =
      selectedCard.data_source === "FACEBOOK"
        ? "FACEBOOK"
        : selectedCard.data_source === "INSTAGRAM"
          ? "INSTAGRAM"
          : "TWITTER";
    if (
      !showSuccessModal &&
      (replyData?.system_replies?.is_replied === undefined ||
        replyData?.system_replies?.is_replied === 0)
    ) {
      let accountsData = [...accounts];
      let accountsProfileImg = [...accountsImg];
      let accountInfoId = [...accountsIds];
      let accountStatus = [...accountsStatus];
      let accountPageIds = [...accountsPageIds];
      setLoading(true);
      setSuggestionLoading(false);
      setCheckAiSuggestions(false);
      let newDataAccounts = [];
      setGetAllAccounts([]);
      MonitorsController.getAllawedAccounts(
        window.localStorage.engagements_id,
        "reply",
      ).then((AllawedData) => {
        let allawedAccountArr =
          AllawedData?.data?.data?.user_accounts?.[dataSource];
        MonitorsController.getAllAccounts(window.localStorage.engagements_id)
          .then((data) => {
            if (data?.data?.status === 200) {
              let dropdownData = data?.data;
              if (
                selectedCard?.data_source === "INSTAGRAM" ||
                selectedCard?.data_source === "FACEBOOK"
              ) {
                dropdownData?.data[dataSource]?.included?.map((accountData) => {
                  if (
                    (!selectedCard?.isDm &&
                      accountData?.attributes?.engagements_products?.includes(
                        headerData?.product,
                      )) ||
                    selectedCard?.isDm
                  ) {
                    if (allawedAccountArr?.includes(+accountData?.id)) {
                      if (selectedCard?.data_source === "INSTAGRAM")
                        accountsData?.push(accountData?.attributes?.user_name);
                      else accountsData?.push(accountData?.attributes?.name);
                      accountsProfileImg?.push(
                        accountData?.attributes?.image_url,
                      );
                      accountInfoId?.push(
                        accountData?.attributes?.account_info_id,
                      );
                      accountStatus?.push(accountData?.attributes?.status);
                      var accountPageIdsVar =
                        replyType === "dm"
                          ? selectedCard?.data_source === "FACEBOOK"
                            ? accountData?.attributes?.page_id
                            : accountData?.attributes?.instagram_id
                          : accountData?.attributes?.page_id;
                      accountPageIds?.push(accountPageIdsVar);
                    }

                    newDataAccounts.push(accountData);
                  }
                });
              } else {
                dropdownData?.data[dataSource]?.data?.map((accountData) => {
                  // commented for now, may be needed later
                  // if (
                  //   accountData?.attributes?.used_in_private_channel === 0 &&
                  //   accountData?.attributes?.used_in_public_channel === 0
                  // ) {
                  if (
                    (!selectedCard?.isDm &&
                      accountData?.attributes?.engagements_products?.includes(
                        headerData?.product,
                      )) ||
                    selectedCard?.isDm
                  ) {
                    if (allawedAccountArr?.includes(+accountData?.id)) {
                      accountsData?.push(accountData?.attributes?.user_name);
                      accountsProfileImg?.push(
                        accountData?.attributes?.image_url,
                      );
                      accountInfoId?.push(accountData?.attributes?.id);
                      accountStatus?.push(accountData?.attributes?.status);
                    }

                    newDataAccounts.push(accountData);
                  }

                  // }
                });
              }
              let allawedArr = [];
              newDataAccounts?.map((item) => {
                if (allawedAccountArr?.includes(+item?.id)) {
                  allawedArr.push(+item?.id);
                }
              });
              setAllawedAccounts(allawedArr);
              setAccounts(accountsData);
              setAccountsImg(accountsProfileImg);
              setAccountsIds(accountInfoId);
              setAccountsStatus(accountStatus);
              setAccountsPageIds(accountPageIds);
              setLoading(false);
              setGetAllAccounts([...newDataAccounts]);
            } else {
              setShowErrorSnackBar(true);
              setLoading(false);
            }
            setSuggestionLoading(true);
          })
          .catch((error) => {
            setLoading(false);
            setSuggestionLoading(true);
          });
      });
    }
  }, [replyType === "dm" ? null : selectedCard]);

  useEffect(() => {
    if (!isEmptyObj(postData)) {
      if (replyType !== "dm") {
        socket.on("engagement_status", (data) => {
          if (data.status_code === 200 || data.status_code === 201) {
            if (data?.content?.item_id) {
              setSelectedCardIteractionID(data?.content?.item_id);
            }
            const repliesArr = postData?.replies?.length
              ? [
                  // ...postData?.replies,
                  {
                    created_at: data?.content?.created_at,
                    isSocket: true,
                    profile_image_url: selectedAccountImg,
                    name: selectedValue,
                    text: data?.content?.text,
                    original_replay: translateStatus ? text : null,
                    is_account_owner: data?.content?.is_account_owner,
                    item_id: data?.content?.item_id,
                    media: mediaFile.length
                      ? mediaFile?.map((url) => {
                          return { media_url: url };
                        })
                      : [],
                  },
                ]
              : [
                  {
                    created_at: data?.content?.created_at,
                    isSocket: true,
                    profile_image_url: selectedAccountImg,
                    name: selectedValue,
                    text: data?.content?.text,
                    original_replay: translateStatus ? text : null,
                    is_account_owner: data?.content?.is_account_owner,
                    item_id: data?.content?.item_id,
                    media: mediaFile.length
                      ? mediaFile?.map((url, index) => {
                          return {
                            media_url: url,
                            isVideo: !(
                              selectedFile[index]?.type === ".gif" ||
                              selectedFile[index]?.type === "image/jpeg" ||
                              selectedFile[index]?.type === "image/gif" ||
                              selectedFile[index]?.type?.includes("gif") ||
                              selectedFile[index]?.type?.includes("image") ||
                              selectedFile[index]?.type?.includes("photo")
                            ),
                          };
                        })
                      : [],
                  },
                ];
            setPostData({
              ...postData,
              replies: [...repliesArr],
            });
            setText("");
            setAttachments([]);
            setMediaFile([]);
            setSelectedFile([]);
            setBase64Code([]);
            setIsDeletedResponse(false);
            setIsReplied(true);
            setTranslateReplyStatus("translateReply");
            setTranslationText("");
          } else {
            if (data?.content?.error == "RATE_LIMIT_ERROR") {
              setSnackbarMessage("limit_rate_error");
              let date = moment
                .unix(+data?.content?.rate_limit_reset)
                .utc()
                .format("DD/MM/YYYY [at] hh:mm A");
              if (intl.locale == "ar") {
                let dateArr = date?.split(" ");
                date = `${dateArr[0]} الساعة ${dateArr[2]} ${dateArr[3] == "PM" ? "م" : "ص"}`;
              }
              setLimitRateDate(date);
              setSnackbarTitle("");
            } else if (
              data?.content?.error == "INTERACTION_ALREADY_COMPLETED"
            ) {
              setSnackbarMessage("interaction_is_completed");
              setSnackbarTitle("");
            } else {
              setSnackbarMessage("request_access_error_desc");
              setSnackbarTitle("request_access_error_heading");
            }
            setSnackbarSeverity("error");
            setShowSnackBar(true);
          }
          setBtnLoading(false);
          setMediaLoader(false);
        });
        socket.on("upload_status", (data) => {
          if (data.status_code === 200 || data.status_code === 201) {
            setAttachments([...attachments, data?.content]);
          } else {
            setShowSnackBar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("request_access_error_heading");
            setSnackbarTitle("request_access_error_desc");
          }
          setMediaLoader(false);
        });
      }
    }
  }, [
    socket,
    postData,
    selectedValue,
    selectedFile,
    selectedAccountImg,
    mediaFile,
    translateStatus,
  ]);

  useEffect(() => {
    if (replyType === "dm") {
      socket.emit("join_room", {
        company_id: window.localStorage.getItem("id"),
        tracker_id: selectedCard.tracker_id,
        conversation_id: selectedCard.conversation_id,
      });
    }
  }, [selectedCard]);
  useEffect(() => {
    if (replyType === "dm") {
      socket.on("join_room", (data) => {});
    }
  }, [selectedCard]);

  useEffect(() => {
    if (replyType === "dm") {
      socket.on("upload_status", (data) => {
        if (data.status_code === 200 || data.status_code === 201) {
          setAttachments([...attachments, data?.content]);
        } else {
          setShowSnackBar(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("request_access_error_desc");
          setSnackbarTitle("request_access_error_heading");
        }
        setMediaLoader(false);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (replyType === "dm") {
      socket.on("live_data", (data) => {
        setNewMsg({
          ...data?.single_interaction?.conversation[0],
        });
      });
    }
  }, [socket]);
  useEffect(() => {
    if (replyType === "dm") {
      //setLoading(true);
      socket.on("engagement_status", (data) => {
        if (data.status_code === 200 || data.status_code === 201) {
          const content = data?.content || {};
          setNewMsg({
            ...content,
            is_temporary: true,
            content: {
              media: JSON.stringify({}),
              text: content?.text,
              url_temporary: !!content?.attachments?.length,
            },
            created_at: content?.created_at,
            direction: "outbound",
            message_id: content?.item_id,
            name: tempUserName,
            system_replies: {
              replier_info: { email: content?.email },
            },
          });

          if (data?.content?.item_id) {
            setSelectedCardIteractionID(data?.content?.item_id);
          }
        } else {
          if (data?.content?.error == "RATE_LIMIT_ERROR") {
            setSnackbarMessage("limit_rate_error_dm");
            let date = moment
              .unix(+data?.content?.rate_limit_reset)
              .utc()
              .format("DD/MM/YYYY [at] hh:mm A");
            if (intl.locale == "ar") {
              let dateArr = date?.split(" ");
              date = `${dateArr[0]} الساعة ${dateArr[2]} ${dateArr[3] == "PM" ? "م" : "ص"}`;
            }
            setLimitRateDate(date);
            setSnackbarTitle("");
          } else if (data?.content?.error == "INTERACTION_ALREADY_COMPLETED") {
            setSnackbarMessage("interaction_is_completed");
            setSnackbarTitle("");
          } else {
            setSnackbarMessage("request_access_error_desc");
            setSnackbarTitle("request_access_error_heading");
          }
          setSnackbarSeverity("error");
          setShowSnackBar(true);
          setBtnLoading(false);
        }
        setMediaLoader(false);
      });
      //on remove message from the conversation
      socket.on("delete_live_data", (data) => {
        const deletedMessageId = data?.single_interaction?.message_id;
        setDeletedMesssagesIds((prev) => [...prev, deletedMessageId]);
      });
    }
  }, [socket, tempUserName]);
  useEffect(() => {
    if (!openSidebar && suggestionLoading) {
      setCheckAiSuggestions(true);
    }
  }, [openSidebar, suggestionLoading]);

  useEffect(() => {
    if (accounts?.length) {
      accounts?.map((account, i) => {
        if (
          (replyType === "dm" &&
            ((selectedCard?.data_source === "TWITTER" &&
              "@" + selectedCard?.agent_username === account) ||
              checkIdAccountDm(
                getAllAccounts,
                selectedCard?.data_source,
                account,
              ))) ||
          replyType !== "dm"
        ) {
          setHaveAccounts(true);
        }
      });
    }
  }, [accounts, replyType, selectedCard, getAllAccounts]);

  const getAllSavedReplies = () => {
    setSavedRepliesLoader(true);
    MonitorsController.getAllSavedReplies(window.localStorage.engagements_id)
      .then((data) => {
        setSavedReplies(data?.data?.data);
        setSavedRepliesLoader(false);
      })
      .catch((err) => {
        setSavedRepliesLoader(false);
        setAnchorEl(null);
        setShowSnackBar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("please_try_again");
        setSnackbarTitle("wrong_request_parameter");
      });
  };

  useEffect(() => {
    const index = accounts?.indexOf(selectedValue);
    if (index !== -1) {
      setSelectedAccountImg(accountsImg[index]);
      setSelectedAccountId(accountsIds[index]);
      setSelectedPageId(accountsPageIds[index]);
    }
  }, [selectedValue]);
  const handleReply = () => {
    if (replyType !== "dm") {
      setMediaLoader(true);
    }
    setBtnLoading(true);

    let publishEngagementObj = {
      "luc-authorization": localStorage.getItem("user_token"),
      account_info_id: selectedAccountId,
      publisher_name: selectedValue,
      data_source_name: selectedCard?.data_source,
      monitor_id: selectedCard?.tracker_id,
      product_id: window.localStorage.getItem("engagementsProductId"),
      text: translateStatus ? translationText?.trim() : text?.trim(),
      attachments: attachments,
      sla_id: selectedCard?.sla_info?.sla_id,
      routing_id: selectedCard?.sla_info?.routing_id,
      screen_name: tempUserName,
    };
    if (replyType === "dm") {
      publishEngagementObj = {
        ...publishEngagementObj,
        item_type: "message",
        parent_id: selectedCard?.conversation_id,
        recipient_id: selectedCard?.client_id,
        agent_id: selectedCard?.agent_id,
      };
    } else {
      publishEngagementObj = {
        ...publishEngagementObj,
        parent_id: selectedCard?.tweet_id,
        item_type: selectedCard?.type === "comment" ? "reply" : "comment",
      };
    }
    socket.emit("publish_engagement", publishEngagementObj);
    if (replyType === "dm") {
      setText("");
      setAttachments([]);
      setMediaFile([]);
      setSelectedFile([]);
      setBase64Code([]);
      setTranslateReplyStatus("translateReply");
      setTranslationText("");
    }
  };
  useEffect(() => {
    replyType !== "dm" && getLastUsedAccount();
    replyInputRef.current?.focus?.();
    if (replyBoxInputTextRef?.current) {
      replyBoxInputTextRef.current = {
        getCaretPosition: () => {
          return replyInputRef?.current?.selectionStart || 0;
        },
        focus: (nextPosition) => {
          setTimeout(() => {
            replyInputRef?.current?.focus();
            replyInputRef?.current?.setSelectionRange(
              nextPosition,
              nextPosition,
            );
          }, 100);
        },
      };
    }
  }, []);
  useEffect(() => {
    if (getAllAccounts?.length && replyType !== "dm") {
      let filteredAccountData = getAllAccounts?.filter(
        (obj) =>
          obj?.id == activeSelectedAccount &&
          allawedAccounts?.includes(+obj?.id),
      );
      let defaultAccountName;
      if (selectedCard?.data_source == "FACEBOOK") {
        defaultAccountName = filteredAccountData?.length
          ? filteredAccountData[0]?.attributes?.name
          : "";
      } else {
        defaultAccountName = filteredAccountData?.length
          ? filteredAccountData[0]?.attributes?.user_name
          : "";
      }

      if (activeSelectedAccount && filteredAccountData?.length)
        setSelectedValue(defaultAccountName);
    }
  }, [getAllAccounts, activeSelectedAccount]);

  useEffect(() => {
    if (replyType === "dm") {
      let filteredAccountData = [];
      let selectedAccountNameDM;

      if (selectedCard?.data_source === "TWITTER") {
        filteredAccountData = getAllAccounts?.filter(
          (obj) =>
            obj?.attributes?.user_name == `@${selectedCard?.agent_username}` &&
            allawedAccounts?.includes(+obj?.id),
        );
        selectedAccountNameDM = filteredAccountData[0]?.attributes?.user_name;
      } else if (selectedCard?.data_source === "FACEBOOK") {
        filteredAccountData = getAllAccounts?.filter(
          (obj) =>
            obj?.attributes?.page_id == selectedCard?.agent_id &&
            allawedAccounts?.includes(+obj?.id),
        );
        selectedAccountNameDM = filteredAccountData[0]?.attributes?.name;
      } else {
        filteredAccountData = getAllAccounts?.filter(
          (obj) =>
            obj?.attributes?.instagram_id == selectedCard?.agent_id &&
            allawedAccounts?.includes(+obj?.id),
        );
        selectedAccountNameDM = filteredAccountData[0]?.attributes?.user_name;
      }
      if (filteredAccountData?.length) {
        setTempUserName(
          filteredAccountData[0]?.attributes?.name
            ? filteredAccountData[0]?.attributes?.name
            : filteredAccountData[0]?.attributes?.user_name,
        );
      }

      if (selectedAccountNameDM) setSelectedValue(selectedAccountNameDM);
    }
  }, [selectedCard, getAllAccounts]);
  const successModal = () => (
    <Box className="display-in-row">
      <Box className="replied-success-modal replied-success-modal-ca">
        <FontAwesomeIcon icon={faCheckCircle} />
        <span>{CheckValueLocale("replied_success", "", {}, intl)},</span>
      </Box>
      <Box className="success-modal-description success-modal-description-ca">
        <span>{CheckValueLocale("by", "", {}, intl)}</span>
        <div>{replyBy ?? localStorage.getItem("email")}</div>
      </Box>
      <Box className="separator-style">-</Box>
      <Box className="success-modal-description success-modal-date">
        <span>{CheckValueLocale("reply_date", "", {}, intl)}</span>
        <div>
          {repliedDate
            ? moment?.unix(repliedDate).utc()?.format("DD/MM/YYYY [at] hh:mm A")
            : replyModalData?.replyTime}
        </div>
      </Box>
    </Box>
  );

  const errorModal = () => (
    <Box className="display-in-row">
      <Box className="replied-error-modal replied-error-modal-ca">
        <CancelIcon />
        <span>{CheckValueLocale("wrong_request_parameter", "", {}, intl)}</span>
      </Box>
      <Box className="error-modal-description error-modal-description-ca">
        <span>{CheckValueLocale("please", "", {}, intl)}</span>
        <div onClick={() => setShowErrorModal(false)}>
          {CheckValueLocale("reply_try_again", "", {}, intl)}
        </div>
      </Box>
    </Box>
  );

  const deleteModalBody = () => {
    return (
      <Box className="delete-modal-content">
        <Box>
          {CheckValueLocale("delete_saved_replies_body", "", {}, intl)}
          <span>{replyToDelete?.attributes?.title}</span>
        </Box>
        <Box>
          {CheckValueLocale("delete_saved_replies_description", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  const unAuthenticatedAccountModal = () => (
    <Box className="display-in-row">
      <Box className="unauthenticated-accounts-modal">
        <ErrorOutlineOutlinedIcon />
        <span>
          {CheckValueLocale("all_accounts_unauthenticated", "", {}, intl)}
        </span>
      </Box>
      <Box
        className="companies-settings-style"
        onClick={() => history.push("/settings")}
      >
        {CheckValueLocale("replies_company_settings", "", {}, intl)}
      </Box>
    </Box>
  );

  const handleCloseDeleteModal = () => {
    setShowDeletePopup(false);
  };

  const handleDeleteSavedReplies = () => {
    setInjectedWord({});
    MonitorsController.deleteSavedReplies(
      replyToDelete?.attributes?.id,
      window.localStorage.engagements_id,
    )
      .then((data) => {
        getAllSavedReplies();
        setShowSnackBar(true);
        setSnackbarSeverity("success");
        setSnackbarTitle("saved_replies_deleted_successfully");
        setInjectedWord({ reply: replyToDelete?.attributes?.title });
        setShowDeletePopup(false);
      })
      .catch((error) => {
        setShowSnackBar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("please_try_again");
        setSnackbarTitle("wrong_request_parameter");
        setShowDeletePopup(false);
      });
  };

  useEffect(() => {
    setIconToDisplay(null);
    (async () => {
      const image = new Image();
      image.src = selectedAccountImg;
      image.onload = () => {
        setIconToDisplay(selectedAccountImg);
      };
    })();
  }, [selectedAccountImg]);

  useEffect(() => {
    if (translateReplyStatus === "replyTranslated" && translateStatus) {
      setTranslateReplyStatus("updateTranslation");
    }
  }, [text]);

  useEffect(() => {
    closeTranslationBox();
  }, [translationTarget, translationSource]);

  let textCheck =
    replyType === "dm"
      ? textLength === 0
      : !mediaFile?.length && textLength === 0;

  const replyBtnStyle =
    textCheck ||
    mediaLoader ||
    selectedValue === "" ||
    textLength > maxChar ||
    btnLoading ||
    (translateReplyStatus !== "replyTranslated" && translateStatus);

  const showSuccessCondition =
    showSuccessModal || replyData?.system_replies?.is_replied === 1;

  const mainModalCondition =
    !showSuccessModal &&
    !showErrorModal &&
    ((accounts?.length > 0 && accountsStatus?.includes("active")) ||
      (getAllAccounts?.length && !allawedAccounts?.length)) &&
    (replyData?.system_replies?.is_replied === undefined ||
      replyData?.system_replies?.is_replied === 0)
      ? "reply-modal reply-modal-channel-analytics"
      : "reply-modal reply-modal-msg";

  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  const dropdownStyle =
    selectedValue === "" ? "reply-modal-dropdown" : "reply-modal-dropdown-icon";

  const breadCrumbsStyle = textLength > maxChar ? "word-reached" : "";
  const emojyAnchorOrigin =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "left",
        }
      : {
          vertical: "top",
          horizontal: "right",
        };

  const emojyTransformOrigin =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "left",
        }
      : {
          vertical: "bottom",
          horizontal: "right",
        };
  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };
  const anchorPositionTranslation =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "left",
        }
      : {
          vertical: "top",
          horizontal: "right",
        };
  const transformPositionTranslation =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "left",
        }
      : {
          vertical: "bottom",
          horizontal: "right",
        };

  const attachmentsLength = replyType === "dm" ? 1 : 4;
  const mediaLength = replyType === "dm" ? base64Code?.length : mediaLoader;

  const disabledCondition =
    selectedValue === "" || attachments?.length === attachmentsLength;
  const disabledStyle =
    disabledCondition || mediaLength ? "disabled-icons" : "modal-icons";
  const disabledStyleFirst = disabledCondition || mediaLength;

  const isTranslationSourceAutoDetect = translationSource === "auto_detect";

  // emoji Dropdown HANDLER
  const handleClickEmoji = () => {
    setAnchorElEmoji(replyInputRef.current);
  };
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
    // added setTimeout here because the setAnchorElEmoji will be called after the handleCloseEmoji finish
    // so if we called the focus directly it will not work the becase the emojy dropdown will still be exist
    setTimeout(() => {
      replyInputRef.current?.focus?.();
    }, 100);
  };
  const openEmoji = Boolean(anchorElEmoji);

  // add emoji to text after select and hide emoji Dropdown
  const emojiSelect = (e) => {
    textareaState === "translation-text"
      ? setTranslationText(translationText + e)
      : handleAppendText(e);
  };

  // translation HANDLER
  const handleClickTranslation = (event) => {
    setAnchorElTranslation(event.currentTarget);
  };
  const handleCloseTranslation = () => {
    setAnchorElTranslation(null);
  };
  const openTranslation = Boolean(anchorElTranslation);
  const translateHandler = (event) => {
    if (translationActive) {
      if (translateStatus) {
        closeTranslationBox();
      } else {
        setTranslateStatus(true);
        replyInputRef.current?.focus?.();
        if (isTranslationSourceAutoDetect) {
          getSourceLanguage();
        }
      }
    } else {
      handleClickTranslation(event);
    }
  };
  const closeTranslationBox = () => {
    setTranslateStatus(false);
    setTranslateReplyStatus("translateReply");
    setTranslationText("");
    replyInputRef.current?.focus?.();
  };
  const handleChangeTranslation = (event) => {
    setTranslationText(event.target.value);
  };
  const handleFocus = (event) => {
    setTextareaState(event.target.id);
  };
  const translateReplyHandler = () => {
    setTranslationTextLoading(true);
    const queryData = {
      text,
      target_language: isTranslationSourceAutoDetect
        ? translationSourceText
        : translationSource,
      source_language: translationTarget,
      product_id: window.localStorage.getItem("engagementsProductId"),
    };
    EngagementsController.detectTranslate(queryData).then((res) => {
      if (!res?.errorMsg) {
        setTranslationText(res?.data?.translation);
        setTranslateReplyStatus("replyTranslated");
      }
      setTranslationTextLoading(false);
      replyInputRef?.current?.focus?.();
    });
  };
  const getSourceLanguage = (target, source) => {
    setTranslationSourceTextLoading(true);
    const clientName = selectedCard?.client_name;
    let lastMsg = "";
    if (selectedCard?.isDm) {
      lastMsg =
        [...chatData]
          ?.reverse()
          ?.find((item) => item?.name === clientName && item?.content?.text)
          ?.content?.text || "";
    } else {
      lastMsg = selectedCard?.text || "";
    }
    if (lastMsg) {
      const sourceValue = translationSource || source;
      const queryData = {
        text: lastMsg,
        target_language: translationTarget || target,
        source_language:
          sourceValue !== "auto_detect" ? translationSource : null,
        product_id: window.localStorage.getItem("engagementsProductId"),
      };
      EngagementsController.detectTranslate(queryData).then((res) => {
        if (!res?.errorMsg) {
          setTranslationSourceText(res?.data?.source_language);
        } else {
          setTranslationSourceText("en");
        }
      });
    } else {
      setTranslationSourceText("en");
    }
    setTranslationSourceTextLoading(false);
  };
  const replyTranslationDisabledButton = text?.trim()?.length === 0;
  const replyTranslationDisabledText =
    translateReplyStatus === "translateReply";
  const replyTranslationTextClass =
    translationText.length !== 0 || translateReplyStatus !== "translateReply";
  const checkIdAccountDm = (dataAccounts, sourceName, accountName) => {
    let filteredAccountData = [];
    if (sourceName == "FACEBOOK") {
      filteredAccountData = dataAccounts?.filter(
        (obj) =>
          obj?.attributes?.page_id == selectedCard?.agent_id &&
          obj?.attributes?.name == accountName,
      );
    } else {
      filteredAccountData = dataAccounts?.filter(
        (obj) =>
          obj?.attributes?.instagram_id == selectedCard?.agent_id &&
          obj?.attributes?.user_name == accountName,
      );
    }
    return filteredAccountData?.length > 0;
  };
  const handleAutoAssign = () => {
    const queryData = {
      id:
        selectedCard?.ai_agent?.ai_agent_id ||
        selectedCard?.user_email?.split("_")?.[2],
      conversation_id: selectedCard?.conversation_id,
      tracker_id: selectedCard?.tracker_id,
      data_source:
        selectedCard?.data_source?.toLowerCase() +
        "_" +
        selectedCard?.channel_type?.toLowerCase(),
    };
    setAutoReplyMessage(null);
    autoAssignAiAgent(queryData, {
      onSuccess: (data) => {
        if (!data?.errorMsg) {
          setRefresh();
          setOpenSnackBarAiAgent(true);
          setSnackBarDataAiAgent({
            severity: "success",
            message: "engagement_assigned_succussfully",
          });
        } else if (
          data?.errorMsg?.response?.data?.error === "Can't assign to team"
        ) {
          setOpenPopupTeamUnavailable(true);
          setAutoReplyMessage(
            data?.errorMsg?.response?.data?.auto_reply_message || "",
          );
        } else {
          setOpenSnackBarAiAgent(true);
          setSnackBarDataAiAgent({
            severity: "error",
            title: "failed_error_message",
            message: "try_again_error_message",
          });
        }
      },
    });
  };

  return (
    <Box className="engagements-reply">
      {!showSuccessModal &&
      !showErrorModal &&
      (replyData?.system_replies?.is_replied === undefined ||
        replyData?.system_replies?.is_replied === 0) ? (
        <>
          {aiAgentId ? (
            <Typography className="ai-agent-reply-container">
              <FontAwesomeIcon icon={faLock} />
              {CheckValueLocale("handled_by_ai_agent", "", {}, intl)}
            </Typography>
          ) : (
            <div className="reply-container">
              <TextField
                value={text}
                inputRef={replyInputRef}
                onChange={handleChange}
                id="original-text"
                onFocus={(event) => {
                  handleFocus(event);
                }}
                multiline
                maxRows={7}
                variant="outlined"
                placeholder={CheckValueLocale("enter_reply", "", {}, intl)}
                className="reply-textarea"
                error={textLength > maxChar}
              />
              {translateStatus ? (
                <div className="reply-translation-container">
                  {!translationSourceTextLoading ? (
                    <>
                      <div className="reply-translation-heading">
                        {translateReplyStatus === "replyTranslated" ? (
                          <p className="reply-translated-hint">
                            <FontAwesomeIcon icon={faCheckCircleRegular} />
                            {CheckValueLocale("reply_translated", "", {}, intl)}
                          </p>
                        ) : (
                          <Button
                            variant="outlined"
                            className="reply-translation-button"
                            onClick={translateReplyHandler}
                            disabled={replyTranslationDisabledButton}
                          >
                            {translationTextLoading ? (
                              <CircularProgress className="circular-progress-translation" />
                            ) : (
                              <>
                                {translateReplyStatus === "translateReply" ? (
                                  <>
                                    <img src={translateButton} />
                                    {CheckValueLocale(
                                      "translate_reply",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <img src={update} />
                                    {CheckValueLocale(
                                      "update_translation",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </>
                                )}{" "}
                                {CheckValueLocale("from_lang", "", {}, intl)} (
                                {translationTarget}){" "}
                                {CheckValueLocale("to_lang", "", {}, intl)} (
                                {isTranslationSourceAutoDetect
                                  ? translationSourceText
                                  : translationSource}
                                )
                              </>
                            )}
                          </Button>
                        )}
                        <Box
                          className={`reply-translation-close ${
                            btnLoading ? "reply-footer-icon-disabled" : ""
                          }`}
                          onClick={!btnLoading ? closeTranslationBox : null}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Box>
                      </div>
                      <TextField
                        value={translationText}
                        onChange={handleChangeTranslation}
                        onFocus={handleFocus}
                        id="translation-text"
                        multiline
                        maxRows={7}
                        variant="outlined"
                        placeholder={CheckValueLocale(
                          "translate_reply_placeholder",
                          "",
                          {},
                          intl,
                        )}
                        className={`reply-translation-textarea ${
                          replyTranslationTextClass
                            ? "reply-translated-textarea"
                            : ""
                        }`}
                        disabled={replyTranslationDisabledText}
                      />
                    </>
                  ) : (
                    <div className="translation-text-loading">
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              ) : null}
              {base64Code?.length !== 0 && (
                <div className="reply-attachments-container">
                  {base64Code?.map((base64, index) => {
                    return (
                      <div className="reply-attachment">
                        {selectedFile[index]?.type.includes("image") ? (
                          <div className="attachments-img-box">
                            <img
                              src={base64}
                              alt="Uploaded"
                              className="attachments-img"
                            />
                            {selectedFile[index]?.type === "image/gif" ? (
                              <div className="attachments-icon-box">
                                <img src={gifSolid} />
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div className="attachments-video-box">
                            <VideoFrame
                              videoUrl={base64}
                              thumbnailHandler={(thumbnail) => (
                                <img
                                  src={thumbnail}
                                  alt="Video Thumbnail"
                                  style={{ maxWidth: "100%" }}
                                />
                              )}
                              width={33}
                              height={33}
                            />
                            <div className="attachments-icon-box">
                              <FontAwesomeIcon icon={faPlay} />
                            </div>
                          </div>
                        )}
                        <div
                          className="remove-attachments-reply"
                          onClick={() => handleRemoveAttachments(index)}
                        >
                          <span className="trash-wrap">
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {mediaErr ? (
            <span className="media-err">
              {CheckValueLocale(mediaErr, "", {}, intl)}
            </span>
          ) : null}
          <Box className="reply-footer">
            <div className="reply-footer-box">
              {loading ? (
                <Box className="account-reply-loading">
                  <Skeleton
                    animation="pulse"
                    variant="circular"
                    width={34}
                    height={34}
                  />
                  <Skeleton animation="pulse" height={11} width="100px" />
                </Box>
              ) : (
                <Box className="reply-footer-dropdown">
                  {selectedValue !== "" ? (
                    iconToDisplay !== null ? (
                      <Avatar
                        src={selectedAccountImg}
                        variant="rounded"
                        className="account-reply-avater"
                      />
                    ) : (
                      <div className="account-box-avater account-default-reply">
                        <DefaultAccountImg selectedValue={selectedValue} />
                      </div>
                    )
                  ) : (
                    <div className="account-box-avater account-reply-empty"></div>
                  )}
                  <Select
                    labelId="engagements-reply-select-label"
                    id="engagements-reply-select"
                    className="engagements-reply-select"
                    value={selectedValue}
                    onChange={handleChangeDropdown}
                    displayEmpty
                    IconComponent={ArrowDropDownRoundedIcon}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return CheckValueLocale("reply_by", "", {}, intl);
                      }
                      return (
                        <Tooltip title={selectedValue} placement="top" arrow>
                          <span>{getNumberOfChar(selectedValue, intl)}</span>
                        </Tooltip>
                      );
                    }}
                    disabled={aiAgentId}
                  >
                    <MenuItem value="" disabled={true} className="reply-option">
                      <Box
                        className="no-accounts-to-reply-sla"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(
                            CheckValueLocale(
                              !getAllAccounts?.length ||
                                !accountsStatus?.includes("active")
                                ? "no_accounts"
                                : !allawedAccounts?.length
                                  ? "no_permission"
                                  : !haveAccounts
                                    ? "no_accounts"
                                    : "reply_by",
                              "",
                              {},
                              intl,
                            ),
                          ),
                        }}
                      />
                    </MenuItem>
                    {accounts?.map((account, i) => {
                      let option = getNumberOfChar(account, intl);
                      return (replyType === "dm" &&
                        ((selectedCard?.data_source === "TWITTER" &&
                          "@" + selectedCard?.agent_username === account) ||
                          checkIdAccountDm(
                            getAllAccounts,
                            selectedCard?.data_source,
                            account,
                          ))) ||
                        replyType !== "dm" ? (
                        <MenuItem
                          value={account}
                          disabled={accountsStatus[i] !== "active"}
                        >
                          <Box className="account-dropdown-list">
                            <div className="list-data-img">
                              {accountsImg[i] !== null ? (
                                <Avatar
                                  src={accountsImg[i]}
                                  variant="rounded"
                                  className="account-reply-avater"
                                />
                              ) : (
                                <div className="account-box-avater account-default-reply">
                                  <DefaultAccountImg selectedValue={option} />
                                </div>
                              )}
                              <span
                                className={`list-data-icon ${selectedCard?.data_source?.toLowerCase()}-icon`}
                              >
                                {selectedCard?.data_source === "TWITTER" ? (
                                  <img src={xPlatform} alt="x-platform-logo" />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={getDatasourceIconName(
                                      selectedCard?.data_source,
                                    )}
                                  />
                                )}
                              </span>
                            </div>
                            <div className="list-data-text">
                              <span className="list-data-option">{option}</span>
                              <span className="list-data-source">
                                {CheckValueLocale(
                                  selectedCard?.data_source,
                                  "",
                                  {},
                                  intl,
                                )?.toLowerCase()}
                              </span>
                            </div>
                          </Box>
                        </MenuItem>
                      ) : null;
                    })}
                  </Select>
                </Box>
              )}
              <Divider
                orientation="vertical"
                className="engagements-reply-divider"
              />
              <Tooltip
                title={
                  !aiAgentId
                    ? CheckValueLocale("saved_replies", "", {}, intl)
                    : null
                }
                placement="top"
                arrow
              >
                <Box
                  className={`reply-footer-icon ${
                    aiAgentId ? "reply-footer-icon-disabled" : ""
                  }`}
                  onClick={!aiAgentId ? handleClick : null}
                  id="saved-replies-btn"
                >
                  <FontAwesomeIcon
                    icon={
                      openDetailsSideBarReplay
                        ? faCommentAltLinesSolid
                        : faCommentAltLines
                    }
                  />
                </Box>
              </Tooltip>
              <Popover
                anchorOrigin={anchorPosition}
                transformOrigin={transformPosition}
                onClose={handleClose}
                open={open}
                anchorEl={anchorEl}
                className="saved-replies-popover"
              >
                {!showCreateRepliesModal ? (
                  <SavedRepliesModal
                    setText={setText}
                    setAnchorEl={setAnchorEl}
                    getAllSavedReplies={getAllSavedReplies}
                    savedReplies={savedReplies}
                    savedRepliesloader={savedRepliesloader}
                    setSavedReplyIds={setSavedReplyIds}
                    setReplyToDelete={setReplyToDelete}
                    setShowDeletePopup={setShowDeletePopup}
                    setShowCreateRepliesModal={setShowCreateRepliesModal}
                  />
                ) : (
                  <CreateRepliesModal
                    savedReplies={savedReplies}
                    setSavedReplies={setSavedReplies}
                    setShowSnackBar={setShowSnackBar}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarTitle={setSnackbarTitle}
                    setSnackbarSeverity={setSnackbarSeverity}
                    setShowCreateRepliesModal={setShowCreateRepliesModal}
                  />
                )}
              </Popover>
              <Tooltip
                title={
                  !aiAgentId
                    ? CheckValueLocale(
                        translateStatus
                          ? "turn_off_translation"
                          : "turn_on_translation",
                        "",
                        {},
                        intl,
                      )
                    : null
                }
                placement="top"
                arrow
              >
                <Box
                  id="engagemnt-footer-translation-btn"
                  className={`reply-footer-icon ${
                    btnLoading || aiAgentId ? "reply-footer-icon-disabled" : ""
                  }`}
                  onClick={!btnLoading || !aiAgentId ? translateHandler : null}
                >
                  <img
                    src={
                      translationActive && translateStatus
                        ? activeTranselation
                        : translate
                    }
                    className={`${
                      translationActive &&
                      translateStatus &&
                      "active-transelation-reply-icon"
                    }`}
                  />
                </Box>
              </Tooltip>
              <Popover
                anchorOrigin={anchorPositionTranslation}
                transformOrigin={transformPositionTranslation}
                onClose={handleCloseTranslation}
                open={openTranslation}
                anchorEl={anchorElTranslation}
              >
                <EngagementTranslation
                  translationType="reply"
                  setTranslationActive={setTranslationActive}
                  translationSource={translationSource}
                  setTranslationSource={setTranslationSource}
                  translationTarget={translationTarget}
                  setTranslationTarget={setTranslationTarget}
                  handleCloseTranslation={handleCloseTranslation}
                  stats={stats}
                  setStats={setStats}
                  languagesArraySource={languagesArraySource}
                  languagesArrayTarget={languagesArrayTarget}
                  setTranslateStatus={setTranslateStatus}
                  getSourceLanguage={getSourceLanguage}
                />
              </Popover>
              <Tooltip
                title={
                  !aiAgentId
                    ? CheckValueLocale("emoji_tooltip", "", {}, intl)
                    : null
                }
                placement="top"
                arrow
              >
                <Box
                  className={`reply-footer-icon emoji-icon ${
                    aiAgentId ? "reply-footer-icon-disabled" : ""
                  }`}
                  onClick={!aiAgentId ? handleClickEmoji : null}
                >
                  <FontAwesomeIcon icon={faGrinAlt} />
                </Box>
              </Tooltip>
              {!showAllIcon ? (
                <>
                  <Tooltip
                    title={CheckValueLocale("more", "", {}, intl)}
                    placement="top"
                    arrow
                  >
                    <Box
                      className="reply-footer-icon"
                      onClick={handleViewMoreIconsClick}
                    >
                      <FontAwesomeIcon icon={faEllipsis} />
                    </Box>
                  </Tooltip>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={viewMoreIconsEl}
                    open={isViewMoreIconsOpen}
                    onClose={handleViewMoreIconsClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: 53,
                      },
                      classes: {
                        root: "replay-footer-veiw-more-icon-root",
                      },
                    }}
                  >
                    <IconReplyEllipsis
                      attachments={attachments}
                      handleAttachmentsUpload={handleAttachmentsUpload}
                      disabledStyleFirst={disabledStyleFirst}
                      disabledCondition={disabledCondition}
                      mediaLength={mediaLength}
                      inputRefGif={inputRefGif}
                      inputRefVideo={inputRefVideo}
                      inputRefImg={inputRefImg}
                      handleClickEmoji={handleClickEmoji}
                      aiAgentId={aiAgentId}
                      fromDropdown={true}
                    />
                  </Menu>
                </>
              ) : (
                <>
                  <Divider
                    orientation="vertical"
                    className="engagements-reply-divider"
                  />
                  <IconReplyEllipsis
                    attachments={attachments}
                    handleAttachmentsUpload={handleAttachmentsUpload}
                    disabledStyleFirst={disabledStyleFirst}
                    disabledCondition={disabledCondition}
                    mediaLength={mediaLength}
                    inputRefGif={inputRefGif}
                    inputRefVideo={inputRefVideo}
                    inputRefImg={inputRefImg}
                    handleClickEmoji={handleClickEmoji}
                    aiAgentId={aiAgentId}
                  />
                </>
              )}
              <Popover
                anchorOrigin={emojyAnchorOrigin}
                transformOrigin={emojyTransformOrigin}
                onClose={handleCloseEmoji}
                open={openEmoji}
                anchorEl={anchorElEmoji}
                classes={{
                  paper: "reply-box-emoji-popover",
                  root: "reply-box-emoji-popover-root",
                }}
              >
                <Picker
                  data={data}
                  onEmojiSelect={(e) => emojiSelect(e?.native)}
                />
              </Popover>
            </div>
            {!aiAgentId ? (
              <div className="reply-footer-box">
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={`reply-word-breadcrumbs ${breadCrumbsStyle}`}
                >
                  <Link color="inherit">{textLength}</Link>
                  <Link color="inherit">{maxChar}</Link>
                </Breadcrumbs>
                <LucButton
                  disabled={replyBtnStyle}
                  loading={btnLoading}
                  onClick={handleReply}
                  size="small"
                  id="engagements-send-reply"
                  className="engagements-send-reply"
                  endIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
                >
                  {CheckValueLocale(
                    translateStatus ? "send_translation" : "send",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              </div>
            ) : (
              <div className="reply-footer-box-ai-agent">
                <LucButton
                  size="small"
                  variant="outline"
                  id="engagements-ai-agent-auto-assign"
                  onClick={handleAutoAssign}
                  loading={autoAssignAiAgentLoading}
                  disabled={autoAssignAiAgentLoading}
                >
                  {CheckValueLocale("auto_assign", "", {}, intl)}
                </LucButton>
                <LucButton
                  size="small"
                  id="engagements-ai-agent-assign-human"
                  onClick={() => setOpenPopupAssignHuman(true)}
                >
                  {CheckValueLocale("assign_to_human", "", {}, intl)}
                </LucButton>
              </div>
            )}
          </Box>
        </>
      ) : showSuccessCondition ? (
        successModal()
      ) : (
        errorModal()
      )}
      {showDeletePopup ? (
        <PopupModal
          title={CheckValueLocale("delete_saved_replies", "", {}, intl)}
          body={deleteModalBody()}
          leftBtn={CheckValueLocale("cancel_btn", "", {}, intl)}
          rightBtn={CheckValueLocale("delete_btn", "", {}, intl)}
          open={showDeletePopup}
          close={handleCloseDeleteModal}
          accept={() => handleDeleteSavedReplies()}
          rightColor="error"
          warning
          leftBtnId="saved-replies-pop-up-cancel-left-btn"
          rightBtnId="saved-replies-pop-up-delete-right-btn"
        />
      ) : null}
      {openPopupAssignHuman ? (
        <AssignHumanAiAgentModal
          selectedCard={selectedCard}
          openPopupAssignHuman={openPopupAssignHuman}
          setOpenPopupAssignHuman={setOpenPopupAssignHuman}
          setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
          setSnackBarDataAiAgent={setSnackBarDataAiAgent}
          setInjectedMessage={setInjectedMessage}
          aiAgentDetails={aiAgentDetails}
          setRefresh={setRefresh}
          chatData={chatData}
        />
      ) : null}
      {openPopupTeamUnavailable && autoReplyMessage !== null ? (
        <TeamUnavailableAiAgentModal
          selectedCard={selectedCard}
          openPopupTeamUnavailable={openPopupTeamUnavailable}
          setOpenPopupTeamUnavailable={setOpenPopupTeamUnavailable}
          setRefresh={setRefresh}
          setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
          setSnackBarDataAiAgent={setSnackBarDataAiAgent}
          chatData={chatData}
          autoReplyMessage={autoReplyMessage}
        />
      ) : null}
      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          autoHideDuration={5000}
          severity={snackBarSeverity}
          message={CheckValueLocale(
            snackBarMessage,
            "",
            { time: limitRateDate },
            intl,
          )}
          title={CheckValueLocale(snackBarTitle, "", injectedWord, intl)}
          handleClose={handleCloseSnack}
        />
      )}
    </Box>
  );
};

export default Reply;
