import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import KnowledgeBaseSwaggerImage from "images/ai-agents/knowledge-base-swagger.svg";
import KnowledgeBaseDocsImage from "images/ai-agents/knowledge-base-docs.svg";
import KnowledgeBasePDFImage from "images/ai-agents/pdf-knowledge-base.svg";
import { useIntl } from "react-intl";
import {
  CheckValueLocale,
  getKnowledgeBaseFileSizeInKBOrMB,
} from "utils/helpers";

const uploadedFileImageMap = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    KnowledgeBaseDocsImage,
  "application/msword": KnowledgeBaseDocsImage,
  "application/pdf": KnowledgeBasePDFImage,
  "application/json": KnowledgeBaseSwaggerImage,
  "application/yaml": KnowledgeBaseSwaggerImage,
};

const AddKnowledgeBaseFileItem = ({ file, handleDelete, index, progress }) => {
  const intl = useIntl();

  return (
    <Box>
      <Box className="uploaded-resource-file-wrapper">
        <Box className="uploaded-resource-file-info-wrapper">
          <img
            className="uploaded-resource-file-image"
            src={uploadedFileImageMap?.[file?.type] || KnowledgeBaseDocsImage}
          />
          <Box className="uploaded-resource-file-info-column">
            <Typography component="h6" className="uploaded-resource-file-name">
              {file?.name}
            </Typography>
            <Typography component="h6" className="uploaded-resource-file-size">
              {CheckValueLocale(
                "knowledge_base_file_size",
                "",
                {
                  size: getKnowledgeBaseFileSizeInKBOrMB(file?.size),
                },
                intl,
              )}
            </Typography>
          </Box>
        </Box>
        <Box className="uploaded-resource-file-progress-bar-wrapper">
          <Box className="files-progress-bar-wrapper">
            <Box className="files-progress-bar-container">
              <LinearProgress
                value={progress}
                variant="determinate"
                className="files-progress-bar"
              />
            </Box>
          </Box>

          <Tooltip
            title={CheckValueLocale("delete_file", "", {}, intl)}
            className="uploaded-files-delete-icon-wrapper"
            onClick={() => handleDelete(index)}
            arrow
            placement="top"
          >
            <IconButton size="small" color="warning">
              <FontAwesomeIcon
                className="uploaded-files-delete-icon"
                icon={faXmark}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider orientation="horizontal" className="uploaded-files-divider" />
    </Box>
  );
};

export default AddKnowledgeBaseFileItem;
