import React, { useEffect, useState } from "react";
import BarChartWidget from "components/widgets/barChartWidget";
import {
  handleAverageEngagementStatsData,
  handleCustomerCareStatsData,
  handleFollowersGrowthStatsData,
} from "../../responseHandling/statsDataHandling";

const CustomDashboardBarChart = (props) => {
  const {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    widgetsMqttResponses,
    customizedWidgets,
    finalWidgetData,
    dashboardInfo,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    allMonitorsIdWidget,
    isComparison,
    customizedType,
    showControl,
  } = props;

  const [widgetFlags, setWidgetFlags] = useState({});
  const [statsDetails, setStatsDetails] = useState([]);

  useEffect(() => {
    if (widget && finalWidgetData) {
      switch (widget?.widget_name) {
        case "comments_customer_care_performance":
        case "customer_care_performance":
          setWidgetFlags({
            showAI: true,
            customerCareChart: true,
            showChartDetailsWidget: false,
          });
          if (isComparison) {
            setStatsDetails(finalWidgetData?.stats_data);
          } else {
            const customerCareStatsData =
              handleCustomerCareStatsData(finalWidgetData);
            setStatsDetails(customerCareStatsData);
          }

          break;

        case "followers_growth":
        case "posts_followers_growth":
        case "follower_growth_overtime":
          setWidgetFlags({
            showGroupBy: true,
            customerCareChart: false,
            showChartDetailsWidget: true,
          });
          if (isComparison) {
            setStatsDetails(finalWidgetData?.stats_data);
          } else {
            const followersGrowthStatsData =
              handleFollowersGrowthStatsData(finalWidgetData);
            setStatsDetails(followersGrowthStatsData);
          }
          break;

        case "average_engagements_per_post":
        case "posts_average_engagement_per_posts":
          setWidgetFlags({
            showGroupBy: true,
            customerCareChart: false,
            showChartDetailsWidget: true,
          });

          if (isComparison) {
            setStatsDetails(finalWidgetData?.stats_data);
          } else {
            const averageEngagementsStatsData =
              handleAverageEngagementStatsData(finalWidgetData);
            setStatsDetails(averageEngagementsStatsData);
          }
          break;

        default:
          break;
      }
    }
  }, [widget, finalWidgetData, isComparison]);

  return (
    <BarChartWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={widgetTitleTooltip || ""}
      data={finalWidgetData?.data || []}
      showChartDetailsWidget={
        !isComparison && !isControlWidget && widgetFlags?.showChartDetailsWidget
      }
      statsDetailsData={statsDetails || []}
      dataColor="#003D59"
      toolTipLabel={"posts"}
      showZoomPercentage
      showGroupBy={
        !isControlWidget && !isComparison && widgetFlags?.showGroupBy
      }
      showAI={!isControlWidget && widgetFlags?.showAI}
      showChartStatsWidget={
        !isComparison && !isControlWidget && widgetFlags?.customerCareChart
      }
      customHeight={
        !isComparison && !isControlWidget
          ? "565px"
          : !widgetFlags?.customerCareChart
            ? "446px"
            : "456px"
      }
      customerCareChart={widgetFlags?.customerCareChart}
      respTimeDistributionTooltip="respone_time_distribution_tooltip"
      statsData={statsDetails || []}
      showFollowersGrowthStats
      showDownloadIcon={!isControlWidget}
      bigImg
      isTwitterAccount={
        widget?.monitor_type?.name === "ACCOUNT_ANALYSIS" && true
      }
      barChartPreLoaderDataSources={finalWidgetData?.preLoaderMonitorsId || []}
      preLoaderTrackerDataSources={allMonitorsIdWidget || []}
      showLegend={false}
      isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
      isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
      // The needed data in widget component for case of Custom Dashboard only
      customDashboardData={{
        dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
        handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
        widget: widget, // the widget metric returned from backend in view API
        customizedType: customizedType, // null, aggregate, comparison
        widgetsMqttResponses, // the main state for RabbitMQ responses
        customizedWidgets, // cuztomized widget state for RabbitMQ responses
        getCustomDashboardView, // call back view widgets after any update in page
        setSnackBarData, // set snackbar related to main index view
        showControl, // show widget control icon
      }}
      isComparison={isComparison}
    />
  );
};

export default CustomDashboardBarChart;
