import { Box, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

export const TONE_OF_VOICE_VALUES = {
  formal: "Formal: Uses formal language with a serious and professional tone.",
  semi_formal: "Semi-Formal: Uses informal language in a professional manner.",
  informal: "Informal: Uses casual language with a friendly tone.",
};

const ToneOfVoiceOption = ({ value, selected, onClick, intl }) => (
  <Grid item xs={4}>
    <Box
      onClick={onClick}
      className={`ai-agent-response-length-item-wrapper tone-of-voice-wrapper ${selected ? "selected" : ""}`}
    >
      <Typography className="ai-agent-response-length-item-title">
        {CheckValueLocale(value, "", {}, intl)}
      </Typography>
      <Typography className="ai-agent-response-length-item-description">
        {CheckValueLocale(
          `ai_agent_formal_tone_of_voice_${value}_description`,
          "",
          {},
          intl,
        )}
      </Typography>
    </Box>
  </Grid>
);

const AgentIdentityToneOfVoiceStyle = ({
  formik,
  customHandleChange,
  isFormDisabled,
}) => {
  const intl = useIntl();
  const toneOfVoiceValue = formik?.values?.response_style;
  const responseStyleError = formik?.errors?.response_style;

  const handleChange = (value) => {
    if (isFormDisabled) return;
    if (
      value === TONE_OF_VOICE_VALUES?.formal ||
      TONE_OF_VOICE_VALUES?.semi_formal
    ) {
      customHandleChange("emoji_usage", "no_emoji");
    }
    customHandleChange("response_style", value);
  };

  return (
    <>
      <Grid className={`${responseStyleError ? "Mui-error" : ""}`} item xs={5}>
        <Box className="ai-agents-form-box-description" component="p">
          {CheckValueLocale(
            "ai_agent_tone_of_voice_response_description",
            "",
            {},
            intl,
          )}
          <Box component="span" className="red-star-req">
            {` *`}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Grid alignItems="stretch" container spacing="9px">
          {Object.keys(TONE_OF_VOICE_VALUES || {})?.map((key) => (
            <ToneOfVoiceOption
              key={key}
              value={key}
              selected={toneOfVoiceValue === TONE_OF_VOICE_VALUES[key]}
              onClick={() => handleChange(TONE_OF_VOICE_VALUES[key])}
              intl={intl}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          {responseStyleError ? (
            <Box component="span" className="ai-agents-form-helper-text">
              {CheckValueLocale(responseStyleError, "", {}, intl)}
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default AgentIdentityToneOfVoiceStyle;
