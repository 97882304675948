import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import "./main.scss";
import EngagementsSideBar from "./components/sideBar/engagementsSideBar";
import EngagementsDetails from "./components/details/engagementsDetails";
import EngagementPublish from "./components/publish";
import PopupModal from "components/popupModal";
import { CheckValueLocale, isEmptyObj, handlelUserRoles } from "utils/helpers";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSparkles,
  faUser,
  faBookmark,
  faCheck,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import SnackBar from "components/snackBar.js";
import NewConversationPopup from "./components/newConversation/newConversationPopup/newConversationPopup";
import NewConversationContent from "./components/newConversation/newConversationPopup/newConversationContent";
import EngagementsController from "services/controllers/engagementsController";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import MonitorsController from "services/controllers/monitorsController";
import AlertController from "services/controllers/alertController";
import { useLocation } from "react-router";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AiAgentHeaderAlerts from "./components/aiAgents/component/aiAgentHeaderAlerts/aiAgentHeaderAlerts";
const Engagements = () => {
  const history = useHistory();
  const reduxAiAgent = useSelector((state) => state?.engagements?.aiAgent);
  const haveOneProduct = !handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS")
    ? (handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
        !handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH")) ||
      (handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") &&
        !handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX"))
    : false;
  const intl = useIntl();
  const location = useLocation();
  const [isSLASideBarOpen, setIsSLASideBarOpen] = useState(true);
  //RabbitMQ configration
  const clientMQTT = useRef();
  const mqttConnectionClosed = useRef(false);

  const [selectedCard, setSelectedCard] = useState({});
  const [cardState, setCardState] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [tabValue, setTabValue] = useState(reduxAiAgent ? 0 : 1);
  const [complete, setComplete] = useState(0);
  const [dates, setDates] = useState({
    endDate: moment().tz("utc", true).unix(),
    startDate: moment().tz("utc", true).subtract(6, "d").startOf("day").unix(),
  });
  const [totalEngagements, setTotalEngagements] = useState({});
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);
  const [navigationMenuStatus, setNavigationMenuStatus] = useState(
    haveOneProduct
      ? handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")
        ? "inbox"
        : "publish"
      : location?.state?.defaultPublish
        ? "publish"
        : "inbox",
  );
  const [openSetupFlow, setOpenSetupFlow] = useState(false);
  const [openSetupFlowFirst, setOpenSetupFlowFirst] = useState(false);
  const [setupDone, setSetupDone] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isDoneSetup, setIsDoneSetup] = useState(true);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [checkAiSuggestions, setCheckAiSuggestions] = useState(false);
  const [openNewConversation, setOpenNewConversation] = useState(false);
  const [convAuthAccounts, setConvAuthAccounts] = useState([]);
  const [convAuthAllawedAccounts, setConvAuthAllawedAccounts] = useState([]);

  const [senderAccountSelect, setSenderAccountSelect] = useState("");

  const [selectedFile, setSelectedFile] = useState([]);
  const [base64Code, setBase64Code] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [text, setText] = useState("");
  const [mediaFile, setMediaFile] = useState([]);
  const [contentSelected, setContentSelected] = useState(false);
  const [contentErrormedia, setContentErrorMedia] = useState("");
  const [accountsPublishSelected, setAccountsPublishSelected] = useState({});
  const [twLimitMedia, setTwLimitMedia] = useState({});
  const [fbLimitMedia, setFbLimitMedia] = useState({});
  const [igLimitMedia, setIGLimitMedia] = useState({});
  const [recevierAccountSelect, setRecevierAccountSelect] = useState("");
  const [recevierItem, setRecevierItem] = useState({});
  const [receiverAccountPage, setReceiverAccountPage] = useState(1);
  const [receiverAccountLoading, setReceiverAccountLoading] = useState(false);
  const [receiverAccountFoucs, setReceiverAccountFoucs] = useState(false);
  const [caretHidden, setCaretHidden] = useState(false);
  const [stopPagination, setStopPagination] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [receiverAccountList, setReceiverAccountList] = useState([]);
  const [response, setResponse] = useState({});
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPostsLoading, setIsPostsLoading] = useState(false);
  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);
  const [contentSelectedArr, setContentSelectedArr] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState([]);
  const [openSidebarToAssignTeam, setOpenSidebarToAssignTeam] = useState(false);
  const [aiAgentList, setAiAgentList] = useState([]);
  const [snackBarData, setSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [injectedMessage, setInjectedMessage] = useState({
    message: {},
    title: {},
  });

  const [isAiAgentDown, setIsAiAgentDown] = useState(false);
  const [isDMLimitReached, setIsDMLimitReached] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [aiAgentDMReachDetails, setAiAgentDMReachDetails] = useState(null);

  const handleCloseAiAgentDownAlert = () => setIsAiAgentDown(false);

  const handleCloseDMLimitReached = () => setIsDMLimitReached(false);

  const handleCloseLimitReached = () => setIsLimitReached(false);

  const alertTypes = [
    {
      alertType: "aiAgentDown",
      handleCloseHeaderAlert: handleCloseAiAgentDownAlert,
      active: isAiAgentDown,
    },
    {
      alertType: "dMLimitReached",
      handleCloseHeaderAlert: handleCloseDMLimitReached,
      active: isDMLimitReached,
      aiAgentDMReachDetails: aiAgentDMReachDetails,
    },
    {
      alertType: "limitReached",
      handleCloseHeaderAlert: handleCloseLimitReached,
      active: isLimitReached,
    },
  ];

  useEffect(() => {
    if (aiAgentList?.length) {
      aiAgentList?.map((aiAgent) => {
        if (aiAgent?.status === "down") setIsAiAgentDown(true);
        if (aiAgent?.quota_limit) setIsLimitReached(true);
        if (aiAgent?.x_rate_limit) {
          setIsDMLimitReached(true);
          setAiAgentDMReachDetails({
            x_reset_time: aiAgent?.x_reset_time,
          });
        }
      });
    }
  }, [aiAgentList]);

  const alertTypesLenght = alertTypes?.filter((alert) => alert?.active)?.length;

  // check if engag Setup is Done and if true we display snakBar and display loader from figma
  // and if the user have access for INBOX we call getAllawedAccounts and getAllDMAuthrizedAccounts and
  // getDMMediaValidations (for dataSources valaditions) apis to be used for conversation DM.

  useEffect(() => {
    if (window.localStorage.engagSetupDone == "true") {
      localStorage?.setItem("engagSetupDone", false);
      setSetupDone(true);
      setTimeout(() => {
        setSetupDone(false);
        setOpenSnackBar(true);
      }, 7000);
      setTimeout(() => {
        setOpenSnackBar(false);
      }, 14000);
    } else {
      setSetupDone(false);
    }
    if (
      handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
      handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")
    ) {
      MonitorsController.getAllawedAccounts(
        window.localStorage.engagements_id,
        "reply",
      ).then((AllawedData) => {
        let allawedAccount = AllawedData?.data?.data?.user_accounts;

        EngagementsController.getAllDMAuthrizedAccounts(
          window?.localStorage?.engagements_id,
        ).then((data) => {
          let accountsArr = [];
          let allaweArr = [];
          data?.data?.data?.TWITTER?.data?.map((item) => {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_source: "TWITTER",
                account_error:
                  item?.attributes?.status == "Re-authenticate"
                    ? ["TWITTER"]
                    : [],
              },
            });
            if (allawedAccount["TWITTER"]?.includes(+item?.id)) {
              allaweArr.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_source: "TWITTER",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["TWITTER"]
                      : [],
                },
              });
            }
          });
          data?.data?.data?.INSTAGRAM?.included?.map((item) => {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_source: "INSTAGRAM",
                account_error:
                  item?.attributes?.status == "Re-authenticate"
                    ? ["INSTAGRAM"]
                    : [],
              },
            });
            if (allawedAccount["INSTAGRAM"]?.includes(+item?.id)) {
              allaweArr.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_source: "INSTAGRAM",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["INSTAGRAM"]
                      : [],
                },
              });
            }
          });
          data?.data?.data?.FACEBOOK?.included?.map((item) => {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_source: "FACEBOOK",
                account_error:
                  item?.attributes?.status == "Re-authenticate"
                    ? ["FACEBOOK"]
                    : [],
              },
            });
            if (allawedAccount["FACEBOOK"]?.includes(+item?.id)) {
              allaweArr.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_source: "FACEBOOK",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["FACEBOOK"]
                      : [],
                },
              });
            }
          });
          setConvAuthAllawedAccounts([...allaweArr]);
          setConvAuthAccounts([...accountsArr]);
        });
      });

      getDMValidationsMedia();
    }
  }, []);

  const handleCloseSnackBar = () => {
    setOpenSnackBarDelete(false);
    setSnackBarData({
      severity: "",
      message: "",
      title: "",
    });
    setInjectedMessage({
      message: {},
      title: {},
    });
  };

  let engagementsProduct = JSON.parse(
    window?.localStorage?.getItem("activeProducts"),
  );

  let engagementsProductId = engagementsProduct?.filter(
    (item) => item?.name == "ENGAGEMENTS" && item?.active,
  );
  if (engagementsProductId.length) {
    if (engagementsProductId[0]?.active) {
      window.localStorage.setItem(
        "engagementsProductId",
        engagementsProductId[0]?.id,
      );
    } else {
      window.location.pathname = "/social/monitor_list";
    }
  } else {
    window.location.pathname = "/social/monitor_list";
  }

  //get api DM validations
  const getDMValidationsMedia = () => {
    EngagementsController.getDMMediaValidations(
      window?.localStorage?.engagements_id,
    ).then((data) => {
      setTwLimitMedia(data?.data?.TWITTER);
      setFbLimitMedia(data?.data?.FACEBOOK);
      setIGLimitMedia(data?.data?.INSTAGRAM);
    });
  };

  const handleCloseNewConversation = () => {
    setOpenNewConversation(false);
    setAccountsPublishSelected({});
    setText("");
    setRecevierAccountSelect("");
    setRecevierItem({});
    setReceiverAccountList([]);
    setAttachments([]);
    setSelectedFile([]);
    setBase64Code([]);
    setReceiverAccountLoading(false);
  };

  //Get user List when search
  const getUserList = (page) => {
    setCaretHidden(false);
    setReceiverAccountLoading(true);
    EngagementsController.getSenderUserList(
      window?.localStorage?.engagements_id,
      accountsPublishSelected?.data_source?.toLowerCase(),
      page,
      recevierAccountSelect,
    ).then((data) => {
      if (!isEmptyObj(data?.data)) {
        // connectWithRabbitMQ(
        //   mqttConnectionClosed,
        //   clientMQTT,
        //   data?.data,
        //   setResponse
        // );
        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else {
        mqttConnectionClosed.current = true;
      }
    });
  };
  //Here we reset selected card from engagement list ,when navigate between publish or inbox or conversitions anlytics.
  useEffect(() => {
    setSelectedCard({});
  }, [navigationMenuStatus]);

  useEffect(() => {
    switch (response?.eventName) {
      case "Twitter__EngagementsUsersListDataPage":
      case "Instagram__EngagementsUsersListDataPage":
      case "Facebook__EngagementsUsersListDataPage":
        setReceiverAccountList(response?.eventData?.UsersList_data);
        setReceiverAccountLoading(false);
        if (receiverAccountPage == 1) {
          setReceiverAccountList(response?.eventData?.UsersList_data);
          response?.eventData?.UsersList_data?.length
            ? setIsListEmpty([])
            : setIsListEmpty([true]);
        } else {
          if (response?.eventData?.UsersList_data.length) {
            setReceiverAccountList([
              ...receiverAccountList,
              ...response?.eventData?.UsersList_data,
            ]);
            setIsListEmpty([]);
          } else {
            setStopPagination(true);
          }
        }
        // setReceiverAccountFoucs(true);
        setContentSelectedArr(true);
        setLoadMore(false);
        break;

      default:
        break;
    }
  }, [response]);

  const newConversationBody = (
    <NewConversationContent
      popupTitle="new_conversation_head"
      setOpen={setOpenNewConversation}
      handleClose={handleCloseNewConversation}
      openNewConversation={openNewConversation}
      convAuthAccounts={convAuthAccounts}
      convAuthAllawedAccounts={convAuthAllawedAccounts}
      setSenderAccountSelect={setSenderAccountSelect}
      senderAccountSelect={senderAccountSelect}
      setContentErrorMedia={setContentErrorMedia}
      contentErrormedia={contentErrormedia}
      setSelectedFile={setSelectedFile}
      selectedFile={selectedFile}
      setBase64Code={setBase64Code}
      base64Code={base64Code}
      setAttachments={setAttachments}
      attachments={attachments}
      text={text}
      setText={setText}
      setMediaFile={setMediaFile}
      mediaFile={mediaFile}
      setAccountsPublishSelected={setAccountsPublishSelected}
      accountsPublishSelected={accountsPublishSelected}
      setContentSelected={setContentSelected}
      contentSelected={contentSelected}
      twLimitMedia={twLimitMedia}
      igLimitMedia={igLimitMedia}
      fbLimitMedia={fbLimitMedia}
      setRecevierAccountSelect={setRecevierAccountSelect}
      recevierAccountSelect={recevierAccountSelect}
      getUserList={getUserList}
      setReceiverAccountList={setReceiverAccountList}
      receiverAccountList={receiverAccountList}
      receiverAccountLoading={receiverAccountLoading}
      setReceiverAccountFoucs={setReceiverAccountFoucs}
      receiverAccountFoucs={receiverAccountFoucs}
      setCaretHidden={setCaretHidden}
      caretHidden={caretHidden}
      setStopPagination={setStopPagination}
      stopPagination={stopPagination}
      setLoadMore={setLoadMore}
      loadMore={loadMore}
      setReceiverAccountPage={setReceiverAccountPage}
      receiverAccountPage={receiverAccountPage}
      setSendBtnDisabled={setSendBtnDisabled}
      sendBtnDisabled={sendBtnDisabled}
      setLoading={setLoading}
      loading={loading}
      setRecevierItem={setRecevierItem}
      recevierItem={recevierItem}
      setSnackBarData={setSnackBarData}
      snackBarData={snackBarData}
      setIsPostsLoading={setIsPostsLoading}
      isPostsLoading={isPostsLoading}
      setOpenSnackBarDelete={setOpenSnackBarDelete}
      setContentSelectedArr={setContentSelectedArr}
      contentSelectedArr={contentSelectedArr}
      setIsListEmpty={setIsListEmpty}
      isListEmpty={isListEmpty}
    />
  );

  useEffect(() => {
    if (history?.location?.pathname) {
      let pathName = history?.location?.pathname;
      // Remove the last letter "/" from the path to avoid multiple conditions
      if (pathName?.endsWith("/")) pathName = pathName?.slice(0, -1);

      const hasInbox =
        handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX");

      const hasPublish =
        handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH");

      // pathName: "/engagements/main"
      if (pathName === "/engagements/main") {
        if (navigationMenuStatus === "publish") {
          history?.push({
            ...history?.location,
            pathname: "/engagements/main/publish",
          });
        } else if (navigationMenuStatus === "inbox") {
          history?.push({
            ...history?.location,
            pathname: "/engagements/main/inbox",
          });
        }
      }
      // pathName: "/engagements/main/inbox"
      else if (pathName === "/engagements/main/inbox") {
        if (hasInbox) {
          if (navigationMenuStatus !== "inbox")
            setNavigationMenuStatus("inbox");
        } else {
          history?.push({
            ...history?.location,
            pathname: "/engagements/main/publish",
          });
        }
      }
      // pathName:  "/engagements/main/publish"
      else if (pathName === "/engagements/main/publish") {
        if (hasPublish) {
          if (navigationMenuStatus !== "publish")
            setNavigationMenuStatus("publish");
        } else {
          history?.push({
            ...history?.location,
            pathname: "/engagements/main/inbox",
          });
        }
      }
      // Invalid Route Redirection if has (Inbox or Publish)
      else {
        if (hasInbox && navigationMenuStatus === "inbox") {
          history?.push({
            ...history?.location,
            pathname: "/engagements/main/inbox",
          });
        } else if (hasPublish && navigationMenuStatus === "publish") {
          history?.push({
            ...history?.location,
            pathname: "/engagements/main/publish",
          });
        }
      }
    }
  }, [history?.location?.pathname, navigationMenuStatus]);

  useEffect(() => {
    if (reduxAiAgent) {
      setOpenSnackBarDelete(true);
      setSnackBarData({
        severity: "info",
        message: "ai_agent_filtered_name",
      });
    }
  }, [reduxAiAgent]);

  const cardUserEmail = selectedCard?.user_email;
  const cardAiAgentId = selectedCard?.ai_agent?.ai_agent_id;
  const cardTeamId = selectedCard?.assigning_info?.team_id;

  const aiAgentId =
    cardAiAgentId && cardTeamId === -5
      ? cardAiAgentId
      : !selectedCard?.ai_agent &&
          cardUserEmail?.includes("Ai_Agent") &&
          cardTeamId === -5
        ? cardUserEmail?.split("_")?.[2]
        : null;

  return (
    <>
      {setupDone ? (
        <Box className="main-setup-loader">
          <FontAwesomeIcon icon={faSpinner} spin />
          {CheckValueLocale("prep_inbox", "", {}, intl)}
          <Box className="main-setup-praf">
            {CheckValueLocale("prag_inbox", "", {}, intl)}
          </Box>
        </Box>
      ) : navigationMenuStatus === "inbox" ? (
        <>
          <Box className="ai-agent-alert-wrapper">
            {alertTypes?.map(({ active, ...rest }) =>
              active ? <AiAgentHeaderAlerts {...rest} /> : null,
            )}
          </Box>
          <Box
            className={`engagements-wrapper engagements-wrapper-reduced-height-${alertTypesLenght}`}
          >
            <EngagementsSideBar
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              cardState={cardState}
              setCardState={setCardState}
              refresh={refresh}
              openNavigationMenu={openNavigationMenu}
              setOpenNavigationMenu={setOpenNavigationMenu}
              navigationMenuStatus={navigationMenuStatus}
              setNavigationMenuStatus={setNavigationMenuStatus}
              openSetupFlow={openSetupFlow}
              setOpenSetupFlow={setOpenSetupFlow}
              openSetupFlowFirst={openSetupFlowFirst}
              setOpenSetupFlowFirst={setOpenSetupFlowFirst}
              isDoneSetup={isDoneSetup}
              setIsDoneSetup={setIsDoneSetup}
              tabValue={tabValue}
              setTabValue={setTabValue}
              complate={complete}
              setOpenNewConversation={setOpenNewConversation}
              openNewConversation={openNewConversation}
              totalEngagements={totalEngagements}
              dates={dates}
              setDates={setDates}
              isSLASideBarOpen={isSLASideBarOpen}
              setIsSLASideBarOpen={setIsSLASideBarOpen}
              setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
              aiAgentList={aiAgentList}
              setAiAgentList={setAiAgentList}
              reduxAiAgent={reduxAiAgent}
              setIsAiAgentDown={setIsAiAgentDown}
              setIsLimitReached={setIsLimitReached}
              setIsDMLimitReached={setIsDMLimitReached}
              setAiAgentDMReachDetails={setAiAgentDMReachDetails}
            />
            <EngagementsDetails
              selectedCard={selectedCard}
              cardState={cardState}
              setCardState={setCardState}
              tabValue={tabValue}
              setTabValue={setTabValue}
              setRefresh={() => {
                setRefresh(refresh + 1);
              }}
              setComplete={setComplete}
              openSidebar={openSidebar}
              setOpenSidebar={setOpenSidebar}
              checkAiSuggestions={checkAiSuggestions}
              setCheckAiSuggestions={setCheckAiSuggestions}
              setTotalEngagements={setTotalEngagements}
              isSLASideBarOpen={isSLASideBarOpen}
              openSidebarToAssignTeam={openSidebarToAssignTeam}
              setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
              aiAgentList={aiAgentList}
              setOpenSnackBarAiAgent={setOpenSnackBarDelete}
              setSnackBarDataAiAgent={setSnackBarData}
              setInjectedMessage={setInjectedMessage}
              aiAgentId={aiAgentId}
            />
            {openNewConversation ? (
              <Box
                className="pop-up-wrapper-publish"
                onClick={handleCloseNewConversation}
              ></Box>
            ) : null}

            {openNewConversation ? (
              <NewConversationPopup popupBody={newConversationBody} />
            ) : null}
          </Box>
        </>
      ) : (
        <EngagementPublish
          openNavigationMenu={openNavigationMenu}
          setOpenNavigationMenu={setOpenNavigationMenu}
          navigationMenuStatus={navigationMenuStatus}
          setNavigationMenuStatus={setNavigationMenuStatus}
          openSetupFlow={openSetupFlow}
          setOpenSetupFlow={setOpenSetupFlow}
          openSetupFlowFirst={openSetupFlowFirst}
          setOpenSetupFlowFirst={setOpenSetupFlowFirst}
          isDoneSetup={isDoneSetup}
          setIsDoneSetup={setIsDoneSetup}
        />
      )}
      <SnackBar
        open={openSnackBar}
        severity={"success"}
        message={CheckValueLocale("setup_success", "", {}, intl)}
      />
      <SnackBar
        open={openSnackBarDelete}
        handleClose={handleCloseSnackBar}
        title={CheckValueLocale(
          snackBarData?.title,
          "",
          injectedMessage?.title,
          intl,
        )}
        severity={snackBarData?.severity}
        message={CheckValueLocale(
          snackBarData?.message,
          "",
          injectedMessage?.message,
          intl,
        )}
      />
    </>
  );
};

export default Engagements;
