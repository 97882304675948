// style
import "../Media.scss";
// Sub Component
import MediaTwItem from "./mediaTwItem";
import { useIntl } from "react-intl";
import WidgetHeader from "components/widgets/widgetHeader";
import { isEmptyArray } from "utils/helpers";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import { Box } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import NoDataFound from "components/no-Data/noDataFound";
import MediaTik from "./mediaTik";

const MediaTw = (props) => {
  const intl = useIntl();
  return (
    <div className="chart_media">
      <WidgetHeader
        title={props.title}
        showDownloadIcon={props.showDownloadIcon}
        showNotAppliedFilter={props?.showNotAppliedFilter}
        iconAfterTitle={props.iconAfterTitle}
        titleToolTip={props.titleToolTip}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
      />
      {_.isEqual(
        props.mediaTwPreLoaderDataSources?.sort(),
        props.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <Box>
          <FetchingData />
        </Box>
      ) : isEmptyArray(props.data) ? (
        <NoDataFound />
      ) : (
        <div className="topmedia-wrapper">
          {props?.data?.map((media, index) =>
            props?.isTiktok ? (
              <MediaTik key={index} media={media} />
            ) : (
              <MediaTwItem key={index} media={media} />
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default MediaTw;
