import {
  Card,
  Stack,
  Button,
  Grid,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { checkProgressExceeded, getPercentage } from "utils/helpers";
import Snackbar from "components/snackBar";
import { useState } from "react";
import CompaniesController from "services/controllers/companiesController";
import {
  commasAfterDigit,
  CheckValueLocale,
  handlelUserRoles,
} from "utils/helpers";
import { useIntl } from "react-intl";
import "./subscription.scss";
import LucButton from "shared/lucButton/lucButton";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 420,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme?.palette?.grey[theme?.palette?.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme?.palette?.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const AiAgentPackage = (props) => {
  const {
    success,
    aiAgentInfo,
    setSuccess,
    sendPackageUpgrade,
    isLoadingUpgrade,
  } = props;
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [aiAgentRequest, setAiAgentRequest] = useState(null);
  const [aiAgentSuccess, setAiAgentSuccess] = useState(null);
  const [upgradePackageRequest, setUpgradePackageRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const requestAiAgentDemo = () => {
    let queryData = {
      service_name: "request_ai_agent_demo",
    };
    setIsLoading(true);
    CompaniesController.requestService(queryData).then((result) => {
      if (!result?.errorMsg) {
        setAiAgentRequest(true);
        setSuccessMsg("request_demo");
        setAiAgentSuccess(true);
      } else {
        setAiAgentRequest(false);
        setErrorMsg("request_demo_failed");
        setAiAgentSuccess(false);
      }
      setIsLoading(false);
    });
  };

  const limitReached =
    aiAgentInfo?.packageInfo?.package_used_admins ==
    aiAgentInfo?.packageInfo?.max_handled_conversations;
  return (
    <div id="channel-analytics">
      <h3 className="sub__head">
        {CheckValueLocale("ai_agent", "", {}, intl)}
      </h3>
      <Card className="body">
        {props.aiAgentActive ? (
          <>
            <Card justifyContent="center">
              {aiAgentInfo?.packageInfo ? (
                <div>
                  <Typography>
                    {CheckValueLocale("package_info", "", {}, intl)}
                  </Typography>
                  <Card className="percentage">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={10}
                      p={2}
                    >
                      <div>
                        <Typography>
                          {CheckValueLocale("package_name", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {aiAgentInfo?.packageInfo?.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {CheckValueLocale("subscribe_start", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(
                              aiAgentInfo?.packageInfo?.subscribe_start,
                            )?.split(" ")[0]
                          }
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {CheckValueLocale("subscribe_end", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(
                              aiAgentInfo?.packageInfo?.subscribe_end,
                            )?.split(" ")[0]
                          }
                        </Typography>
                      </div>
                    </Stack>
                  </Card>
                  <Card justifyContent="center">
                    <Typography>
                      {CheckValueLocale("package_stats", "", {}, intl)}
                    </Typography>
                    <Card className="percentage">
                      <CardContent>
                        <Grid container lg={12} md={12} sm={12} spacing={5}>
                          <Grid item lg={12} md={12} sm={12}>
                            <Typography className="head-social-list">
                              {CheckValueLocale(
                                "max_handled_engagements",
                                "",
                                {},
                                intl,
                              )}
                            </Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              mb={1}
                            >
                              <div>
                                {commasAfterDigit(
                                  aiAgentInfo?.packageInfo?.package_used_admins,
                                )}{" "}
                                {CheckValueLocale("of", "", {}, intl)}{" "}
                                {commasAfterDigit(
                                  aiAgentInfo?.packageInfo
                                    ?.max_handled_conversations,
                                )}
                              </div>
                              <div className="percentage">
                                {`${getPercentage(
                                  aiAgentInfo?.packageInfo?.package_used_admins,
                                  aiAgentInfo?.packageInfo
                                    ?.max_handled_conversations,
                                )}%`}
                              </div>
                            </Stack>
                            <BorderLinearProgress
                              className={
                                `${limitReached ? "limit-reached" : ""}` &&
                                ` ${
                                  aiAgentInfo?.packageInfo
                                    ?.package_used_admins >=
                                  aiAgentInfo?.packageInfo
                                    ?.max_handled_conversations
                                    ? "red-color"
                                    : ""
                                }`
                              }
                              variant="determinate"
                              value={
                                checkProgressExceeded(
                                  aiAgentInfo?.packageInfo?.package_used_admins,
                                  aiAgentInfo?.packageInfo
                                    ?.max_handled_conversations,
                                ) ??
                                getPercentage(
                                  aiAgentInfo?.packageInfo?.package_used_admins,
                                  aiAgentInfo?.packageInfo
                                    ?.max_handled_conversations,
                                )
                              }
                              id="linear-progress-color"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Card>
                </div>
              ) : (
                <Typography>
                  {CheckValueLocale("channel_analytics_demo", "", {}, intl)}
                </Typography>
              )}
            </Card>
            {handlelUserRoles("GENERAL", "CREATE_REQUEST") && (
              <Stack direction="row" justifyContent="flex-end">
                <LucButton
                  onClick={() => {
                    sendPackageUpgrade(aiAgentInfo?.id);
                    setUpgradePackageRequest(true);
                  }}
                  id="settings-subscription-ai-agent-package-btn"
                  loading={isLoadingUpgrade}
                >
                  {CheckValueLocale("request_upgrade", "", {}, intl)}
                </LucButton>
              </Stack>
            )}
          </>
        ) : (
          <>
            <Typography>
              {CheckValueLocale("ai_agent_request_demo", "", {}, intl)}
            </Typography>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  requestAiAgentDemo();
                }}
                id="settings-subscription-ai-agent-package-btn"
              >
                {isLoading ? (
                  <span className="sending-request-btn-text">
                    <CircularProgress className="circular-progress-subscription circular-progress" />
                    {CheckValueLocale("sending_request", "", {}, intl)}
                  </span>
                ) : (
                  CheckValueLocale("request_demo", "", {}, intl)
                )}
              </Button>
            </Stack>
          </>
        )}
      </Card>
      {success != null ? (
        <Snackbar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span>
                  <br />
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_success", "", {}, intl)
                    : CheckValueLocale(successMsg, "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_failed", "", {}, intl)
                    : CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span>
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
      {aiAgentSuccess != null ? (
        <Snackbar
          open={aiAgentSuccess != null ? true : false}
          handleClose={() => {
            setAiAgentSuccess(null);
          }}
          severity={aiAgentSuccess ? "success" : "error"}
          message={
            aiAgentSuccess ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("request_demo_success", "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default AiAgentPackage;
