import { Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import AIAgentImageUploadFilled from "images/ai-agents/upload-ai-agent-image-filled.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { addSwaggerFileValidationSchema } from "pages/engagements/components/aiAgents/aiAgentsValidation/addSwaggerFileValidationSchema";
import { useContext } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import yaml from "js-yaml";

const fileTypeMap = {
  text_file: {
    "application/pdf": [".pdf"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      [".pptx"],
    "text/plain": [".txt"],
    "text/html": [".html"],
    "message/rfc822": [".eml"],
    "text/csv": [".csv"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  },
  swagger: {
    "application/json": [".json"],
    "application/yaml": [".yaml"],
  },
};

const AddKnowledgeBaseFilesDropZone = ({ files, setFiles, acceptType }) => {
  const intl = useIntl();
  const isSwagger = acceptType === "swagger";
  const { setAiAgentSnackBar } = useContext(AiAgentsContext);

  const handleSwaggerOnDrop = (acceptedFiles) => {
    const firstFile = acceptedFiles?.[0];
    const reader = new FileReader();
    if (firstFile?.type === "application/json") {
      reader.onload = (e) => {
        const fileContent = e.target.result;
        const fileContentJSON = JSON.parse(fileContent);
        addSwaggerFileValidationSchema(intl)
          .validate(fileContentJSON, { abortEarly: false })
          .then(() => setFiles([...files, ...acceptedFiles]))
          .catch((error) => {
            const errors = error?.errors;
            if (errors.length) {
              setAiAgentSnackBar({
                success: false,
                message: CheckValueLocale(errors?.[0], "", {}, intl),
              });
            } else {
              setAiAgentSnackBar({
                success: false,
                message: CheckValueLocale("invalid_swagger_file", "", {}, intl),
              });
            }
          });
      };

      reader.readAsText(firstFile);
      return;
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e?.target?.result;
        try {
          const fileContentYAML = yaml.load(fileContent);
          addSwaggerFileValidationSchema(intl)
            .validate(fileContentYAML, {
              abortEarly: false,
            })
            .then(() => {
              const modifiedFile = new File([firstFile], firstFile.name, {
                type: "application/yaml",
              });
              setFiles([...files, modifiedFile]);
            })
            .catch((error) => {
              const errors = error.errors;
              if (errors?.length) {
                setAiAgentSnackBar({
                  success: false,
                  message: CheckValueLocale(errors?.[0], "", {}, intl),
                });
              } else {
                setAiAgentSnackBar({
                  success: false,
                  message: CheckValueLocale(
                    "invalid_swagger_file",
                    "",
                    {},
                    intl,
                  ),
                });
              }
            });
        } catch (error) {
          setAiAgentSnackBar({
            success: false,
            message: CheckValueLocale("invalid_swagger_file", "", {}, intl),
          });
          return;
        }
      };

      reader.readAsText(firstFile);
    }
  };

  const handleTextFilesUpload = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) =>
      isSwagger
        ? handleSwaggerOnDrop(acceptedFiles)
        : handleTextFilesUpload(acceptedFiles),
    multiple: isSwagger ? false : true,
    accept: fileTypeMap?.[acceptType] || undefined,
    disabled: isSwagger && files?.length > 0 ? true : false,
  });

  return (
    <Box {...getRootProps()} className="knowledge-base-drop-zone">
      <input {...getInputProps()} />
      <img src={AIAgentImageUploadFilled} />
      <Box className="knowledge-base-drop-zone-row">
        <Typography className="knowledge-base-drop-zone-title">
          {CheckValueLocale("knowledge_base_drop_zone_text", "", {}, intl)}
        </Typography>
        <Typography className="knowledge-base-drop-zone-browse">
          {CheckValueLocale("ai_agent_upload_files_browse", "", {}, intl)}
        </Typography>
      </Box>
    </Box>
  );
};

export default AddKnowledgeBaseFilesDropZone;
