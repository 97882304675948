import { useEffect, useState } from "react";
import { Button, Menu, MenuItem, Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { NavLink, useHistory } from "react-router-dom";
import "shared/main.scss";
//Locals
import { useIntl } from "react-intl";
import "../mainHeader/mainHeader.scss";
import UserAccount from "../../services/controllers/userAccountController";
import {
  CheckValueLocale,
  handleActiveFeature,
  handlelUserRoles,
  handleSingleDropdownMenuItem,
} from "utils/helpers";
import Services from "services/api/Services.js";

const SocialListingMenu = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const [anchorSL, setAnchorSL] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const openSL = Boolean(anchorSL);
  const linksArray = [
    {
      isActive: handlelUserRoles("SM", "VIEW_MONITOR_LIST"),
      link: "/social/monitor_list",
      text: "monitors_list",
      id: "monitor-list-menu-monitors-list",
    },
    {
      isActive: handleActiveFeature("SM", "twitter_top_hashtags"),
      link: "/top-trends",
      text: "top_trends",
      id: "monitor-list-menu-top-hashtags",
    },
  ];

  const handleHoverSocialListening = (event) => {
    if (anchorSL !== event.currentTarget) {
      setAnchorSL(event.currentTarget);
    }
  };

  const handleClickSocialListening = (event) => {
    if (anchorSL !== event.currentTarget) {
      setAnchorSL(event.currentTarget);
    }
    handleSingleDropdownMenuItem(linksArray, history);
  };

  const handleCloseSL = () => {
    setAnchorSL(null);
  };

  const [isLoading, setLoading] = useState(false);

  const getSmData = async (page) => {
    setLoading(true);
    let baseUrl = Services.switchUrl;

    await UserAccount.getSmData().then((result) => {
      if (result.data !== undefined) {
        localStorage.setItem("user_token", result.data.data.jwt);
        window.open(
          baseUrl +
            page +
            "?lang=" +
            localStorage.lang +
            "&sm_token=" +
            result.data.data.jwt,
          "_self",
        );
      }
    });
  };

  const subMenuPaths = [
    "/social/monitor_list",
    "/social/monitors/create_monitor",
    "/top-trends",
  ];
  useEffect(() => {
    setIsActive(
      subMenuPaths.includes(history.location.pathname) ||
        history.location.pathname.startsWith("/monitor/"),
    );

    for (let [key, value] of Object.entries(subMenuPaths)) {
      if (history.location.pathname.includes(`${value}`)) {
        setIsActive(true);
      }
    }
  }, [history.location.pathname]);

  let menuDirection = intl.locale === "ar" ? "right" : "left";

  return (
    <Box className="navbar-header" onMouseLeave={handleCloseSL}>
      <Button
        id="main-header-social-media"
        aria-owns={anchorSL ? "simple-menu" : undefined}
        onMouseOver={handleHoverSocialListening}
        onMouseUp={handleCloseSL}
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={openSL ? "true" : undefined}
        disableElevation
        onClick={handleClickSocialListening}
        endIcon={<KeyboardArrowDown />}
        className={`${
          isActive
            ? "selected-route btn-dropdown-nav social-listing-menu"
            : "btn-dropdown-nav  social-listing-menu"
        } dropdown-menu-arrow header-dropdown-menu`}
      >
        {CheckValueLocale("social_listening", "", {}, intl)}
      </Button>
      <Menu
        id="main-header-social-media-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
          onMouseLeave: handleCloseSL,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuDirection,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuDirection,
        }}
        anchorEl={anchorSL}
        open={openSL}
        onClose={handleCloseSL}
        className="social-listening-menu"
      >
        {linksArray
          ?.filter((link) => link?.isActive)
          .map((link) => {
            return (
              <MenuItem
                className="social-menu-list menu-list"
                onClick={() => handleCloseSL()}
                disableRipple
                id={link?.id}
              >
                <NavLink activeClassName="selected-route" to={link?.link}>
                  {CheckValueLocale(link?.text, "", {}, intl)}
                </NavLink>
              </MenuItem>
            );
          })}
        {/* remove custom Dashboard from Navbar until fixed */}
        {/* <MenuItem
          className="social-menu-list menu-list"
          disableRipple
          onClick={() => handleCloseSL()}
          id="monitor-list-menu-comparison-dashboards"
        >
          <a
            onClick={() => {
              getSmData("#/comp_dash");
              handleCloseSL();
            }}
          >
            {CheckValueLocale("comparison_dashboards", "", {}, intl)}
          </a>
        </MenuItem> */}
      </Menu>
    </Box>
  );
};
export default SocialListingMenu;
