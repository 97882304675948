export const maxTabsCount = 1001;

export const allowedTabsChange = ["sla_tabs", "content_tabs"];

export const slaTabsInitialValues = {
  all: 0,
  my_assigned: 0,
  overdue: 0,
  team_assigned: 0,
  unassigned: 0,
  ai_agent: 0,
};

export const slaInitialMutableTabs = [
  "all",
  "my_assigned",
  "overdue",
  "team_assigned",
  "unassigned",
  "ai_agent",
];
