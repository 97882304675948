import Services from "./Services";

var customDashboard = {};

// Get Monitors for selected product Social Listening
customDashboard.getCustomDashboardMonitors = (queryData) => {
  return Services.getData(`api/v3/dashboards/custom/monitors`, queryData);
};

// Get Accounts for selected product Omnichannel
customDashboard.getCustomDashboardAccounts = (queryData) => {
  return Services.getData(`api/v3/dashboards/custom/monitors`, queryData);
};

customDashboard.createNewDashboard = (queryData) => {
  return Services.postData(`api/v3/dashboards`, queryData);
};

// Custom dashboard get sidebar widgets
customDashboard.getSidebarWidgets = (id) => {
  return Services.getData(`api/v3/dashboards/custom/widgets/${id}`);
};

// Custom dashboard get content widgets
customDashboard.getContentWidgets = (id) => {
  return Services.getData(`api/v3/dashboards/custom/edit/widgets_list/${id}`);
};

// Update Custom Dashboard
customDashboard.updateCustomDashboard = (id, queryData) => {
  return Services.putData(`api/v3/dashboards/${id}`, queryData);
};

// Update live Custom Dashboard
customDashboard.updateLiveCustomDashboard = (queryData) => {
  return Services.putData(`api/v3/dashboards/custom/live-log`, queryData);
};

// Custom dashboard View widgets
customDashboard.getCustomDashboardView = (id, queryData) => {
  return Services.getData(`api/v3/dashboards/custom/${id}`, queryData);
};

// Custom dashboard control widget available monitors list (to aggregate or compare)
customDashboard.getCustomizedWidgetMonitors = (dashboardId, widgetId) => {
  return Services.getData(
    `api/v3/dashboards/custom/${dashboardId}/customized-widget/monitrs-to-be-linked/${widgetId}`,
  );
};

// Custom dashboard control widget (Customize Widget)
customDashboard.customizeWidget = (dashboardId, widgetId, queryData) => {
  return Services.putData(
    `api/v3/dashboards/custom/${dashboardId}/customized_widget/customize_widget/${widgetId}`,
    queryData,
  );
};

// Custom dashboard control widget (Remove Customize Widget)
customDashboard.removeCustomizeWidget = (dashboardId, widgetId) => {
  return Services.deleteData(
    `api/v3/dashboards/custom/${dashboardId}/customized_widget/${widgetId}`,
  );
};

export default customDashboard;
