import { useInfiniteQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useAiAgentLogs = ({
  start_date,
  end_date,
  user_ids,
  products,
  item_id,
  enabled,
}) => {
  return useInfiniteQuery({
    queryKey: ["aiAgentLogs", { item_id }],
    queryFn: ({ pageParam }) =>
      CompaniesController.getUserActivityLogs(
        start_date,
        end_date,
        user_ids,
        products,
        pageParam,
        undefined,
        item_id,
      ),
    initialPageParam: 1,
    getNextPageParam: ({ data }) => {
      const hasNextPage = data?.data?.pagination?.next;
      return hasNextPage !== undefined ? hasNextPage : undefined;
    },
    enabled,
  });
};
