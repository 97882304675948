import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import { WorkingHoursInDay } from "./workingHoursForDay";
import { useEffect } from "react";

export const days = {
  sunday: "Sunday",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
};

const daysData = [
  {
    day: days.sunday,
    symbol: "sunday_symbol",
  },
  {
    day: days.monday,
    symbol: "monday_symbol",
  },
  {
    day: days.tuesday,
    symbol: "tuesday_symbol",
  },
  {
    day: days.wednesday,
    symbol: "wednesday_symbol",
  },
  {
    day: days.thursday,
    symbol: "thursday_symbol",
  },
  {
    day: days.friday,
    symbol: "friday_symbol",
  },
  {
    day: days.saturday,
    symbol: "saturday_symbol",
  },
];

export const defaultDaysData = {
  from: "",
  to: "",
  selected: false,
};

export const workgingHoursData = {
  [days.sunday]: [{ ...defaultDaysData }],
  [days.monday]: [{ ...defaultDaysData }],
  [days.tuesday]: [{ ...defaultDaysData }],
  [days.wednesday]: [{ ...defaultDaysData }],
  [days.thursday]: [{ ...defaultDaysData }],
  [days.friday]: [{ ...defaultDaysData }],
  [days.saturday]: [{ ...defaultDaysData }],
};
export const WorkingHours = ({
  tabsData,
  setTabsData,
  tabsErrors,
  setTabsErrors,
  title,
  subtitle,
  workingHoursTimezoneText,
  disabled = false,
}) => {
  const intl = useIntl();
  const isNoDaySelected = Object.keys(tabsData?.workingHours)?.every((day) => {
    const dayRanges = tabsData?.workingHours?.[day];
    return dayRanges?.length === 1 && !dayRanges?.[0]?.selected;
  });
  const handleSelectDay = (day, selected) => {
    setTabsData({
      ...tabsData,
      workingHours: {
        ...tabsData?.workingHours,
        [day]: [{ ...defaultDaysData, selected: !selected }],
      },
    });
  };
  const handleAddRow = (day) => {
    const newDayRanges = [...tabsData?.workingHours?.[day]];
    newDayRanges.push({ ...defaultDaysData });
    setTabsData((prev) => ({
      ...prev,
      workingHours: {
        ...prev?.workingHours,
        [day]: newDayRanges,
      },
    }));
  };
  const handleRemoveRow = (day, index) => {
    const newDayRanges = [...tabsData?.workingHours?.[day]];
    newDayRanges.splice(index, 1);
    setTabsData((prev) => ({
      ...prev,
      workingHours: {
        ...prev?.workingHours,
        [day]: newDayRanges,
      },
    }));
  };
  useEffect(() => {
    // error should be happen if the user has filled "from" and not "to" or vice versa
    // also the error should happen if there only one range in the day and it is not filled.
    // but if they are both filled, the error should be removed, or if they are empty that doesn't count as an error.
    // we will check always all last ranges in all days, if they are both filled or both empty, we will remove the error
    const isWorkingHoursError = tabsErrors?.workingHours;
    const isWorkingHoursPreError = tabsErrors?.workgingHoursPreError;
    const workingHoursError = Object.keys(tabsData?.workingHours)?.some(
      (day) => {
        const dayRanges = tabsData?.workingHours?.[day];
        const lastRange = dayRanges?.[dayRanges?.length - 1];
        if (lastRange?.from && !lastRange?.to) return true;
        if (!lastRange?.from && lastRange?.to) return true;
        if (
          dayRanges?.length === 1 &&
          lastRange?.selected &&
          (!lastRange?.from || !lastRange?.to)
        ) {
          return true;
        }
        return false;
      },
    );
    if ((isWorkingHoursError || isWorkingHoursPreError) && !workingHoursError) {
      setTabsErrors((prevTabsErrors) => ({
        ...prevTabsErrors,
        workgingHoursPreError: false,
        workingHours: false,
      }));
    } else if (
      workingHoursError ||
      isWorkingHoursPreError !== workingHoursError
    ) {
      setTabsErrors((prevTabsErrors) => ({
        ...prevTabsErrors,
        workgingHoursPreError: workingHoursError,
      }));
    }
  }, [tabsData?.workingHours]);

  const hasSubTitle = subtitle?.trim()?.length > 0;
  const showWorkingHoursTimezoneText =
    typeof workingHoursTimezoneText === "string"
      ? workingHoursTimezoneText?.trim()?.length > 0
      : !!workingHoursTimezoneText;
  return (
    <Box className="selectable-tab-content-container">
      <Box className="selectable-tab-content-stack">
        {title ? title : null}
        <Typography variant="body2" className="selectable-tab-content-title">
          {CheckValueLocale(
            hasSubTitle ? subtitle : "working_title",
            "",
            {},
            intl,
          )}
        </Typography>
        <Typography
          variant="body2"
          className="selectable-tab-content-description"
        >
          {CheckValueLocale("working_days", "", {}, intl)}
        </Typography>
        <Box className="selectable-tab-content-days">
          {daysData?.map((day, index) => {
            const selected = tabsData?.workingHours?.[day.day]?.[0]?.selected;
            return (
              <Box
                className={classNames(
                  "selectable-tab-content-days-item",
                  selected && "days-item-active",
                  disabled && selected && "days-item-disabled",
                  disabled && !selected && "days-item-disabled-not-selected",
                )}
                key={index}
                onClick={() => !disabled && handleSelectDay(day.day, selected)}
              >
                <Typography
                  variant="body2"
                  className="selectable-tab-content-days-item-description"
                >
                  {CheckValueLocale(day?.symbol, "", {}, intl)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className="selectable-tab-content-stack">
        <Typography
          variant="body2"
          className="selectable-tab-content-description"
        >
          {CheckValueLocale("working_hours", "", {}, intl)}
        </Typography>
        <Typography variant="body2" className="selectable-tab-content-title">
          {showWorkingHoursTimezoneText
            ? workingHoursTimezoneText
            : CheckValueLocale("select_hours_gmt", "", {}, intl)}
        </Typography>
        <Box className="selectable-tab-content-hours">
          {isNoDaySelected ? (
            <Box className="selectable-tab-content-hours-empty">
              {CheckValueLocale("no_day_selected", "", {}, intl)}
            </Box>
          ) : (
            daysData?.map((day, index) => {
              const dayRanges = tabsData?.workingHours?.[day?.day];
              const selected = dayRanges?.[0]?.selected;
              if (!selected) return null;
              return (
                <WorkingHoursInDay
                  key={day?.day}
                  dayName={day?.day}
                  dayRanges={dayRanges}
                  handleAddRow={handleAddRow}
                  handleRemoveRow={handleRemoveRow}
                  setTabsData={setTabsData}
                  tabsErrors={tabsErrors}
                  setTabsErrors={setTabsErrors}
                  disabled={disabled}
                />
              );
            })
          )}
        </Box>
      </Box>
    </Box>
  );
};
