import ReactEcharts from "echarts-for-react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

let parseArabic = (str) => {
  return Number(
    str
      ?.toString()
      ?.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => {
        return d.charCodeAt(0) - 1632; // Convert Arabic numbers
      })
      ?.replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (d) => {
        return d.charCodeAt(0) - 1776; // Convert Persian numbers
      }),
  );
};

const StackedLineChart = (props) => {
  const intl = useIntl();
  const formatToolTip = (data) => {
    let format = data[0].axisValue + "<br />",
      total = 0;
    //you need to pass isSentimentAnalysis to StackedLineWidget if you want to show percenatge & multiply value by 100
    let percentageIcon =
      props.isSentimentAnalysis && !props.isPostsReachImpression ? "%" : "";

    // ** Only with Custom Dashboard (Comparison Type) ** //
    if (props?.isComparison) {
      data?.forEach((item) => {
        if (item?.data?.value !== undefined || item?.data?.value !== "")
          format += `${item?.marker} ${item?.seriesName}: ${parseArabic(
            Number(item?.data?.value),
          )} <br />`;

        total += parseArabic(Number(item?.data?.value));
      });
    } else if (props?.isSentimentAnalysis && !props?.isPostsReachImpression) {
      data?.forEach((item) => {
        if (item?.data?.value !== "") {
          if (item?.data?.native_value) {
            format += `${item?.marker} ${item?.seriesName}: ${parseArabic(
              Number(item?.data?.value)?.toFixed(3),
            )}${percentageIcon} (${item?.data?.native_value}) <br />`;
          } else {
            format += `${item?.marker} ${item?.seriesName}: ${parseArabic(
              Number(item?.data?.value)?.toFixed(3),
            )}${percentageIcon} <br />`;
          }
          total += parseArabic(Number(item?.data?.value));
        }
      });
    } else {
      data?.forEach((item) => {
        if (item?.data !== "") {
          format +=
            item?.marker +
            " " +
            item?.seriesName +
            ": " +
            parseArabic(item?.data?.toFixed(3)) +
            percentageIcon +
            "<br />";
          total += parseArabic(item?.data);
        }
      });
    }
    return props.handleOpenQuickInspect
      ? format +
          `<strong>${CheckValueLocale(
            "click_for_details",
            "",
            {},
            intl,
          )}</strong>`
      : format;
  };
  const onChartClick = (params) => {
    //here it is used for open quick-inspect-model
    let seriesName;
    params.color === "#89BB2A"
      ? (seriesName = "positive")
      : params.color === "#F9A700"
        ? (seriesName = "neutral")
        : (seriesName = "negative");
    if (props.duration === undefined || props.duration === "day") {
      props.handleOpenQuickInspect &&
        props.handleOpenQuickInspect(
          params.name,
          props.inspectorName,
          seriesName,
        );
    }
  };
  let lineObjects = [];

  props?.seriesData?.map((data) => {
    const arabic2englishNumber = (s) =>
      s?.toString()?.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));

    const arrStr = data?.value;
    const dataVal = arrStr?.map((i, index) => {
      let x = arabic2englishNumber(i);

      // ** Only with Custom Dashboard (Comparison Type) ** //
      if (props?.isComparison) {
        return { value: parseFloat(x) };
      } else if (props?.isPostsReachImpression) {
        return parseFloat(x);
      } else if (props?.isSwitchCase) {
        if (props?.isSentimentAnalysis) {
          let convertedNumericValue = arabic2englishNumber(
            data?.native_value[index],
          );
          return {
            value: parseFloat(x) * 100,
            native_value: parseFloat(convertedNumericValue),
          };
        }
        return parseFloat(x);
      } else {
        if (props?.isSentimentAnalysis) {
          let convertedNumericValue = arabic2englishNumber(
            data?.native_value[index],
          );
          return {
            value: parseFloat(x) * 100,
            native_value: parseFloat(convertedNumericValue),
          };
        }
        return parseFloat(x) * 100;
      }
    });

    lineObjects.push({
      emphasis: {
        itemStyle: props.handleOpenQuickInspect && {
          borderWidth: 10,
        },
        focus: "series",
      },
      cursor: props.handleOpenQuickInspect ? "pointer" : "default",
      name: data?.name,
      type: props.showLogChart ? "bar" : "line",
      data: dataVal,
      // props.hideLegend used to set size of symbol on hover
      symbolSize: !props.hideLegend ? 1 : 0,
      // showSymbol: props.isSwitchCase ? !props.isSwitchCase : true,
      smooth: true,
      showSymbol: false,
      lineStyle: {
        color: data?.color !== undefined ? data?.color : "#80868C",
        type: "solid",
      },
      itemStyle: {
        color: data?.color !== undefined ? data?.color : "#80868C",
      },
    });
  });

  let options = {
    grid: {
      x: 40,
      x2: 45,
      y: props.legend === true ? 60 : 30,
      y2: 65,
      containLabel: true,
      left: "3%",
      right: "3%",
    },
    dataZoom: [
      {
        start: props.zoomPercentage,
        end: 100,
        bottom: 13,
        left: 60,
        right: 45,
        fillerColor: "rgba(42, 170, 226, 0.06)",
        dataBackground: {
          areaStyle: {
            color: "transparent",
          },
        },
        selectedDataBackground: {
          lineStyle: {
            color: "rgba(203, 213, 225, 1)",
          },
          areaStyle: {
            color: "transparent",
          },
        },
      },
    ],
    barMaxWidth: 24,
    barMinWidth: 18,
    tooltip: {
      trigger: "axis",
      backgroundColor: "#fff",
      borerRadius: 2,
      textStyle: {
        fontSize: 12,
      },
      padding: [10, 15],
      show: props.showNoDataDialog ? false : true,
      formatter: (val) => formatToolTip(val),
    },
    legend: {
      top: 0,
      right: props.legendPosition === "right" && 30,
      itemWidth: 15,
      left: props.legendPosition !== "right" && 30,
      icon: "roundRect",
      show: props.hideLegend,
    },
    xAxis: {
      type: "category",
      boundaryGap: props.showLogChart ? true : false,
      data: props.xAxisData,
      axisLine: {
        lineStyle: {
          width: 1,
          color: "#80868C",
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        textStyle: {
          color: "rgba(0, 0, 0, 0.42)",
        },
      },
    },
    yAxis: {
      type: "value",

      axisLine: {
        lineStyle: {
          width: 1,
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        textStyle: {
          color: "rgba(0, 0, 0, 0.42)",
        },
      },
    },
    series: lineObjects,
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "300px", width: "100%" }}
      theme="custom_theme"
      onEvents={{ click: onChartClick }} //add this event for onClick work in quick-inspect
    />
  );
};

export default StackedLineChart;
