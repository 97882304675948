import { Link } from "react-router-dom";
import NotfoundDAshboard from "images/noDataFound/not-found-dashboard.svg";
import "./NotFoundDashboard.scss";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const NotFoundDashboard = () => {
  const intl = useIntl();
  return (
    <>
      <Box className="not-found-custom-dashboard-page">
        <Box component={"img"} src={NotfoundDAshboard} />

        <Box className="not-found-empty-box">
          <Typography
            component={"h3"}
            className="not-found-custom-dashbourd-page-header"
          >
            {CheckValueLocale(
              "not_found_custom_dashbourd_page_header",
              "",
              {},
              intl,
            )}
          </Typography>
          <Typography
            component={"span"}
            className="try-access-anther-custom-dashbourd"
          >
            {CheckValueLocale(
              "try_access_anther_custom_dashbourd",
              "",
              {},
              intl,
            )}
            <Link to="/dashboard/dashboard_list">
              {CheckValueLocale("go_to_dashboards", "", {}, intl)}
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default NotFoundDashboard;
