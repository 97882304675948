import { useRef, useState } from "react";
import { allowedTabsChange, maxTabsCount } from "./constant";

export const tabsContentInitialValues = {
  all: 0,
  new: 0,
  active: 0,
  complete: 0,
};

const mutableTabs = ["all", "new", "active", "complete"];

export const useTabsContentCounter = (filters, countLoading) => {
  // will hold the previous value of the sla tab before the filters get changed
  const currentSLATab = useRef(filters?.sla_status);
  // tabsContentCount will hold the count of each tab
  const [tabsContentCount, setTabsContentCount] = useState({
    ...tabsContentInitialValues,
    // changedCountKeys will hold the keys of the tabs that have been changed
    changedCountKeys: [],
    // isInitialLoad will be true if the component is loaded for the first time
    // also we need to it to true when we change the active tab in the sla sidebar
    isInitialLoad: true,
    // mutableTabs will hold the tabs that can be changed that less than maxTabsCount
    mutableTabs: mutableTabs,
  });

  const updateTabsContentCount = (newTabsContentCount, changeType) => {
    const mutableTabs =
      newTabsContentCount?.mutableTabs || tabsContentCount?.mutableTabs;

    setTabsContentCount((prevTabsContentCount) => {
      let changedCountKeys = [];
      // only if the component is not in the initial load and the changeType is allowed we will set the changedCountKeys
      if (
        !prevTabsContentCount?.isInitialLoad &&
        allowedTabsChange.includes(changeType)
      ) {
        changedCountKeys = Object.keys(newTabsContentCount)?.filter?.(
          // if this tab have changed and it's and it's not in the loading state
          (key) =>
            // if the new value is different than the previous value
            newTabsContentCount[key] != prevTabsContentCount[key] &&
            // if the curr or prev counter is less than the maxTabsCount we will add it to the changedCountKeys
            (newTabsContentCount[key] < maxTabsCount ||
              prevTabsContentCount[key] < maxTabsCount) &&
            // if the content load is loading and it's the current tab we will not add it to the changedCountKeys
            !(countLoading && filters?.engagement_status == key),
        );
      }
      return {
        ...prevTabsContentCount,
        ...newTabsContentCount,
        mutableTabs: mutableTabs,
        changedCountKeys,
        isInitialLoad: false,
      };
    });

    // we will clear the changedCountKeys after 900 ms
    setTimeout(clearChangedCountKeys, 900);
  };

  // after applying the animation we will reset the changedCountKeys
  const clearChangedCountKeys = () => {
    setTabsContentCount((prevTabsContentCount) => {
      return {
        ...prevTabsContentCount,
        changedCountKeys: [],
      };
    });
  };

  // reset tabsContentCount when the filters are changed
  const resetTabsContentCount = () => {
    const hasSLATabChanged = currentSLATab.current !== filters?.sla_status;
    let newData = {};
    // if the sla tab has changed we need to set isInitialLoad to true, otherwise we keep it as it is
    if (hasSLATabChanged) {
      newData = {
        ...tabsContentInitialValues,
        changedCountKeys: [],
        isInitialLoad: true,
      };
    }
    setTabsContentCount((prevTabsContentCount) => ({
      ...prevTabsContentCount,
      ...newData,
    }));
    // update the currentSLATab to the new value
    currentSLATab.current = filters?.sla_status;
  };

  return {
    tabsContentCount,
    setTabsContentCount: updateTabsContentCount,
    resetTabsContentCount,
  };
};
