import { styled } from "@mui/material/styles";
import { useIntl } from "react-intl";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import {
  Box,
  Typography,
  Card,
  Grid,
  CardHeader,
  CardContent,
  IconButton,
} from "@mui/material";
import AIIcon from "../../../../images/notification-icons/AiVector.svg";
import LineSVG from "../../../../images/notification-icons/Line.svg";
import {
  CheckValueLocale,
  checkProgressExceeded,
  getPercentage,
} from "utils/helpers";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, barColorCode }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses?.colorPrimary}`]: {
      backgroundColor:
        theme?.palette?.grey?.[theme?.palette?.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses?.bar}`]: {
      borderRadius: 5,
      backgroundColor: barColorCode,
    },
  }),
);

const CommnetsSentimentAnalysis = (props) => {
  const intl = useIntl();
  const { overallSentiment_label } = props || {};
  const totalOverallSentimentLabel =
    overallSentiment_label?.Positive +
    overallSentiment_label?.Neutral +
    overallSentiment_label?.Negative;
  return (
    <Grid item xs={6}>
      <Card className="box-card">
        <CardHeader
          action={
            <Box>
              <IconButton className="ai-btn">
                <img src={AIIcon} />
              </IconButton>
              <img src={LineSVG} />
              {/* <WidgetDownloader
                chartId={"comments_sentiment_analysis"}
                bigImg
              /> */}
            </Box>
          }
          title={CheckValueLocale("comments_sentiment_analysis", "", {}, intl)}
        />
        <CardContent className="comments-analysis-body">
          <Box className="progress-box">
            <Box className="progress-box-header">
              <Typography variant="subtitle1">
                {CheckValueLocale(
                  "comments_sentiment_analysis_positive",
                  "",
                  {},
                  intl,
                )}
              </Typography>
              <Box>
                <Typography variant="subtitle2">
                  ({overallSentiment_label?.Positive})
                </Typography>
                <Typography variant="h6" sx={{ color: "#BAD532" }}>
                  {totalOverallSentimentLabel
                    ? `${getPercentage(
                        overallSentiment_label?.Positive,
                        totalOverallSentimentLabel,
                      )}%`
                    : "0%"}
                </Typography>
              </Box>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={
                checkProgressExceeded(
                  overallSentiment_label?.Positive,
                  totalOverallSentimentLabel,
                ) ??
                getPercentage(
                  overallSentiment_label?.Positive,
                  totalOverallSentimentLabel,
                )
              }
              barColorCode="#BAD532"
            />
          </Box>
          <Box className="progress-box">
            <Box className="progress-box-header">
              <Typography variant="subtitle1">
                {CheckValueLocale(
                  "comments_sentiment_analysis_neutral",
                  "",
                  {},
                  intl,
                )}
              </Typography>
              <Box>
                <Typography variant="subtitle2">
                  ({overallSentiment_label?.Neutral})
                </Typography>
                <Typography variant="h6" sx={{ color: "#F5B819" }}>
                  {totalOverallSentimentLabel
                    ? `${getPercentage(
                        overallSentiment_label?.Neutral,
                        totalOverallSentimentLabel,
                      )}%`
                    : "0%"}
                </Typography>
              </Box>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={
                checkProgressExceeded(
                  overallSentiment_label?.Neutral,
                  totalOverallSentimentLabel,
                ) ??
                getPercentage(
                  overallSentiment_label?.Neutral,
                  totalOverallSentimentLabel,
                )
              }
              barColorCode="#F5B819"
            />
          </Box>
          <Box className="progress-box">
            <Box className="progress-box-header">
              <Typography variant="subtitle1">
                {CheckValueLocale(
                  "comments_sentiment_analysis_negative",
                  "",
                  {},
                  intl,
                )}
              </Typography>
              <Box>
                <Typography variant="subtitle2">
                  ({overallSentiment_label?.Negative})
                </Typography>
                <Typography variant="h6" sx={{ color: "#ED1846" }}>
                  {totalOverallSentimentLabel
                    ? `${getPercentage(
                        overallSentiment_label?.Negative,
                        totalOverallSentimentLabel,
                      )}%`
                    : "0%"}
                </Typography>
              </Box>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={
                checkProgressExceeded(
                  overallSentiment_label?.Negative,
                  totalOverallSentimentLabel,
                ) ??
                getPercentage(
                  overallSentiment_label?.Negative,
                  totalOverallSentimentLabel,
                )
              }
              barColorCode="#ED1846"
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CommnetsSentimentAnalysis;
