import { CheckValueLocale } from "utils/helpers";
import * as Yup from "yup";

const parameterSchema = Yup.object().shape({
  name: Yup.string().required(),
  in: Yup.string().required(),
  required: Yup.boolean().required(),
  description: Yup.string().required(),
  example: Yup.string().required(),
  schema: Yup.object().shape({
    type: Yup.string().required(),
  }),
});

const httpMethodSchema = Yup.object().shape({
  tags: Yup.array().of(Yup.string()).required().min(1),
  parameters: Yup.array().of(parameterSchema).min(1),
  summary: Yup.string().required(),
});

const pathsArrayValid = (intl) =>
  Yup.array()
    .of(Yup.string())
    .required(
      CheckValueLocale(
        "ai_agent_swagger_file_missing_field",
        "",
        {
          field: "paths",
        },
        intl,
      ),
    );

export const addSwaggerFileValidationSchema = (intl) =>
  Yup.object().shape({
    openapi: Yup.string().required(
      CheckValueLocale(
        "ai_agent_swagger_file_missing_field",
        "",
        {
          field: "openapi",
        },
        intl,
      ),
    ),
    info: Yup.object()
      .shape({
        title: Yup.string().required(
          CheckValueLocale(
            "ai_agent_swagger_file_missing_field",
            "",
            {
              field: "title",
            },
            intl,
          ),
        ),
        version: Yup.string().required(
          CheckValueLocale(
            "ai_agent_swagger_file_missing_field",
            "",
            {
              field: "version",
            },
            intl,
          ),
        ),
        description: Yup.string().required(
          CheckValueLocale(
            "ai_agent_swagger_file_missing_field",
            "",
            {
              field: "description",
            },
            intl,
          ),
        ),
      })
      .required(
        CheckValueLocale(
          "ai_agent_swagger_file_missing_field",
          "",
          {
            field: "info",
          },
          intl,
        ),
      ),
    servers: Yup.array()
      .of(
        Yup.object().shape({
          url: Yup.string()
            .url(
              CheckValueLocale(
                "ai_agent_swagger_file_invalid_url",
                "",
                {},
                intl,
              ),
            )
            .required(
              CheckValueLocale(
                "ai_agent_swagger_file_missing_field",
                "",
                {
                  field: "url",
                },
                intl,
              ),
            ),
          description: Yup.string().required(
            CheckValueLocale(
              "ai_agent_swagger_file_missing_field",
              "",
              {
                field: "description",
              },
              intl,
            ),
          ),
        }),
      )
      .required(
        CheckValueLocale(
          "ai_agent_swagger_file_missing_field",
          "",
          {
            field: "servers",
          },
          intl,
        ),
      )
      .min(
        1,
        CheckValueLocale(
          "ai_agent_swagger_file_missing_field",
          "",
          {
            field: "servers",
          },
          intl,
        ),
      ),
    paths: Yup.object()
      .test(
        "paths",
        CheckValueLocale("ai_agent_swagger_file_invalid_paths", "", {}, intl),
        (value) => {
          if (value) {
            if (Object.keys(value).length === 0) {
              return pathsArrayValid(intl).isValid(value);
            }

            return Object.keys(value).every((pathKey) => {
              const pathObject = value[pathKey];
              return ["get", "post", "put", "delete", "patch"].some(
                (methodKey) => {
                  if (pathObject[methodKey]) {
                    return httpMethodSchema.isValidSync(pathObject[methodKey]);
                  }
                  return false;
                },
              );
            });
          }
          return false;
        },
      )
      .required(
        CheckValueLocale(
          "ai_agent_swagger_file_missing_field",
          "",
          {
            field: "paths",
          },
          intl,
        ),
      ),
  });
