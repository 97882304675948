import { useInfiniteQuery } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useAiAgentPublishedVersions = (id, enabled) => {
  return useInfiniteQuery({
    queryKey: ["aiAgentPublishedVersions", id],
    queryFn: ({ pageParam = 1 }) =>
      AiAgentsController.getPublishedVersions(id, { page: pageParam }),
    getNextPageParam: (data) => {
      const hasNextPage = data?.data?.pagination?.next;
      return hasNextPage ? data?.data?.pagination?.next : undefined;
    },
    initialPageParam: 1,
    enabled,
  });
};
