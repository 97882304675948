import React, { useEffect, useState } from "react";
import LineChartWidget from "components/widgets/lineChartWidget";

const CustomDashboardLineChart = (props) => {
  const {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    widgetsMqttResponses,
    finalWidgetData,
    customizedWidgets,
    dashboardInfo,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    allMonitorsIdWidget,
    customizedType,
    showControl,
  } = props;
  const [totalLabel, setTotalLabel] = useState(null);
  const totalLabelColor = "#003D59";

  useEffect(() => {
    if (widget && finalWidgetData) {
      switch (widget?.widget_name) {
        case "volume_overtime":
          if (
            widget?.dashboard_page_name === "TWITTER_PRIVATE" ||
            widget?.dashboard_page_name === "INSTAGRAM_PRIVATE" ||
            widget?.dashboard_page_name === "FACEBOOK_PRIVATE" ||
            widget?.dashboard_page_name === "LINKEDIN_PUBLIC"
          ) {
            setTotalLabel("interactions");
          } else {
            setTotalLabel(
              handledWidgetName === "posts_volume" ||
                handledWidgetName === "posts_volume_overtime"
                ? "posts"
                : "comments",
            );
          }

          break;

        case "posts_volume_overtime":
        case "comments_volume_overtime":
          setTotalLabel(
            handledWidgetName === "posts_volume" ||
              handledWidgetName === "posts_volume_overtime"
              ? "posts"
              : "comments",
          );
          break;

        case "engagers_volume":
          setTotalLabel("engagers");
          break;

        case "page_views":
          setTotalLabel("views");
          break;

        case "videos_views":
        case "video_volume":
          setTotalLabel("videos");
          break;

        case "comments_volume":
          setTotalLabel("comments");
          break;

        default:
          break;
      }
    }
  }, [widget, finalWidgetData]);

  return (
    <LineChartWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={widgetTitleTooltip || ""}
      data={finalWidgetData?.data || []}
      totalLabel={totalLabel}
      labelColor={totalLabelColor}
      totalValue={finalWidgetData?.total_count || 0}
      lineColor={"#003D59"}
      showDownloadIcon={!isControlWidget}
      lineChartPreLoaderDataSources={finalWidgetData?.preLoaderMonitorsId || []}
      preLoaderTrackerDataSources={allMonitorsIdWidget || []}
      showChartDetailsWidget={null}
      showChartStatsWidget={null}
      isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
      isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
      // The needed data in widget component for case of Custom Dashboard only
      customDashboardData={{
        dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
        handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
        widget: widget, // the widget metric returned from backend in view API
        customizedType: customizedType, // null, aggregate, comparison
        widgetsMqttResponses, // the main state for RabbitMQ responses
        customizedWidgets, // cuztomized widget state for RabbitMQ responses
        getCustomDashboardView, // call back view widgets after any update in page
        setSnackBarData, // set snackbar related to main index view
        // show widget control icon and with stop it in LINKEDIN [ volume overtime ]
        showControl: showControl,
      }}
    />
  );
};

export default CustomDashboardLineChart;
