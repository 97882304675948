import { useMutation, useQueryClient } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const usePauseAiAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (queryData) => AiAgentsController.pauseAiAgent(queryData),
    onSuccess: async (data) => {
      if (!data?.errorMsg) {
        await queryClient.invalidateQueries({
          queryKey: ["aiAgentDetails"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["aiAgentList"],
        });
      }
    },
  });
};
