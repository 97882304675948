import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/pro-solid-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { FieldArray, Formik, isEmptyArray } from "formik";
import { useCallback, useMemo, useRef } from "react";
import { editSwaggerValidationSchema } from "pages/engagements/components/aiAgents/aiAgentsValidation/editSwaggerValidationSchema";

const EditKnowledgeBaseSwaggerEndpoints = ({
  open,
  setEditKnowledgeBaseSwaggerEndpoints,
  endpoint,
  endpoints,
  index,
  setEndpoints,
}) => {
  const formikRef = useRef();
  const intl = useIntl();

  const headersParams = useMemo(() => {
    return endpoint?.parameters?.filter((param) => param?.in === "header");
  }, [endpoint?.parameters]);

  const queryParams = useMemo(() => {
    return endpoint?.parameters?.filter(
      (param) => param?.in === "query" || param?.in === "path",
    );
  }, [endpoint?.parameters]);

  const bodyParams = useMemo(() => {
    return endpoint?.parameters?.filter((param) => param?.in === "body");
  }, [endpoint?.parameters]);

  const newBodyParams = endpoint?.requestBody;

  const initialValues = {
    ...endpoint,
    method: endpoint?.method?.toUpperCase(),
    parameters: !isEmptyArray(queryParams)
      ? queryParams
      : [{ name: "", description: "", in: "query" }],
    headers: !isEmptyArray(headersParams)
      ? headersParams
      : [{ name: "", description: "", in: "header" }],
    requestBody: !isEmptyArray(bodyParams) ? bodyParams : {},
    newBodyParams,
  };

  const handleSubmit = useCallback(
    (innerValues) => {
      const values = formikRef?.current?.values;
      if (values) {
        const fixedEndpointValues = {
          required: false,
          type: "string",
        };

        const updatedEndpoint = {
          ...endpoints?.[index],
          ...values,
          method: values?.method?.toLowerCase(),
          parameters: [
            ...values?.parameters?.map((param) => ({
              ...param,
              in: "query",
              ...fixedEndpointValues,
            })),
            ...values?.headers?.map((header) => ({
              ...header,
              in: "header",
              ...fixedEndpointValues,
            })),
            ...bodyParams,
          ],
        };

        const updatedEndpoints = [...endpoints];
        updatedEndpoints[index] = updatedEndpoint;
        // setEndpoints(updatedEndpoints);
        // setEditKnowledgeBaseSwaggerEndpoints(false);
      }
    },
    [
      endpoints,
      setEndpoints,
      setEditKnowledgeBaseSwaggerEndpoints,
      bodyParams,
      index,
    ],
  );

  const isBodyShown =
    initialValues?.method === "POST" || initialValues?.method === "PUT";

  const modalBody = (
    <Formik
      validationSchema={editSwaggerValidationSchema(intl)}
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form className="edit-swagger-endpoint-container">
            <Box>
              <Typography
                component="span"
                className="edit-endpoint-field-label"
              >
                {CheckValueLocale("endpoint_name", "", {}, intl)}
                <Box component="span" className="red-star-req">
                  {` *`}
                </Box>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                className="ai-agents-form-input"
                name="operationId"
                onBlur={handleBlur}
                value={values?.operationId}
                onChange={handleChange}
                error={!!errors?.operationId}
                helperText={touched?.operationId && errors?.operationId}
              />
            </Box>
            <Box>
              <Typography
                component="span"
                className="edit-endpoint-field-label"
              >
                {CheckValueLocale("description", "", {}, intl)}
                <Box component="span" className="red-star-req">
                  {` *`}
                </Box>
              </Typography>
              <TextField
                multiline
                variant="outlined"
                className="edit-swagger-endpoint-description"
                minRows={2}
                fullWidth
                name="description"
                onBlur={handleBlur}
                value={values?.description}
                onChange={handleChange}
                error={!!errors?.description}
                helperText={touched?.description && errors?.description}
              />
            </Box>
            <Box>
              <Typography
                component="span"
                className="edit-endpoint-field-label with-underline-dashed"
              >
                {CheckValueLocale("endpoint", "", {}, intl)}
                <Box component="span" className="red-star-req">
                  {` *`}
                </Box>
              </Typography>
              <Box className="ai-agent-swagger-method-description-container">
                <Select
                  size="small"
                  className="edit-swagger-endpoint-select-method"
                  value={values?.method}
                  onChange={handleChange}
                  name="method"
                >
                  <MenuItem value="GET">GET</MenuItem>
                  <MenuItem value="POST">POST</MenuItem>
                  <MenuItem value="PUT">PUT</MenuItem>
                  <MenuItem value="DELETE">DELETE</MenuItem>
                  <MenuItem value="PATCH">PATCH</MenuItem>
                </Select>
                <TextField
                  variant="outlined"
                  size="small"
                  className="ai-agents-form-input"
                  value={values?.summary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors?.summary}
                  helperText={touched?.summary && errors?.summary}
                  name="summary"
                />
              </Box>
            </Box>
            {isBodyShown ? (
              <Box>
                <Typography
                  component="span"
                  className="edit-endpoint-field-label with-underline-dashed"
                >
                  {CheckValueLocale(
                    "endpoint_body",
                    "",
                    {
                      type: "JSON",
                    },
                    intl,
                  )}
                </Typography>
                <TextField
                  multiline
                  variant="outlined"
                  className="edit-swagger-endpoint-description"
                  minRows={4}
                  fullWidth
                  value={JSON.stringify(values?.newBodyParams || "", null, 2)}
                  onChange={handleChange}
                  name="newBodyParams"
                />
              </Box>
            ) : null}
            <Box className="edit-endpoint-parameters-container">
              <Typography
                component="span"
                className="edit-endpoint-field-label with-underline-dashed remove-margin"
              >
                {CheckValueLocale("headers", "", {}, intl)}
              </Typography>
              <FieldArray name="headers">
                {({ push, remove }) => (
                  <>
                    {values?.headers?.map((header, index) => (
                      <Box className="edit-endpoint-parameters-container">
                        <Box className="edit-endpoint-parameters-key-value-container">
                          <TextField
                            name={`headers.${index}.name`}
                            variant="outlined"
                            size="small"
                            className="ai-agents-form-input"
                            placeholder={CheckValueLocale("key", "", {}, intl)}
                            value={header?.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors?.headers?.[index]?.name}
                          />
                          <TextField
                            variant="outlined"
                            size="small"
                            className="ai-agents-form-input"
                            placeholder={CheckValueLocale(
                              "capital_value",
                              "",
                              {},
                              intl,
                            )}
                            name={`headers.${index}.description`}
                            value={header?.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors?.headers?.[index]?.description}
                          />
                          <IconButton
                            onClick={() => {
                              if (values?.parameters?.length === 1) {
                                setFieldValue(`headers.${index}.name`, "");
                                setFieldValue(
                                  `headers.${index}.description`,
                                  "",
                                );
                                return;
                              }
                              remove(index);
                            }}
                            size="medium"
                          >
                            <FontAwesomeIcon
                              className="edit-endpoint-remove-icon"
                              size="xs"
                              icon={faX}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                    <Box className="ai-agent-edit-swagger-endpoint-add-field-array-button">
                      <LucButton
                        startIcon={<FontAwesomeIcon icon={faPlus} />}
                        variant="text"
                        onClick={() =>
                          push({ name: "", description: "", in: "header" })
                        }
                      >
                        {CheckValueLocale("add", "", {}, intl)}
                      </LucButton>
                    </Box>
                  </>
                )}
              </FieldArray>
              <Box className="edit-endpoint-parameters-container">
                <Typography
                  component="span"
                  className="edit-endpoint-field-label with-underline-dashed remove-margin"
                >
                  {CheckValueLocale("parameters", "", {}, intl)}
                </Typography>
                <FieldArray name="parameters">
                  {({ push, remove }) => (
                    <>
                      {values?.parameters?.map((parameter, index) => (
                        <>
                          <Box className="edit-endpoint-parameters-key-value-container">
                            <TextField
                              variant="outlined"
                              size="small"
                              className="ai-agents-form-input"
                              placeholder={CheckValueLocale(
                                "key",
                                "",
                                {},
                                intl,
                              )}
                              name={`parameters.${index}.name`}
                              value={parameter?.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!errors?.parameters?.[index]?.name}
                            />
                            <TextField
                              variant="outlined"
                              size="small"
                              className="ai-agents-form-input"
                              placeholder={CheckValueLocale(
                                "capital_value",
                                "",
                                {},
                                intl,
                              )}
                              name={`parameters.${index}.description`}
                              value={parameter?.description}
                              onChange={handleChange}
                              error={!!errors?.parameters?.[index]?.description}
                            />
                            <IconButton
                              onClick={() => {
                                if (values?.parameters?.length === 1) {
                                  setFieldValue(`parameters.${index}.name`, "");
                                  setFieldValue(
                                    `parameters.${index}.description`,
                                    "",
                                  );
                                  return;
                                }
                                remove(index);
                              }}
                              size="medium"
                            >
                              <FontAwesomeIcon
                                className="edit-endpoint-remove-icon"
                                size="xs"
                                icon={faX}
                              />
                            </IconButton>
                          </Box>
                        </>
                      ))}
                      <Box className="ai-agent-edit-swagger-endpoint-add-field-array-button">
                        <LucButton
                          startIcon={<FontAwesomeIcon icon={faPlus} />}
                          variant="text"
                          onClick={() =>
                            push({ name: "", description: "", in: "query" })
                          }
                        >
                          {CheckValueLocale("add", "", {}, intl)}
                        </LucButton>
                      </Box>
                    </>
                  )}
                </FieldArray>
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );

  return (
    <PopupModal
      title={CheckValueLocale("endpoint_details", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("save", "", {}, intl)}
      disabled={false}
      isRightBtnLoading={false}
      open={open || false}
      close={() => setEditKnowledgeBaseSwaggerEndpoints(false)}
      accept={handleSubmit}
      classeName="ai-agent-popupModal"
      dialogContentId="delete-knowledge-base-source"
    />
  );
};

export default EditKnowledgeBaseSwaggerEndpoints;
