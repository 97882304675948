import { Box } from "@mui/material";
import NavigationSidebar from "../../navigationSidebar/navigationContent";
import SwitchMenuList from "../../navigationSidebar/switchNavigationContent";
import { useState } from "react";

const AiAgentsHeader = () => {
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);
  const [navigationMenuStatus, setNavigationMenuStatus] = useState("ai_agent");
  return (
    <Box className="ai-agents-header-wrapper">
      <Box onClick={() => setOpenNavigationMenu(true)}>
        <NavigationSidebar
          openNavigationMenu={openNavigationMenu}
          navigationMenuStatus={navigationMenuStatus}
          setNavigationMenuStatus={setNavigationMenuStatus}
          notOpened={false}
        />
      </Box>
      <SwitchMenuList
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        navigationMenuStatus={"ai_agent"}
        setNavigationMenuStatus={setNavigationMenuStatus}
        className="ai-agents-analytics-header-switch-menu"
      />
    </Box>
  );
};

export default AiAgentsHeader;
