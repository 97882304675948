import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  startDateUnix: moment().subtract(6, "d").startOf("day").unix(),
  endDateUnix: moment().unix(),
  startDateUnixToDisplay: moment().subtract(6, "d").startOf("day").unix(),
  endDateUnixToDisplay: moment().unix(),
  startDate: null,
  endDate: null,
  newFilterParams: {},
  pickerState: "last_7_days",
  savedFilterId: "",
  savedFilterName: "",
  showDeleteFilterBtn: false,
  dataSource: "",
  filterParamsCount: 0,
  applicableFilters: {},
  savedFilter: [],
  showUserSnackbar: "",
};

const socialListeningSlice = createSlice({
  name: "socialListening",
  initialState,
  reducers: {
    clear: (state) => {
      state.newFilterParams = {};
      state.savedFilterId = "";
      state.savedFilterName = "";
      state.showDeleteFilterBtn = false;
      state.pickerState = "last_7_days";
      state.filterParamsCount = 0;
      state.savedFilter = [];
    },
    clearForSavedFilter: (state) => {
      state.newFilterParams = {};
      state.savedFilterId = "";
      state.savedFilterName = "";
      state.showDeleteFilterBtn = false;
      state.pickerState = "last_7_days";
      state.filterParamsCount = 0;
    },
    filters: (state, action) => {
      state.newFilterParams = action?.payload;
    },
    savedFilter: (state, action) => {
      state.savedFilter = action?.payload;
    },
    filtersCount: (state, action) => {
      state.filterParamsCount = action?.payload;
    },
    reduxApplicableFilters: (state, action) => {
      state.applicableFilters = action?.payload;
    },
    reduxDatesUnix: (state, action) => {
      state.startDateUnix = action?.payload?.startDate;
      state.endDateUnix = action?.payload?.endDate;
    },
    reduxDatesUnixToDisplay: (state, action) => {
      state.startDateUnixToDisplay = action?.payload?.startDate;
      state.endDateUnixToDisplay = action?.payload?.endDate;
    },
    reduxDates: (state, action) => {
      state.startDate = action?.payload?.startCustomDate;
      state.endDate = action?.payload?.endCustomDate;
    },
    reduxDatePickerState: (state, action) => {
      state.pickerState = action?.payload;
    },
    reduxSavedFilterId: (state, action) => {
      state.savedFilterId = action?.payload;
      state.showDeleteFilterBtn = true;
    },
    reduxSavedFilterName: (state, action) => {
      state.savedFilterName = action?.payload;
    },
    reduxShowUserSnackbar: (state, action) => {
      state.showUserSnackbar = action?.payload;
    },
  },
});

export default socialListeningSlice.reducer;
export const {
  clear,
  filters,
  filtersCount,
  reduxApplicableFilters,
  reduxDates,
  reduxDatePickerState,
  reduxDatesUnix,
  reduxSavedFilterId,
  reduxSavedFilterName,
  savedFilter,
  reduxDatesUnixToDisplay,
  clearForSavedFilter,
  reduxShowUserSnackbar,
} = socialListeningSlice.actions;
