import { useMutation, useQueryClient } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useDeleteAiAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => AiAgentsController.deleteAiAgent(data),
    onSuccess: (data) => {
      if (!data?.errorMsg) {
        queryClient.invalidateQueries({
          queryKey: ["aiAgentList"],
        });
      }
    },
  });
};
