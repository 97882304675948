import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import { useIntl } from "react-intl";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers";

const DeleteEndpointItem = ({ open, handleClose, stats, handleDelete }) => {
  const intl = useIntl();
  return (
    <Menu
      id="knowledge-base-options-menu"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={stats.anchorEl}
      open={open}
      onClose={handleClose}
      className="menu-ai-agent"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: intl?.locale == "en" ? "right" : "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: intl?.locale == "en" ? "right" : "left",
      }}
    >
      <MenuItem
        className="delete-endpoint-menu-item"
        onClick={handleDelete}
        disableRipple
      >
        <FontAwesomeIcon icon={faTrash} />
        {CheckValueLocale("delete_this_endpoint", "", {}, intl)}
      </MenuItem>
    </Menu>
  );
};

export default DeleteEndpointItem;
