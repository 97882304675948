import { useMutation, useQueryClient } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useEditAiAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, queryData }) => {
      var ai_info = new FormData();
      for (var key of Object.keys(queryData || {})) {
        if (queryData[key] instanceof File) {
          ai_info.append(key, queryData[key]);
        } else if (Array.isArray(queryData[key])) {
          queryData[key]?.forEach((element) => {
            ai_info.append(key + "[]", element);
          });
        } else {
          if (queryData[key] != null) {
            ai_info.append(key, queryData[key]);
          }
          if (queryData[key] === null && key === "ai_agent_image") {
            ai_info.append(key, "");
          }
        }
      }
      return AiAgentsController.editAiAgent({ id, queryData: ai_info });
    },
    onSuccess: async (data) => {
      if (!data?.errorMsg) {
        await queryClient.invalidateQueries({
          queryKey: ["aiAgentDetails"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["aiAgentList"],
        });
      }
    },
  });
};
