const pieChartWidgets = [
  "dialects_subdialects",
  "associated_topics",
  "gender_distribution",
  "top_cities",
  "top_countries",
  "top_languages",
  "top_sources",
  "account_types",
  "content_style",
  "topics",
  // -------------------- Omnichannel --------------------
  "comments_content_style",
  "comments_associated_topics",
  "comments_top_countries",
  "comments_top_cities",
  "comments_gender_distribution",
  "comments_top_languages",
  "comments_dialects_sub_dialects",
  "comments_account_types",
  "comments_top_sources",
  "dialects",
  "dialects_sub_dialects",
  "channel_accounts",
  "gender",
];

const tableWidgets = [
  "top_keywords",
  "top_hash_tags",
  "top_hashtags",
  "top_influencers",
  "top_engagers",
  "top_verified_engagers",
  "top_authors",
  "top_sites",
  "top_engaged_sites",
  "top_influencers",
  "top_verified_engagers",
  "top_engagers",
  "most_engaged_contacts",
  "comments_top_keywords",
  "comments_top_hash_tags",
  "comments_top_engagers",
  "comments_top_verified_engagers",
  "comments_top_influencers",
  "posts_top_keywords",
  "posts_top_hash_tags",
];

const stackedBarWidgets = [
  "interactions",
  "page_likes_unlikes",
  "gender_age_distribution",
  "page_contact_details_interactions",
  "reach_funnel",
  "page_actions",
  "reactions",
  "posts_reach",
  // -------------------- Omnichannel --------------------
  "posts_posts_interactions",
  "comments_comments_interactions",
  "comments_posts_interactions",
  "posts_reach_funnel",
  "posts_reactions",
  "comments_reactions",
  "videos_interactions",
];

const themesWidgets = ["themes", "comments_themes"];

const barChartWidgets = [
  "followers_growth",
  "customer_care_performance",
  "average_engagements_per_post",
  // -------------------- Omnichannel --------------------
  "posts_followers_growth",
  "comments_customer_care_performance",
  "posts_average_engagement_per_posts",
  "follower_growth_overtime",
];

const mediaWidgets = [
  "top_images",
  "top_videos",
  // -------------------- Omnichannel --------------------
  "posts_top_images",
  "posts_top_videos",
  "top_videos",
];

const stackedLineWidgets = [
  "sentiment_analysis",
  "posts_reach_impressions",
  "page_reach_impressions",
  // -------------------- Omnichannel --------------------
  "comments_sentiment_analysis",
];

const lineChartWidgets = [
  "volume_overtime",
  "engagers_volume",
  "page_views",
  "videos_views",
  // -------------------- Omnichannel --------------------
  "comments_volume_overtime",
  "posts_volume_overtime",
  "video_volume",
  "comments_volume",
];

const punchCardWidgets = [
  "account_authors_activity",
  "engagers_activity",
  // -------------------- Omnichannel --------------------
  "posts_account_authors_activity",
  "comments_account_authors_activity",
  "account_contacts_activity",
];

const handleCheckContentTypeWidget = (dashboardInfo, widget) => {
  if (
    (dashboardInfo?.product === "sm" &&
      widget?.data_source === "INSTAGRAM" &&
      widget?.monitor_type?.name === "KEYWORD") ||
    (dashboardInfo?.product === "cxm" &&
      (widget?.dashboard_page_name === "FACEBOOK_PRIVATE" ||
        widget?.dashboard_page_name === "INSTAGRAM_PRIVATE" ||
        widget?.dashboard_page_name === "LINKEDIN_PUBLIC" ||
        widget?.dashboard_page_name === "TWITTER_PRIVATE"))
  ) {
    return "piechart";
  } else return "stackedbar";
};
const handleWidgetTypes = (dashboardInfo, widget) => {
  if (
    pieChartWidgets?.includes(widget?.widget_name) ||
    ((widget?.widget_name === "content_type" ||
      widget?.widget_name === "posts_content_type") &&
      handleCheckContentTypeWidget(dashboardInfo, widget) == "piechart")
  ) {
    return "pieChartWidget";
  } else if (tableWidgets?.includes(widget?.widget_name)) {
    return "tableWidget";
  } else if (mediaWidgets?.includes(widget?.widget_name)) {
    return "mediaWidget";
  } else if (
    stackedBarWidgets?.includes(widget?.widget_name) ||
    ((widget?.widget_name === "content_type" ||
      widget?.widget_name === "posts_content_type") &&
      handleCheckContentTypeWidget(dashboardInfo, widget) == "stackedbar")
  ) {
    return "stackedBarWidget";
  } else if (themesWidgets?.includes(widget?.widget_name)) {
    return "themesWidget";
  } else if (barChartWidgets?.includes(widget?.widget_name)) {
    return "barChartWidget";
  } else if (stackedLineWidgets?.includes(widget?.widget_name)) {
    return "stackedLineWidget";
  } else if (punchCardWidgets?.includes(widget?.widget_name)) {
    return "punchCardWidget";
  } else if (lineChartWidgets?.includes(widget?.widget_name)) {
    return "lineChartWidget";
  }
};
export default handleWidgetTypes;
