import { Box, Chip, Divider, Grid } from "@mui/material";
import AiAgentsFields from "../aiAgentsFields";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import UploadImage from "./uploadImage";

const BasicInformation = ({
  formik,
  aiAgentshandleChange,
  customHandleChange,
  isFormDisabled,
}) => {
  const intl = useIntl();

  const enableAgentNickName = formik?.values?.enable_agent_nickname;
  return (
    <Box className="ai-agents-form-box-wrapper">
      <Box className="ai-agents-form-box-title" component="h4">
        {CheckValueLocale("ai_agent_basic_information", "", {}, intl)}
      </Box>
      <Box className="ai-agents-form-box">
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale("ai_agent_name_label", "", {}, intl)}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <AiAgentsFields
              type="text"
              id="ai-agent-name-luci"
              label={CheckValueLocale("ai_agent_name", "", {}, intl)}
              name="name"
              value={formik?.values?.name}
              onChange={aiAgentshandleChange}
              error={!!formik?.errors?.name}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(formik?.errors?.name, "", {}, intl)}
              required
              disabled={isFormDisabled}
            />
          </Grid>
        </Grid>
        <div className="ai-agents-form-hidden-divider" />
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale("ai_agent_nickname_label", "", {}, intl)}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <AiAgentsFields
              disabled={isFormDisabled}
              type="switch"
              id="enable-ai-agent-luci"
              switchLabel={CheckValueLocale("enable_ai_agent", "", {}, intl)}
              name="enable_agent_nickname"
              checked={enableAgentNickName}
              onChange={(e) =>
                customHandleChange(
                  "enable_agent_nickname",
                  !enableAgentNickName,
                )
              }
              error={!!formik?.errors?.enable_agent_nickname}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(
                formik?.errors?.enable_agent_nickname,
                "",
                {},
                intl,
              )}
            />
            {enableAgentNickName ? (
              <AiAgentsFields
                disabled={isFormDisabled}
                type="text"
                id="ai-agent-agent-nickname-luci"
                name="agent_nickname"
                value={formik?.values?.agent_nickname}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.agent_nickname}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.agent_nickname,
                  "",
                  {},
                  intl,
                )}
              />
            ) : null}
          </Grid>
        </Grid>
        <Divider orientation="horizontal" className="ai-agents-form-divider" />
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale("ai_agent_image_label", "", {}, intl)}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <UploadImage
              disabled={isFormDisabled}
              value={formik?.values?.ai_agent_image}
              isFormDisabled={isFormDisabled}
              onChangeValue={(image) =>
                customHandleChange("ai_agent_image", image)
              }
            />
          </Grid>
        </Grid>
        <Divider orientation="horizontal" className="ai-agents-form-divider" />
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale(
                "ai_agent_select_enganement_type",
                "",
                {},
                intl,
              )}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <AiAgentsFields
              type="radio"
              id="ai-agent-type-luci"
              horizontalOptions
              required
              name="engagement_type"
              value={formik?.values?.engagement_type}
              onChange={aiAgentshandleChange}
              error={!!formik?.errors?.engagement_type}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(
                formik?.errors?.engagement_type,
                "",
                {},
                intl,
              )}
              options={[
                {
                  value: "direct_messages",
                  name: CheckValueLocale(
                    "ai_agent_direct_message_type",
                    "",
                    {},
                    intl,
                  ),
                  disabled: isFormDisabled,
                },
                {
                  value: "comments_mentions",
                  name: (
                    <>
                      {CheckValueLocale(
                        "ai_agent_comments_mentions_type",
                        "",
                        {},
                        intl,
                      )}
                    </>
                  ),
                  chip: (
                    <Chip
                      className="ai-agents-form-chip"
                      label={CheckValueLocale("soon_ai_agent", "", {}, intl)}
                    />
                  ),
                  disabled: true,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BasicInformation;
