import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Lottie from "react-lottie";
import typingAnimation from "./typing-animation.json";
import AIImage from "images/ai-agents/ai-agent-chat-icon.svg";

const AIAgentTypingAnimation = ({ isTyping, aiAgentDetails }) => {
  const intl = useIntl();
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);

  useEffect(() => {
    setIsAnimationPlaying(isTyping);
  }, [isTyping]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: typingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const aiAgentImage = aiAgentDetails?.ai_agent_image || AIImage;

  return (
    <div className="ai-agent-chat-thinking-row">
      <Avatar src={aiAgentImage} className="ai-agent-chat-message-avatar" />
      <div className="ai-agent-chat-thinking-container">
        {isAnimationPlaying && (
          <Lottie options={defaultOptions} height={15} width={31.35} />
        )}
      </div>
    </div>
  );
};

export default AIAgentTypingAnimation;
