import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";

const DaysSlider = (props) => {
  const selectDay = (day) => {
    props?.selectDay(day);
  };

  const navigate = (direction) => {
    const { days, navigateDays } = props || {};
    let date = "";
    if (direction === "prev") {
      date = moment(days?.[0])?.subtract(1, "days")?.format("YYYY-MM-DD");
    } else {
      date = moment(days?.[10])?.add(1, "days")?.format("YYYY-MM-DD");
    }
    navigateDays(date, direction);
  };

  const { intl } = props || {};

  const { days, activeDay, analysis_created_at, tweet_datetime } = props || {};

  const prev = localStorage?.lang == "ar" ? "right" : "left";
  const next = localStorage?.lang == "ar" ? "left" : "right";

  const prevDisabled = moment(tweet_datetime)?.isBefore(days?.[0], "day")
    ? false
    : true;
  const nextDisabled = moment(analysis_created_at)?.isAfter(
    days[days?.length - 1],
    "day",
  )
    ? false
    : true;

  return (
    <div className="day-slider">
      <button
        className="slidePrev"
        disabled={prevDisabled}
        onClick={() => navigate("prev")}
        id="notification-single-tweet-analysis-navigate-back-btn"
      >
        <FontAwesomeIcon icon={faAngleDoubleLeft} />
      </button>
      {days?.map((day, i) => {
        return (
          moment(day)?.isSameOrBefore(analysis_created_at, "day") &&
          moment(day)?.isSameOrAfter(tweet_datetime, "day") && (
            <div
              className={day === activeDay ? "day-item active" : "day-item"}
              key={i}
              onClick={() => selectDay(day)}
            >
              <span className="bullet"></span>
              <span className="theDay">{day}</span>
            </div>
          )
        );
      })}
      <button
        className="slideNext"
        disabled={nextDisabled}
        onClick={() => navigate("next")}
        id="notification-single-tweet-analysis-navigate-next-btn"
      >
        <FontAwesomeIcon icon={faAngleDoubleRight} />
      </button>
    </div>
  );
};

export default DaysSlider;
