import { Box } from "@mui/material";
import AIAgentChatMessage from "./aiAgentChatMessage";
import AIAgentTypingAnimation from "./aiAgentTypingAnimation";
import { useSelector } from "react-redux";
import { useContext, useEffect, useRef } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";

const AIAgentChat = ({ isAiAgentThinking, aiAgentDetails }) => {
  const { aiAgentSelected, selectedAiAgentPublishedVersion } =
    useContext(AiAgentsContext);
  const { chatMessages } = useSelector((state) => state?.aiAgentChatSlice);
  const lastItemRef = useRef(null);
  const accessKey = selectedAiAgentPublishedVersion
    ? `${aiAgentSelected}_${selectedAiAgentPublishedVersion}`
    : aiAgentSelected;
  const currentChatMessages = chatMessages?.[accessKey] || [];

  useEffect(() => {
    if (lastItemRef) {
      lastItemRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentChatMessages]);

  let lastSender = null;
  return (
    <Box className="ai-agent-chat-content-box">
      {currentChatMessages?.map((msg, index) => {
        const showNameOrAvatar = lastSender !== msg?.isSent;
        lastSender = msg?.isSent;
        const isLastMessage = index === currentChatMessages?.length - 1;
        return (
          <AIAgentChatMessage
            key={index}
            text={msg?.text}
            isSent={msg?.isSent}
            showNameOrAvatar={showNameOrAvatar}
            itemRef={isLastMessage ? lastItemRef : null}
            aiAgentDetails={aiAgentDetails}
          />
        );
      })}
      {isAiAgentThinking ? (
        <AIAgentTypingAnimation
          aiAgentDetails={aiAgentDetails}
          isTyping={true}
        />
      ) : null}
    </Box>
  );
};

export default AIAgentChat;
