import React from "react";
import { Box, Tooltip } from "@mui/material";
import total from "images/engagements-images/total.svg";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import {
  faCommentDots,
  faComments,
  faQuoteLeft,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";

const Details = (props) => {
  const intl = useIntl();
  const {
    showTotalEngagementsHandler,
    selectedCard,
    showEngagementsHint,
    engagementsDetails,
  } = props;
  return (
    <Box className="user-profile-side-bar-content user-details-side-bar-content-container">
      {showEngagementsHint ? (
        <Box className="content-box engagement-details-box">
          <CustomizedTooltip
            id="engagements-details-tooltip"
            title={
              <span className="total-tooltip">
                {CheckValueLocale(
                  "total_engagements_profile_hint",
                  "",
                  {},
                  intl,
                )}
              </span>
            }
            placement={
              window?.localStorage?.lang === "en" ? "left-start" : "right-start"
            }
            arrow
          >
            <span className="title-box">
              {CheckValueLocale("engagements_details", "", {}, intl)}:
            </span>
          </CustomizedTooltip>

          <span
            className="value-box show-total"
            onClick={showTotalEngagementsHandler}
          >
            {CheckValueLocale("show", "", {}, intl)}
            <FontAwesomeIcon
              className="show-engagements-icon"
              icon={faExternalLink}
            />
          </span>
        </Box>
      ) : null}
      <Box className="content-box">
        <span className="title-box">
          <img src={total} />
          {CheckValueLocale("total_engagements_profile", "", {}, intl)}:
        </span>
        <span className="value-box">
          {engagementsDetails?.map((el) => {
            return el?.total_engagements ? (
              <>
                {+el?.total_engagements >= 1000
                  ? `${
                      (+el?.total_engagements / 1000)?.toFixed() > 1000
                        ? (
                            (+el?.total_engagements / 1000)?.toFixed() / 1000
                          )?.toFixed() + "m "
                        : (+el?.total_engagements / 1000)?.toFixed() + "k "
                    }`
                  : `${el?.total_engagements} `}
              </>
            ) : (
              "-"
            );
          })}
        </span>
      </Box>
      <Box className="content-box">
        <span className="title-box">
          <FontAwesomeIcon icon={faCommentDots} />
          {CheckValueLocale("original_posts", "", {}, intl)}:
        </span>
        <span className="value-box">
          {engagementsDetails?.map((el) => {
            return el?.original ? (
              <>
                {+el?.original >= 1000
                  ? `${
                      (+el?.original / 1000)?.toFixed() > 1000
                        ? (
                            (+el?.original / 1000)?.toFixed() / 1000
                          )?.toFixed() + "m "
                        : (+el?.original / 1000)?.toFixed() + "k "
                    }`
                  : `${el?.original} `}
              </>
            ) : (
              "-"
            );
          })}
        </span>
      </Box>
      <Box className="content-box">
        <span className="title-box">
          <FontAwesomeIcon icon={faComments} />
          {CheckValueLocale("comment_count", "", {}, intl)}:
        </span>
        <span className="value-box">
          {engagementsDetails?.map((el) => {
            return el?.reply ? (
              <>
                {+el?.reply >= 1000
                  ? `${
                      (+el?.reply / 1000)?.toFixed() > 1000
                        ? ((+el?.reply / 1000)?.toFixed() / 1000)?.toFixed() +
                          "m "
                        : (+el?.reply / 1000)?.toFixed() + "k "
                    }`
                  : `${el?.reply} `}
              </>
            ) : (
              "-"
            );
          })}
        </span>
      </Box>
      <Box className="content-box">
        <span className="title-box">
          <FontAwesomeIcon icon={faRetweet} />
          {CheckValueLocale("profile_reposts", "", {}, intl)}:
        </span>
        <span className="value-box">
          {engagementsDetails?.map((el) => {
            return el?.retweet ? (
              <>
                {+el?.retweet >= 1000
                  ? `${
                      (+el?.retweet / 1000)?.toFixed() > 1000
                        ? ((+el?.retweet / 1000)?.toFixed() / 1000)?.toFixed() +
                          "m "
                        : (+el?.retweet / 1000)?.toFixed() + "k "
                    }`
                  : `${el?.retweet} `}
              </>
            ) : (
              "-"
            );
          })}
        </span>
      </Box>
      <Box className="content-box">
        <span className="title-box">
          <FontAwesomeIcon icon={faQuoteLeft} />
          {CheckValueLocale("quotes", "", {}, intl)}:
        </span>
        <span className="value-box">
          {engagementsDetails?.map((el) => {
            return el?.quote ? (
              <>
                {+el?.quote >= 1000
                  ? `${
                      (+el?.quote / 1000)?.toFixed() > 1000
                        ? ((+el?.quote / 1000)?.toFixed() / 1000)?.toFixed() +
                          "m "
                        : (+el?.quote / 1000)?.toFixed() + "k "
                    }`
                  : `${el?.quote} `}
              </>
            ) : (
              "-"
            );
          })}
        </span>
      </Box>
    </Box>
  );
};

export default Details;
