import { faFileImport } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Grid, Tooltip } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import AiAgentsFields from "./aiAgentsFields";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useLuciBusinessDetailsInformel } from "pages/engagements/components/aiAgents/aiAgentsHooks/useLuciBusinessDetailsInformel";
import { useShowLuciBusinessDetails } from "pages/engagements/components/aiAgents/aiAgentsHooks/useShowLuciBusinessDetails";

const ImproveAiResponses = ({
  formik,
  aiAgentshandleChange,
  customHandleChange,
  isFormDisabled,
}) => {
  const intl = useIntl();

  const {
    mutate: showLuciBusinessDetails,
    isPending: showLuciBusinessDetailsLoading,
  } = useShowLuciBusinessDetails();

  const { data: luciBusinessDetails, isLoading: luciBusinessDetailsLoading } =
    useLuciBusinessDetailsInformel();

  const luciIndustryOptions =
    luciBusinessDetails?.step1
      ?.find((item) => item?.key === "industry")
      ?.value?.map((value) => {
        return {
          name: CheckValueLocale(`luci_${value}`, "", {}, intl),
          id: value,
        };
      }) || [];

  const businessTypeOptions =
    luciBusinessDetails?.step1
      ?.find((item) => item?.key === "business_type")
      ?.value?.map((value) => {
        return {
          name: CheckValueLocale(`luci_${value}`, "", {}, intl),
          id: value,
        };
      }) || [];

  const brandToneVoiceOptions =
    luciBusinessDetails?.step2
      ?.find((item) => item?.key === "brand_tone_voice")
      ?.value?.map((value) => {
        return {
          name: CheckValueLocale(`luci_${value}`, "", {}, intl),
          id: value,
        };
      }) || [];

  const luciDataFillBtnDisabled =
    showLuciBusinessDetailsLoading || luciBusinessDetailsLoading;

  const luciDataFillBtnHandler = () => {
    formik?.setErrors({});
    showLuciBusinessDetails(null, {
      onSuccess: (data) => {
        const businessDetails = data?.data?.business_details;
        let luciObj = {
          isEditObj: true,
        };
        if (businessDetails?.business_type) {
          luciObj = {
            ...luciObj,
            business_type: businessDetails?.business_type,
          };
          customHandleChange(
            "business_type",
            businessDetails?.business_type,
            luciObj,
          );
        }
        if (businessDetails?.business_hq) {
          luciObj = {
            ...luciObj,
            business_hq: businessDetails?.business_hq,
          };
          customHandleChange(
            "business_hq",
            businessDetails?.business_hq,
            luciObj,
          );
        }
        if (businessDetails?.brand_tone_voice) {
          luciObj = {
            ...luciObj,
            business_brand_of_tone_voice: businessDetails?.brand_tone_voice,
          };
          customHandleChange(
            "business_brand_of_tone_voice",
            businessDetails?.brand_tone_voice,
            luciObj,
          );
        }
        if (businessDetails?.industry) {
          luciObj = {
            ...luciObj,
            business_industry: businessDetails?.industry,
          };
          customHandleChange(
            "business_industry",
            businessDetails?.industry,
            luciObj,
          );
        }
      },
    });
  };

  const activeProducts = JSON.parse(
    window?.localStorage?.activeProducts || null,
  );
  const luciActive = activeProducts?.find((product) => product?.name == "LUCI");

  return (
    <Box className="ai-agents-form-box-wrapper">
      <Box className="ai-agents-form-box-title" component="h4">
        {CheckValueLocale("improve_ai_responses", "", {}, intl)}
      </Box>
      <Box className="ai-agents-form-box">
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale("improve_ai_res_first_desc", "", {}, intl)}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Tooltip
              title={
                <Box component="span" className="ai-use-luci-tooltip">
                  {CheckValueLocale("use_luci_data_fill_tooltip", "", {}, intl)}
                </Box>
              }
              id="use-luci-data-fill-tooltip"
              placement="bottom"
              arrow
            >
              {luciActive ? (
                <Box className="use-luci-fill-btn">
                  <LucButton
                    variant="outline"
                    startIcon={<FontAwesomeIcon icon={faFileImport} />}
                    loading={showLuciBusinessDetailsLoading}
                    disabled={luciDataFillBtnDisabled || isFormDisabled}
                    onClick={luciDataFillBtnHandler}
                    id="ai-agent-button-luci-data-fill"
                  >
                    {CheckValueLocale("use_luci_data_fill_btn", "", {}, intl)}
                  </LucButton>
                </Box>
              ) : null}
            </Tooltip>
            <Box className="ai-agents-form-fields-wrapper">
              <AiAgentsFields
                disabled={isFormDisabled}
                labelClassName="text-underlined"
                type="select"
                id="industsry-ai-agents-luci"
                selectOptions={luciIndustryOptions}
                label={CheckValueLocale("luci_industry", "", {}, intl)}
                name="business_industry"
                value={formik?.values?.business_industry}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.business_industry}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.business_industry,
                  "",
                  {},
                  intl,
                )}
                required
                multiple
                luciSelect
              />
              <AiAgentsFields
                disabled={isFormDisabled}
                labelClassName="text-underlined"
                type="select"
                id="business-type-ai-agents-luci"
                selectOptions={businessTypeOptions}
                label={CheckValueLocale("luci_business_type", "", {}, intl)}
                name="business_type"
                value={formik?.values?.business_type}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.business_type}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.business_type,
                  "",
                  {},
                  intl,
                )}
                required
                multiple
                luciSelect
              />
              <AiAgentsFields
                disabled={isFormDisabled}
                labelClassName="text-underlined"
                type="text"
                id="business-hq-ai-agents-luci"
                label={CheckValueLocale("luci_business_hq", "", {}, intl)}
                placeholder={CheckValueLocale(
                  "luci_business_hq_label",
                  "",
                  {},
                  intl,
                )}
                name="business_hq"
                value={formik?.values?.business_hq}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.business_hq}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.business_hq,
                  "",
                  {},
                  intl,
                )}
                required
              />
              <AiAgentsFields
                disabled={isFormDisabled}
                labelClassName="text-underlined"
                type="select"
                id="business-type-ai-agents-luci"
                selectOptions={brandToneVoiceOptions}
                label={CheckValueLocale("luci_brand_tone_voice", "", {}, intl)}
                name="business_brand_of_tone_voice"
                value={formik?.values?.business_brand_of_tone_voice}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.business_brand_of_tone_voice}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.business_brand_of_tone_voice,
                  "",
                  {},
                  intl,
                )}
                required
                luciSelect
              />
            </Box>
          </Grid>
        </Grid>
        <Divider orientation="horizontal" className="ai-agents-form-divider" />
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale("improve_ai_res_sec_desc", "", {}, intl)}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <AiAgentsFields
              disabled={isFormDisabled}
              type="textarea"
              id="instructions-ai-agents-luci"
              label={CheckValueLocale(
                "instructions_for_ai_agent",
                "",
                {},
                intl,
              )}
              placeholder={CheckValueLocale("write_here", "", {}, intl)}
              name="ai_agent_instruction"
              value={formik?.values?.ai_agent_instruction}
              onChange={aiAgentshandleChange}
              error={!!formik?.errors?.ai_agent_instruction}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(
                formik?.errors?.ai_agent_instruction,
                "",
                {},
                intl,
              )}
              required
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ImproveAiResponses;
