// *******COMMON FILE,Any change in this file will have effect on many pages
// Please Don't Change, delete or update in this file without perrmission

import ReactEcharts from "echarts-for-react";
import { useIntl } from "react-intl";
import { CheckValueLocale, secondsToHms } from "utils/helpers";
import { getSocialUrlImg } from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView/components/controlWidget/components/dataSourceImage";

const BarChart = (props) => {
  const { listDataSourceIcon } = props; // to display monitor dataSource icon when this widget in comparison
  const intl = useIntl();

  const formatToolTip = (data, toolTipLabel) => {
    let data_value = data[0].value;

    // ** Custom Dashboard Comparison Only **
    if (props?.isComparison === true) {
      let tooltipText = "";
      props?.tooltipData?.[data?.[0]?.dataIndex]?.map((item) => {
        return (tooltipText += `${data?.[0]?.marker} ${CheckValueLocale(
          item?.name,
          "",
          {},
          intl,
        )}: ${
          item?.name === "avg_response_time_in_seconds"
            ? secondsToHms(item?.value)?.join(":")
            : item?.value
        } <br />`);
      });
      return tooltipText;
    }

    if (props?.isScaleQues === true) {
      return `${data[0].marker} ${Number(data_value).toLocaleString()}`;
    }
    if (props.isSurvey) {
      return `${data[0].marker} ${CheckValueLocale(
        props.getSurveyName(data[0].name),
        "",
        {},
        intl,
      )}   : ${Number(data_value).toLocaleString()}`;
    }
    if (toolTipLabel) {
      return `${CheckValueLocale(data[0].name, "", {}, intl)} <br /> ${
        data[0].marker
      } ${toolTipLabel} : ${Number(data_value).toLocaleString()}`;
    }

    return `${data[0].marker} ${CheckValueLocale(
      data[0].name,
      "",
      {},
      intl,
    )}: ${Number(data_value).toLocaleString()}`;
  };

  //only used when this widget is in the comparison, to display monitor dataSource icon
  const richObject = {};
  if (listDataSourceIcon?.length) {
    listDataSourceIcon?.forEach((dataSource) => {
      richObject[dataSource] = {
        width: 16,
        height: 16,
        backgroundColor: {
          image: getSocialUrlImg(dataSource),
        },
      };
    });
  }

  let option = {
    color: props.dataColor,
    tooltip: {
      trigger: "axis",
      show: props.noData ? false : true,
      backgroundColor: "#fff",
      textStyle: {
        fontSize: 12,
      },
      padding: [10, 15],
      formatter: (val) => formatToolTip(val),
    },
    legend: {
      show: props.showLegend, // Start legendPosition___________________//
      legend:
        props.legendPosition === "right"
          ? {
              data: props.legend,
              itemGap: 25,
              type: "scroll",
              icon: "circle",
              orient: "vertical",
              itemHeight: 15,
              itemWidth: 15,
              right: 1,
              top: 20,
            }
          : props.legendPosition === "topLeft"
            ? {
                data: props.legend,
                itemGap: 15,
                itemHeight: 14,
                itemWidth: 14,
                left: 1,
                top: 0,
              }
            : props.legendPosition === "topRight"
              ? {
                  data: props.legend,
                  itemGap: 15,
                  itemHeight: 14,
                  itemWidth: 14,
                  right: 1,
                  top: 0,
                }
              : {
                  data: props.legend,
                  bottom: 0,
                },
      // End legendPosition___________________//
    },
    calculable: false,
    grid: {
      x: 10,
      x2: 10,
      y: 30,
      y2: 10,
      left: props.yAxisGridLeft,
      right: "1%",
      bottom: props.showZoomPercentage ? "20%" : "5%",
      height: props.gridHeight,
    },

    xAxis: {
      data: props.dataLabels,
      type: "category",
      axisLabel: {
        interval: listDataSourceIcon?.length ? 0 : "auto",
        fontSize: 11,
        color: props.xAxisFontColor,
        fontWeight: props.xAxisFontWeight,
        textStyle: {
          color: "rgba(0, 0, 0, 0.42)",
        },
        formatter: function (value, index) {
          if (listDataSourceIcon?.length) {
            //to view monitor dataSource icon
            const monitorName = value?.split("-")?.[0];
            const index = value?.split("-")?.[1];
            if (intl?.locale === "ar") {
              return `{text|${monitorName}} {${listDataSourceIcon?.[index]}|}`;
            }
            return `{${listDataSourceIcon?.[index]}|} {text|${monitorName}}`;
          } else {
            return `${value}`; //if this widget is not in the comparison
          }
        },
        rich: {
          text: {
            color: "#00000099",
            fontSize: 12,
            lineHeight: 14.4,
            fontWeight: 400,
          },
          ...richObject,
        },
      },
      axisLine: {
        show: true,
        onZero: true,
        lineStyle: {
          width: 0,
        },
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: [
      {
        type: "value",
        axisLabel: {
          textStyle: {
            color: "rgba(0, 0, 0, 0.42)",
          },
        },
      },
    ],
    series: [
      {
        name: props.dataLabels,
        cursor: props.handleOpenQuickInspect ? "pointer" : "default",
        type: "bar",
        stack: "bar",
        barMaxWidth: "50px",
        data: props.dataValues,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: function (param) {
            return props.customBarColor
              ? props.customBarColor[param.name]
              : props?.isSurvey
                ? props.colorsSurvey[param.name]
                : props.dataColor;
          },
        },
      },
    ],
    dataZoom: [
      {
        showDetail: !props?.isComparison,
        start: props.sliderPercentage,
        end: props.showZoomPercentage && !props?.isComparison ? 50 : 100,
        bottom: 10,
        show: props.showZoomPercentage ? true : false,
        fillerColor: "rgba(42, 170, 226, 0.06)",
        dataBackground: {
          areaStyle: {
            color: "transparent",
          },
        },
        selectedDataBackground: {
          lineStyle: {
            color: "rgba(203, 213, 225, 1)",
          },
          areaStyle: {
            color: "transparent",
          },
        },
      },
    ],
  };

  return (
    <ReactEcharts
      notMerge={true}
      option={option}
      theme="custom_theme"
      style={{ height: "370px", width: "100%" }}
    />
  );
};

export default BarChart;
