import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const AddKnowledgeBaseStatusCell = ({ status }) => {
  const intl = useIntl();
  const isFailed = status === "failed";
  return (
    <Tooltip
      arrow
      placement="left-start"
      title={
        isFailed
          ? CheckValueLocale(
              "knowledge_base_resourse_failed_status_tooltip",
              "",
              {},
              intl,
            )
          : ""
      }
    >
      <Box className={`status ${status}`}>
        {CheckValueLocale(status, "", {}, intl)}
      </Box>
    </Tooltip>
  );
};

export default AddKnowledgeBaseStatusCell;
