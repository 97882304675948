import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import AiAgentsFields from "../aiAgentsFields";
import { useFormik } from "formik";
import { addUrlResourceValidationSchema } from "pages/engagements/components/aiAgents/aiAgentsValidation/addUrlResourceValidationSchema";
import { useAddAiAgentResource } from "pages/engagements/components/aiAgents/aiAgentsHooks/useAddAiAgentResource";
import { useContext } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";

const AddURLKnowledgeBase = ({
  open,
  setIsAddURLKnowledgeBaseOpen,
  handleClose,
}) => {
  const intl = useIntl();
  const { setAiAgentSnackBar, aiAgentSelected } = useContext(AiAgentsContext);
  const { mutate: addUrl, isPending } = useAddAiAgentResource();

  const formik = useFormik({
    initialValues: {
      title: "",
      url: "",
    },
    validationSchema: addUrlResourceValidationSchema(intl),
    onSubmit: (values) => {
      addUrl(
        {
          url: values?.url,
          title: values?.title,
          ai_agent_id: aiAgentSelected,
          source_type: "url",
          user_id: localStorage.getItem("user_id"),
        },
        {
          onSuccess: (data) => {
            const hasError = data?.errorMsg;
            handleClose();
            setAiAgentSnackBar({
              success: !hasError,
              message: hasError
                ? CheckValueLocale("try_again_error_message", "", {}, intl)
                : CheckValueLocale("url_added_success", "", {}, intl),
            });
            setIsAddURLKnowledgeBaseOpen(hasError);
            formik?.resetForm();
          },
        },
      );
    },
  });

  const modalBody = (
    <Box className="url-knowledge-base-body-container">
      <AiAgentsFields
        className="ai-agent-reset-margin"
        type="text"
        label={CheckValueLocale("add_url_title", "", {}, intl)}
        value={formik?.values?.title}
        onChange={formik?.handleChange}
        name="title"
        error={!!formik?.errors?.title}
        onBlur={formik?.handleBlur}
        helperText={formik?.touched?.title && formik?.errors?.title}
        placeholder={CheckValueLocale("add_url_placeholder", "", {}, intl)}
        required
      />
      <AiAgentsFields
        className="ai-agent-reset-margin"
        type="text"
        label={CheckValueLocale("url", "", {}, intl)}
        value={formik?.values?.url}
        onChange={formik?.handleChange}
        name="url"
        error={!!formik?.errors?.url}
        onBlur={formik?.handleBlur}
        helperText={formik?.touched?.url ? formik?.errors?.url : undefined}
        placeholder="http://"
        required
      />
      <Box className="add-knowledge-base-note-row">
        <Typography className="add-knowledge-base-note-title">
          {CheckValueLocale("please_note", "", {}, intl)}
        </Typography>
        <Typography className="add-knowledge-base-note-body">
          {CheckValueLocale("add_url_note", "", {}, intl)}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("add_url_link", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("add", "", {}, intl)}
      disabled={false}
      isRightBtnLoading={isPending}
      open={open || false}
      close={() => {
        if (!isPending) {
          handleClose();
          setIsAddURLKnowledgeBaseOpen(null);
          formik?.resetForm();
        }
      }}
      accept={() => formik?.handleSubmit()}
      classeName="ai-agent-popupModal"
      dialogContentId="pause-ai-agent"
    />
  );
};

export default AddURLKnowledgeBase;
