import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import "react-intl-tel-input/dist/main.css";
import "./mainAddNewUser.scss";
import { CheckValueLocale, getSortedArray } from "utils/helpers";
import StepsHeader from "./componants/header";
import StepOne from "./componants/stepOne";
import StepTwo from "./componants/stepTwo";
import StepThree from "./componants/stepThree";
import StepsFooter from "./componants/footer";
import BreadCrumb from "components/breadCrumb";
import CompaniesController from "services/controllers/companiesController";
import { workgingHoursData } from "./tabs/workingHours";
import { typeFormatMonitor } from "../sharedLogic/inviteUserAdapter";

const AddNewUser = () => {
  const intl = useIntl();
  const [smId, setSmId] = useState(false);
  const [cxmId, setCxmId] = useState(false);
  const [cxmData, setCxmData] = useState({});
  const [smData, setSmData] = useState({});
  const [accData, setAccData] = useState({});
  const [steps, setSteps] = useState(["user_det", "pro_perm"]);
  const [activeStep, setActiveStep] = useState(0);
  const [callApiDone, setCallApiDone] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [stepOneData, setStepOneData] = useState({
    first_name: "",
    last_name: "",
    mail: "",
    phone: "",
    job_title: "",
    Valid: false,
  });
  const [stepTwoData, setStepTwoData] = useState({
    general_setting: "moderator",
    social_listening: "moderator",
    channel_analytics: "moderator",
    surveys: "moderator",
    audience: "moderator",
    engagement: "no_acc",
    ai_agent: "no_acc",
    lucidya_api: "moderator",
  });
  const [accTableDetailsData, setAccTableDetailsData] = useState({
    account_access: [],
    account_access_page: 1,
    account_access_counter: 0,
    account_access_pages: 0,
    account_access_loading: false,

    monitors_access: [],
    monitors_access_page: 1,
    monitors_access_counter: 0,
    monitors_access_pages: 0,
    monitors_access_loading: false,

    channels_access: [],
    channels_access_page: 1,
    channels_access_counter: 0,
    channels_access_pages: 0,
    channels_access_loading: false,
  });

  const [accTableData, setAccTableData] = useState({
    engagement_features: [],
    account_access: [],
    monitors_access: [],
    channels_access: [],
  });

  const [accTableSelectedData, setAccTableSelectedData] = useState({
    engagement_features: {
      engag_acc_title: [],
      saved_reps_mang: [],
    },
    account_access: {},
    monitors_access: {},
    channels_access: {},
  });

  const [tableCols, setTableCols] = useState({});
  const [teamsData, setTeamsData] = useState({
    teamsOptions: [],
    teamsSelected: [],
  });
  const [advancedTabsData, setAdvancedTabsData] = useState({
    languages: [{ value: "", subValue: "" }],
    skills: [{ value: "", subValue: "" }],
    workingHours: workgingHoursData,
  });
  const [infoTabsData, setInfoTabsData] = useState({
    sentiments: [],
    themes: [],
    segments: [],
    topics: [
      {
        id: "",
        name: "",
        topics: [],
      },
    ],
    dialects: [],
  });
  const [tabsErrors, setTabsErrors] = useState({
    topicsPreError: false,
    topics: false,
    workgingHoursPreError: false,
    workingHours: false,
  });

  const [accEngagId, setAccEngagId] = useState({
    engag_acc_title: null,
    saved_reps_mang: null,
  });

  useEffect(() => {
    let activeProducts = JSON.parse(window?.localStorage?.activeProducts);
    let smID = activeProducts?.filter(
      (item) => item?.name == "SM" && item?.active,
    )?.[0]?.id;

    let cxmID = activeProducts?.filter(
      (item) => item?.name == "CXM" && item?.active,
    )?.[0]?.id;

    if (smID) {
      setSmId(smID);
    } else {
      delete accTableData?.monitors_access;
      setAccTableData({ ...accTableData });
    }
    if (cxmID) {
      setCxmId(cxmID);
    } else {
      delete accTableData?.channels_access;
      setAccTableData({ ...accTableData });
    }
  }, []);

  const getAccount = async (pageNum, limit = 6) => {
    setAccTableDetailsData({
      ...accTableDetailsData,
      account_access_loading: true,
    });
    await CompaniesController.getAccounts(pageNum, limit).then((result) => {
      if (result?.data?.data?.length) {
        let data = [];
        result?.data?.data?.map((item) => {
          if (item?.attributes?.account_type == "TWITTER") {
            data.push({
              id: `TWITTER-${item?.attributes?.id}`,
              data_source: item?.attributes?.account_type,
              profileImg: item?.attributes?.image_url,
              name: item?.attributes?.name,
              handleName: item?.attributes?.user_name
                ? item?.attributes?.user_name?.includes("@")
                  ? item?.attributes?.user_name
                  : "@" + item?.attributes?.user_name
                : null,
            });
          }
        });

        result?.data?.included?.map((item) => {
          data.push({
            id: `${item?.type == "facebook_page" ? "FACEBOOK" : "INSTAGRAM"}-${
              item?.attributes?.id
            }`,
            data_source:
              item?.type == "facebook_page" ? "FACEBOOK" : "INSTAGRAM",
            profileImg: item?.attributes?.image_url,
            name: item?.attributes?.name,
            handleName: item?.attributes?.user_name
              ? item?.attributes?.user_name?.includes("@")
                ? item?.attributes?.user_name
                : "@" + item?.attributes?.user_name
              : null,
          });
        });
        setAccTableData({
          ...accTableData,
          account_access: [...accTableData?.account_access, ...data],
        });
        setAccTableDetailsData({
          ...accTableDetailsData,
          account_access: [...accTableDetailsData?.account_access, ...data],
          account_access_counter: result?.data?.pagination?.next
            ? result?.data?.pagination?.count +
              [...accTableDetailsData?.account_access, ...data]?.length
            : [...accTableDetailsData?.account_access, ...data]?.length,
          account_access_pages: result?.data?.pagination?.pages,
          account_access_loading: false,
        });
        setAccData({
          account_access: [...data],
          account_access_counter: result?.data?.pagination?.next
            ? result?.data?.pagination?.count +
              [...accTableDetailsData?.account_access, ...data]?.length
            : [...accTableDetailsData?.account_access, ...data]?.length,
          account_access_pages: result?.data?.pagination?.pages,
          account_access_loading: false,
        });
      }
    });
  };

  const getMonitors = async (pageNum, limit = 6) => {
    setAccTableDetailsData({
      ...accTableDetailsData,
      monitors_access_loading: true,
    });
    await CompaniesController.getMonitorsOrChannels(smId, pageNum, limit).then(
      (result) => {
        if (result?.data?.data?.length) {
          let data = [];
          result?.data?.data?.map((item) =>
            data.push({
              id: item?.attributes?.id,
              data_source: item?.attributes?.data_sources || [],
              type: typeFormatMonitor(item?.attributes?.monitor_type_name),
              name: item?.attributes?.account_name,
            }),
          );
          setAccTableData({
            ...accTableData,
            monitors_access: [...accTableData?.monitors_access, ...data],
          });
          setAccTableDetailsData({
            ...accTableDetailsData,
            monitors_access: [...accTableDetailsData?.monitors_access, ...data],
            monitors_access_counter: result?.data?.pagination?.count,
            monitors_access_pages: result?.data?.pagination?.pages,
            monitors_access_loading: false,
          });
          setSmData({
            monitors_access: [...data],
            monitors_access_counter: result?.data?.pagination?.count,
            monitors_access_pages: result?.data?.pagination?.pages,
            monitors_access_loading: false,
          });
        }
      },
    );
  };

  const getChannels = async (pageNum, limit = 6) => {
    setAccTableDetailsData({
      ...accTableDetailsData,
      channels_access_loading: true,
    });
    await CompaniesController.getMonitorsOrChannels(cxmId, pageNum, limit).then(
      (result) => {
        if (result?.data?.data?.length) {
          let data = [];
          result?.data?.data?.map((item) =>
            data.push({
              id: item?.attributes?.id,
              data_source: item?.attributes?.data_sources || [],
              type: item?.attributes?.is_dm ? "dm_cxm" : "public_cxm",
              name: item?.attributes?.account_name,
            }),
          );
          setAccTableData({
            ...accTableData,
            channels_access: [...accTableData?.channels_access, ...data],
          });
          setAccTableDetailsData({
            ...accTableDetailsData,
            channels_access: [...accTableDetailsData?.channels_access, ...data],
            channels_access_counter: result?.data?.pagination?.count,
            channels_access_pages: result?.data?.pagination?.pages,
            channels_access_loading: false,
          });
          setCxmData({
            channels_access: [...data],
            channels_access_counter: result?.data?.pagination?.count,
            channels_access_pages: result?.data?.pagination?.pages,
            channels_access_loading: false,
          });
        }
      },
    );
  };

  const getEngagPermissions = async () => {
    await CompaniesController.getEngagPermissions().then((result) => {
      if (result?.data) {
        setTableCols({ ...result?.data });
        setAccEngagId({
          engag_acc_title:
            result?.data?.engagement_features?.engagement_access?.id,
          saved_reps_mang: result?.data?.engagement_features?.saved_replies?.id,
        });
      }
    });
  };

  // handle pagination
  useEffect(() => {
    if (accTableDetailsData?.account_access_page > 1) {
      getAccount(accTableDetailsData?.account_access_page, 6);
    }
  }, [accTableDetailsData?.account_access_page]);

  useEffect(() => {
    if (accTableDetailsData?.monitors_access_page > 1) {
      getMonitors(accTableDetailsData?.monitors_access_page, 6);
    }
  }, [accTableDetailsData?.monitors_access_page]);

  useEffect(() => {
    if (accTableDetailsData?.channels_access_page > 1) {
      getChannels(accTableDetailsData?.channels_access_page, 6);
    }
  }, [accTableDetailsData?.channels_access_page]);

  // first call
  const getTablesData = async () => {
    setNextLoading(true);
    await getEngagPermissions();
    await getAccount(accTableDetailsData?.account_access_page, 6);

    smId && (await getMonitors(accTableDetailsData?.monitors_access_page, 6));

    cxmId && (await getChannels(accTableDetailsData?.channels_access_page, 6));
    setCallApiDone(true);
  };

  // handle Data and stop loading and redirct to step 3
  useEffect(() => {
    if (callApiDone) {
      let objColamsData = {};
      let objData = {
        ...accTableData,
        account_access: accData?.account_access?.length
          ? [...accData?.account_access]
          : [],
      };
      if (smId) {
        objData = {
          ...objData,
          monitors_access: smData?.monitors_access?.length
            ? [...smData?.monitors_access]
            : [],
        };
      }
      if (cxmId) {
        objData = {
          ...objData,
          channels_access: cxmData?.channels_access?.length
            ? [...cxmData?.channels_access]
            : [],
        };
      }
      if (tableCols?.engagement_features) {
        if (tableCols?.engagement_features?.engagement_access) {
          objData.engagement_features = ["engag_acc_title"];
          let dataArr =
            tableCols?.engagement_features?.engagement_access?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features0 = getSortedArray(
            [
              "FULL_ACCESS",
              "INBOX",
              "PUBLISH",
              "SL_ENGAGEMENT",
              "OC_ENGAGEMENT",
            ],
            dataArr,
          );
        }
        if (tableCols?.engagement_features?.saved_replies) {
          objData.engagement_features = [
            ...objData?.engagement_features,
            "saved_reps_mang",
          ];
          let dataArr =
            tableCols?.engagement_features?.saved_replies?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features1 = getSortedArray(
            ["FULL_ACCESS", "CREATE_SAVED_REPLY", "DELETE_SAVED_REPLY"],
            dataArr,
          );
        }
      } else {
        delete objData?.engagement_features;
      }

      if (tableCols?.channels_access) {
        objColamsData.channels_access = tableCols?.channels_access?.map(
          (item) => item?.permission_name,
        );
      } else {
        delete objData?.channels_access;
      }
      if (tableCols?.monitors_access) {
        objColamsData.monitors_access = tableCols?.monitors_access?.map(
          (item) => item?.permission_name,
        );
      } else {
        delete objData?.monitors_access;
      }
      if (tableCols?.account_access) {
        let dataArr = tableCols?.account_access?.map(
          (item) => item?.permission_name,
        );
        objColamsData.account_access = getSortedArray(
          ["FULL_ACCESS", "REPLY", "PUBLISH"],
          dataArr,
        );
      } else {
        delete objData?.account_access;
      }
      setTableCols({ ...objColamsData });
      setAccTableData({
        ...objData,
      });
      setAccTableDetailsData({
        ...accTableDetailsData,
        ...smData,
        ...accData,
        ...cxmData,
      });
      setNextLoading(false);
      setActiveStep(2);
    }
  }, [callApiDone]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    if (!stepTwoData?.engagement || stepTwoData?.engagement == "no_acc") {
      setSteps(["user_det", "pro_perm"]);
    } else {
      setSteps(["user_det", "pro_perm", "Engag_perm"]);
    }
  }, [stepTwoData]);

  const links = [
    {
      name: "settings",
      url: "settings",
    },
    {
      name: "user_list",
      url: "settings/user_managements",
    },
  ];

  return (
    <Box className="main-add-user">
      <BreadCrumb links={links} />
      <Box className="add-user-tit">
        {CheckValueLocale("create_new_user", "", {}, intl)}
      </Box>
      <StepsHeader steps={steps} activeStep={activeStep} />
      <Box className="main-step-contant">
        {activeStep == 0 ? (
          <StepOne setStepOneData={setStepOneData} stepOneData={stepOneData} />
        ) : activeStep == 1 ? (
          <StepTwo stepTwoData={stepTwoData} setStepTwoData={setStepTwoData} />
        ) : (
          <StepThree
            accTableData={accTableData}
            accTableSelectedData={accTableSelectedData}
            setAccTableSelectedData={setAccTableSelectedData}
            accTableDetailsData={accTableDetailsData}
            setAccTableDetailsData={setAccTableDetailsData}
            setAccTableData={setAccTableData}
            tableCols={tableCols}
            advancedTabsData={advancedTabsData}
            setAdvancedTabsData={setAdvancedTabsData}
            infoTabsData={infoTabsData}
            setInfoTabsData={setInfoTabsData}
            tabsErrors={tabsErrors}
            setTabsErrors={setTabsErrors}
            teamsData={teamsData}
            setTeamsData={setTeamsData}
          />
        )}
      </Box>
      <StepsFooter
        isStepOneValid={stepOneData.Valid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps}
        callApiDone={callApiDone}
        getTablesData={getTablesData}
        nextLoading={nextLoading}
        setNextLoading={setNextLoading}
        accTableSelectedData={accTableSelectedData}
        stepTwoData={stepTwoData}
        stepOneData={stepOneData}
        accEngagId={accEngagId}
        advancedTabsData={advancedTabsData}
        infoTabsData={infoTabsData}
        tabsErrors={tabsErrors}
        teamsData={teamsData}
      />
    </Box>
  );
};
export default AddNewUser;
