import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "./component/header";
import SuggestionTabs from "./component/suggestionTabs";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

// { setOpenDetailsSideBar }

const AiSuggestions = ({
  setOpenSidebar,
  text,
  selectedValue,
  itemData,
  chatData,
  setText,
  replyBoxInputTextRef,
  setCheckAiSuggestions,
  setOpenDetailsSideBarReplay,
  monitor_id,
  interaction_id,
  data_source_name,
  savedSuggTab,
  aiRepsTab,
  setSavedSuggTab,
  setAiRepsTab,
  setSavedSuggTabDate,
  isCashedReplays,
  aiReplaysRefresh,
  setAiReplaysRefresh,
}) => {
  const intl = useIntl();

  const [suggestionsData, setSuggestionsData] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [suggestionAiData, setSuggestionAiData] = useState({});
  const [saveDataDone, setSaveDataDone] = useState(false);
  const [suggestionsDataDone, setSuggestionsDataDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [insertedReplyId, setInsertedReplyId] = useState(null);
  const [ToggleState, setToggleState] = useState(1);

  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });
  const aiSuggestionsData = (refresh = undefined) => {
    setLoading(true);
    let context = [];
    if (!itemData?.isDm) {
      context?.push(itemData?.text);
    } else {
      chatData.map((item) => {
        context?.push(item?.content?.text);
      });
    }
    EngagementsController.getAiSuggestions(
      window.localStorage.getItem("engagementsProductId"),
      [...context],
      itemData?.name == "anonymous" ? "" : itemData?.name,
      selectedValue,
      monitor_id,
      interaction_id,
      data_source_name,
      refresh,
    ).then((result) => {
      setSuggestionsData(result?.data?.data?.answers);
      setSuggestionsDataDone(true);
      setLoading(false);
    });
  };

  const AiSavedResponses = (refresh = undefined) => {
    let context = [];
    if (!itemData?.isDm) {
      context?.push(itemData?.text);
    } else {
      chatData.map((item) => {
        context?.push(item?.content?.text);
      });
    }
    EngagementsController.getAiSavedResponses(
      window.localStorage.getItem("engagementsProductId"),
      [...context],
      monitor_id,
      interaction_id,
      data_source_name,
      refresh,
    ).then((result) => {
      if (refresh) {
        setSavedSuggTabDate(result?.data?.data?.caching_date);
      }
      setSaveData(result?.data?.data?.data);
      setSaveDataDone(true);
      setSuggestionAiData(result?.data?.data);
    });
  };
  useEffect(() => {
    if (aiReplaysRefresh) {
      aiSuggestionsData(true);
      AiSavedResponses(true);
      setAiReplaysRefresh(false);
    }
  }, [aiReplaysRefresh]);

  useEffect(() => {
    aiSuggestionsData();
    AiSavedResponses();
  }, []);

  const handleSetText = (text) => {
    const pointerPosition =
      replyBoxInputTextRef?.current?.getCaretPosition?.() || 0;
    setText((prevText) => {
      let newText =
        prevText?.slice(0, pointerPosition) +
        text +
        prevText?.slice(pointerPosition);
      return newText;
    });
    replyBoxInputTextRef?.current?.focus?.(pointerPosition + text?.length);
  };
  useEffect(() => {
    if (!savedSuggTab && aiRepsTab) {
      setToggleState(2);
    }

    if (saveDataDone && suggestionsDataDone && (savedSuggTab || aiRepsTab)) {
      if (savedSuggTab) {
        if (saveData?.length && isCashedReplays) {
          let arrData = [...saveData]?.sort(
            (a, b) => +b?.attributes?.score - +a?.attributes?.score,
          );
          if (+arrData[0]?.attributes?.score >= 0.8) {
            handleSetText(arrData[0]?.attributes?.text);
            setInsertedReplyId(arrData[0]?.id);
          }
        }
      } else {
        if (suggestionsData?.length) {
          let arrData = [...suggestionsData]?.sort(
            (a, b) => +b?.score - +a?.score,
          );
          if (+arrData[0]?.score >= 0.8) {
            handleSetText(arrData[0]?.text);
            setInsertedReplyId(`${0}-${arrData[0]?.text}`);
          }
        }
      }
      setSavedSuggTab(false);
      setAiRepsTab(false);
    }
  }, [
    saveData,
    suggestionsData,
    saveDataDone,
    suggestionsDataDone,
    savedSuggTab,
    aiRepsTab,
  ]);

  return (
    <Box className="details-side-bar-wrapper">
      <Header setOpenSidebar={setOpenSidebar} />
      <SuggestionTabs
        setOpenSidebar={setOpenSidebar}
        suggestionsData={suggestionsData}
        setText={setText}
        replyBoxInputTextRef={replyBoxInputTextRef}
        saveData={saveData}
        setSaveData={setSaveData}
        aiSuggestionsData={() => {
          aiSuggestionsData(true);
          AiSavedResponses(true);
        }}
        setSuggestionsData={setSuggestionsData}
        AiSavedResponses={() => {
          aiSuggestionsData(true);
          AiSavedResponses(true);
        }}
        loading={loading}
        setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
        suggestionAiData={suggestionAiData}
        insertedReplyId={insertedReplyId}
        setInsertedReplyId={setInsertedReplyId}
        ToggleState={ToggleState}
        setToggleState={setToggleState}
      />
    </Box>
  );
};
export default AiSuggestions;
