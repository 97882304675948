import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  TextField,
  Typography,
  Popper,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "../style.scss";
import { getSocialUrlImg } from "./dataSourceImage";

const CustomDashboardAutoComplete = ({
  placeholder,
  options,
  selectedOptions,
  setSelectedOptions,
  loading,
}) => {
  const intl = useIntl();

  const formControlRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [maxSelect, setMaxSelect] = useState(7);
  const [reachedLimit, setReachedLimit] = useState(false);

  useEffect(() => {
    if (selectedOptions?.length >= maxSelect) setReachedLimit(true);
    else setReachedLimit(false);
  }, [selectedOptions]);

  const handleChange = (e, newValue) => {
    if (newValue?.length <= maxSelect) {
      let newSelectedOptions = [];
      newValue?.map((option) => {
        newSelectedOptions?.push({
          id: option?.id,
          data_source_id: option?.data_source_id,
          data_source_name: option?.data_source_name,
          metric_name: option?.metric_name,
          is_selected: option?.is_selected,
        });
      });

      setSelectedOptions(newSelectedOptions);
    }
  };

  const handleInputChange = (e, value) => setInputValue(value);

  const optionItemRender = (props, option, { selected }) => {
    const isDisabled =
      !selected && reachedLimit && !selectedOptions?.includes(option);

    return (
      <li
        id="control-widget-monitors-list-item"
        key={option?.value}
        aria-disabled={true}
        {...props}
      >
        <Box
          className={`control-widget-monitors-list-item ${isDisabled ? "disabled" : ""}`}
        >
          <Checkbox checked={selected} disabled={isDisabled} />
          {option?.data_source_name ? (
            <Box
              component={"img"}
              src={getSocialUrlImg(
                option?.is_dm
                  ? `${option?.data_source_name}_PRIVATE`
                  : option?.data_source_name,
              )}
              alt="dataSource-monitor-icon"
            />
          ) : null}
          <Typography className="monitor-name-title-text">
            {option?.name}
          </Typography>
        </Box>
      </li>
    );
  };

  const inputRender = (params) => (
    <TextField
      {...params}
      onChange={(e) => setInputValue(e.target.value)}
      label={
        open || isInputFocused
          ? null
          : selectedOptions?.length
            ? `${selectedOptions?.length} ${CheckValueLocale("selected_num", "", {}, intl)}`
            : placeholder
      }
      InputLabelProps={{ shrink: false }}
      onFocus={() => setIsInputFocused(true)}
      onBlur={() => setIsInputFocused(false)}
      onKeyDown={(e) => {
        if (e.key === "Backspace" && !inputValue) e.stopPropagation();
      }}
      disabled={loading}
    />
  );

  const getOptionSelected = (option, anotherOption) => {
    return (
      option?.id == anotherOption?.id &&
      option?.data_source_id == anotherOption?.data_source_id
    );
  };

  const CustomPopper = (props) => {
    return (
      <Popper
        {...props}
        className="custom-popper"
        style={{ zIndex: 99999, width: 440 }}
      />
    );
  };

  return (
    <FormControl
      id="custom-dashboard-widget-control-monitors-select"
      className="select-form-control"
      ref={formControlRef}
    >
      <Autocomplete
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options}
        value={selectedOptions}
        inputValue={inputValue}
        renderInput={inputRender}
        renderOption={optionItemRender}
        onChange={handleChange}
        onInputChange={handleInputChange} // Handle input value change
        isOptionEqualToValue={getOptionSelected}
        noOptionsText={CheckValueLocale("empty_options_list", "", {}, intl)}
        disableClearable
        autoHighlight
        disableCloseOnSelect={true}
        getOptionLabel={(option) => option?.name}
        renderTags={() => null} // Disable rendering of tags
        PopperComponent={CustomPopper}
        size="small"
        autoComplete
        fullWidth
        multiple
        disabled={loading}
      />
    </FormControl>
  );
};

export default CustomDashboardAutoComplete;
