import { Box, Grid, Tooltip, Typography } from "@mui/material";
import AiAgentsFields from "../aiAgentsFields";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import AiAgentsWarning from "../../../../aiAgentsWarning";
import { useEffect, useState } from "react";
import { useInboundRoutingsList } from "pages/engagements/components/aiAgents/aiAgentsHooks/useInboundRoutingsList";
import HumanAssignment from "./humanAssignment";

const AutoAssignment = ({
  formik,
  aiAgentshandleChange,
  customHandleChange,
  aiAgentDetails,
  isFormDisabled,
}) => {
  const intl = useIntl();
  const [isInboundRoutingsLoading, setIsInboundRoutingsLoading] =
    useState(false);

  const assignmentInboundRouting =
    formik?.values?.auto_assignment === "inbound_routing";

  const {
    data: inboundRoutingsList,
    isLoading: inboundRoutingsListLoading,
    refetch: refetchInboundRoutingsList,
    isRefetching: isRefetchInboundRoutingsList,
  } = useInboundRoutingsList(assignmentInboundRouting);

  const onOpenInboundRoutingsDropdown = () => {
    setIsInboundRoutingsLoading(true);
    refetchInboundRoutingsList();
  };

  useEffect(() => {
    if (
      inboundRoutingsList &&
      !inboundRoutingsListLoading &&
      !isRefetchInboundRoutingsList
    ) {
      setIsInboundRoutingsLoading(false);
    }
  }, [
    inboundRoutingsList,
    inboundRoutingsListLoading,
    isRefetchInboundRoutingsList,
  ]);

  const inboundRoutingsOptionsList =
    inboundRoutingsList?.map((item) => {
      return item?.attributes?.id;
    }) || [];

  const inboundRoutingsOptionsListObj =
    inboundRoutingsList?.map((item) => {
      return {
        id: item?.attributes?.id,
        name: item?.attributes?.name,
      };
    }) || [];

  const onClickAddNewRouting = () => {
    window.open("/settings/inbound_routing/create/step_1", "_blank");
  };

  const aiUnassignedUsed = aiAgentDetails?.unassigned_used?.status;
  const allowRoutingAiToHuman = formik?.values?.allow_routing_from_ai_to_human;

  const getInboundRoutingObj = (id) =>
    inboundRoutingsOptionsListObj?.find((item) => item?.id == id);

  const hasRoutingTeam = !!inboundRoutingsList?.find(
    (route) => formik?.values?.inbound_routing_id == route?.attributes?.id,
  )?.attributes?.team?.data;

  const showRoutingTeamWarning =
    !hasRoutingTeam &&
    formik?.values?.inbound_routing_id &&
    formik?.values?.auto_assignment === "inbound_routing";

  return (
    <Box className="ai-agents-form-box-wrapper">
      <Box className="ai-agents-form-box-title" component="h4">
        {CheckValueLocale("auto_assignment", "", {}, intl)}
      </Box>
      <Box className="ai-agents-form-box">
        <Grid container spacing={3} className="ai-agents-form-box-container">
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale("auto_assignment_first_desc", "", {}, intl)}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <AiAgentsFields
              disabled={isFormDisabled}
              type="radio"
              horizontalOptions
              required
              aria-labelledby="ai-agent-auto-assignment-type"
              name="auto_assignment"
              value={formik?.values?.auto_assignment}
              onChange={aiAgentshandleChange}
              error={!!formik?.errors?.auto_assignment}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(
                formik?.errors?.auto_assignment,
                "",
                {},
                intl,
              )}
              options={[
                {
                  value: "inbound_routing",
                  name: CheckValueLocale(
                    "ai_agent_inbound_routing_radio_label",
                    "",
                    {},
                    intl,
                  ),
                  disabled: isFormDisabled,
                },
                {
                  value: "unassigned",
                  name: (
                    <Tooltip
                      title={
                        aiUnassignedUsed ? (
                          <Box component="span" className="ai-use-luci-tooltip">
                            {CheckValueLocale(
                              "ai_unassigned_used_tooltip",
                              "",
                              { name: aiAgentDetails?.unassigned_used?.name },
                              intl,
                            )}
                          </Box>
                        ) : (
                          ""
                        )
                      }
                      id="ai-unassigned-used-tooltip"
                      placement="bottom"
                      arrow
                    >
                      <Box component="span">
                        {CheckValueLocale("unassigned_sla", "", {}, intl)}
                      </Box>
                    </Tooltip>
                  ),
                  disabled: aiUnassignedUsed || isFormDisabled,
                },
              ]}
            />
            {formik?.values?.auto_assignment === "inbound_routing" ? (
              <AiAgentsFields
                disabled={isFormDisabled}
                type="autoComplete"
                id="ai-agent-inbound-routings-select"
                name="inbound_routing_id"
                value={formik?.values?.inbound_routing_id}
                error={!!formik?.errors?.inbound_routing_id}
                placeholder={CheckValueLocale("select", "", {}, intl)}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.inbound_routing_id,
                  "",
                  {},
                  intl,
                )}
                optionsList={inboundRoutingsOptionsList}
                optionsListObj={inboundRoutingsOptionsListObj}
                onOpen={onOpenInboundRoutingsDropdown}
                handleChange={(e, value) =>
                  customHandleChange("inbound_routing_id", value)
                }
                emptyListMessage={CheckValueLocale(
                  "no_inbound_routings",
                  "",
                  {},
                  intl,
                )}
                customOptionLabel={CheckValueLocale(
                  "new_routing",
                  "",
                  {},
                  intl,
                )}
                onCustomOptionClick={onClickAddNewRouting}
                icon={faExternalLink}
                isLoading={isInboundRoutingsLoading}
                renderOptionContent={(option) => (
                  <Box className="autocomplete-select-controll-option-ai-agents">
                    <Typography className="single-option">
                      {CheckValueLocale(
                        getInboundRoutingObj(option)?.name,
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                    {getInboundRoutingObj(option)?.used_by_ai_agent &&
                    getInboundRoutingObj(option)?.used_by_ai_agent !==
                      aiAgentDetails?.id ? (
                      <Typography className="single-option">
                        {CheckValueLocale(
                          "handled_by_another_ai_agent",
                          "",
                          {},
                          intl,
                        )}
                      </Typography>
                    ) : null}
                  </Box>
                )}
              />
            ) : null}
            <AiAgentsFields
              type="switch"
              disabled={isFormDisabled}
              name="allow_routing_from_ai_to_human"
              error={!!formik?.errors?.allow_routing_from_ai_to_human}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(
                formik?.errors?.allow_routing_from_ai_to_human,
                "",
                {},
                intl,
              )}
              checked={allowRoutingAiToHuman}
              onChange={(e) =>
                customHandleChange(
                  "allow_routing_from_ai_to_human",
                  !allowRoutingAiToHuman,
                )
              }
              switchLabel={
                <>
                  {CheckValueLocale("allow_routing_ai_to_human", "", {}, intl)}
                  <Box component={"span"} className="ai-agents-form-hint-label">
                    {`(${CheckValueLocale("optional", "", {}, intl)})`}
                  </Box>
                </>
              }
            />
            {showRoutingTeamWarning ? (
              <AiAgentsWarning
                title={CheckValueLocale(
                  "selected_routing_has_no_available_team",
                  "",
                  {},
                  intl,
                )}
                buttonText={CheckValueLocale("settings", "", {}, intl)}
                buttonClick={() => window.open("/settings/teams/add", "_blank")}
              />
            ) : null}
          </Grid>
        </Grid>
        {allowRoutingAiToHuman ? (
          <HumanAssignment
            formik={formik}
            aiAgentshandleChange={aiAgentshandleChange}
            customHandleChange={customHandleChange}
            disabled={isFormDisabled}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default AutoAssignment;
