import {
  changeFromObjectToArrayOfObject,
  handleSentimentResponse,
} from "utils/helpers";
import {
  handleCustomerCareStatsData,
  handleFollowersGrowthStatsData,
} from "../statsDataHandling";

const widgetsMapping = {
  // ----------- Twitter Private -----------
  interactions: {
    value: (response) => ({
      data: response?.interactions,
      total_count: response?.total_interactions,
    }),
  },
  themes: {
    value: (response) => ({
      data: response?.themes,
    }),
  },
  sentiment: {
    value: (response) => ({
      data: handleSentimentResponse(response?.sentiment_overtime),
      pie_data: response?.sentiment_piechart,
    }),
  },
  dialects: {
    value: (response) => ({
      pie_data: response?.dialects,
      pie_sub_data: response?.sub_dialects,
    }),
  },
  channel_accounts: {
    value: (response) => ({
      pie_data: response?.channel_accounts,
    }),
  },
  content_type: {
    value: (response) => ({
      pie_data: response?.content_type_piechart,
    }),
  },
  top_keywords: {
    value: (response) => ({
      pie_data: response?.top_keywords,
    }),
  },
  genders: {
    value: (response) => ({
      pie_data: response?.genders,
    }),
  },
  top_engaged_authors: {
    value: (response) => ({
      pie_data: response?.top_engaged_authors,
    }),
  },
  top_influencers: {
    value: (response) => ({
      pie_data: response?.top_influencers,
    }),
  },
  verified_authors: {
    value: (response) => ({
      pie_data: response?.verified_authors?.authors,
    }),
  },
  channel_activity: {
    value: (response) => ({
      ...response,
    }),
  },

  // ----------- Twitter Public -----------
  Twitter__AccountPage__followers_growth: {
    value: (response) => ({
      data: response?.followers_change_over_time,
      avg_chage_of_day: response?.avg_chage_of_day,
      max_change_of_followers: response?.max_change_of_followers,
      total_followers: response?.total_followers?.[0],
      stats_data: handleFollowersGrowthStatsData(response),
    }),
  },
  Twitter__AccountPage__posts_interactions: {
    value: (response) => ({
      data: response?.posts_interactions?.posts_interactions_over_time,
      pie_data: response?.posts_interactions?.posts_interactions_legends,
      average_interactions: response?.posts_interactions?.average_interactions,
      max_interaction_date: response?.posts_interactions?.max_interaction_date,
      max_interactions: response?.posts_interactions?.max_interactions,
      min_interaction_date: response?.posts_interactions?.min_interaction_date,
      min_interactions: response?.posts_interactions?.min_interactions,
    }),
  },
  Twitter__AccountPage__reach_funnel: {
    value: (response) => ({
      data: response?.reach_funnel,
    }),
  },
  Twitter__AccountPage__content_type: {
    value: (response) => ({
      data: response?.posts_content_type,
      pie_data: response?.posts_content_types_legends,
    }),
  },
  Twitter__AccountPage__account_author_activity: {
    value: (response) => ({
      activity_data: response?.account_author_activity,
      min_value: response?.min_value,
      max_value: response?.max_value,
    }),
  },
  Twitter__EngagementsPage__volume_overtime: {
    value: (response) => ({
      data: response?.posts_over_time,
      total_count: response?.total_posts_count,
    }),
  },
  Twitter__EngagementsPage__sentiment_analysis: {
    value: (response) => ({
      data: handleSentimentResponse(response?.sentiment_overtime),
      pie_data: response?.sentiment_piechart,
    }),
  },
  Twitter__EngagementsPage__associated_topics: {
    value: (response) => ({
      pie_data: response?.associated_topics,
    }),
  },
  Twitter__EngagementsPage__top_keywords: {
    value: (response) => ({
      pie_data: response?.top_keywords,
    }),
  },
  Twitter__EngagementsPage__content_style: {
    value: (response) => ({
      pie_data: response,
    }),
  },
  Twitter__EngagementsPage__top_hashtags: {
    value: (response) => ({
      pie_data: response?.top_hashtags,
    }),
  },
  Twitter__EngagementsPage__top_languages: {
    value: (response) => ({
      pie_data: response?.top_languages,
    }),
  },
  Twitter__EngagementsPage__top_countries: {
    value: (response) => ({
      pie_data: response?.top_countries,
    }),
  },
  Twitter__EngagementsPage__dialects_and_sub_dialects: {
    value: (response) => ({
      pie_data: response?.dialects,
      pie_sub_data: response?.sub_dialects,
    }),
  },
  Twitter__EngagementsPage__top_cities: {
    value: (response) => ({
      pie_data: response?.top_cities,
    }),
  },
  Twitter__EngagementsPage__gender_distribution: {
    value: (response) => ({
      pie_data: response?.top_genders,
    }),
  },
  Twitter__EngagementsPage__account_types: {
    value: (response) => ({
      pie_data: response?.account_types,
    }),
  },
  Twitter__AccountPage__top_images: {
    value: (response) => ({
      media_data: response?.top_images,
    }),
  },
  Twitter__AccountPage__top_videos: {
    value: (response) => ({
      media_data: response?.top_videos,
    }),
  },
  Twitter__EngagementsPage__top_engagers: {
    value: (response) => ({
      pie_data: response?.top_engagers,
    }),
  },
  Twitter__EngagementsPage__top_verified_engagers: {
    value: (response) => ({
      pie_data: response?.top_verified_engagers,
    }),
  },
  Twitter__EngagementsPage__top_influencers: {
    value: (response) => ({
      pie_data: response?.top_influencers,
    }),
  },
  Twitter__CASingleDataSourceInsightsPage__customer_care_performance: {
    value: (response) => ({
      data: changeFromObjectToArrayOfObject(response?.response_time_segment),
      total_number_of_questions: response?.total_number_of_questions,
      avg_response_time_in_seconds: response?.avg_response_time_in_seconds,
      number_of_answered_questions: response?.number_of_answered_questions,
      stats_data: handleCustomerCareStatsData(response),
    }),
  },
  Twitter__EngagementsPage__themes_and_sub_themes: {
    value: (response) => ({ data: response?.themes }),
  },
  Twitter__EngagementsPage__top_sources: {
    value: (response) => ({
      pie_data: response?.top_sources,
    }),
  },
  Twitter__AccountPage__volume_overtime: {
    value: (response) => ({
      data: response?.posts_over_time,
      total_count: response?.total_posts_count,
    }),
  },
};

export const TwitterOmniRespHandling = (
  response,
  handleWidgetResponse,
  dataSource,
) => {
  const widgetKey = widgetsMapping?.[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      response?.metric_id,
      response?.monitor_id,
      response?.eventName?.includes("__")
        ? dataSource
        : `${dataSource}_PRIVATE`,
      widgetKey?.value(response?.eventData),
      response?.customized_metric_id,
    );
};
