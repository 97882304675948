import { Box, Typography } from "@mui/material";
import KnowledgeBaseLinkImage from "images/ai-agents/knowledge-base-link.svg";
import KnowledgeBaseSwaggerImage from "images/ai-agents/knowledge-base-swagger.svg";
import KnowledgeBaseDocsImage from "images/ai-agents/knowledge-base-docs.svg";
import KnowledgeBasePDFImage from "images/ai-agents/pdf-knowledge-base.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const AddKnowledgeBaseTableName = ({ name, type, fileSize, url }) => {
  const intl = useIntl();
  const getImageType = {
    link: KnowledgeBaseLinkImage,
    swagger: KnowledgeBaseSwaggerImage,
    docs: KnowledgeBaseDocsImage,
  };

  const isPDF = name?.includes(".pdf");
  const displayedImage = isPDF ? KnowledgeBasePDFImage : getImageType?.[type];
  return (
    <Box className="ai-agent-knowledge-base-name-cell-container">
      <Box>
        <img
          src={displayedImage}
          alt="knowledge base type"
          className="knowledge-base-table-image"
        />
      </Box>
      <Box>
        <Typography
          className={`knowledge-base-table-name
          ${type === "link" ? "knowledge-base-table-name-link" : "knowledge-base-table-name-normal"}`}
          onClick={() => {
            if (type === "link") {
              window.open(url, "_blank");
            }
          }}
        >
          {name}
        </Typography>
        {fileSize ? (
          <Typography className="knowledge-base-table-metadata">
            {CheckValueLocale(
              "ai_agent_resource_size",
              "",
              {
                size: fileSize,
              },
              intl,
            )}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default AddKnowledgeBaseTableName;
