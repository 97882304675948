import { Box } from "@mui/material";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const ResumeAiAgentModal = ({
  aiAgentDetails,
  openPopupResume,
  setOpenPopupResume,
  accept,
  isDisabledOrLoading,
  otherAiAgentName,
}) => {
  const intl = useIntl();

  let modalBody = (
    <Box>
      <Box
        component="p"
        className="ai-agent-modal-hint ai-agent-resume-modal-hint"
      >
        {CheckValueLocale(
          "resume_ai_agent_model_desc",
          "",
          { name: aiAgentDetails?.name, otherAiAgentName },
          intl,
        )}
      </Box>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("resume_ai_agent", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("resume_anyway", "", {}, intl)}
      disabled={isDisabledOrLoading}
      isRightBtnLoading={isDisabledOrLoading}
      open={openPopupResume || false}
      close={!isDisabledOrLoading ? () => setOpenPopupResume(null) : null}
      accept={accept}
      classeName={"ai-agent-popupModal"}
      dialogContentId="resume-ai-agent"
    />
  );
};
export default ResumeAiAgentModal;
