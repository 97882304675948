import { Box, FormHelperText, Typography } from "@mui/material";
import { CheckValueLocale, classNames } from "utils/helpers";
import { useIntl } from "react-intl";

const SelectTeamAlgorithm = ({
  routingAlgorithmList,
  value,
  handleChange,
  helperText,
  disabled,
}) => {
  const SelectedAlgorithmCard = ({
    title,
    desc,
    selected,
    onSelect,
    disabled,
  }) => {
    const intl = useIntl();
    return (
      <Box
        className={classNames(
          "routing-auto-assign-member-cards-item",
          selected && "selected",
          disabled && "disabled",
        )}
        onClick={onSelect}
      >
        <Typography className="routing-auto-assign-member-cards-item-title">
          {CheckValueLocale(title, "", {}, intl)}
        </Typography>
        <Typography className="routing-auto-assign-member-cards-item-desc">
          {CheckValueLocale(desc, "", {}, intl)}
        </Typography>
      </Box>
    );
  };
  return (
    <Box className={`team-algorithm-group ${helperText ? "Mui-error" : ""}`}>
      <Box className="team-and-slas-section">
        <Box className="routing-auto-assign-member-cards">
          {routingAlgorithmList?.map((algorithm) => (
            <SelectedAlgorithmCard
              key={algorithm?.id}
              title={algorithm?.name}
              desc={algorithm?.desc}
              selected={value === algorithm?.id}
              onSelect={() => (!disabled ? handleChange(algorithm?.id) : null)}
              disabled={disabled}
            />
          ))}
        </Box>
      </Box>
      {helperText ? (
        <FormHelperText className="ai-agents-form-helper-text">
          {helperText}
        </FormHelperText>
      ) : null}
    </Box>
  );
};
export default SelectTeamAlgorithm;
