import { Box } from "@mui/material";
import { sanitize } from "dompurify";
import { useIntl } from "react-intl";
import SurveyRequestImgEn from "images/ai-agents/survey-request-en.png";
import SurveyRequestImgAr from "images/ai-agents/survey-request-ar.png";
import LucidyaSurveyIcon from "images/ai-agents/lucidya-survey-icon.svg";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { useSurveyRequest } from "pages/engagements/components/aiAgents/aiAgentsHooks/useSurveyRequest";
import { useContext } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";

const SurveyRequest = () => {
  const intl = useIntl();
  const { setAiAgentSnackBar } = useContext(AiAgentsContext);
  const { mutate: createSurveyRequest, isPending: createSurveyRequesLoading } =
    useSurveyRequest();

  const requestSurveyDemo = () => {
    let queryData = {
      service_name: "request_survey_demo",
    };
    createSurveyRequest(queryData, {
      onSuccess: (data) => {
        if (!data?.errorMsg) {
          setAiAgentSnackBar({
            success: true,
            message: CheckValueLocale("addons_success", "", {}, intl),
          });
        } else {
          setAiAgentSnackBar({
            success: false,
            title: CheckValueLocale("failed_error_message", "", {}, intl),
            message: CheckValueLocale("try_again_error_message", "", {}, intl),
          });
        }
      },
    });
  };
  const isEn = intl?.locale === "en";
  const SurveyRequestImg = isEn ? SurveyRequestImgEn : SurveyRequestImgAr;

  return (
    <Box className="ai-agents-survey-request-wrapper">
      <Box className="survey-request-text-wrapper">
        <img src={LucidyaSurveyIcon} alt="lucidya-survey-icon" />
        <Box
          className="survey-request-text"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              CheckValueLocale("survey_request_text", "", {}, intl),
            ),
          }}
        ></Box>
        <LucButton
          type="secondary"
          variant="outline"
          size="small"
          loading={createSurveyRequesLoading}
          disabled={createSurveyRequesLoading}
          onClick={requestSurveyDemo}
          id="ai-agent-button-survey-request"
        >
          {CheckValueLocale("home_page_request_demo", "", {}, intl)}
        </LucButton>
      </Box>
      <Box className="survey-request-image-wrapper">
        <img src={SurveyRequestImg} alt="survey-request-image" />
      </Box>
    </Box>
  );
};

export default SurveyRequest;
