import React, { useEffect, useState } from "react";
import TableWidget from "components/widgets/tableWidget/tableWidget";

const CustomDashboardTableWidget = (props) => {
  const {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    widgetsMqttResponses,
    finalWidgetData,
    customizedWidgets,
    dashboardInfo,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    allMonitorsIdWidget,
    customizedType,
    showControl,
  } = props;

  const [widgetFlags, setWidgetFlags] = useState({});

  useEffect(() => {
    if (widget && finalWidgetData) {
      const checkIsXPlatform =
        widget?.data_source === "TWITTER" ||
        widget?.data_source === "TWITTER_PRIVATE";

      // This switch case for table widget flags only
      switch (widget?.widget_name) {
        case "comments_top_keywords":
        case "posts_top_keywords":
        case "top_keywords":
          if (
            widget?.dashboard_page_name === "INSTAGRAM_PRIVATE" ||
            widget?.dashboard_page_name === "TWITTER_PRIVATE"
          ) {
            setWidgetFlags({
              leftTitle: "keyword",
              rightTitle: "number_of_conversations",
              keywords: true,
              wordsList: true,
            });
          } else if (widget?.dashboard_page_name === "LINKEDIN_PUBLIC") {
            setWidgetFlags({
              leftTitle: "keyword",
              rightTitle: "interactions",
              keywords: true,
              wordsList: true,
            });
          } else
            setWidgetFlags({
              leftTitle: "keyword",
              rightTitle: "number_of_posts",
              keywords: true,
              wordsList: true,
            });
          break;

        case "comments_top_hash_tags":
        case "posts_top_hash_tags":
        case "top_hashtags":
          setWidgetFlags({
            leftTitle: "hashtags",
            rightTitle: "number_of_posts",
            wordsList: true,
            hashtags: true,
          });
          break;

        case "comments_top_engagers":
          setWidgetFlags({
            leftTitle: "users",
            rightTitle: "engagements",
            itemsList: true,
            circularPP: true,
            isXPlatform: checkIsXPlatform,
            secondaryTitle: "engagement_overview_title",
          });
          break;

        case "top_engagers":
          if (checkIsXPlatform) {
            setWidgetFlags({
              leftTitle: "users",
              rightTitle: "followers",
              itemsList: true,
              circularPP: true,
              isXPlatform: checkIsXPlatform,
              secondaryTitle: "engagement_overview_title",
            });
          } else if (widget?.dashboard_page_name === "TIKTOK_PUBLIC") {
            setWidgetFlags({
              leftTitle: "username",
              rightTitle: "engagements",
              itemsList: true,
              isTiktok: true,
            });
          } else if (widget?.dashboard_page_name === "LINKEDIN_PUBLIC") {
            setWidgetFlags({
              leftTitle: "users",
              rightTitle: "interactions",
              itemsList: true,
              isTiktok: true,
            });
          } else if (widget?.dashboard_page_name === "INSTAGRAM_PRIVATE") {
            setWidgetFlags({
              leftTitle: "users",
              rightTitle: "interactions",
              itemsList: true,
              circularPP: true,
            });
          } else
            setWidgetFlags({
              leftTitle: "users",
              rightTitle: "engagements",
              itemsList: true,
              circularPP: true,
            });
          break;

        case "top_influencers":
        case "comments_top_influencers":
        case "comments_top_verified_engagers":
        case "top_verified_engagers":
          setWidgetFlags({
            leftTitle: "users",
            rightTitle: "followers",
            itemsList: true,
            circularPP: true,
            isXPlatform: checkIsXPlatform,
            secondaryTitle: "engagement_overview_title",
          });
          break;

        case "comments_top_engagers":
          setWidgetFlags({
            leftTitle: "username",
            rightTitle: "number_of_posts",
            wordsList: true,
            isXPlatform: checkIsXPlatform,
            secondaryTitle: "engagement_overview_title",
          });
          break;
        case "top_authors":
          setWidgetFlags({
            leftTitle: "username",
            rightTitle: "number_of_posts",
            wordsList: true,
          });
          break;

        case "top_sites":
          setWidgetFlags({
            leftTitle: "links",
            rightTitle: "reach",
            wordsList: true,
            links: true,
          });
          break;

        case "top_engaged_sites":
          setWidgetFlags({
            leftTitle: "links",
            rightTitle: "posts",
            wordsList: true,
            links: true,
          });
          break;

        default:
          break;
      }
    }
  }, [widget, finalWidgetData]);

  return (
    <TableWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={widgetTitleTooltip || ""}
      isXPlatform={widgetFlags?.isXPlatform}
      secondaryTitle={widgetFlags?.secondaryTitle}
      leftColomnTitle={widgetFlags?.leftTitle}
      rightColomnTitle={widgetFlags?.rightTitle}
      wordsList={widgetFlags?.wordsList}
      itemsList={widgetFlags?.itemsList}
      links={widgetFlags?.links}
      keywords={widgetFlags?.keywords}
      hashtags={widgetFlags?.hashtags}
      circularPP={widgetFlags?.circularPP}
      isTiktok={widgetFlags?.isTiktok}
      data={finalWidgetData?.pie_data || []}
      showDownloadIcon={!isControlWidget}
      bigImg
      tablePagination
      count={finalWidgetData?.pie_data?.length || 0}
      preLoaderTrackerDataSources={finalWidgetData?.preLoaderMonitorsId || []}
      tableChartPreLoaderDataSources={allMonitorsIdWidget || []}
      isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
      isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
      // The needed data in widget component for case of Custom Dashboard only
      customDashboardData={{
        dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
        handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
        customizedType: customizedType, // null, aggregate, comparison
        widget: widget, // the widget metric returned from backend in view API
        widgetsMqttResponses, // the main state for RabbitMQ responses
        customizedWidgets, // cuztomized widget state for RabbitMQ responses
        getCustomDashboardView, // call back view widgets after any update in page
        setSnackBarData, // set snackbar related to main index view
        noComparison: true, // To exclude tables from data comparison
        showControl, // show widget control icon
      }}
    />
  );
};

export default CustomDashboardTableWidget;
