import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import AiAgentsFields from "../../aiAgentsFormContent/component/aiAgentsFields";
import SelectTeamBox from "./selectTeamBox";

const RoutingSelectTeam = ({ unassignedStatus, formik }) => {
  const intl = useIntl();

  return (
    <>
      <AiAgentsFields
        type="radio"
        aria-labelledby="auto_assignment"
        name="auto_assignment"
        value={formik?.values?.auto_assignment}
        onChange={formik?.handleChange}
        options={[
          {
            value: "unassigned",
            name: CheckValueLocale("unassigned_tab_aption", "", {}, intl),
          },
          {
            value: unassignedStatus ? "manual_assign" : "auto_assign",
            name: unassignedStatus ? (
              <>
                {CheckValueLocale("manual_assign_tab_aption", "", {}, intl)}
                <Box component={"span"} className="ai-agents-form-hint-label">
                  {" "}
                  {CheckValueLocale(
                    "manual_assign_tab_aption_hint",
                    "",
                    {},
                    intl,
                  )}{" "}
                </Box>
              </>
            ) : (
              CheckValueLocale("auto_assigned_tab_aption", "", {}, intl)
            ),
          },
        ]}
      />
      {formik?.values?.auto_assignment === "manual_assign" ? (
        <SelectTeamBox formik={formik} />
      ) : null}
      <AiAgentsFields
        type="textarea"
        id="auto-reply-customer"
        label={CheckValueLocale("auto_reply_customer", "", {}, intl)}
        labelHint={`(${CheckValueLocale("optional", "", {}, intl)})`}
        placeholder={CheckValueLocale("write_here", "", {}, intl)}
        name="auto_reply"
        value={formik?.values?.auto_reply}
        onChange={formik?.handleChange}
      />
    </>
  );
};
export default RoutingSelectTeam;
