import SnackBar from "components/snackBar";
import { AiAgentsContext } from "../aiAgentsContext";
import { useContext } from "react";
import { Box } from "@mui/material";

const AiAgentsSnackBar = () => {
  const { aiAgentSnackBar, setAiAgentSnackBar } = useContext(AiAgentsContext);
  return aiAgentSnackBar?.success != null ? (
    <SnackBar
      open={aiAgentSnackBar?.success != null ? true : false}
      handleClose={() => {
        setAiAgentSnackBar({
          success: null,
          title: "",
          message: "",
          severity: null,
        });
      }}
      severity={
        aiAgentSnackBar?.severity ||
        (aiAgentSnackBar?.success ? "success" : "error")
      }
      title={aiAgentSnackBar?.title}
      message={
        <Box className="ai-agent-snackBar-message">
          {aiAgentSnackBar?.message}
        </Box>
      }
    />
  ) : null;
};

export default AiAgentsSnackBar;
