import { useEffect, useState } from "react";
import { Button, Menu, MenuItem, Box, Chip } from "@mui/material";
import { NavLink, useHistory } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
//Locals
import { useIntl } from "react-intl";
import {
  CheckValueLocale,
  handlelUserRoles,
  handleSingleDropdownMenuItem,
} from "utils/helpers";

const ChannelAnalyticsMenu = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const [anchorCA, setAnchorCA] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const openCA = Boolean(anchorCA);
  const linksArray = [
    {
      isActive: handlelUserRoles("CXM", "VIEW_CHANNEL"),
      link: "/insights/main",
      text: "analytics",
      id: "channel-analytics-menu-analytics",
    },
    {
      isActive: handlelUserRoles("CXM", "VIEW_INTERACTION"),
      link: "/interactions",
      text: "interactions",
      id: "channel-analytics-menu-interactions",
    },
    {
      isActive:
        handlelUserRoles("CXM", "CREATE_CHANNEL") ||
        handlelUserRoles("CXM", "EDIT_CHANNEL") ||
        handlelUserRoles("CXM", "DESTROY_CHANNEL"),
      link: "/manage-channels/list",
      text: "manage_channels",
      id: "channel-analytics-menu-manage-channels",
    },
  ];

  const handleClickChannelAnalytics = (event) => {
    setAnchorCA(event.currentTarget);
    handleSingleDropdownMenuItem(linksArray, history);
  };

  const handleHoverChannelAnalytics = (event) => {
    setAnchorCA(event.currentTarget);
  };
  const handleCloseCA = () => {
    setAnchorCA(null);
  };

  const subMenuPaths = [
    "/insights/main",
    "/interactions",
    "/manage-channels",
    "/insights",
  ];
  useEffect(() => {
    setIsActive(subMenuPaths.includes(history.location.pathname));
    for (let [key, value] of Object.entries(subMenuPaths)) {
      if (history.location.pathname.includes(`${value}`)) {
        setIsActive(true);
      }
    }
  }, [history.location.pathname]);

  let menuDirection = intl.locale === "ar" ? "right" : "left";

  return (
    <Box className="navbar-header" onMouseLeave={handleCloseCA}>
      <Button
        id="main-header-channel-analytics"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={openCA ? "true" : undefined}
        disableElevation
        onClick={handleClickChannelAnalytics}
        onMouseOver={handleHoverChannelAnalytics}
        endIcon={<KeyboardArrowDown />}
        className={`${
          isActive
            ? "selected-route btn-dropdown-nav channel-analytics-menu"
            : "btn-dropdown-nav channel-analytics-menu"
        } dropdown-menu-arrow header-dropdown-menu`}
      >
        {CheckValueLocale("channel_analytics", "", {}, intl)}
      </Button>
      <Menu
        id="demo-customized-menu"
        anchorEl={anchorCA}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuDirection,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuDirection,
        }}
        open={openCA}
        onClose={handleCloseCA}
        MenuListProps={{ onMouseLeave: handleCloseCA }}
        className="channel-analytics-menu"
      >
        {linksArray
          ?.filter((link) => link?.isActive)
          .map((link) => {
            return (
              <MenuItem
                className="menu-list"
                onClick={() => handleCloseCA()}
                disableRipple
                id={link?.id}
              >
                <NavLink activeClassName="selected-route" to={link?.link}>
                  {CheckValueLocale(link?.text, "", {}, intl)}
                </NavLink>
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
};
export default ChannelAnalyticsMenu;
