import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Timeline } from "@mui/lab";
import AIAgentLogItem from "./activityLogItem";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useInView } from "react-intersection-observer";
import { Fragment, useEffect } from "react";
import { useAiAgentLogs } from "pages/engagements/components/aiAgents/aiAgentsHooks/useAiAgentLogs";
import moment from "moment";
import AiAgentFunction from "../../../aiAgentFunction";
import HeaderSkeletonItem from "../activityLogSkeletonItem";
import SideBarEmpty from "../../../aiAgentsSideBar/sideBarEmpty";

const ActivityLogDrawer = ({ toggleDrawer, isOpen, id }) => {
  const intl = useIntl();
  const isAR = intl?.locale === "ar";
  const currentYear = moment().year();
  const { groupByYear } = AiAgentFunction();
  const { inView, ref: inViewRef } = useInView({
    threshold: 0.5,
  });

  const {
    data: logsData,
    fetchNextPage,
    hasNextPage,
    isPending,
    isFetchingNextPage,
  } = useAiAgentLogs({
    start_date: 1641059901, // we will change this later on when we go live with this feature
    end_date: moment().tz("utc", true).unix(),
    item_id: id,
    enabled: isOpen,
    products: "Ai Agent",
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage]);

  const flatLogs =
    logsData?.pages?.flatMap((page) => page?.data?.data?.data) || [];

  const groupedLogs = groupByYear(flatLogs);
  const sortedYears = Object.keys(groupedLogs || {}).sort((a, b) => b - a);
  return (
    <Drawer
      anchor={isAR ? "left" : "right"}
      open={isOpen}
      onClose={() => toggleDrawer(false)}
    >
      <Box className="ai-agent-drawer-box" role="presentation">
        <Box className="ai-agent-drawer-drawer-header">
          <Typography className="ai-agent-drawer-drawer-header-typography">
            {CheckValueLocale("ai_agent_activity_log", "", {}, intl)}
          </Typography>
          <IconButton onClick={() => toggleDrawer(false)}>
            <FontAwesomeIcon icon={faXmark} className="ai-agent-drawer-exit" />
          </IconButton>
        </Box>
        <Box className="ai-agent-drawer-drawer-content">
          {isPending ? (
            [1, 2, 3, 4, 5].map((item) => <HeaderSkeletonItem key={item} />)
          ) : flatLogs?.length === 0 ? (
            <Box className="ai-agent-drawer-empty-box">
              <SideBarEmpty emptyText="no_routings_found" />
            </Box>
          ) : (
            sortedYears?.map((year) => (
              <Fragment key={year}>
                {parseInt(year) !== currentYear && (
                  <Stack className="ai-agent-drawer-divider-stack">
                    <Divider className="ai-agent-drawer-divider">
                      <Chip
                        label={year}
                        className="ai-agent-drawer-divider-chip"
                      />
                    </Divider>
                  </Stack>
                )}
                <Timeline className="ai-agent-drawer-timeline" position="right">
                  {groupedLogs?.[year]?.map((item, index) => (
                    <AIAgentLogItem
                      key={item?.id}
                      changedBy={item?.attributes?.user_name}
                      date={item?.attributes?.created_at}
                      status={item?.attributes?.action}
                      img={item?.attributes?.avatar}
                      isLast={index === groupedLogs?.[year]?.length - 1}
                      isFirst={index === 0}
                      fileName={item?.attributes?.file_name}
                      itemRef={
                        index === groupedLogs?.[year]?.length - 1
                          ? inViewRef
                          : null
                      }
                      inside_subentity_name={
                        item?.attributes?.inside_subentity_name
                      }
                    />
                  ))}
                  {isFetchingNextPage && (
                    <Box className="ai-agent-paginated-circular-progress-container">
                      <CircularProgress size={20} />
                    </Box>
                  )}
                </Timeline>
              </Fragment>
            ))
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default ActivityLogDrawer;
