import { Box, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import AiAgentEmojiHighImage from "images/ai-agents/ai-agent-emoji-high.png";
import AiAgentEmojiMediumImage from "images/ai-agents/ai-agent-emoji-medium.png";
import AiAgentEmojiLowImage from "images/ai-agents/ai-agent-emoji-low.png";
import AiAgentEmojiNoneImage from "images/ai-agents/ai-agent-emoji-none.png";
import { TONE_OF_VOICE_VALUES } from "./agentIdentityToneOfVoiceStyle";

const EMOJI_FREQUENCY_VALUES = {
  high: {
    value: "high",
    image: AiAgentEmojiHighImage,
    alt: "High",
  },
  medium: {
    value: "medium",
    image: AiAgentEmojiMediumImage,
    alt: "Medium",
  },
  low: {
    value: "low",
    image: AiAgentEmojiLowImage,
    alt: "Low",
  },
  no_emoji: {
    value: "no_emoji",
    image: AiAgentEmojiNoneImage,
    alt: "No Emoji",
  },
};

const EmojiFrequencyOption = ({
  value,
  image,
  alt,
  selected,
  onClick,
  intl,
  isFormalOrSemiFormal,
}) => {
  const isSelectionDisabled =
    isFormalOrSemiFormal && value !== EMOJI_FREQUENCY_VALUES?.no_emoji;
  return (
    <Grid item xs={6}>
      <Box
        onClick={onClick}
        className={`ai-agent-emoji-item-wrapper ${selected ? "selected" : ""} ${isSelectionDisabled ? "formal-semi-formal-disable" : ""}`}
      >
        <img src={image} alt={alt} />
        <Box>
          <Typography className="ai-agent-emoji-item-title">
            {CheckValueLocale(
              `ai_agent_emoji_${value === "no_emoji" ? "none" : value}`,
              "",
              {},
              intl,
            )}
          </Typography>
          <Typography className="ai-agent-emoji-item-description">
            {CheckValueLocale(
              `ai_agent_${value === "no_emoji" ? "none" : value}_emoji_description`,
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const AgentEmojiFrequency = ({
  formik,
  customHandleChange,
  isFormDisabled,
}) => {
  const intl = useIntl();
  const frequencyValue = formik?.values?.emoji_usage;
  const emojiError = formik?.errors?.emoji_usage;

  const isFormalOrSemiFormal =
    formik?.values?.response_style === TONE_OF_VOICE_VALUES?.formal ||
    formik?.values?.response_style === TONE_OF_VOICE_VALUES?.semi_formal;

  const handleChange = (value) => {
    if (isFormDisabled || isFormalOrSemiFormal) return;
    customHandleChange("emoji_usage", value);
  };

  return (
    <>
      <Grid className={`${emojiError ? "Mui-error" : ""}`} item xs={5}>
        <Box className="ai-agents-form-box-description" component="p">
          {CheckValueLocale("ai_agent_emoji_frequency", "", {}, intl)}
          <Box component="span" className="red-star-req">
            {` *`}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Grid alignItems="stretch" container spacing="20px">
          {Object.entries(EMOJI_FREQUENCY_VALUES || {})?.map(
            ([key, { value, image, alt }]) => (
              <EmojiFrequencyOption
                key={key}
                value={value}
                image={image}
                alt={alt}
                selected={frequencyValue === value}
                onClick={() => handleChange(value)}
                intl={intl}
                isFormalOrSemiFormal={isFormalOrSemiFormal}
              />
            ),
          )}
          <Grid item xs={12}>
            {emojiError ? (
              <Box component="span" className="ai-agents-form-helper-text">
                {CheckValueLocale(emojiError, "", {}, intl)}
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AgentEmojiFrequency;
