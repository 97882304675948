import React from "react";
import PunchCardWidget from "components/widgets/punchCardWidget";

const CustomDashboardPunchCard = (props) => {
  const {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    widgetsMqttResponses,
    finalWidgetData,
    customizedWidgets,
    dashboardInfo,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    allMonitorsIdWidget,
    customizedType,
    showControl,
  } = props;

  return (
    <PunchCardWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={widgetTitleTooltip || ""}
      clientActivity={finalWidgetData?.activity_data || []}
      minValueClient={finalWidgetData?.min_value || []}
      maxValueClient={finalWidgetData?.max_value || []}
      showDownloadIcon={!isControlWidget}
      bigImg
      authorActivityPreLoaderDataSources={
        finalWidgetData?.preLoaderMonitorsId || []
      }
      preLoaderTrackerDataSources={allMonitorsIdWidget || []}
      isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
      isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
      // The needed data in widget component for case of Custom Dashboard only
      customDashboardData={{
        dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
        handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
        widget: widget, // the widget metric returned from backend in view API
        customizedType: customizedType, // null, aggregate, comparison
        widgetsMqttResponses, // the main state for RabbitMQ responses
        customizedWidgets, // cuztomized widget state for RabbitMQ responses
        getCustomDashboardView, // call back view widgets after any update in page
        setSnackBarData, // set snackbar related to main index view
        noComparison: true, // To exclude PunchCard from data comparison
        showControl, // show widget control icon
      }}
    />
  );
};

export default CustomDashboardPunchCard;
