import Facebook from "images/social-icons/cutom-dashboard/FB.svg";
import PrivateMessenger from "images/social-icons/cutom-dashboard/FB-DM.svg";
import Instagram from "images/social-icons/cutom-dashboard/Instagram.svg";
import PrivateInstagram from "images/social-icons/cutom-dashboard/Insta-DM.svg";
import XPlatform from "images/social-icons/cutom-dashboard/Xplatform.svg";
import PrivatexPlatform from "images/social-icons/cutom-dashboard/Xplatform-DM.svg";
import LinkedInImage from "images/social-icons/cutom-dashboard/Linke-in.svg";
import Newspaper from "images/social-icons/cutom-dashboard/New-blogs.svg";
import TikTokImage from "images/social-icons/cutom-dashboard/Tiktok.svg";

export const getSocialUrlImg = (dataSource) => {
  const socialImage = {
    FACEBOOK: Facebook,
    FACEBOOK_PRIVATE: PrivateMessenger,
    INSTAGRAM: Instagram,
    INSTAGRAM_PRIVATE: PrivateInstagram,
    TWITTER: XPlatform,
    TWITTER_PRIVATE: PrivatexPlatform,
    TALKWALKER: Newspaper,
    TIKTOK: TikTokImage,
    LINKEDIN: LinkedInImage,
  };

  return socialImage?.[dataSource] || "";
};
