import { Box } from "@mui/material";
import aiAgentsEmpty from "images/ai-agents/ai-agents-empty-side-bar.svg";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const SideBarEmpty = ({ emptyText }) => {
  const intl = useIntl();
  return (
    <Box className="ai-agents-side-bar-empty">
      <img src={aiAgentsEmpty} alt="ai-agents-empty" />
      <Box component={"p"} className="ai-agents-empty-description">
        {CheckValueLocale(emptyText, "", {}, intl)}
      </Box>
    </Box>
  );
};

export default SideBarEmpty;
