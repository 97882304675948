import { useMutation, useQueryClient } from "@tanstack/react-query";
import AiAgentsController from "services/controllers/aiAgentsController";

export const useEditPublishedVersion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ queryData }) =>
      AiAgentsController.editPublishedVersion(queryData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["aiAgentDetails"] });
      queryClient.invalidateQueries({ queryKey: ["aiAgentList"] });
      queryClient.invalidateQueries({ queryKey: ["aiAgentResources"] });
    },
  });
};
