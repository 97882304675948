const defaultColors = [
  "#003D59",
  "#009BDF",
  "#45B9E9",
  "#97D7F3",
  "#CCCCCC",
  "#80868C",
  "#27D386",
  "#218072",
  "#C79121",
  "#FFCC14",
];

const customColors = {
  page_negative_interactions_over_time: "#C51162",
  page_positive_interactions_over_time: "#4CAF50",
  male: "#2AAAE2",
  female: "#FF4081",
  like_reaction: "#1892F2",
  celebrate_reaction: "#F7B94D",
  curious_reaction: "#1C404E",
  love_reaction: "#FB5C77",
  care_reaction: "#F7B94D",
  haha_reaction: "#FEDC5F",
  wow_reaction: "#00D7B5",
  angry_reaction: "#EC8A92",
  sad_reaction: "#1C404E",
  insightful_reaction: "#EC8A92",
  support_reaction: "#00D7B5",

  original: "#ED6C02",
  "المنشور الأصلي": "#ED6C02",

  positive: "#57C242",
  neutral: "#F9C92E",
  negative: "#FD0F47",
  محايد: "#F9C92E",
  سلبي: "#FD0F47",
  إيجابي: "#57C242",

  passive: "#F5B819",
  detractors: "#ED1846",
  promoters: "#9FC653",
  محايد: "#F5B819",
  المنتقدين: "#ED1846",
  المروجين: "#9FC653",

  "مواقع التواصل الاجتماعي": "#003D59",
  "social media": "#003D59",
  "وسائل التواصل الاجتماعي": "#003D59",
  chats: "#B7E8FE",
  المحادثات: "#B7E8FE",
  emails: "#45B9E9",
  "البريد الإلكترونى": "#45B9E9",
  ratings: "#C3DFF5",
  التقييم: "#C3DFF5",
  calls: "#CFF0FF",
  الاتصالات: "#CFF0FF",

  twitter: "#003D59",
  تويتر: "#003D59",
  "x platform": "#003D59",
  "منصة إكس": "#003D59",
  intercom: "#B7E8FE",
  إنتركوم: "#B7E8FE",
  gmail: "#97D7F3",
  جيميل: "#97D7F3",
  facebook: "#009BDF",
  فيسبوك: "#009BDF",
  instagram: "#45B9E9",
  إنستقرام: "#45B9E9",
  whatsapp: "#38bd6a",
  "واتس آب": "#38bd6a",
  googlemybusiness: "#C3DFF5",
  "google my business": "#C3DFF5",
  "جوجل التجاري": "#C3DFF5",
  linkedin: "#CFF0FF",
  "لينكد ان": "#CFF0FF",
  "فيس بوك": "#009BDF",
  انستقرام: "#45B9E9",
  genesys: "#CFF0FF",
  جينيسيز: "#CFF0FF",
  tiktok: "#C3E1F8",
  تيكتوك: "#C3E1F8",
  "تيك توك": "#C3E1F8",

  male: "#009BDF",
  ذكور: "#009BDF",
  female: "#F680BC",
  اناث: "#F680BC",
  unknown: "#6D737A",
  undefined: "#6D737A",
  "غير معرف": "#6D737A",
  "غير معروف": "#6D737A",

  saudi: "#189C53",
  سعودي: "#189C53",
  white: "#003D59",
  "common/un-classified": "#003D59",
  "بيضاء/ غير معرف": "#003D59",
  "modern arabic": "#717D7E",
  "modern ar": "#717D7E",
  "لهجة عامة": "#717D7E",
  فصيحة: "#717D7E",
  egyptian: "#B8961F",
  مصرية: "#B8961F",
  iraqi: "#A03500",
  عراقية: "#A03500",
  shami: "#894BA2",
  شامية: "#894BA2",
  khaleji: "#2785C3",
  خليجية: "#2785C3",
  maghrebi: "#ED452E",
  مغربية: "#ED452E",
  "najdi-sa": "#52D78B",
  "hijaze-sa": "#52D78B",
  "other-sa": "#52D78B",

  lebanese: "#C49AD4",
  syrian: "#C49AD4",
  palestinian: "#C49AD4",
  kuwaiti: "#83C0EB",
  bahrani: "#83C0EB",
  emirati: "#83C0EB",
  moroccan: "#F39488",
  algerian: "#F39488",
  libyan: "#F39488",
  white: "#71BECC",
  "modern ar": "#AAB7B8",
  "saudi arabia": "#009BDF",
  egypt: "#003D59",
  "united states": "#717D7E",
  man: "#B8961F",
  oman: "#B8961F",
  canada: "#A03500",
  india: "#894BA2",
  bermuda: "#2785C3",
  "united kingdom": "#ED452E",
  afghanistan: "#ED452E",
  finland: "#ED452E",

  // themes colors
  complaint: "#E85954",
  compliment: "#9FC653",
  complement: "#9FC653",
  other: "#D3D3D3",
  question: "#2AAAE2",
  "customer services": "#23A41A",
  delivery: "#A4CEE7",
  "portal/app": "#B0E381",
  payment: "#CCB0D8",
  "products & services": "#1176B9",
  undefined: "#FEC160",
  complement: "#9FC653",
  "custom services": "#23A41A",
  أسئلة: "#2AAAE2",
  تكملة: "#9FC653",
  أخري: "#D3D3D3",
  "غير معرف": "#FEC160",
  توصيل: "#A4CEE7",
  شكاوي: "#E85954",
  "خدمة العملاء": "#23A41A",
  دفع: "#CCB0D8",
  "البوابة / التطبيق": "#B0E381",
  "المنتجات والخدمات": "#1176B9",
  "خدمات المنتج": "#1176B9",
  مدح: "#9FC653",
  شكوي: "#E85954",
  شكوى: "#E85954",
  السؤال: "#2AAAE2",
  أخرى: "#D3D3D3",
};

const handleWidgetColors = (legends) => {
  const labelsColors = { labels: [], colors: [] };

  let tempColors = [...defaultColors]; // set copy of defaultColors to avoid modifying the original one

  if (legends) {
    labelsColors.labels = legends?.map((legend) => legend?.name);

    labelsColors.colors = legends?.map((legend) => {
      if (customColors?.[legend?.name?.toLowerCase()] !== undefined)
        return customColors?.[legend?.name?.toLowerCase()];
      else {
        const defaultColor = tempColors?.shift(); // Get the first color from tempColors and remove it from the tempArray
        return defaultColor;
      }
    });
  }

  return labelsColors;
};

export default handleWidgetColors;
