import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import {
  handleXlsData,
  handleSortedCase,
  handleAuthorActivity,
} from "../excelCommonFn";

const twitter_public = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var followersGrowth = [],
      authorActivity = [],
      reachFunnel = [],
      postsVolume = [],
      postsInteractions = [],
      postsContentType = [],
      topImages = [],
      topVideos = [],
      commentsVolume = [],
      sentimentAnalysis = [],
      theme = [],
      subTheme = [],
      themesTrend = [],
      subThemesTrend = [],
      excelContentStyles = [],
      excelAssociatedTopics = [],
      topKeywords = [],
      topHashtags = [],
      excelTopLanguages = [],
      excelDialects = [],
      excelSubDialects = [],
      excelTopCountries = [],
      excelTopCities = [],
      excelGenderDistribution = [],
      excelAccountTypes = [],
      topEngagers = [],
      excelTopSources = [],
      topInfleuncers = [],
      topVerifiedEngagers = [],
      overview = [],
      latestInteractions = [],
      customerCareData = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "overview":
            value?.map((i) => {
              overview.push({
                [lang.xlsx_name]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.xlsx_value]:
                  i?.name == "net_sentiment"
                    ? lang[i?.value[0]?.name]
                    : i?.value,
              });
            });
            allSheetData.push([overview, lang.xlxs_overview]);
            break;
          case "customerCareData":
            value?.map((i) => {
              customerCareData.push({
                [lang.name]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.value]: i?.value,
              });
            });
            allSheetData.push([customerCareData, lang.customer_performance]);
            break;
          case "followersGrowth":
            value?.map((i) => {
              followersGrowth.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([followersGrowth, [lang.followers_growth]]);
            break;
          // _______________________________________________________________________________

          case "reachFunnel":
            value?.map((i) => {
              reachFunnel.push({
                [lang.reach_funnel]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.Retweets]: i?.value[0],
                [lang.original]: i?.value[1],
                [lang.total]: parseInt(i?.value[0]) + parseInt(i?.value[1]),
              });
            });
            allSheetData.push([reachFunnel, [lang.reach_funnel]]);
            break;
          // // _______________________________________________________________________________

          case "postsInteractions":
            value?.map((i) => {
              postsInteractions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.Retweets]: i?.value[0],
                [lang.likes]: i?.value[1],
                [lang.replies]: i?.value[2],
                [lang.total]: i?.value[0] + i?.value[1] + i?.value[2],
              });
            });
            allSheetData.push([postsInteractions, lang.posts_interactions]);
            break;
          // // _______________________________________________________________________________

          case "authorActivity":
            handleAuthorActivity(value[0], value[1], value[2], lang)?.map(
              (i) => {
                authorActivity?.push({
                  [lang.day]: i[0],
                  [lang.hour_group]: i[1],
                  [lang.no_of_activity]: i[2],
                });
              },
            );
            allSheetData.push([authorActivity, lang.account_authors_activity]);
            break;
          // //_______________________________________________________________________________

          case "postsContentType":
            value?.map((i) => {
              postsContentType.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.video]: i?.value[0],
                [lang.image]: i?.value[1],
                [lang.url]: i?.value[2],
                [lang.text]: i?.value[3],
                [lang.total]:
                  i?.value[0] + i?.value[1] + i?.value[2] + i?.value[3],
              });
            });
            allSheetData.push([postsContentType, lang.posts_content_type]);
            break;
          // // _______________________________________________________________________________

          case "topImages":
            value?.map((i) => {
              topImages?.push({ [lang.image]: i?.id });
            });
            allSheetData.push([topImages, lang.top_images]);

            break;
          // _______________________________________________________________________________

          case "topVideos":
            value?.map((i) => {
              topVideos.push({ [lang.video]: i?.id });
            });

            allSheetData.push([topVideos, lang.top_videos]);

            break;
          // // _______________________________________________________________________________

          case "postsVolume":
            value?.map((i) => {
              postsVolume.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([postsVolume, lang.posts_volume]);
            break;

          // // _______________________________________________________________________________

          case "latestInteractions":
            value?.map((i) => {
              latestInteractions.push({
                [lang.latest_interactions]: i?.tweet_link,
              });
            });
            allSheetData.push([latestInteractions, lang.latest_interactions]);

            break;
          // // _______________________________________________________________________________

          case "commentsVolume":
            value?.map((i) => {
              commentsVolume.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value,
              });
            });
            allSheetData.push([commentsVolume, lang.comments_volume]);
            break;
          // // _______________________________________________________________________________
          case "excelAccountTypes":
            value?.map((i) => {
              excelAccountTypes.push({
                [lang.xls_account_types]: lang[i?.name]
                  ? lang[i?.name]
                  : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([excelAccountTypes, lang["xls_account_types"]]);
            break;
          // // _______________________________________________________________________________
          case "excelTopSources":
            value?.map((i) => {
              excelTopSources.push({
                [lang.top_sources]: lang[i?.name?.toLowerCase()]
                  ? lang[i?.name?.toLowerCase()]
                  : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([excelTopSources, lang.top_sources]);
            break;
          // // _______________________________________________________________________________
          case "topVerifiedEngagers":
            value?.map((i) => {
              topVerifiedEngagers.push({
                [lang.top_verified_engagers]: i?.id,
                [lang.users]: i?.name,
                [lang.followers]: i?.followers_count,
                [lang.engagements]: i?.total_engagements,
              });
            });
            allSheetData.push([
              topVerifiedEngagers,
              lang.top_verified_engagers,
            ]);
            break;
          // _______________________________________________________________________________
          case "topEngagers":
            value?.map((i) => {
              topEngagers.push({
                [lang.top_engagers]: i?.id,
                [lang.users]: i?.name,
                [lang.followers]: i?.followers_count,
                [lang.engagements]: i?.total_engagements,
              });
            });
            allSheetData.push([topEngagers, lang.top_engagers]);
            break;
          // // _______________________________________________________________________________
          case "topInfleuncers":
            value?.map((i) => {
              topInfleuncers.push({
                [lang.top_infleuncers]: i?.id,
                [lang.user]: i?.name,
                [lang.followers]: i?.followers_count,
                [lang.engagements]: i?.total_engagements,
              });
            });
            allSheetData.push([topInfleuncers, lang.top_infleuncers]);
            break;

          // //_______________________________________________________________________________

          case "excelGenderDistribution":
            value?.map((i) => {
              excelGenderDistribution.push({
                [lang.gender]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([
              excelGenderDistribution,
              lang.gender_distribution,
            ]);
            break;
          //_______________________________________________________________________________

          case "excelContentStyles":
            value?.map((i) => {
              excelContentStyles?.push({
                [lang.comments_content_style]: lang[i?.name]
                  ? lang[i?.name]
                  : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([
              excelContentStyles,
              lang.comments_content_style,
            ]);
            break;

          //_______________________________________________________________________________
          case "excelTopCountries":
            value?.map((i) => {
              excelTopCountries.push({
                [lang.countries]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([excelTopCountries, lang.top_countries]);
            break;
          //_______________________________________________________________________________

          case "excelTopCities":
            value?.map((i) => {
              excelTopCities.push({
                [lang.cities]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([excelTopCities, lang.top_cities]);
            break;
          // _______________________________________________________________________________

          case "excelTopLanguages":
            value?.map((i) => {
              excelTopLanguages.push({
                [lang.language]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([excelTopLanguages, lang.top_languages]);
            break;

          //_______________________________________________________________________________

          case "excelAssociatedTopics":
            value?.map((i) => {
              excelAssociatedTopics.push({
                [lang.topic]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([
              excelAssociatedTopics,
              lang.comments_associated_topics,
            ]);
            break;
          // //_______________________________________________________________________________

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i?.name == value?.neutral[index].name) {
                let total =
                  value?.neutral[index]?.value +
                  value?.positive[index]?.value +
                  value?.negative[index]?.value;
                sentimentAnalysis.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? ((value?.neutral[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.positive]:
                    total !== 0
                      ? ((value?.positive[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.negative]:
                    total !== 0
                      ? ((value?.negative[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([
              sentimentAnalysis,
              lang.comments_sentiment_analysis,
            ]);
            break;

          // //_______________________________________________________________________________
          // // _______________________________________________________________________________

          case "themeXlxs":
            //themes
            let mainThemes = value?.theme_analysis?.main_themes_view;
            mainThemes &&
              Object.entries(mainThemes).forEach(([key1, value1]) => {
                let subThemes = value1?.sub_themes;
                subThemes &&
                  Object.entries(subThemes).forEach(([key2, value2]) => {
                    theme.push({
                      [lang.themes]: `${lang[key1]} (${lang[key2]})`,
                      [lang.total_interactions]: value2,
                    });
                  });
              });
            allSheetData.push([theme, lang.themes]);

            //sub-themes
            let mainSubThemes = value?.theme_analysis?.sub_themes_view;
            mainSubThemes &&
              Object.entries(mainSubThemes).forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes;
                mainThemes &&
                  Object.entries(mainThemes).forEach(([key2, value2]) => {
                    subTheme.push({
                      [lang.sub_themes]: `${lang[key1]} (${lang[key2]})`,
                      [lang.total_interactions]: value2,
                    });
                  });
              });
            allSheetData.push([subTheme, lang.sub_themes]);

            //themes-trend
            let mainthemesTrend = value?.theme_trend;
            mainthemesTrend &&
              Object.entries(mainthemesTrend).forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes_view;
                let rawObj = {};
                mainThemes &&
                  Object.entries(mainThemes).forEach(([key2, value2]) => {
                    let subThemes = value2?.sub_themes;
                    subThemes &&
                      Object.entries(subThemes).forEach(([key3, value3]) => {
                        rawObj = {
                          ...rawObj,
                          [`${lang[key2]} (${lang[key3]})`]: value3,
                        };
                      });
                  });
                themesTrend.push({
                  [lang.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData.push([themesTrend, lang.themes_trend]);

            //sub-themes-trend
            mainthemesTrend &&
              Object.entries(mainthemesTrend).forEach(([key1, value1]) => {
                let subThemesTrendObj = value1?.sub_themes_view;
                let rawObj = {};
                subThemesTrendObj &&
                  Object.entries(subThemesTrendObj).forEach(
                    ([key2, value2]) => {
                      let mainThemes = value2?.main_themes;
                      mainThemes &&
                        Object.entries(mainThemes).forEach(([key3, value3]) => {
                          rawObj = {
                            ...rawObj,
                            [`${lang[key2]} (${lang[key3]})`]: value3,
                          };
                        });
                    },
                  );
                subThemesTrend.push({
                  [lang.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData.push([subThemesTrend, lang.sub_themes_trend]);
            break;

          // //_______________________________________________________________________________

          case "excelDialects":
            handleSortedCase(
              value,
              excelDialects,
              lang.dialect,
              lang.main_dialect_filter,
              lang,
            );
            allSheetData.push([excelDialects, lang.main_dialect_filter]);
            break;
          // //_______________________________________________________________________________

          case "excelSubDialects":
            handleSortedCase(
              value,
              excelSubDialects,
              lang.sub_dialects,
              lang.number,
              lang,
            );
            allSheetData.push([excelSubDialects, lang.sub_dialects]);
            break;
          // _______________________________________________________________________________

          case "topKeywords":
            value?.map((i) => {
              topKeywords.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([
              topKeywords,
              lang.comments_top_keywords_xlsx_sheetname,
            ]);
            break;
          // // _______________________________________________________________________________

          case "topHashtags":
            value?.map((i) => {
              topHashtags.push({
                [lang.xlsx_hashtags]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([
              topHashtags,
              lang.comments_top_hashtags_xlsx_sheetname,
            ]);
            break;
          // _______________________________________________________________________________

          case "topImages":
            value?.map((i) => {
              topImages?.push({ [lang.image]: i?.id });
            });
            allSheetData.push([topImages, lang.comments_top_images]);
            break;
          // _______________________________________________________________________________

          case "topVideos":
            value?.map((i) => {
              topVideos.push({ [lang.video]: i?.id });
            });
            allSheetData.push([topVideos, lang.comments_top_videos]);
            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });

    return handleXlsData(allSheetData);
  }
};
export { twitter_public };
