import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import _ from "lodash";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers/index.js";
import StackedLineChart from "../echarts/stackedLineChart.js";
import WidgetHeader from "./widgetHeader.js";

const StackedLineWidget = (props) => {
  const {
    dataPiechart,
    isSentimentAnalysisWidget,
    inspectorName,
    isQuickExplore,
    isCityAnalysisWidget,
    isSentimentAnalysisShowCharts,
  } = props;
  let totalSentiment = dataPiechart?.reduce(function (prev, current) {
    return prev + +current.value;
  }, 0);
  const intl = useIntl();
  const [dataForChart, setDataForChart] = useState(props.data);
  const [duration, setDuration] = useState();
  const [scaleType, setScaleType] = useState();
  const [dataPieChartValue, setDataPieChartValue] = useState([]);

  const getChartMeta = (data, duration) => {
    setDataForChart(data);
    setDuration(duration);
  };
  const getChartScales = (scale) => {
    setScaleType(scale);
  };
  useEffect(() => {
    getChartMeta(props.data);
  }, [props.data]);

  let seriesData = [],
    xAxisData = new Set(),
    zoomPercentage = 0,
    showNoDataDialog = false;

  const colors =
    props.colors === undefined
      ? ["#E50035", "#F9A700", "#89BB2A"]
      : props.colors;
  //this function is used for handle DataChart Value
  const handleDataChartValue = (e, value) => {
    const arr = [...dataPieChartValue];
    const arrIndex = arr.indexOf(
      CheckValueLocale(value.toLowerCase(), "", {}, intl),
    );
    if (arrIndex === -1) {
      arr.push(CheckValueLocale(value.toLowerCase(), "", {}, intl));
    } else {
      arr.splice(arrIndex, 1);
    }
    setDataPieChartValue(arr);
  };

  if (dataForChart) {
    for (var key in dataForChart) {
      // This function used to avoid duplication of Days when we insert multiple data sources to chart.
      // And it will work too with single data source but it needs some refactor.
      let date_array = [];
      let value_array = [];
      let native_value_array = [];

      dataForChart[key].forEach((item) => {
        native_value_array?.push(item?.native_value);
        let item_format;
        if (duration === undefined || duration === "day") {
          item_format = moment.unix(item.name).utc().format("YYYY-MM-DD");
        } else {
          item_format = item.name;
        }
        if (!date_array.includes(item_format)) {
          date_array.push(item_format);
          value_array.push(item.value);
        } else {
          let index_of_value = date_array.indexOf(item_format);
          // Here we will Ignore it if new value is 0 because it doesn't make sense to add 0
          if (item.value != 0) {
            // If The already existing value is 0, we will add new value to it.
            if (value_array[index_of_value] == 0) {
              value_array[index_of_value] = item.value;
              // if old value is bigger than 0, we will divide old value with new one by 2
            } else {
              value_array[index_of_value] =
                (value_array[index_of_value] + item.value) / 2;
            }
          }
        }
      });

      // Adding Chart data
      seriesData?.push({
        name:
          props?.isCustomDashboard && props?.isComparison // to void same monitor name different datasources
            ? key?.split("__")?.[0]
            : CheckValueLocale(key.toLowerCase(), "", {}, intl),
        date: date_array,
        value: value_array,
        native_value: native_value_array,
      });

      // adding X Axis labels
      if (duration === undefined || duration === "day") {
        dataForChart[key]?.map((item) =>
          xAxisData?.add(moment.unix(item?.name).utc().format("YYYY-MM-DD")),
        );
      } else {
        dataForChart[key].map((item) => xAxisData.add(item.name));
      }
    }
    xAxisData = [...xAxisData];
    let sortSentimentSeries = [];
    seriesData.forEach((data, index) => {
      // add color for series
      if (!props?.isTopicSection) {
        if (data.name === seriesData[0].name) {
          if (props.hideLegend) {
            if (
              dataPieChartValue.indexOf(
                CheckValueLocale("negative", "", {}, intl),
              ) === -1
            ) {
              data.color = colors[0];
              sortSentimentSeries[2] = data;
            } else {
              sortSentimentSeries[2] = {};
            }
          } else {
            data.color = colors[0];
            sortSentimentSeries[2] = data;
          }
        } else if (data.name === seriesData[1].name) {
          if (props.hideLegend) {
            if (
              dataPieChartValue.indexOf(
                CheckValueLocale("positive", "", {}, intl),
              ) === -1
            ) {
              data.color = colors[2];
              sortSentimentSeries[0] = data;
            } else {
              sortSentimentSeries[0] = {};
            }
          } else {
            data.color = colors[2] ? colors[2] : colors[1];
            sortSentimentSeries[0] = data;
          }
        } else if (data.name === seriesData[2].name) {
          if (props.hideLegend) {
            if (
              dataPieChartValue.indexOf(
                CheckValueLocale("neutral", "", {}, intl),
              ) === -1
            ) {
              data.color = colors[1];
              sortSentimentSeries[1] = data;
            } else {
              sortSentimentSeries[1] = {};
            }
          } else {
            data.color = colors[1];
            sortSentimentSeries[1] = data;
          }
        }
      } else {
        if (
          dataPieChartValue.indexOf(
            CheckValueLocale(seriesData[index].name, "", {}, intl),
          ) === -1
        ) {
          data.color = colors[index];
          sortSentimentSeries[index] = data;
        } else {
          data = {};
          sortSentimentSeries[index] = data;
        }
      }
    });
    seriesData = [...sortSentimentSeries];
    // finding zoom percentage if data length more then 30
    if (xAxisData.length > 30) {
      zoomPercentage = Math.floor(100 - (10 / xAxisData.length) * 100);
    }
  }
  let isEmptySentimentAnalysis =
    isSentimentAnalysisShowCharts &&
    Object.values(dataForChart)?.every((x) => x?.length === 0);
  let isEmptyCityAnalysis =
    isCityAnalysisWidget &&
    Object.values(dataForChart || {})?.every((x) => x?.length === 0);

  //Specific for [ Comments Sentiment Analysis widget ]
  const isSentimentNegative = dataForChart?.negative?.some(
    (value) => value?.native_value > 0,
  );
  const isSentimentPositive = dataForChart?.positive?.some(
    (value) => value?.native_value > 0,
  );
  const isSentimentNeutral = dataForChart?.neutral?.some(
    (value) => value?.native_value > 0,
  );
  const checkSentimentAnalysis =
    isSentimentNegative || isSentimentPositive || isSentimentNeutral;

  // Specific for [ Posts Reach And Impressions widget ]
  const postsReach = dataForChart?.reach?.some((value) => value?.value > 0);
  const postsImpression = dataForChart?.impression?.some(
    (value) => value?.value > 0,
  );
  const checkReachImpression = postsReach || postsImpression;

  const isEmptyComparisonData = (dataForChart) => {
    return !Object?.values(dataForChart)?.some((data) =>
      data?.some((item) => item?.value > 0),
    );
  };

  return (
    <div
      className={`sentiment_chart ${
        isSentimentAnalysisWidget && "sentiment-analysis-widget"
      }`}
      id={
        props?.isCustomDashboard
          ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
          : props?.title
      }
    >
      <WidgetHeader
        showGroupBy={props?.showGroupBy && !isEmpty(dataForChart)}
        showScales={props?.showScales}
        title={props?.title}
        showAI={props?.showAI}
        iconAfterTitle={props?.iconAfterTitle}
        showDownloadIcon={props?.showDownloadIcon}
        totalLabel={
          _.isEqual(
            props?.stackedLineWidgetPreLoaderDataSources?.sort(),
            props?.preLoaderTrackerDataSources?.sort(),
          ) !== true
            ? undefined
            : props.totalLabel
        }
        totalValue={
          _.isEqual(
            props?.stackedLineWidgetPreLoaderDataSources?.sort(),
            props?.preLoaderTrackerDataSources?.sort(),
          ) !== true
            ? undefined
            : props?.totalValue
        }
        data={props?.data}
        nestedLoop={true}
        getChartMeta={getChartMeta}
        bigImg={props?.bigImg}
        chartId={
          props?.isCustomDashboard
            ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
            : props?.title
        }
        titleToolTip={props?.titleToolTip}
        getChartScales={getChartScales}
        // below 4 props is used to display static labels, to follow design
        dataPiechart={dataPiechart}
        totalSentiment={totalSentiment}
        hideLegend={props?.hideLegend}
        labelColors={props?.labelColors}
        handleDataChartValue={handleDataChartValue}
        dataPieChartValue={dataPieChartValue}
        showReportIcon={props?.showReportIcon}
        hidePercentage={props?.hidePercentage}
        isSentimentAnalysisWidget={isSentimentAnalysisWidget}
        isEmptySentimentAnalysis={isEmptySentimentAnalysis}
        isEmptyCityAnalysis={isEmptyCityAnalysis}
        inspectorName={inspectorName}
        handleOpenQuickInspect={props?.handleOpenQuickInspect}
        isQuickExplore={isQuickExplore}
        dataReached={_.isEqual(
          props?.stackedLineWidgetPreLoaderDataSources?.sort(),
          props?.preLoaderTrackerDataSources?.sort(),
        )}
        isSentimentNeutral={isSentimentNeutral}
        isSentimentPositive={isSentimentPositive}
        isSentimentNegative={isSentimentNegative}
        postsReach={postsReach}
        postsImpression={postsImpression}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
        listDataSourceIcon={
          props?.isComparison
            ? dataPiechart?.map((monitor) => monitor?.dataSource)
            : []
        }
      />
      {_.isEqual(
        props?.stackedLineWidgetPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <>
          <FetchingData />
        </>
      ) : isEmpty(dataForChart) ||
        isEmptySentimentAnalysis ||
        isEmptyCityAnalysis ||
        (dataForChart?.impression?.length == 0 &&
          dataForChart?.reach?.length == 0) ||
        (isSentimentAnalysisShowCharts &&
          !checkSentimentAnalysis &&
          !isCityAnalysisWidget) ||
        (!isSentimentAnalysisShowCharts &&
          !checkReachImpression &&
          !isCityAnalysisWidget) ||
        (props?.isComparison && isEmptyComparisonData(dataForChart)) ? (
        <>
          <NoDataFound />
        </>
      ) : (
        <>
          <Grid container spacing={3}>
            {props.isSwitchCase ? null : (
              <Grid item md={3}>
                {dataPiechart?.map((item, index) => (
                  <Box key={index} mb={7}>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography
                        className="text-transform-letter"
                        style={{
                          color: "#6D737A",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {item.name
                          ? CheckValueLocale(item.name, "", {}, intl)
                          : ""}
                      </Typography>

                      <Box display="flex" alignItems="center">
                        <Typography sx={{ color: "#6D737A", fontSize: 12 }}>
                          ({item.value})
                        </Typography>
                        <Box sx={{ mx: "3px" }} className="text-label" />
                        <Typography
                          sx={{
                            color:
                              item.name === "positive"
                                ? "#BAD532"
                                : item.name === "negative"
                                  ? "#ED1846"
                                  : "#F5B819",
                          }}
                        >
                          {totalSentiment !== 0
                            ? ((item.value / totalSentiment) * 100).toFixed(2) +
                              "%"
                            : "0%"}
                        </Typography>
                      </Box>
                    </Box>
                    {}
                    <CustomLinearProgress
                      value={((item.value / totalSentiment) * 100).toFixed(2)}
                      variant="determinate"
                      customColor={
                        item.name === "positive"
                          ? "#BAD532"
                          : item.name === "negative"
                            ? "#ED1846"
                            : "#F5B819"
                      }
                    />
                  </Box>
                ))}
              </Grid>
            )}

            <Grid
              item
              md={props.isSwitchCase ? 12 : 9}
              className="stacked-line-widget-dashboard"
            >
              <StackedLineChart
                zoomPercentage={zoomPercentage}
                xAxisData={xAxisData}
                seriesData={seriesData}
                hideLegend={props.hideLegend === undefined ? true : false}
                showNoDataDialog={showNoDataDialog}
                isSwitchCase={props.isSwitchCase}
                legendPosition={window.localStorage.lang === "ar" && "right"}
                handleOpenQuickInspect={props.handleOpenQuickInspect}
                inspectorName={props.inspectorName}
                showLogChart={scaleType == "logarithmic"}
                isSentimentAnalysis={
                  props.isSentimentAnalysis === false ? false : true
                }
                isPostsReachImpression={props?.isPostsReachImpression}
                duration={duration}
                isComparison={props?.isComparison} // ** Only with Custom Dashboard (Comparison Type) ** //
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default StackedLineWidget;

const CustomLinearProgress = styled(LinearProgress)`
  height: 8px;
  border-radius: 5px;
  background-color: #f1f5f9;
  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${(props) => props.customColor};
  }
`;
