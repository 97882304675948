import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import AiAgentPublishedVersionItem from "./aiAgentPublishedVersionItem";
import { useAiAgentPublishedVersions } from "pages/engagements/components/aiAgents/aiAgentsHooks/useAiAgentPublishedVersions";
import { useInView } from "react-intersection-observer";
import { useContext, useEffect } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import HeaderSkeletonItem from "../activityLogSkeletonItem";
import SideBarEmpty from "../../../aiAgentsSideBar/sideBarEmpty";

const AiPublishedVersionsDrawer = ({ toggleDrawer, isOpen, id, edited }) => {
  const intl = useIntl();
  const isAR = intl?.locale === "ar";
  const { setAiAgentCurrentVersion } = useContext(AiAgentsContext);
  const {
    data: versionsData,
    fetchNextPage,
    hasNextPage,
    isPending,
    isFetchingNextPage,
  } = useAiAgentPublishedVersions(id, isOpen);
  const flatVersions =
    versionsData?.pages?.flatMap((page) => page?.data?.versions) || [];

  const [ref, inView] = useInView({
    threshold: 0.9,
  });

  useEffect(() => {
    if ((inView, hasNextPage)) {
      fetchNextPage();
    }
  }, [inView, hasNextPage]);

  return (
    <Drawer
      anchor={isAR ? "left" : "right"}
      open={isOpen}
      onClose={() => toggleDrawer(false)}
    >
      <Box className="ai-agent-drawer-box" role="presentation">
        <Box className="ai-agent-published-drawer-drawer-header">
          <Typography className="ai-agent-published-drawer-drawer-header-typography">
            {CheckValueLocale("published_version", "", {}, intl)}
          </Typography>
          <IconButton onClick={() => toggleDrawer(false)}>
            <FontAwesomeIcon
              icon={faXmark}
              className="ai-agent-published-version-drawer-exit"
            />
          </IconButton>
        </Box>
        <Box className="ai-agent-published-drawer-drawer-content">
          {isPending ? (
            [1, 2, 3, 4, 5].map((item) => <HeaderSkeletonItem key={item} />)
          ) : flatVersions?.length === 0 ? (
            <Box>
              <SideBarEmpty emptyText="no_routings_found" />
            </Box>
          ) : (
            flatVersions?.map((item, index) => {
              if (index === 0) {
                setAiAgentCurrentVersion(item?.id);
              }
              return (
                <AiAgentPublishedVersionItem
                  itemRef={index === flatVersions?.length - 1 ? ref : null}
                  index={index}
                  key={item?.ai_agent_id}
                  changedBy={item?.user?.name}
                  changedByImage={item?.user?.avatar || ""}
                  date={item?.created_at}
                  id={item?.id}
                  isLastItem={index === flatVersions?.length - 1}
                  closeDrawer={() => toggleDrawer(false)}
                  edited={edited}
                  publishedVersionResources={
                    item?.data?.ai_agent_resources || []
                  }
                />
              );
            })
          )}
          {isFetchingNextPage && (
            <Box className="ai-agent-paginated-circular-progress-container">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default AiPublishedVersionsDrawer;
