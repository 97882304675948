import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { faMeh, faFrown, faSmile } from "@fortawesome/free-solid-svg-icons";
import OverviewRetweetIcon from "../../../../images/notification-icons/OverviewRetweet.svg";
import OverviewHeartIcon from "../../../../images/notification-icons/OverviewHeart.svg";
import OverviewClendarIcon from "../../../../images/notification-icons/OverviewClendar.svg";
import OverviewClockIcon from "../../../../images/notification-icons/OverviewClock.svg";
import OverviewTagIcon from "../../../../images/notification-icons/OverviewTag.svg";
import ImagePlaceholder from "../../../../images/shared-images/placeholder-for-broken-image.png";
import { CheckValueLocale, checkIfWeNeedShowMoreTweet } from "utils/helpers";
import FullTextTweetPopUp from "components/fullTextTweetPopUp";

const TweetItem = (props) => {
  const intl = useIntl();
  const [sentimentIcon, setSentimentIcon] = useState("");
  const [sentimentLabel, setSentimentLabel] = useState("");
  const [sentimentColor, setSentimentColor] = useState("");
  const [showFullTextTweet, setShowFullTextTweet] = useState(false);
  const { timeZone } = props || {};
  const {
    user,
    truncated,
    extended_tweet,
    text,
    entities,
    retweet_count,
    quote_count,
    favorite_count,
    created_at,
    categories,
    category,
    overallSentiment_label,
    tweet_full_text,
  } = props?.data || {};

  const tweet_time = moment(created_at)?.format("HH:mm A");
  const tweet_date = moment(created_at)?.format("DD-MM-YYYY");

  let mediaItem = {};
  let mediaUrl = "";

  const getTweetText = () => {
    const tweetText =
      truncated && extended_tweet !== undefined
        ? extended_tweet?.full_text
        : text;
    return tweetText;
  };

  const formatMediaUrl = (item) => {
    let url = "";
    if (item?.type === "video") {
      item?.video_info?.variants?.forEach((variant) => {
        if (variant?.content_type === "video/mp4") {
          url = variant?.url;
        }
      });
    } else if (item?.type === "animated_gif") {
      url = item?.video_info?.variants?.[0]?.url;
    } else {
      url = item?.media_url_https;
    }
    return url;
  };

  if (
    extended_tweet !== undefined &&
    extended_tweet?.entities !== undefined &&
    "media" in extended_tweet?.entities &&
    extended_tweet?.entities?.media?.length
  ) {
    mediaItem = extended_tweet?.extended_entities?.media?.[0];
    mediaUrl = formatMediaUrl(mediaItem);
  }
  if (
    entities !== undefined &&
    "media" in entities &&
    entities?.media?.length
  ) {
    mediaItem = entities?.media?.[0];
    mediaUrl = formatMediaUrl(mediaItem);
  }

  function getCategoryName() {
    if (categories !== undefined) {
      var categoryPos = categories?.[category];
      if (categoryPos !== undefined) {
        return categoryPos?.category_name;
      } else {
        return CheckValueLocale("unknown", "", {}, intl);
      }
    } else {
      return CheckValueLocale("unknown", "", {}, intl);
    }
  }

  const handleSentiment = () => {
    let icon, label, color;
    switch (overallSentiment_label) {
      case "Negative":
        icon = "negative";
        label = faFrown;
        color = "#E50C35";
        break;
      case "Positive":
        icon = "positive";
        label = faSmile;
        color = "#89BB2A";
        break;
      case "Neutral":
        icon = "neutral";
        label = faMeh;
        color = "#F9A700";
        break;
      default:
        icon = "none";
        label = faMeh;
        color = "#80868c";
        break;
    }
    setSentimentIcon(icon);
    setSentimentLabel(label);
    setSentimentColor(color);
  };
  const handleImageError = (event) => {
    event.target.src = ImagePlaceholder;
    event.target.alt = "placeholder-image";
  };

  useEffect(() => {
    handleSentiment();
  }, []);

  return (
    <Box className="replies-list-box">
      <Box className="replies-list-header">
        <Box className="header-left">
          <Avatar src={user?.profile_image_url_https} />
          <Typography variant="subtitle1">{user?.name}</Typography>
          <Typography variant="subtitle2">{`@${user?.screen_name}`}</Typography>
        </Box>
        <Box className="header-right">
          <Box style={{ color: sentimentColor, fontWeight: 500 }}>
            <FontAwesomeIcon
              icon={sentimentLabel}
              style={{ margin: "0px 5px" }}
            />
            {sentimentIcon ? CheckValueLocale(sentimentIcon, "", {}, intl) : ""}
          </Box>
          {/* <IconButton className="more-btn">
            <MoreHorizIcon />
          </IconButton> */}
        </Box>
      </Box>
      <Box className="replies-list-body">
        <Typography variant="body1">
          {getTweetText()}
          {checkIfWeNeedShowMoreTweet(getTweetText(), tweet_full_text) ? (
            <Box
              component="span"
              className="tweet-full-text-read-all-btn"
              onClick={() => setShowFullTextTweet(true)}
            >
              {CheckValueLocale("read_all", "", {}, intl)}
            </Box>
          ) : null}
        </Typography>
        {mediaItem && mediaUrl && (
          <div className={`tweet-media-${mediaItem?.type}`}>
            {mediaItem?.type === "video" ? (
              <video controls className="tweet-overview-media">
                <source type="video/mp4" src={mediaUrl} />
              </video>
            ) : (
              <img
                className="tweet-overview-media overview-content-img"
                src={mediaUrl}
                alt="overview img"
                onError={handleImageError}
              />
            )}
          </div>
        )}
      </Box>
      <Box className="replies-list-footer">
        <Box>
          <img src={OverviewRetweetIcon} alt="retweet icon" />
          <Typography>{retweet_count + quote_count}</Typography>
        </Box>
        <Box className="heart-box">
          <img src={OverviewHeartIcon} alt="heart icon" />
          <Typography>{favorite_count}</Typography>
        </Box>
        <Box className="time-box">
          <img src={OverviewClendarIcon} alt="calender icon" />
          <Typography>{tweet_date}</Typography>
        </Box>
        <Box className="time-box">
          <img src={OverviewClockIcon} alt="clock icon" />
          <Typography sx={{ direction: "ltr" }}>
            {tweet_time}{" "}
            {timeZone > 0
              ? `${CheckValueLocale("gmt", "", {}, intl)}+${timeZone}`
              : `${CheckValueLocale("gmt", "", {}, intl)}-${timeZone}`}
          </Typography>
        </Box>
        <Box className="time-box">
          <img src={OverviewTagIcon} alt="overview icon" />
          <Typography>
            {CheckValueLocale("overview_category", "", {}, intl)}:{" "}
            {getCategoryName()}
          </Typography>
        </Box>
      </Box>
      {showFullTextTweet ? (
        <FullTextTweetPopUp
          closeFilter={() => setShowFullTextTweet(false)}
          post={props?.data}
          tweet_date={moment(created_at).format("D MMM, YYYY h:mm A")}
          showRetweet={true}
          showHearts={true}
        />
      ) : null}
    </Box>
  );
};

export default TweetItem;
