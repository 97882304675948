import {
  changeFromObjectToArrayOfObject,
  handleGenderAgeDistResponse,
  handleSentimentResponse,
} from "utils/helpers";
import { handleCustomerCareStatsData } from "../statsDataHandling";

export const facebookRespHandling = (
  response,
  handleWidgetResponse,
  dataSource,
) => {
  const eventNameObj = {
    Facebook__EngagementsPage__associated_topics: {
      value: (response) => ({
        pie_data: response?.associated_topics,
      }),
    },
    Facebook__EngagementsPage__top_hashtags: {
      value: (response) => ({
        pie_data: response?.top_hashtags,
      }),
    },
    Facebook__AccountPage__top_hashtags: {
      value: (response) => ({
        pie_data: response?.top_hashtags,
      }),
    },
    Facebook__EngagementsPage__dialects_and_sub_dialects: {
      value: (response) => ({
        pie_data: response?.dialects,
        pie_sub_data: response?.sub_dialects,
      }),
    },
    Facebook__EngagementsPage__sentiment_analysis: {
      value: (response) => ({
        data: handleSentimentResponse(response?.sentiment_overtime),
        pie_data: response?.sentiment_piechart,
      }),
    },
    Facebook__EngagementsPage__volume_overtime: {
      value: (response) => ({
        data: response?.posts_over_time,
        total_count: response?.total_posts_count,
      }),
    },
    Facebook__EngagementsPage__top_keywords: {
      value: (response) => ({
        pie_data: response?.top_keywords,
      }),
    },
    Facebook__AccountPage__top_keywords: {
      value: (response) => ({
        pie_data: response?.top_keywords,
      }),
    },
    Facebook__EngagementsPage__reactions: {
      value: (response) => ({
        data: response?.posts_reactions?.posts_reactions_over_time,
        pie_data: response?.posts_reactions?.posts_reactions_piechart,
      }),
    },
    Facebook__AccountPage__page_views: {
      value: (response) => ({
        data: response?.page_views_overtime,
        total_count: response?.total_page_views?.[0]?.value,
      }),
    },
    Facebook__EngagementsPage__posts_interactions: {
      value: (response) => ({
        data: response?.posts_interactions?.posts_interactions_over_time,
        pie_data: response?.posts_interactions?.posts_interactions_legends,
        average_interactions:
          response?.posts_interactions?.average_interactions,
        max_interaction_date:
          response?.posts_interactions?.max_interaction_date,
        max_interactions: response?.posts_interactions?.max_interactions,
        min_interaction_date:
          response?.posts_interactions?.min_interaction_date,
        min_interactions: response?.posts_interactions?.min_interactions,
        total_interactions: response?.posts_interactions?.total_interactions,
      }),
    },
    Facebook__AccountPage__page_likes_unlikes: {
      value: (response) => ({
        data: response?.page_likes_unlikes_overtime,
        pie_data: response?.pie_chart_page_likes_unlikes,
      }),
    },
    Facebook__AccountPage__page_reach_impressions: {
      value: (response) => ({
        data: {
          reach:
            response?.page_reach_impressions_overtime?.page_reach_over_time,
          impression:
            response?.page_reach_impressions_overtime
              ?.page_impressions_over_time,
        },
        pie_data: response?.total_page_reach_impressions?.map((item) => ({
          ...item,
        })),
      }),
    },
    Facebook__AccountPage__page_actions: {
      value: (response) => ({
        data: response?.page_actions_overtime,
        pie_data: response?.pie_chart_page_actions,
      }),
    },
    Facebook__AccountPage__page_contact_details_interactions: {
      value: (response) => ({
        data: response?.page_contact_details_interactions_overtime,
        pie_data: response?.pie_chart_page_contact_details_interactions,
      }),
    },
    Facebook__AccountPage__volume_overtime: {
      value: (response) => ({
        data: response?.posts_over_time,
        total_count: response?.total_posts_count,
      }),
    },
    Facebook__AccountPage__account_author_activity: {
      value: (response) => ({
        activity_data: response?.account_author_activity,
        min_value: response?.min_value,
        max_value: response?.max_value,
      }),
    },
    Facebook__AccountPage__reach_funnel: {
      value: (response) => ({
        data: response?.reach_funnel_overtime,
        pie_data: response?.reach_funnel_legends,
      }),
    },
    Facebook__AccountPage__content_type: {
      value: (response) => ({
        data: response?.posts_content_type,
        pie_data: response?.posts_content_types_legends,
      }),
    },
    Facebook__AccountPage__page_videos_views: {
      value: (response) => ({
        data: response?.page_videos_views_overtime,
        total_count: response?.total_page_videos_views?.[0]?.value,
      }),
    },
    Facebook__AccountPage__reactions: {
      value: (response) => ({
        data: response?.posts_reactions?.posts_reactions_over_time,
        pie_data: response?.posts_reactions?.posts_reactions_piechart,
      }),
    },
    Facebook__AccountPage__posts_interactions: {
      value: (response) => ({
        data: response?.posts_interactions?.posts_interactions_over_time,
        pie_data: response?.posts_interactions?.posts_interactions_legends,
        average_interactions:
          response?.posts_interactions?.average_interactions,
        max_interaction_date:
          response?.posts_interactions?.max_interaction_date,
        max_interactions: response?.posts_interactions?.max_interactions,
        min_interaction_date:
          response?.posts_interactions?.min_interaction_date,
        min_interactions: response?.posts_interactions?.min_interactions,
        total_interactions: response?.posts_interactions?.total_interactions,
      }),
    },
    Facebook__AccountPage__top_videos: {
      value: (response) => ({
        media_data: response?.top_videos,
      }),
    },
    Facebook__AccountPage__top_images: {
      value: (response) => ({
        media_data: response?.top_images,
      }),
    },
    Facebook__EngagementsPage__gender_distribution: {
      value: (response) => ({
        data: handleGenderAgeDistResponse(response?.gender_distribution),
        pie_data: response?.gender_piechart,
      }),
    },
    Facebook__EngagementsPage__top_languages: {
      value: (response) => ({
        pie_data: response?.top_languages,
      }),
    },
    Facebook__EngagementsPage__top_countries: {
      value: (response) => ({
        pie_data: response?.top_countries,
      }),
    },
    Facebook__EngagementsPage__engagers_volume: {
      value: (response) => ({
        data: response?.engagers_volume?.engagers_volume_over_time,
        total_count: response?.engagers_volume?.total_engagers,
      }),
    },
    Facebook__EngagementsPage__account_author_activity: {
      value: (response) => ({
        activity_data: response?.account_author_activity,
        min_value: response?.min_value,
        max_value: response?.max_value,
      }),
    },
    Facebook__EngagementsPage__top_cities: {
      value: (response) => ({
        pie_data: response?.top_cities,
      }),
    },
    Facebook__CustomerCarePage__customer_care_performance: {
      value: (response) => ({
        data: changeFromObjectToArrayOfObject(response?.response_time_segment),
        total_number_of_questions: response?.total_number_of_questions,
        avg_response_time_in_seconds: response?.avg_response_time_in_seconds,
        number_of_answered_questions: response?.number_of_answered_questions,
        stats_data: handleCustomerCareStatsData(response),
      }),
    },
  };

  const eventNameKey = eventNameObj?.[response?.eventName];

  if (eventNameKey) {
    handleWidgetResponse(
      response?.metric_id,
      response?.monitor_id,
      dataSource,
      eventNameKey?.value(response?.eventData),
      response?.customized_metric_id,
    );
  }
};
