import { useEffect, useState } from "react";
import { Button, Menu, MenuItem, Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { NavLink, useHistory } from "react-router-dom";
import "shared/main.scss";
//Locals
import { useIntl } from "react-intl";
import "../mainHeader/mainHeader.scss";
import {
  CheckValueLocale,
  handlelUserRoles,
  handleActiveFeature,
  handleSingleDropdownMenuItem,
} from "utils/helpers";

const UtilitiesMenu = ({ smRoles, cxmRoles }) => {
  const intl = useIntl();
  const history = useHistory();
  const [anchorSL, setAnchorSL] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const openSL = Boolean(anchorSL);

  const linksArray = [
    {
      isActive: handlelUserRoles("GENERAL", "VIEW_UTILITIES"),
      link: "/reports/list",
      text: "reports",
      id: "utilities-list-menu-reports",
    },
    {
      isActive: handlelUserRoles("GENERAL", "VIEW_UTILITIES"),
      link: "/alerts",
      text: "alerts",
      id: "utilities-list-menu-alerts",
    },
    {
      isActive: Boolean(smRoles || cxmRoles),
      link: "/social/benchmark",
      text: "benchmark",
      id: "utilities-list-menu-benchmark",
    },
    {
      isActive:
        ((handlelUserRoles("SM", "VIEW_DASHBOARD_LIST") ||
          handlelUserRoles("CXM", "VIEW_DASHBOARD_LIST")) &&
          handleActiveFeature("SM", "is_dashboards_enabled")) ||
        (handlelUserRoles("SM", "VIEW_DASHBOARD_LIST") &&
          handleActiveFeature("SM", "is_sl_custom_dashboards_enabled")) ||
        (handlelUserRoles("CXM", "VIEW_DASHBOARD_LIST") &&
          handleActiveFeature("CXM", "is_cxm_custom_dashboards_enabled")),
      link: "/dashboard",
      text: "dashboards",
      id: "utilities-list-menu-dashboards",
    },
  ];

  const handleClickUtilities = (event) => {
    if (anchorSL !== event.currentTarget) {
      setAnchorSL(event.currentTarget);
    }
    handleSingleDropdownMenuItem(linksArray, history);
  };

  const handleHoverUtilities = (event) => {
    if (anchorSL !== event.currentTarget) {
      setAnchorSL(event.currentTarget);
    }
  };

  const handleCloseSL = () => {
    setAnchorSL(null);
  };

  const subMenuPaths = [
    "/social/benchmark",
    "/alerts",
    "/reports/list",
    "/dashboard",
  ];
  useEffect(() => {
    setIsActive(
      subMenuPaths?.includes(history?.location?.pathname) ||
        history?.location?.pathname?.startsWith("/reports/") ||
        history?.location?.pathname?.startsWith("/dashboard/"),
    );

    for (let [key, value] of Object?.entries(subMenuPaths)) {
      if (history?.location?.pathname?.includes(`${value}`)) {
        setIsActive(true);
      }
    }
  }, [history.location.pathname]);

  let menuDirection = intl.locale === "ar" ? "right" : "left";

  return (
    <Box className="navbar-header" onMouseLeave={handleCloseSL}>
      <Button
        id="main-header-social-media"
        aria-owns={anchorSL ? "simple-menu" : undefined}
        onMouseOver={handleHoverUtilities}
        onMouseUp={handleCloseSL}
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={openSL ? "true" : undefined}
        disableElevation
        onClick={handleClickUtilities}
        endIcon={<KeyboardArrowDown />}
        className={`${
          isActive
            ? "selected-route btn-dropdown-nav utilites-menu"
            : "btn-dropdown-nav utilites-menu"
        } dropdown-menu-arrow header-dropdown-menu`}
      >
        {CheckValueLocale("utilities", "", {}, intl)}
      </Button>
      <Menu
        id="main-header-social-media-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
          onMouseLeave: handleCloseSL,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuDirection,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuDirection,
        }}
        anchorEl={anchorSL}
        open={openSL}
        onClose={handleCloseSL}
        className="social-listening-menu"
      >
        {linksArray
          ?.filter((link) => link?.isActive)
          .map((link) => {
            return (
              <MenuItem
                className="utilities-menu-list menu-list"
                onClick={() => handleCloseSL()}
                disableRipple
                id={link?.id}
              >
                <NavLink activeClassName="selected-route" to={link?.link}>
                  {CheckValueLocale(link?.text, "", {}, intl)}
                </NavLink>
              </MenuItem>
            );
          })}
        {/* {handlelUserRoles("GENERAL", "VIEW_UTILITIES") ? (
          <MenuItem
            className="utilities-menu-list menu-list"
            onClick={() => handleCloseSL()}
            disableRipple
            id="utilities-list-menu-reports"
          >
            <NavLink activeClassName="selected-route" to="/reports/list">
              {CheckValueLocale("reports", "", {}, intl)}
            </NavLink>
          </MenuItem>
        ) : null} */}
      </Menu>
    </Box>
  );
};
export default UtilitiesMenu;
