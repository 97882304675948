import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Typography } from "@mui/material";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";

const HeaderAlerts = ({ message, endSection }) => {
  return (
    <Box className="ai-agent-header-alert-container">
      <Box className="ai-agent-header-alert-start-section-container">
        <IconButton>
          <FontAwesomeIcon
            className="ai-agent-name-tooltip-icon"
            icon={faCircleExclamation}
          />
        </IconButton>
        <Typography className="ai-agent-header-message">{message}</Typography>
      </Box>
      {endSection}
    </Box>
  );
};

export default HeaderAlerts;
