import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import { SelectDropdown } from "../sheardComponents/components/selectDropdown/selectDropdown";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  classes: {
    paper: "selectable-tab-content-hours-row-inputs-menu",
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 116,
    },
  },
};

const [hours, hoursObj] = (() => {
  const hours = [];
  const hoursObject = {};
  for (let i = 0; i < 24; i++) {
    const hour = i % 12 || 12;
    const type = i < 12 ? "am" : "pm";
    const name = `${hour}:00 ${type}`;
    const id = i + 1;
    const bigEnd = i < 10 ? "0" + i : i;
    hours.push({ type, name, id, twentyFourHour: bigEnd + ":00" });
    hoursObject[id] = `${hour}:00${type}`;
    hours.push({
      type,
      name: `${hour}:30 ${type}`,
      id: i + 1.5,
      twentyFourHour: bigEnd + ":30",
    });
    hoursObject[i + 1.5] = `${hour}:30${type}`;
  }
  return [hours, hoursObject];
})();

// hourNumberToString will be used to convert the hour number to string. e.g 1 => 12:00, 1.5 => 12:30, ..., 24.5 => 23:30
export const hourNumberToString = (hour) => {
  let hourObj = "";
  hours.forEach((hourItem) => {
    if (hourItem?.id === hour) {
      hourObj = hourItem?.twentyFourHour;
      return;
    }
  });
  return hourObj;
};

// hourStringToNumber will be used to convert the hour string to number. e.g 12:00=> 1 , 12:30 => 1.5, ..., 23:30 => 24.5
export const hourStringToNumber = (hour) => {
  let hourNumber = 0;
  hours.forEach((hourItem) => {
    const hourString = hourItem?.twentyFourHour;
    if (hourString === hour) {
      hourNumber = hourItem?.id;
      return;
    }
  });
  return hourNumber;
};

// filterIntersectionsHours will be used to filter the hours for a select options,
// for example for the from select, we will ensure that it after all the previous hours
// so that the user can't select the same hour in the same day
const filterIntersectionsHours = (currRange, dayRanges, rangeType = "from") => {
  let filteredHours = hours.slice();
  dayRanges?.forEach((range) => {
    const { from, to } = range;
    if (from && to && from !== currRange?.from && to !== currRange?.to) {
      if (from < to) {
        // If 'from' is selected before 'to'
        filteredHours = filteredHours.filter(
          (hour) => hour?.id < from || hour?.id > to,
        );
      } else {
        // If 'to' is selected before 'from'
        filteredHours = filteredHours.filter(
          (hour) => hour?.id > to && hour?.id < from,
        );
      }
      if (currRange?.from && rangeType === "to" && to > currRange?.from) {
        filteredHours = filteredHours.filter(
          (hour) => hour?.id > currRange?.from && hour?.id < from,
        );
      }
      if (currRange?.to && rangeType === "from" && from < currRange?.to) {
        filteredHours = filteredHours.filter(
          (hour) => hour?.id > to && hour?.id < currRange?.to,
        );
      }
    }
  });
  // if from is selected, we will filter the to hours to be after the selected from hour
  if (currRange?.from && rangeType === "to") {
    filteredHours = filteredHours.filter((hour) => hour?.id > currRange?.from);
  } else if (currRange?.to && rangeType === "from") {
    // if to is selected, we will filter the from hours to be before the selected to hour
    filteredHours = filteredHours.filter((hour) => hour?.id < currRange?.to);
  }
  return filteredHours;
};

export const WorkingHoursInDay = ({
  dayName,
  dayRanges = [],
  setTabsData,
  handleAddRow,
  handleRemoveRow,
  tabsErrors,
  disabled = false,
}) => {
  const intl = useIntl();
  const lastRowValue = dayRanges?.[dayRanges?.length - 1];
  const handleChange = (value, index, rangeType) => {
    const newDayRanges = [...dayRanges];
    newDayRanges[index][rangeType] = value;
    setTabsData((prev) => ({
      ...prev,
      workingHours: {
        ...prev?.workingHours,
        [dayName]: newDayRanges,
      },
    }));
  };

  return (
    <>
      {dayRanges?.map((day, index) => {
        const hasError = tabsErrors?.workingHours;
        const bothError = dayRanges?.length === 1 && !day?.from && !day?.to;
        const toError = hasError && (bothError || (day?.from && !day?.to));
        const fromError = hasError && (bothError || (!day?.from && day?.to));
        return (
          <Box
            className="selectable-tab-content-hours-row"
            key={`${index}-${day?.from}`}
          >
            <Typography
              variant="body2"
              className="selectable-tab-content-hours-row-title"
            >
              {CheckValueLocale(dayName, "", {}, intl)}
            </Typography>
            <Box className="selectable-tab-content-hours-row-inputs">
              <Box className="selectable-tab-content-hours-row-inputs-wrapper">
                <SelectDropdown
                  disabled={disabled}
                  options={filterIntersectionsHours(day, dayRanges, "from")}
                  value={day?.from}
                  onChange={(value) =>
                    handleChange(value?.target?.value, index, "from")
                  }
                  label={CheckValueLocale("hour_from", "", {}, intl)}
                  className={classNames(
                    "selectable-tab-content-hours-row-input",
                    fromError && "select-error",
                  )}
                  MenuProps={MenuProps}
                  error={fromError}
                  renderValue={(value) => {
                    return hoursObj[value];
                  }}
                />
                {fromError ? (
                  <Typography
                    variant="body2"
                    className="selectable-tab-content-hours-row-inputs-helper"
                  >
                    {CheckValueLocale("topics_field_required", "", {}, intl)}
                  </Typography>
                ) : null}
              </Box>
              <Box className="selectable-tab-content-hours-row-inputs-wrapper">
                <SelectDropdown
                  disabled={disabled}
                  options={filterIntersectionsHours(day, dayRanges, "to")}
                  value={day?.to}
                  onChange={(value) =>
                    handleChange(value?.target?.value, index, "to")
                  }
                  label={CheckValueLocale("hour_to", "", {}, intl)}
                  className={classNames(
                    "selectable-tab-content-hours-row-input",
                    toError && "select-error",
                  )}
                  MenuProps={MenuProps}
                  error={toError}
                  renderValue={(value) => {
                    return hoursObj[value];
                  }}
                />
                {toError ? (
                  <Typography
                    variant="body2"
                    className="selectable-tab-content-hours-row-inputs-helper"
                  >
                    {CheckValueLocale("topics_field_required", "", {}, intl)}
                  </Typography>
                ) : null}
              </Box>
              {index === 0 ? (
                <LucButton
                  minWidth={32}
                  className="icon-tab-button"
                  disabled={
                    !lastRowValue?.from || !lastRowValue?.to || disabled
                  }
                  onClick={() => handleAddRow(dayName)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </LucButton>
              ) : (
                <LucButton
                  variant="outline"
                  type="red"
                  disabled={disabled}
                  minWidth={32}
                  className="icon-tab-button"
                  onClick={() => handleRemoveRow(dayName, index)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </LucButton>
              )}
            </Box>
          </Box>
        );
      })}
    </>
  );
};
